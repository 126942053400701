import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, makeStyles} from '@mui/material';
import Dropzone from 'react-dropzone'

import { UserCircle as UserCircleIcon } from '../../icons/user-circle';

import styles from './ContactDialog.module.css'

import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../store/cms-context';



const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));

function ContactDialog(props) {

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);


  const onSelect = (index, checked) => {

    const temp_variant_image = {...props.selected_variant_image}

    if(checked){
      temp_variant_image.image_index = index
    }else{
      temp_variant_image.image_index = -1
    }

    props.set_selected_variant_image(temp_variant_image)
    
  }

  function set_contact_state(key, value){
    
    const temp_state = {...props.contact_dialog_state}

    temp_state.contact[key] = value

    props.set_contact_dialog_state(temp_state)
    
  }

  function handle_contact(){

    const contact = {...props.contact_dialog_state.contact}


    const email = contact.email.trim()
    
    const phone = contact.phone.trim()

    if(email.length === 0 || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid email!")
      return
    }

    if(phone.length > 0 && !(/^\s*(?:\+?(\d{1,4}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(phone))){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid phone number!")
      return
    }else if(phone.length === 0 && (contact.daily_sales_whatsapp || contact.hourly_sales_whatsapp || contact.tagged_posts_whatsapp)){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid phone number, to enable Whatsapp notifications!")
      return
    }

    contact.email = email

    contact.phone = phone

    props.post_contact(contact, props.contact_dialog_state.type.toLowerCase())

  }

  

  function handle_close(){

    const temp_state = {...props.contact_dialog_state}

    temp_state.is_dialog_open = false

    props.set_contact_dialog_state(temp_state)

  }


  return (
    <div>
      <Dialog
        open={props.contact_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.contact_dialog_state.type} Contact
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

             <Grid
              item
              xs={12}
             
              >

                <Avatar
                    sx={{
                      height: 120,
                      width: 120,
                      ml: 'auto',
                      mr:'auto'
                    }}
                    src="/static/images/profile.png"
                  >
                    
                    <UserCircleIcon fontSize="small" />

                </Avatar>
                
              </Grid> 


              <Grid
              item
              xs={12}
              style={{marginTop:16}}
              >

                <TextField id="outlined-basic" disabled={props.contact_dialog_state.type === "Update"} placeholder='e.g. email@address.com' label="Email" variant="outlined" value={props.contact_dialog_state.contact.email} onChange={(event)=>{set_contact_state("email", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" placeholder='e.g. +44....' label="Phone number" variant="outlined" value={props.contact_dialog_state.contact.phone} onChange={(event)=>{set_contact_state("phone", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

            </Grid>

            <Grid
            item
            xs={12}
            >

              
            <FormGroup sx={{marginTop:2}}>
            <Typography fontSize={14}><strong>Daily Sales</strong></Typography>
              <div style={{margin:8}}>
              <FormControlLabel control={<CustomSwitch checked={props.contact_dialog_state.contact.daily_sales_email} onChange={(event) => {set_contact_state("daily_sales_email", event.target.checked)}}></CustomSwitch>} label="Email" />
              <FormControlLabel control={<CustomSwitch checked={props.contact_dialog_state.contact.daily_sales_whatsapp} onChange={(event) => {set_contact_state("daily_sales_whatsapp", event.target.checked)}}/>} label="Whatsapp" />
              </div>
            </FormGroup>
            <FormGroup style={{marginTop:8}}>
              <Typography fontSize={14}><strong>Hourly Sales</strong></Typography>
              <div style={{margin:8}}>
              <FormControlLabel control={<CustomSwitch checked={props.contact_dialog_state.contact.hourly_sales_email} onChange={(event) => {set_contact_state("hourly_sales_email", event.target.checked)}}/>} label="Email" />
              <FormControlLabel control={<CustomSwitch checked={props.contact_dialog_state.contact.hourly_sales_whatsapp} onChange={(event) => {set_contact_state("hourly_sales_whatsapp", event.target.checked)}}/>} label="Whatsapp" />
              </div>
            </FormGroup>

            <FormGroup style={{marginTop:8}}>
              <Typography fontSize={14}><strong>Tagged Messages</strong></Typography>
              <div style={{margin:8}}>
             { /*<FormControlLabel control={<CustomSwitch checked={props.contact_dialog_state.contact.tagged_messages_email} onChange={(event) => {set_contact_state("tagged_messages_email", event.target.checked)}}/>} label="Email" /> */}
              <FormControlLabel control={<CustomSwitch checked={props.contact_dialog_state.contact.tagged_posts_whatsapp} onChange={(event) => {set_contact_state("tagged_posts_whatsapp", event.target.checked)}}/>} label="Whatsapp" />
              </div>
            </FormGroup>

            </Grid>


            
        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        <LoadingButton variant='outlined' loading={props.contact_dialog_state.isLoading} disabled={false} onClick={handle_contact}>{props.contact_dialog_state.type}</LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default ContactDialog;