import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme/theme';
import App from './App'
import {BrowserRouter} from  'react-router-dom'
import { CssBaseline } from '@mui/material';
import { CmsContextProvider } from './store/cms-context'


ReactDOM.render(
  
  <CmsContextProvider>

    <BrowserRouter>
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <App></App>
    </ThemeProvider>
    </BrowserRouter>
    
  </CmsContextProvider>
 ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();




// npx generate-react-cli component NestedList