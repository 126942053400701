import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, FormControl, InputLabel, Select, MenuItem, List, Container, ListItem, IconButton} from '@mui/material';

import Dropzone from 'react-dropzone'

import CardTravelIcon from '@mui/icons-material/CardTravel';

import { UserCircle as UserCircleIcon } from '../../../../icons/user-circle';

import DeleteIcon from '@mui/icons-material/Delete';

import FolderIcon from '@mui/icons-material/Folder';


import ListItemAvatar from '@mui/material/ListItemAvatar';

import ListItemText from '@mui/material/ListItemText';

import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../../../store/cms-context';

import Inventory2Icon from '@mui/icons-material/Inventory2';




const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));


function PreorderGoalDialog(props) {

  console.log("preoder goal dialog rendered")

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);


  const onSelect = (index, checked) => {

    const temp_variant_image = {...props.selected_variant_image}

    if(checked){
      temp_variant_image.image_index = index
    }else{
      temp_variant_image.image_index = -1
    }

    props.set_selected_variant_image(temp_variant_image)
    
  }

  function set_preorder_goal_state(key, value) {
    
    const temp_state = {...props.preorder_goal_dialog_state}

    temp_state.preorder_goal[key] = value

    props.set_preorder_goal_dialog_state(temp_state)
    
  }


  function parseOrKeep(value) {
    if (typeof value === 'string') {
      return parseInt(value);
    }
    return value;
  }


  function handle_preorder_goal() {
    
    const preorder_goal = {...props.preorder_goal_dialog_state.preorder_goal}
    
    if(preorder_goal.product.trim().length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid product!")
      return
    }

    if(preorder_goal.variants.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please add valid variants!")
      return
    }

    console.log("preorder goal was: ", preorder_goal)

    const kind = preorder_goal.selected_kind

    const selected_warehouse = preorder_goal.selected_warehouse

    const product = preorder_goal.product

    const variants = preorder_goal.variants

    const payload = {"kind": kind, "warehouse": selected_warehouse, "product": product, "variants": variants}

    var is_valid = true;

    variants.forEach((variant) => {

      if(variant.quantity.toString().trim().length === 0){
        is_valid = false;
      }else{
        variant.quantity = parseOrKeep(variant.quantity)
        if(variant.quantity < 0){
          is_valid = false
        }
      }
      
    })
    
    if(!is_valid){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid quantity for each SKU!")
      return
    }

    if(preorder_goal.id){

      payload.id = preorder_goal.id

    }

    props.post_preorder_goal(payload, props.preorder_goal_dialog_state.type)

  }



  

  function handle_close(){

    const temp_state = {...props.preorder_goal_dialog_state}

    temp_state.is_dialog_open = false

    props.set_preorder_goal_dialog_state(temp_state)

  }

  function handleDelete(){

    

  }


  return (
    <div>
      <Dialog
        open={props.preorder_goal_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.preorder_goal_dialog_state.type} Target
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

             <Grid
              item
              xs={12}
             
              >

                <Avatar

                    sx={{
                        height: 120,
                        width: 120,
                        ml: 'auto',
                        mr:'auto'
                    }}
                    src="/static/images/preorder.png"
                    >

                </Avatar>
                
              </Grid> 


              <Grid
              item
              xs={12}
              style={{marginTop:16}}
              >

                <TextField id="outlined-basic" placeholder='e.g. SNAG-30-LUX-LIQUORICE' label="Product" variant="outlined" value={props.preorder_goal_dialog_state.preorder_goal.product} onChange={(event) => {set_preorder_goal_state("product", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>
                
              </Grid>

              {Object.keys(props.preorder_goal_dialog_state.preorder_goal.kinds).length > 0 ? <Grid item xs={12}>

              <FormControl sx={{width: '100%'}}>

              <InputLabel id="demo-simple-select-autowidth-label">Kind</InputLabel>

              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                fullWidth
                value={props.preorder_goal_dialog_state.preorder_goal.selected_kind}
                onChange={(event) => {const temp_dialog_state = {...props.preorder_goal_dialog_state}; temp_dialog_state.preorder_goal.selected_kind = event.target.value; props.set_preorder_goal_dialog_state(temp_dialog_state);}}
                label="Kind"
              >

                {props.preorder_goal_dialog_state.preorder_goal.kinds.map((preorder_goal, index) => (
                  <MenuItem key={index} value={preorder_goal}>{preorder_goal}</MenuItem>
                ))}
                
              </Select>

              </FormControl>

              </Grid> : null }

              {Object.keys(props.preorder_goal_dialog_state.preorder_goal.warehouses).length > 0 ? <Grid item xs={12}>

              <FormControl sx={{ width:'100%'}}>

              <InputLabel id="demo-simple-select-autowidth-label">Warehouse</InputLabel>

              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                fullWidth
                value={props.preorder_goal_dialog_state.preorder_goal.selected_warehouse}
                onChange={(event) => {const temp_dialog_state = {...props.preorder_goal_dialog_state}; temp_dialog_state.preorder_goal.selected_warehouse = event.target.value; props.set_preorder_goal_dialog_state(temp_dialog_state);}}
                label="Warehouse"
              >

                {props.preorder_goal_dialog_state.preorder_goal.warehouses.map((warehouse, index) => (
                  <MenuItem key={index} value={warehouse}>{warehouse}</MenuItem>
                ))}
                
              </Select>

              </FormControl>

              </Grid> : null }

            <Grid
            item
            sx={{marginTop:2}}
            xs={12}>

            <Grid
            container
            spacing={3}
            alignItems="center"
            >

             <Grid
              item
              xs={12}
             
              >

                <Avatar
                    sx={{
                      height: 120,
                      width: 120,
                      ml: 'auto',
                      mr:'auto'
                    }}
                    src="/static/images/product_variant.png"
                  >
                    
                    <UserCircleIcon fontSize="small" />

                </Avatar>
                
              </Grid> 


              <Grid
              item
              xs={12}
              style={{marginTop:16}}
              >

                <TextField id="outlined-basic" label="Name" variant="outlined" value={props.preorder_goal_dialog_state.preorder_goal.variant_name} onChange={(event) => {set_preorder_goal_state("variant_name", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>
              

              <Grid
              item
              xs={12}
              >

                <Button variant="contained" fullWidth onClick = {() => {const temp_state = {...props.preorder_goal_dialog_state}; var variant_name = props.preorder_goal_dialog_state.preorder_goal.variant_name.trim(); if(variant_name.length === 0) {cmsContext.setSnackBarOpen(true, "error", "Please enter a valid SKU!"); return;}; temp_state.preorder_goal.variants.push({"name": props.preorder_goal_dialog_state.preorder_goal.variant_name, "quantity": 0}); temp_state.preorder_goal.variant_name = ''; props.set_preorder_goal_dialog_state(temp_state);}}>Add</Button>

              </Grid>

              <Grid
              item
              xs={12}
              > 

               <Grid
               container
               >

                {props.preorder_goal_dialog_state.preorder_goal.variants.map((variant, index) => {

                  return(

                  <React.Fragment key={index}>

                  <Grid
                  item
                  xs={6}
                  sx={{marginTop:2}}
                  >

                    <div style={{display:'flex'}}>

                      <Avatar alt="Remy Sharp" src="/static/images/logistics.png" />

                      <Typography sx={{marginTop: 1, marginLeft: 2}} color="black" variant="button">{variant.name}</Typography>

                    </div>

                  </Grid>

                  <Grid
                  item
                  xs={6}
                  sx={{marginTop:2}}
                  >

                    <div style={{'textAlign': 'right'}}>

                      <TextField
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={variant.quantity}
                        onChange={(event) => {const temp_state = {...props.preorder_goal_dialog_state}; temp_state.preorder_goal.variants[index].quantity = event.target.value; temp_state.preorder_goal.variant_name = ''; props.set_preorder_goal_dialog_state(temp_state);}}
                        style={{ width: "80px" }} 
                        size="small"
                        variant="outlined"
                      />

                      <IconButton aria-label="delete" onClick={() => {const temp_state = {...props.preorder_goal_dialog_state}; temp_state.preorder_goal.variants.splice(index, 1); temp_state.preorder_goal.variant_name = ''; props.set_preorder_goal_dialog_state(temp_state);}}>
                          <DeleteIcon />
                      </IconButton>

                    </div>

                  </Grid>

                  </React.Fragment>

                  )

                })


                }

                </Grid>

              </Grid>

            </Grid>

            </Grid>

            </Grid>

            
        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        <LoadingButton variant='outlined' loading={props.preorder_goal_dialog_state.isLoading} disabled={false} onClick={() => {handle_preorder_goal()}}>{props.preorder_goal_dialog_state.type}</LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default React.memo(PreorderGoalDialog);