import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, makeStyles, Autocomplete, FormControl, InputAdornment, OutlinedInput, InputLabel} from '@mui/material';
import Dropzone from 'react-dropzone'

import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../../../../store/cms-context';
import { CurrenciesList } from '../../../../../Utilities/CurrenciesInfo';



const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));

function RateDialog(props) {

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);


  const onSelect = (index, checked) => {

    const temp_variant_image = {...props.selected_variant_image}

    if(checked){
      temp_variant_image.image_index = index
    }else{
      temp_variant_image.image_index = -1
    }

    props.set_selected_variant_image(temp_variant_image)
    
  }

  function set_rate_state(key, value){
    
    const temp_state = {...props.rate_dialog_state}

    temp_state.rate[key] = value

    props.set_rate_dialog_state(temp_state)
    
  }

  function handle_rate(){

    var rate = {...props.rate_dialog_state.rate}

    if(rate.source_currency.constructor === Object && rate.target_currency.constructor === Object){

      const source_currency = rate.source_currency.code;

      const target_currency = rate.target_currency.code;

      const value = isNaN(parseFloat(rate.value)) ? -1 : parseFloat(rate.value);

      console.log("value was: ", value)

      if(value <= 0){

        cmsContext.setSnackBarOpen(true, "error", "Please enter valid values for all fields")
  
        return
  
      }
      
      const final_rate = {'source_currency': source_currency, 'target_currency': target_currency, 'rate': value}

      if(rate.id){
        final_rate.id = rate.id
      }

      console.log("all checks passed")
  
      props.post_rate(final_rate, props.rate_dialog_state.type)


    }else{

      cmsContext.setSnackBarOpen(true, "error", "Please enter valid values for all fields")
      return

    }

  }

  

  function handle_close(){

    const temp_state = {...props.rate_dialog_state}

    temp_state.is_dialog_open = false

    props.set_rate_dialog_state(temp_state)

  }


  return (
    <div>
      <Dialog
        open={props.rate_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.rate_dialog_state.type} Rate
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

             <Grid
              item
              xs={12}
              >
                <div style={{width: "100%", display: 'flex',justifyContent: 'center'}}>

                <img
                alt="currency_conversion"
                style={{
                  width:120,
                  height:120
                }}
                  src="/static/images/money-exchange.png"
                  >
                </img>    

                </div>     
                
              </Grid> 


              <Grid
              item
              xs={12}
              >

                <Autocomplete
                    id="product"
                    options={CurrenciesList}
                    getOptionLabel={(option) => option.name ? option.name : ''}
                    value={props.rate_dialog_state ? props.rate_dialog_state.rate.source_currency : ''}
                    isOptionEqualToValue={(option, value) => option.code === value.code }
                    onChange={(event, newValue) => {

                      console.log("change called: ", newValue )

                      const temp_state = {...props.rate_dialog_state}

                      temp_state.rate.source_currency = newValue

                      props.set_rate_dialog_state(temp_state)

                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.code}>
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} label="Source Currency" placeholder="Source Currency" />
                    )}
              />   

              </Grid>

              <Grid
              item
              xs={12}
              >

                <Autocomplete
                    id="product"
                    options={CurrenciesList}
                    getOptionLabel={(option) => option.name ? option.name : ''}
                    value={props.rate_dialog_state ? props.rate_dialog_state.rate.target_currency : ''}
                    isOptionEqualToValue={(option, value) => option.code === value.code }
                    onChange={(event, newValue) => {

                      console.log("change called: ", newValue )

                      const temp_state = {...props.rate_dialog_state}

                      temp_state.rate.target_currency = newValue

                      props.set_rate_dialog_state(temp_state)

                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.code}>
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} label="Target Currency" placeholder="Target Currency" />
                    )}
              />   

              </Grid>

              <Grid
              item
              xs={12}
              md={12}
            >

            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-discount">Rate</InputLabel>
              <OutlinedInput
                id="outlined-adornment-rate"
                value={props.rate_dialog_state.rate ? props.rate_dialog_state.rate.value : ''}
                type="number"
                onChange={(event) => {

                    const temp_state = {...props.rate_dialog_state}

                    temp_state.rate.value = event.target.value

                    console.log("new state was: ", temp_state)

                    props.set_rate_dialog_state(temp_state)

                }}
               
                label="Rate"
              />
            </FormControl>

            </Grid>

            </Grid>

        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        <LoadingButton variant='outlined' loading={props.rate_dialog_state.isLoading} disabled={false} onClick={handle_rate}>{props.rate_dialog_state.type}</LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default RateDialog;