import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, FormControl, InputLabel, Select, MenuItem, List, Container, ListItem, IconButton, Chip} from '@mui/material';

import Dropzone from 'react-dropzone'

import CardTravelIcon from '@mui/icons-material/CardTravel';

import { UserCircle as UserCircleIcon } from '../../../../icons/user-circle';

import DeleteIcon from '@mui/icons-material/Delete';

import FolderIcon from '@mui/icons-material/Folder';

import ListItemAvatar from '@mui/material/ListItemAvatar';

import ListItemText from '@mui/material/ListItemText';

import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../../../store/cms-context';

import Inventory2Icon from '@mui/icons-material/Inventory2';

import CurrenciesInfo from '../../../../Utilities/CurrenciesInfo';


const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));


function MintsoftOrderSplitDialog(props) {

  console.log("mintsoft dialog rendered: ", props.mintsoft_order_split_target_dialog_state)

  const cmsContext = React.useContext(CmsContext);


  function set_mintsoft_order_split_target(key, value) {
    
    const temp_state = {...props.mintsoft_order_split_target_dialog_state}

    temp_state.mintsoft_order_split_target[key] = value

    props.set_mintsoft_order_split_target_dialog_state(temp_state)
    
  }



  function handle_mintsoft_order_split_target() {
    
    const mintsoft_order_split_target = {...props.mintsoft_order_split_target_dialog_state.mintsoft_order_split_target}
    
    if(mintsoft_order_split_target.selected_client.trim().length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid mintsoft client!")
      return
    }

    if(!mintsoft_order_split_target.selected_currency || mintsoft_order_split_target.selected_currency.code == null){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid currency!")
      return
    }

    const threshold = parseFloat(mintsoft_order_split_target.threshold.toString().trim()) || 0;

    if(threshold < 1){

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid threshold value!")
      return

    }

    console.log("mintsoft split was: ", mintsoft_order_split_target)

    const client = mintsoft_order_split_target.selected_client

    const currency = mintsoft_order_split_target.selected_currency.code

    const payload = {"client": client, "currency": currency, "threshold": threshold, "omitted_tags": mintsoft_order_split_target.omitted_tags}

    if(mintsoft_order_split_target.id) {

      payload.id = mintsoft_order_split_target.id

    }

    console.log("payload was: ", payload)

    props.post_mintsoft_order_split_target(payload, props.mintsoft_order_split_target_dialog_state.type)

  }


  function handle_close(){

    const temp_state = {...props.mintsoft_order_split_target_dialog_state}

    temp_state.is_dialog_open = false

    props.set_mintsoft_order_split_target_dialog_state(temp_state)

  }

  function handle_tag_delete(index) {
    
    const temp_dialog_state = {...props.mintsoft_order_split_target_dialog_state}

    temp_dialog_state.mintsoft_order_split_target.omitted_tags.splice(index, 1)

    props.set_mintsoft_order_split_target_dialog_state(temp_dialog_state)

  }


  return (

      <Dialog
        open={props.mintsoft_order_split_target_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.mintsoft_order_split_target_dialog_state.type} Target
        </DialogTitle>

        <DialogContent dividers>

          <Grid
            container
            spacing={3}
            alignItems="center"
            >

             <Grid
              item
              xs={12}
             
              >

                <Avatar

                    sx={{
                        height: 120,
                        width: 120,
                        ml: 'auto',
                        mr:'auto'
                    }}
                    src="/static/images/mintsoft.png"
                    >

                </Avatar>
                
              </Grid> 


              {Object.keys(props.all_mintsoft_clients).length > 0 ? <Grid item xs={12}>

              <FormControl sx={{width: '100%'}}>

              <InputLabel id="demo-simple-select-autowidth-label">Mintsoft Client</InputLabel>

              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                fullWidth
                value={props.mintsoft_order_split_target_dialog_state.mintsoft_order_split_target.selected_client}
                onChange={(event) => {const temp_dialog_state = {...props.mintsoft_order_split_target_dialog_state}; temp_dialog_state.mintsoft_order_split_target.selected_client = event.target.value; props.set_mintsoft_order_split_target_dialog_state(temp_dialog_state);}}
                label="Mintsoft Client"
              >

                {props.all_mintsoft_clients.map((mintsoft_client, index) => (
                  <MenuItem key={index} value={mintsoft_client.short_name}>{mintsoft_client.short_name}</MenuItem>
                ))}
                
              </Select>

              </FormControl>

              </Grid> : null }

              <Grid item xs={12}>

              <FormControl sx={{ width:'100%'}}>

              <InputLabel id="demo-simple-select-autowidth-label">Currency</InputLabel>

              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                fullWidth
                value={props.mintsoft_order_split_target_dialog_state.mintsoft_order_split_target.selected_currency}
                onChange={(event) => {const temp_dialog_state = {...props.mintsoft_order_split_target_dialog_state}; temp_dialog_state.mintsoft_order_split_target.selected_currency = event.target.value; props.set_mintsoft_order_split_target_dialog_state(temp_dialog_state);}}
                label="Currency"
              >

                {Object.keys(CurrenciesInfo).map((currency, index) => (
                  <MenuItem key={index} value={CurrenciesInfo[currency]}>{CurrenciesInfo[currency].name}</MenuItem>
                ))}
                
              </Select>

              </FormControl>

              </Grid>

              <Grid
                  item
                  xs={12}
              >

                <TextField
                  type="number"
                  label="Threshold"
                  value={props.mintsoft_order_split_target_dialog_state.mintsoft_order_split_target.threshold}
                  onChange={(event) => {const temp_state = {...props.mintsoft_order_split_target_dialog_state}; temp_state.mintsoft_order_split_target.threshold = event.target.value; props.set_mintsoft_order_split_target_dialog_state(temp_state);}}
                  fullWidth
                  variant="outlined"
                />

              </Grid>


              <Grid
                  item
                  xs={12}
              >

                <Grid container spacing={1}>

                  <Grid
                  item
                  xs={12}
                  >

                    <Typography fontSize={12}>

                      <strong>

                      Omitted Tags

                      </strong>

                    </Typography>

                  </Grid>

                  <Grid item xs={9}>

                  <TextField
                  size='small'
                  fullWidth
                  value={props.mintsoft_order_split_target_dialog_state.mintsoft_order_split_target.omitted_tags_text_field}
                  onChange={(event) => {

                    const temp_state = {...props.mintsoft_order_split_target_dialog_state}

                    temp_state.mintsoft_order_split_target.omitted_tags_text_field = event.target.value

                    props.set_mintsoft_order_split_target_dialog_state(temp_state)

                  }
                  }
                  >

                  </TextField>

                  </Grid>

                  <Grid
                  item
                  xs={3}
                  >

                    <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={() => {

                      const temp_dialog_state = {...props.mintsoft_order_split_target_dialog_state}
                      
                      var temp_val = temp_dialog_state.mintsoft_order_split_target.omitted_tags_text_field

                      temp_val = temp_val.trim()

                      if(temp_val.length < 1){

                        cmsContext.setSnackBarOpen(true, "error", "Please enter a valid tag value!")

                        return

                      }

                      temp_dialog_state.mintsoft_order_split_target.omitted_tags.push(temp_val)

                      temp_dialog_state.mintsoft_order_split_target.omitted_tags_text_field = ""

                      console.log("setting dialog state: ", temp_dialog_state)

                      props.set_mintsoft_order_split_target_dialog_state(temp_dialog_state)

                    }}
                    >
                      
                      Add

                    </Button>
                    
                  </Grid>

                  {props.mintsoft_order_split_target_dialog_state.mintsoft_order_split_target.omitted_tags.map((tag, index) => {

                    return <Grid key={index} item> <Chip label={tag} color='primary' onDelete={() => {handle_tag_delete(index)}} /></Grid>

                  })}

                </Grid>

        
              </Grid>


            </Grid>

        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        <LoadingButton variant='outlined' loading={props.mintsoft_order_split_target_dialog_state.isLoading} disabled={false} onClick={() => {handle_mintsoft_order_split_target()}}>{props.mintsoft_order_split_target_dialog_state.type}</LoadingButton>
        </DialogActions>
      </Dialog>

  );
}


export default React.memo(MintsoftOrderSplitDialog);