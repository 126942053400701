import React, { useState, useRef, useEffect, useContext, useCallback } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../store/cms-context';

import { getAccessJwtToken } from '../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import cloneDeep from 'lodash/cloneDeep';

import FacebookTaggedListTable from "../FacebookTaggedListTable/FacebookTaggedListTable";

import FacebookDialog from "../FacebookDialog/FacebookDialog";

import ToggleButtons from "../ToggleButtons/ToggleButtons";

import FacebookCountryBreakdownListTable from "./FacebookCountryBreakdownListTable";

import FacebookAdGroupDialog from "../FacebookAdGroupDialog/FacebookAdGroupDialog";
import { default_ad_group_dialog } from "../../Utilities/Metrics";



const default_spend = 25;

function FacebookCountryBreakDowns(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_facebook_breakdowns, set_all_facebook_breakdowns] = React.useState([]);

  const [facebook_dialog_state, set_facebook_dialog_state] = React.useState({facebook: {}, is_dialog_open: false, is_dialog_loading: false});

  const [facebook_ad_group_dialog_state, set_facebook_ad_group_dialog_state] = React.useState(cloneDeep(default_ad_group_dialog));

  const [next_token, set_next_token] = React.useState({});

  const [timestampOrder, setTimestampOrder] = React.useState('descending');

  const [hasMore, setHasMore] = React.useState(false);

  const [calendar_open, set_calendar_open] = React.useState(false);

  const [time_range, set_time_range] = React.useState('');

  const [ranges_button_text, set_ranges_button_text] = React.useState('Time range');

  const [QueryExecutionId, setQueryExecutionId] = React.useState('');
  
  const [selected_country, set_selected_country] = React.useState('All Countries');

  const [filter_spend, set_filter_spend] = React.useState(default_spend);

  const [filter_title, set_filter_title] = React.useState('');

  const [filter_headline, set_filter_headline] = React.useState('');

  const [filter_description, set_filter_description] = React.useState('');

  useEffect(() =>{

    console.log("selected country was: ", selected_country)

  },[selected_country])

  useEffect(() => {

    isMountedRef.current = true

    get_all_facebook_breakdowns(selected_country, time_range, timestampOrder, QueryExecutionId, next_token, filter_spend, filter_title, filter_headline, filter_description)

    return () => isMountedRef.current = false;

  }, [])



  
  function handle_timestamp_order_change(tOrder){

    setTimestampOrder(tOrder)

    set_all_facebook_breakdowns([])

    setQueryExecutionId('')

    set_next_token('')
    
    get_all_facebook_breakdowns(selected_country, time_range, tOrder, '', '', '', '', '', '', '')

  }


  function show_create_facebook_dialog(){
    
    const temp_dialog_state = cloneDeep({});

    temp_dialog_state.is_dialog_open = true

    set_facebook_dialog_state(temp_dialog_state);

  }


  function onDateChange(ranges) {
    // ranges ...
   // alert("changed check the console log");

   if(ranges.startDate && ranges.endDate){

    set_ranges_button_text(ranges.startDate.toISOString().split('T')[0] + " - " + ranges.endDate.toISOString().split('T')[0])

    set_time_range(ranges.startDate.toISOString().split('T')[0] + "-to-" + ranges.endDate.toISOString().split('T')[0])

   }

    console.log("ranges: ", ranges);

  };

  function clear_time_range(){

    console.log("clear time range called")

    set_time_range('')

    set_ranges_button_text('Time range')

    get_all_facebook_breakdowns(selected_country, '', timestampOrder, '', '', filter_spend, filter_title, filter_headline, filter_description)

  }

  function clearSearch() {

    set_all_facebook_breakdowns([])

    set_time_range('')
    
    set_selected_country('All Countries')

    set_filter_spend('')

    set_filter_title('')

    set_ranges_button_text('Time range')

    setQueryExecutionId('')

    set_next_token('')

    get_all_facebook_breakdowns('', '', '', '', '', '', '', '', '', '')

  }

  function perform_search() {

    set_all_facebook_breakdowns([])

    get_all_facebook_breakdowns(selected_country, time_range, timestampOrder, '', '', filter_spend, filter_title, filter_headline, filter_description)

  }



  const post_ad_group = useCallback((post_fb_ad_gp_dialog_state, all_facebook_breakdowns, facebook_ad_group_dialog_state) => {

    var request_body = {}

    const ad_group_dialog_state = {...post_fb_ad_gp_dialog_state}

    const request_type = ad_group_dialog_state.type

    console.log("ad group dialog state from function was: ", ad_group_dialog_state)

    if(ad_group_dialog_state.adgroup.name.trim().length === 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid ad group name!")
      return

    }

    const ads = []

    // continue here: sometimes is_checked doesn't works

    // reset is_checked whenever dialog closed or any other main operation

    all_facebook_breakdowns.forEach((ad) => {

      if(ad.is_checked){

        ads.push({'id':ad.ad_id, 'image_hash': ad.image_hash})

      }

    })

    console.log("ads were: ", ads)

    if(ads.length === 0){

      cmsContext.setSnackBarOpen(true, "error", "Please select valid ads!")
      return

    }

    request_body.ad_group = ad_group_dialog_state.adgroup

    request_body.request_type = request_type

    request_body.ads = ads

    console.log('body was: ', request_body)


    toggle_ad_group_dialog(true, true, facebook_ad_group_dialog_state)

    
    getAccessJwtToken().then((jwt) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/facebook/ad_groups', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt
          },
          body: JSON.stringify(request_body)
          })
          .then(res => res.json())
          .then(
            (result) => {

              if(result.message){

                const temp_breakdowns = [...all_facebook_breakdowns]

                temp_breakdowns.forEach((breakdown) => {
                  breakdown.is_checked = false
                })

                set_all_facebook_breakdowns(temp_breakdowns)
                toggle_ad_group_dialog(false, false, facebook_ad_group_dialog_state)
                cmsContext.setSnackBarOpen(true, "success", result.message)

              }else if(result.errorMessage){

                toggle_ad_group_dialog(true, false, facebook_ad_group_dialog_state)
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)

              }else{

                toggle_ad_group_dialog(true, false, facebook_ad_group_dialog_state)
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error: ', result)

              }
  
            },
        
            (error) => {

              toggle_ad_group_dialog(true, false, facebook_ad_group_dialog_state)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was: ', error)

            }
          
      )

    })

  }, []);



  return(

    <>
   

   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
 
  >

    
    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Facebook Ads Country Breakdown</Typography>

          {/* <div>

          <ToggleButtons timestampOrder={timestampOrder} handle_timestamp_order_change={handle_timestamp_order_change}></ToggleButtons>

          </div> : null */}

        </Stack>

      </Grid>



      <Grid item xs={12}>

        <FacebookCountryBreakdownListTable clear_time_range={clear_time_range} filter_title={filter_title} set_filter_title={set_filter_title} filter_headline={filter_headline} set_filter_headline={set_filter_headline} filter_description={filter_description} set_filter_description={set_filter_description} filter_spend={filter_spend} set_filter_spend={set_filter_spend} selected_country={selected_country} set_selected_country={set_selected_country} clearSearch={clearSearch} ranges_button_text={ranges_button_text} perform_search={perform_search} onDateChange={onDateChange} calendar_open={calendar_open} set_calendar_open={set_calendar_open} timestampOrder={timestampOrder} handle_timestamp_order_change={handle_timestamp_order_change} set_dialog={set_dialog} all_facebook_breakdowns={all_facebook_breakdowns} set_all_facebook_breakdowns={set_all_facebook_breakdowns} show_ad_group_dialog={show_ad_group_dialog}></FacebookCountryBreakdownListTable>
        
      </Grid>



      <Grid item xs={12}>

      {isLoading ? <div style={{textAlign:'center', marginTop:40}}> <CircularProgress /> </div> : null}

      </Grid>

      <Grid item xs={12}>

      <div style={{width:'100%', textAlign:'center'}}>
      {hasMore && !isLoading ? <Button variant="contained" onClick = {() => {get_all_facebook_breakdowns(selected_country, time_range, timestampOrder, QueryExecutionId, next_token, filter_spend, filter_title, filter_headline, filter_description)}}>Load more</Button> : null}

      </div>
      </Grid>

    </Grid>
        
    </Container>

    <FacebookDialog facebook_dialog_state={facebook_dialog_state} set_facebook_dialog_state={set_facebook_dialog_state}></FacebookDialog>

    <FacebookAdGroupDialog post_ad_group={post_ad_group} all_facebook_breakdowns={all_facebook_breakdowns} facebook_ad_group_dialog_state={facebook_ad_group_dialog_state} set_facebook_ad_group_dialog_state={set_facebook_ad_group_dialog_state}></FacebookAdGroupDialog>

    </Box>


    </> 


  );




 

  function set_dialog(facebook, is_open) {

    toggle_dialog(true, true, facebook)

    getAccessJwtToken().then((jwt) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/facebook/breakdowns', {
          crossDomain: true,
          method: 'GET',
          headers: {
            'Authorization':jwt,
            'image_hash': facebook.image_hash
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
              toggle_dialog(true, false, facebook)
  
              if(result.breakdowns) {

                const breakdown = result.breakdowns[0]

                const dialog_state = {}

                dialog_state.facebook = cloneDeep(facebook)

                dialog_state.breakdown = breakdown

                dialog_state.is_dialog_open = is_open
            
                set_facebook_dialog_state(dialog_state)

              }else if(result.errorMessage) {

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else {

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {

              toggle_dialog(false, false, facebook)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
              
            }
           
      )

    })

  }

  function toggle_dialog(is_open, isLoading, facebook) {

    const dialog_state = {}

    dialog_state.is_dialog_open = is_open

    dialog_state.is_dialog_loading = isLoading

    dialog_state.facebook = cloneDeep(facebook)

    set_facebook_dialog_state(dialog_state)

  }


  async function get_picture_async(account_id, image_hash, creative_id, index){

    console.log('getting picture: ', account_id, " : image has : ", image_hash, index)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/facebook/images', {
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization': jwt,
            'source_account_id': account_id,
            'image_hash': image_hash,
            'creative_id': creative_id
          }})
          .then(res => res.json())
          .then(
            (result) => {

              console.log('result was: ', result)

              set_all_facebook_breakdowns((previous_breakdowns) => {

                const temp_breakdowns = [...previous_breakdowns]
          
                if(index < temp_breakdowns.length){

                  if(result.errorMessage){
                    temp_breakdowns[index].url = "error"
                  }else{
                    temp_breakdowns[index].url = result.url;
                  }
                  
                }
          
                return(set_all_facebook_breakdowns(temp_breakdowns))
          
              })
  
            },
        
            (error) => {
              console.log("Error")
            }
           
      )

    })


  }


  function get_all_facebook_breakdowns(selected_country, time_range, timestampOrder, QueryExecutionId , next_token, filter_spend, filter_title, filter_headline, filter_description) {

    console.log('executing get all tags')
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    if(filter_spend.toString().trim().length === 0){

      filter_spend = default_spend;

      set_filter_spend(default_spend)

    }

    console.log("filter spend: ", filter_spend)

    console.log("order was: ", timestampOrder, timestampOrder === 'ascending')

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/facebook/breakdowns', {
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':jwt,
            'NextToken': next_token,
            'QueryExecutionId': QueryExecutionId,
            'is_ascending': timestampOrder === 'ascending' ? "ASC" : "DESC",
            'selected_country': selected_country,
            'image_hash': '',
            'time_range': time_range,
            'filter_spend': filter_spend,
            'filter_title':filter_title.trim(), 
            'filter_headline':filter_headline.trim(),
            'filter_description':filter_description.trim()
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
              setIsLoading(false)

              if(result.breakdowns){

                console.log('setting analuytis')

                result.breakdowns.forEach((breakdown) =>{

                  breakdown.id = breakdown.ad_id + "_" + breakdown.country

                  breakdown.is_checked = false
                  
                })

                if(result.breakdowns.length === 0){

                  setHasMore(false)
                  cmsContext.setSnackBarOpen(true, "info", "No data yet. Wait for sync to complete.")

                }

                var temp_breakdowns = [...all_facebook_breakdowns]

                if(QueryExecutionId.length === 0){
                  temp_breakdowns = []
                }
                
                temp_breakdowns = temp_breakdowns.concat(result.breakdowns)

                console.log('setting all', temp_breakdowns)

                set_all_facebook_breakdowns(temp_breakdowns)
                

                for(var breakdown_index in temp_breakdowns){

                  if(temp_breakdowns[breakdown_index].account_id && temp_breakdowns[breakdown_index].image_hash){
         
                    get_picture_async(temp_breakdowns[breakdown_index].account_id, temp_breakdowns[breakdown_index].image_hash, temp_breakdowns[breakdown_index].creative_id, breakdown_index)

                  }

                }



                if(result.NextToken){

                  set_next_token(result.NextToken)

                  if(Object.keys(result.NextToken).length > 0){
                    setHasMore(true)
                  }else{
                    setHasMore(false)
                  }
                  
                }else{

                  setHasMore(false)
                  set_next_token('')

                }

                setQueryExecutionId(result.QueryExecutionId)

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
           
      )

    })

  }


  function toggle_ad_group_dialog(is_open, is_loading, facebook_ad_group_dialog_state) {
      
      const temp_state = {...facebook_ad_group_dialog_state}

      console.log("temp state was: ", temp_state)
  
      temp_state.is_dialog_open = is_open

      temp_state.is_dialog_loading = is_loading

      console.log("new dialog state was: ", temp_state)

      set_facebook_ad_group_dialog_state(temp_state)

  }

  function show_ad_group_dialog(type) {

      const temp_state = cloneDeep(default_ad_group_dialog)

      temp_state.is_dialog_open = true

      temp_state.type = type

      const temp_breakdowns = [...all_facebook_breakdowns]

      const selected_ads = []

      var invalid_image = false

      temp_breakdowns.forEach((ad) => {

        if(ad.is_checked){
  
          selected_ads.push(cloneDeep(ad))

          if(!ad.url){
            invalid_image = true
          }
  
        }
  
      })

      temp_state.ads = selected_ads
  
      if(selected_ads.length === 0 || invalid_image){
  
        cmsContext.setSnackBarOpen(true, "error", "Please select valid ads, or wait for images to load!")
        return
  
      }
  

      set_facebook_ad_group_dialog_state(temp_state)

  }


  function set_dialog_loading(is_loading) {  

    const temp_facebook_state = {...facebook_dialog_state}

    temp_facebook_state.isLoading = is_loading

    set_facebook_dialog_state(temp_facebook_state)

  }

  function reset_dialog_state(){

    set_facebook_dialog_state(cloneDeep({}))

  }

  function post_facebook(facebook, request_type) {

    console.log('updating')

    var request_body = {}

    request_body.facebook = facebook

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)

    set_dialog_loading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/facebook/breakdowns', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {

              set_dialog_loading(false)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }

  function delete_facebook_tags(){

    console.log('delete facebook_tags')

    var temp_facebook_tags = [...all_facebook_breakdowns].filter((facebook) => facebook.is_checked);

    console.log('filtered were', temp_facebook_tags)

    if(temp_facebook_tags.length === 0){
      // might never execute (need to select facebook_tags to display delete button)
      cmsContext.setSnackBarOpen(true, "error", "Please select valid facebook_tags!")
      return;
    }else if(temp_facebook_tags.length > 20){
      cmsContext.setSnackBarOpen(true, "error", "Please select a maximum of 20 facebook_tags!")
      return;
    }

    temp_facebook_tags = temp_facebook_tags.map((facebook) => {

      return({'id': facebook.id})

    })

    console.log('final facebook_tags were', temp_facebook_tags)

    const request_body = {}

    request_body.facebook_tags = temp_facebook_tags

    request_body.request_type = "delete"

    request_body.account_email = cmsContext.current_shop.email
    

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{


      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/facebook_breakdowns', {
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_all_facebook_breakdowns()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {
                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)
                }
          );
  
      });

  }


};


export default FacebookCountryBreakDowns;