import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../store/cms-context';

import { getAccessJwtToken } from '../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';


import cloneDeep from 'lodash/cloneDeep';


import FacebookTag from "./FacebookTag";

import FacebookTaggedListTable from "../FacebookTaggedListTable/FacebookTaggedListTable";

import FacebookDialog from "../FacebookDialog/FacebookDialog";
import ToggleButtons from "../ToggleButtons/ToggleButtons";



function FacebookTags(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_facebook_tags, set_all_facebook_tags] = React.useState([]);

  const [facebook_dialog_state, set_facebook_dialog_state] = React.useState({'facebook':{}, is_dialog_open:false});

  const [next_token, set_next_token] = React.useState({});

  const [timestampOrder, setTimestampOrder] = React.useState('descending');

  const [hasMore, setHasMore] = React.useState(false);

  const [filter_tags, set_filter_tags] = React.useState('')

  const [filter_user_name, set_filter_user_name] = React.useState('')

  const [calendar_open, set_calendar_open] = React.useState(false);

  const [time_range, set_time_range] = React.useState('');

  const [ranges_button_text, set_ranges_button_text] = React.useState('Time range')

  const [QueryExecutionId, setQueryExecutionId] = React.useState('');








  useEffect(() => {

    isMountedRef.current = true

    get_all_facebook_tags(filter_user_name, filter_tags, time_range, timestampOrder, QueryExecutionId ,next_token)

    return () => isMountedRef.current = false;

  }, [])



  function handle_timestamp_order_change(tOrder){

    setTimestampOrder(tOrder)

    set_all_facebook_tags([])

    setQueryExecutionId('')

    set_next_token('')
    
    get_all_facebook_tags(filter_user_name, filter_tags, time_range, tOrder, '', '')

  }





  function show_create_facebook_dialog(){
    
    const temp_dialog_state = cloneDeep({});

    temp_dialog_state.is_dialog_open = true

    set_facebook_dialog_state(temp_dialog_state);

  }


  function onDateChange(ranges) {
    // ranges ...
   // alert("changed check the console log");

   if(ranges.startDate && ranges.endDate){


    set_ranges_button_text(ranges.startDate.toISOString().split('T')[0] + " - " + ranges.endDate.toISOString().split('T')[0])

    set_time_range(ranges.startDate.toISOString().split('T')[0] + "-to-" + ranges.endDate.toISOString().split('T')[0])

   }

    console.log("ranges: ", ranges);

  };

  function clearSearch(){

    set_all_facebook_tags([])

    set_time_range('')

    set_ranges_button_text('Time range')

    set_filter_tags('')

    set_filter_user_name('')

    setQueryExecutionId('')

    set_next_token('')

    get_all_facebook_tags('', '', '', timestampOrder, '', '')

  }

  function perform_search(){

    set_all_facebook_tags([])

    get_all_facebook_tags(filter_user_name, filter_tags, time_range, timestampOrder, '', '')

  }



  return(

    <>
   

   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
 
  >

    
    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Facebook Tags</Typography>

          <div>

          <ToggleButtons timestampOrder={timestampOrder} handle_timestamp_order_change={handle_timestamp_order_change}></ToggleButtons>
          {/*<Button variant="contained" onClick = {() => {show_create_facebook_dialog()}}>Create Facebook</Button>*/}

          </div>

        </Stack>

      </Grid>




      <Grid item xs={12}>

        <FacebookTaggedListTable clearSearch={clearSearch} ranges_button_text={ranges_button_text} perform_search={perform_search} onDateChange={onDateChange} calendar_open={calendar_open} set_calendar_open={set_calendar_open} filter_user_name={filter_user_name} set_filter_user_name={set_filter_user_name} filter_tags={filter_tags} set_filter_tags={set_filter_tags} timestampOrder={timestampOrder} handle_timestamp_order_change={handle_timestamp_order_change} set_dialog={set_dialog}  all_facebook_tags={all_facebook_tags} set_all_facebook_tags={ () =>{}}></FacebookTaggedListTable>

      </Grid>



      <Grid item xs={12}>


      {isLoading ? <div style={{textAlign:'center', marginTop:40}}> <CircularProgress /> </div> : null}

      </Grid>

      <Grid item xs={12} >

      <div style={{width:'100%', textAlign:'center'}}>
      {hasMore && !isLoading ? <Button variant="contained" onClick = {() => {get_all_facebook_tags(filter_user_name, filter_tags, time_range, timestampOrder, QueryExecutionId,next_token)}}>Load more</Button> : null}

      </div>
      </Grid>

    </Grid>
        
    </Container> 

    <FacebookDialog facebook_dialog_state={facebook_dialog_state} set_facebook_dialog_state={set_facebook_dialog_state}></FacebookDialog>

    </Box>


    </> 


  );


  function refresh(){

    console.log('refresh called')

  }



 

  function set_dialog(facebook, is_open) {

    const dialog_state = {}

    dialog_state.facebook = cloneDeep(facebook)

    dialog_state.is_dialog_open = is_open

    set_facebook_dialog_state(dialog_state)

  }

  async function get_picture_async(key, index){

    console.log('getting picture: ', key, " : " , index)


    const picUrl = await Storage.get(key, {
      level: 'public',
      bucket: 'snag-cms175447-staging',
      expires:86400,
      region: 'eu-west-2',
    });

    console.log('url was', picUrl)

    set_all_facebook_tags((previous_tags) =>{

      const temp_tags = [...previous_tags]

      if(index < temp_tags.length){
        temp_tags[index].url = picUrl;
      }

      return(set_all_facebook_tags(temp_tags))

    })

  }

  function get_all_facebook_tags(filter_user_name, filter_tags, time_range, timestampOrder, QueryExecutionId ,next_token) {

    console.log('executing get all tags')
    
    console.log('store_access_token')

    console.log('filter tags were: ', filter_tags)

    // user api

    // error handling and state managment required

    setIsLoading(true)

    console.log("order was: ", timestampOrder, timestampOrder === 'ascending')

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/facebook/tags',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':jwt,
            'NextToken': next_token,
            'QueryExecutionId': QueryExecutionId,
            'is_ascending': timestampOrder === 'ascending' ? "ASC" : "DESC",
            'user_name': filter_user_name,
            'tags': filter_tags.replaceAll("'","cms_apostraphe"),
            'time_range': time_range
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
  
              setIsLoading(false)
  
              console.log('result was',result)

             
              if(result.tags){

                console.log('setting analuytis')

                result.tags.forEach((facebook) =>{
                  facebook.is_checked = false
                })

                if(result.tags.length === 0){

                  setHasMore(false)
                  cmsContext.setSnackBarOpen(true, "info", "No tags yet. Wait for sync to complete.")

                }

                var temp_tags = [...all_facebook_tags]

                if(QueryExecutionId.length === 0){
                  temp_tags = []
                }

                temp_tags = temp_tags.concat(result.tags)

                console.log('setting all', temp_tags)

                set_all_facebook_tags(temp_tags)


                for(var tag_index in temp_tags){

                  if((!temp_tags[tag_index].url)  && temp_tags[tag_index].s3_key && temp_tags[tag_index].s3_key.length > 0){
         
                    get_picture_async(temp_tags[tag_index].s3_key, tag_index)

                  }

                }



                if(result.NextToken){
                  set_next_token(result.NextToken)
                  if(Object.keys(result.NextToken).length > 0){
                  setHasMore(true)
                  }else{
                    setHasMore(false)
                  }
                  
                }else{
                  setHasMore(false)
                  set_next_token('')
                }

                setQueryExecutionId(result.QueryExecutionId)

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
           
      )

    })


  }


  function set_dialog_loading(is_loading){

    const temp_facebook_state = {...facebook_dialog_state}

    temp_facebook_state.isLoading = is_loading

    set_facebook_dialog_state(temp_facebook_state)

  }

  function reset_dialog_state(){

    set_facebook_dialog_state(cloneDeep({}))

  }

  function post_facebook(facebook, request_type){

    set_dialog_loading(true)

    console.log('updating')

    var request_body = {}

    request_body.facebook = facebook

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/facebook_tags', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)
                reset_dialog_state()

                get_all_facebook_tags()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                set_dialog_loading(false)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
                set_dialog_loading(false)

              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })



  }

  function delete_facebook_tags(){

    console.log('delete facebook_tags')

    var temp_facebook_tags = [...all_facebook_tags].filter((facebook) => facebook.is_checked);

    console.log('filtered were', temp_facebook_tags)

    if(temp_facebook_tags.length === 0){
      // might never execute (need to select facebook_tags to display delete button)
      cmsContext.setSnackBarOpen(true, "error", "Please select valid facebook_tags!")
      return;
    }else if(temp_facebook_tags.length > 20){
      cmsContext.setSnackBarOpen(true, "error", "Please select a maximum of 20 facebook_tags!")
      return;
    }

    temp_facebook_tags = temp_facebook_tags.map((facebook) => {

      return({'id': facebook.id})

    })

    console.log('final facebook_tags were', temp_facebook_tags)

    const request_body = {}

    request_body.facebook_tags = temp_facebook_tags

    request_body.request_type = "delete"

    request_body.account_email = cmsContext.current_shop.email
    

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{


      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/facebook_tags',{
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  console.log('result was',result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_all_facebook_tags()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {
                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)
                }
          );
  
      });

  }


};


export default FacebookTags;