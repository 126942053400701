import * as React from 'react';
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Button, Stack, Avatar, Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { key_ui_map_ql } from '../../../Utilities/Metrics';

import { UserCircle as UserCircleIcon } from '../../../icons/user-circle';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'variants',
    numeric: false,
    disablePadding: false,
    label: 'Variants',
  },
  {
    id: 'Active from',
    numeric: false,
    disablePadding: false,
    label: 'Active from',
  },
  {
    id: 'Active to',
    numeric: false,
    disablePadding: false,
    label: 'Active to',
  }
];

function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (

        <Stack direction="row"  sx={{ flex: '1 1 100%' }} alignItems="center">

        <Typography
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>

         {numSelected === 1 ? 
           <>

          <Button style={{marginLeft:8}}onClick={() => {props.handle_style_state("edit")}}>
            Edit
          </Button> 

          <Button style={{marginLeft:8}}onClick={() => {props.handle_style_state("create")}}>
            Create Product
          </Button> 

          {/*<Button onClick={() => {props.handle_style_state("deploy")}}>
            Deploy
         </Button> */}
          
          </>
          
          : null}

          </Stack>

      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        </Typography>
      )}

  
      {/*numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={(event) =>{props.delete_styles()}}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )*/}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

 function StylesListTable(props) {

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const rows = [];

  const selected = React.useMemo(() => {

    return(props.all_styles.filter((style) => style.is_checked).length)

  },[props.all_styles])



  console.log('table options were', props.all_styles)


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {

    const is_checked = event.target.checked;

    props.set_all_styles((previous_styles) => {

      var temp_styles = [...previous_styles]

      temp_styles = temp_styles.map((style) => {
  
        style.is_checked = is_checked;
  
        return(style);
  
      })

      console.log('settings styles', temp_styles)
  
      return(props.set_all_styles(temp_styles))

    })

  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  // Avoid a layout jump when reaching the last page with empty rows.

        
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    
  const handle_row_update = (index, type, value, optionNum) => {

    if(type === 'price' && value < 0){
      return;
    }

   return props.set_all_styles((previousStyles) => {

      const temp_options = [...previousStyles];

      console.log('temp options', temp_options,'num: ', optionNum)

      temp_options[index][type] = value;

      return(props.set_all_styles(temp_options))

    })

  }

  const handle_style_state = (type) => {

    if(type === "edit"){

    const style = props.all_styles.filter((style) => style.is_checked === true)

    if(style.length > 0){

      console.log('style was', style)

      props.set_dialog_style({...style[0]}, true)

    }else{

      console.log("Error getting style")
      
    }

  }else if(type === "create"){

    const style = props.all_styles.filter((style) => style.is_checked === true)

    if(style.length > 0){

      console.log('create style was', style)

      props.set_create_product_dialog({...style[0]}, true)

    }else{

      console.log("Error getting style")

    }

  }

  }


  

  return (
    <>
    {props.all_styles.length > 0 ? <Box sx={{ width: '100%'}} style={{marginTop:20}}>
      <Paper sx={{ width: '100%', mb: 2 }} >
        <EnhancedTableToolbar numSelected={selected} delete_styles={props.delete_styles} handle_style_state={handle_style_state} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.all_styles.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {props.all_styles.map((style, index) => {

                var conditions = "";

                if("style_rules" in style){

                  for(var i=0; i<style.style_rules.length; i++){

                    conditions = conditions + key_ui_map_ql[style.style_rules[i].column] + " " + key_ui_map_ql[style.style_rules[i].relation] + " " + style.style_rules[i].condition

                    if(i > 0 && i < style.rules.length - 1){
                      conditions = conditions + ", "
                    }

                  }

                }

                var url = "";

                if(style.image && style.image.url){

                  url = style.image.url

                }else{

                  url = "/static/images/brand_identity.png"
                  
                }

              return(

                <TableRow
                      hover
                    // onClick={(event) => handleClick(event, option_value.value)}
                      role="checkbox"
                      //aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                    // selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={style.is_checked}
                         onChange={(event) => {handle_row_update(index, "is_checked", event.target.checked)}}
                          /*
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          */
                        />
                      </TableCell>
                      <TableCell align="left" style={{paddingRight:0}}>
                      <Typography fontSize={12}><strong>{style.style.name}</strong></Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        //id={labelId}
                        scope="row"
                        style={{minWidth:200}}
                      >
                         <Typography fontSize={12}>{style.all_variants.length}</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{minWidth:200}}
                      >
                        <Typography fontSize={12}>{style.style.active_from}</Typography>
                      </TableCell>
                      <TableCell align="left">
                       <Typography fontSize={12}>{style.style.active_to}</Typography>
                      </TableCell>
                    </TableRow>
                      
                
                  );


                 
              })}
              
            </TableBody>
          </Table>
        </TableContainer>
    
      </Paper>
      
    </Box> : null}
    </>
  );
}


export default React.memo(StylesListTable);