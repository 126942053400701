import { Grid, Paper, Typography, Link, IconButton, Stack, CircularProgress} from '@mui/material';


import React, { useCallback, useContext, useEffect } from 'react';

import Dropzone from 'react-dropzone'

import {useDropzone} from 'react-dropzone';

import styles from '.././StorageImages.module.css'

import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { arrayMove } from 'react-sortable-hoc';

import CmsContext from '../../../store/cms-context';

import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";

import { getAccessJwtToken } from '../../../store/globalUtilities';

import { Reorder, getItemStyle, getoptionListStyle } from "./utils";

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box } from "@mui/system";
import { Divider, TextField, Button, Checkbox } from "@mui/material";
import { DeleteOutline, Add, CheckBox, Umbrella } from "@mui/icons-material";

import { Storage } from 'aws-amplify';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};



const SortableItem = SortableElement((props) => (

  <div className={styles.item}>

      <Box sx={{ bgcolor: 'background.paper', border:'1px solid lightgray', borderRadius:0.5, width:'100%', paddingTop: '100%', position:'relative', overflow:'hidden'}} >
      {props.image && props.image.preview && props.image.preview.length > 0 ? <img alt="123" src={props.image.preview} style={{position: 'absolute',top:0, left:0, bottom:0, right: 0, marginTop:'auto', marginBottom:'auto', width:'100%',cursor:'pointer'}}></img> : <CircularProgress style={{position: 'absolute',top:12, left:0, bottom:0, right: 0, marginTop:'auto', marginBottom:'auto', marginLeft:'auto', marginRight:'auto'}} /> }
      <Checkbox size="small" style={{ top:0, left:0,position: 'absolute'}} checked={props.image.is_checked} onClick={(event) => {props.onSelect(props.position, event.target.checked)}}></Checkbox>
      <DragIndicatorIcon style={{ top:2, right:1,position: 'absolute'}}></DragIndicatorIcon>
      </Box>

      <TextField label="Tags" InputLabelProps={{shrink:true}} size="small" style={{marginTop:12}} fullWidth value={props.image.tags} onChange={(event) => {props.handle_tags_change(event.target.value,  props.position)}}></TextField>

  </div>
    
));


const SortableList = SortableContainer((props) => (
  <div className={styles.container}>
    {props.items.map((image, index) => (
      <SortableItem
        onSelect={props.onSelect}
        handle_tags_change={props.handle_tags_change}
        key={index}
        index={index}
        position={index}
        image={image}
      />
    ))}
    </div>
));




function MediaGrid(props){

    // console.log('Media render', props.imageFiles)

    const cmsContext = useContext(CmsContext);


    const {getRootProps, getInputProps} = useDropzone({
      accept: 'image/*',
      onDrop: acceptedFiles => {
        props.setImageFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
      }
    });


    const handle_delete = useCallback((index)=>{

      const temp_files = [...props.imageFiles];

      temp_files.splice(index, 1);

      props.setImageFiles(temp_files.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));

    },[props]);



    useEffect(() =>{

      // console.log("media files changed: ", props.domain, props.imageFiles)

    },[props.imageFiles])


    const deleteSelectedImages = () => {

      var temp_images = [...props.imageFiles]

      temp_images = temp_images.filter((image) => !image.is_checked)

      props.setImageFiles(temp_images)

      

    }

    async function handle_image_delete(file){

      // console.log('deleting file',file , file.modified_name)

      try {

        // console.log('remove key', cmsContext.current_shop.email + "/images/" + file.modified_name)
        
        const result = await Storage.remove(cmsContext.current_shop.email + "/images/" + file.modified_name);

        // console.log('delete result was: ' , result)
  
      } catch (error) {
        //cmsContext.setSnackBarOpen(true, "error", error)
        // console.log('error deleting some fils')
      }

    }


    const onSelectAllClick = (checked) => {

      // console.log('checked was', checked)

      const temp_images = [...props.imageFiles]

      temp_images.forEach((image) => {
        image.is_checked = checked
      })
      
      // console.log('images were', temp_images)

      props.setImageFiles(temp_images)

      /*

      props.setImageFiles((previous_images) => {

        const temp_images = [...previous_images]

        temp_images.forEach((image) => {
          image.is_checked = checked
        })
        
        // console.log('images were', temp_images)

        return props.setImageFiles(temp_images)

      })
      */
    }



    const onDragEnd = useCallback((result) => {



      if (!result.destination) {
        //// console.log("no-change");
        return;
      }

      const temp_images = [...props.imageFiles]
  
        if (result.type === "OPTIONS") {
          // console.log(result);
          const images = Reorder(
            temp_images,
            result.source.index,
            result.destination.index
          );
  
          // console.log('images were', images)
    
          props.setImageFiles(images)
          
        }
  
        /*
  
      props.setImageFiles((previous_images) =>{
  
        const temp_images = [...previous_images]
  
        if (result.type === "OPTIONS") {
          // console.log(result);
          const images = Reorder(
            temp_images,
            result.source.index,
            result.destination.index
          );
  
          // console.log('images were', images)
    
          return props.setImageFiles(images)
          
        }
  
  
      })

      */
  
    
      
    },[props]);
  
    const onDragUpdate = useCallback(() => {
  
  
  
    },[]);


    function onSelect(index, checked) {

      // console.log('index was: ', index)
    
      const temp_images = [...props.imageFiles]
    
        // console.log('previous images were', temp_images)
    
        temp_images[index].is_checked = checked
    
        props.setImageFiles(temp_images)
    
    
    
    }
    
  

   
  
    function handle_tags_change(tags, index){
      

      const all_images = [...props.imageFiles]

      all_images[index].tags = tags

      props.setImageFiles(all_images)
      

    }
  
  


    React.useEffect(() => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      props.imageFiles.forEach(file => URL.revokeObjectURL(file.preview));
    }, [props.imageFiles]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
      // console.log('on sort end')
      props.setImageFiles(arrayMove(props.imageFiles, oldIndex, newIndex));
    };


    return(

        <Paper elevation = {2} style={{marginTop:20, padding:20}}>
        
        <Grid
          container
          spacing={3}
          alignItems="center"
        >



          

              <Grid
              item
              xs={12}
              >

                  <Grid
                  container
                  spacing={2}
                  >

                <Grid
                  item
                  xs={12}
                  >
          
                    <Dropzone accept={'image/jpeg, image/png'} onDrop={acceptedFiles => {props.handle_accepted_files(acceptedFiles)}}>
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div {...getRootProps()}  className={styles.dropzone} >
                          <input {...getInputProps()} />
                          <div style={{textAlign:'center'}}>
                          <img src='/static/images/add_file.png' alt='add_file' style={{'width':'25%', 'height':'25%',marginTop:10}}>
                          </img>
                          <Typography variant='body2'><Link to="/" onClick={(e)=>{e.preventDefault()}}>Add files</Link> or Drag and Drop</Typography>
                          </div>
                          </div>
                      </section>
                    )}
                    </Dropzone>

                  </Grid>

  
       
                </Grid>
        

            </Grid>

            {props.imageFiles && props.imageFiles.length > 0 ? 
            
            <>

            <Grid
              item
              xs={12}
            >

              <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={2}
            >

            {props.selected_images.length > 0 ?

              <Checkbox
              color="primary"
              indeterminate={props.selected_images.length > 0 && props.selected_images.length < props.imageFiles.length}
              checked={props.imageFiles.length > 0 && props.selected_images.length === props.imageFiles.length}
              onChange={ (event) => {onSelectAllClick(event.target.checked)}}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
              />

            : <Typography variant='h6'>Media</Typography>}

              <div style={{display:'flex'}}>

              {props.selected_images.length > 0 && Object.keys(props.editing_image).length === 0 ? <Button onClick={() => {props.start_editing()}} size="small" variant='outlined'>Edit</Button> : null}

              {props.editing_image && props.editing_image.preview && props.selected_images.length === 1 ? <Button sx={{marginLeft:1}} onClick={() => {props.apply_editor_changes()}} size="small" variant='outlined'>Apply</Button> : null}

              {props.selected_images.length > 0 ?<Button sx={{marginLeft:1}} size="small" variant='outlined' color="error" onClick = {() => {deleteSelectedImages()}}>{props.selected_images.length < 2 ? "Delete file" : "Delete files"}</Button> : null}

              </div>

            </Stack>
          
            </Grid>

            <Grid
              item
              xs={12}
              >

            <SortableList
              onSelect={onSelect}
              handle_tags_change={handle_tags_change}
              items={props.imageFiles}
              onSortEnd={onSortEnd}
              axis="xy"
              helperClass={styles.SortableHelper}
            />

            </Grid>
              

            </>
          
      
             : null }

                              
        </Grid>


   

        </Paper>

    )




}


export default React.memo(MediaGrid);