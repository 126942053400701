import { Grid, Paper, TextField, FormControlLabel, OutlinedInput, FormGroup, Divider, Checkbox, FormControl, Typography, InputLabel, List, ListItem, IconButton, ListItemButton, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Chip , CircularProgress, InputAdornment, Button, Select, FormHelperText, MenuItem} from '@mui/material';


import React, { useEffect } from 'react';



import CommentIcon from '@mui/icons-material/Comment';


import { Box } from '@mui/system';

import Link from '@mui/material/Link';

import SearchIcon from '@mui/icons-material/Search';
import { sort_options } from '../../Utilities/Metrics';

import CloseIcon from '@mui/icons-material/Close';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Reorder, getItemStyleCollectionProducts , getoptionListStyle } from "../CreateProduct/SubComponents/utils";


function CollectionPrducts(props){




    const onDragEnd = React.useCallback((result) => {



      if (!result.destination) {
        //console.log("no-change");
        return;
      }
  
      const temp_products = [...props.collection_products]
  
        if (result.type === "OPTIONS") {
          console.log(result);
          const products = Reorder(
            temp_products,
            result.source.index,
            result.destination.index
          );
  
          console.log('images were', products)
    
          props.set_collection_products(products)
          
        }
      
    },[props]);
  
    const onDragUpdate = React.useCallback(() => {
  
  
  
    },[]);
  

    return(

     
      <Paper elevation = {2} style={{marginTop:20, padding:20}}>
  
      <Grid
        container
        spacing={3}
        alignItems="center"
      >

    

        <Grid
          item
          xs={12}
        >
      
         <Typography variant='h6'>Products</Typography>

      
        </Grid>

        <Grid
          item
          xs={12}
        >

      <Grid
        container
        spacing={1}
        alignItems="center"
        >
      


        {props.collection_type === "manual" ?

        <>

        <Grid
          item
          xs={6}
        >

          <TextField
            id="outlined_search_products"
            size='small'
            style={{width:'100%'}}
            value=''
            onChange={(event) => {props.set_product_dialog_open(true);props.set_search_value(event.target.value);}}
            InputProps={{endAdornment : (
              <InputAdornment position="end">
                <SearchIcon></SearchIcon>
              </InputAdornment>
            ),
            }}
            label="Search products"
          />

        </Grid>

        <Grid
          item
          xs={2.2}
        >

          <Button variant="contained" sx={{width:'100%'}} onClick={() =>{props.set_product_dialog_open(true)}}>Browse</Button>

        </Grid>

        </> : null} 

        <Grid
          item
          xs={props.collection_type === "manual" ? 3.8 : 12}
        >

      <FormControl fullWidth size="small">

      <Select
          id="product-status-select"
          fullWidth
          value={props.sort_order}
          onChange={(event) =>{console.log("value was: ", event.target.value);props.set_sort_order(event.target.value)}}
          >

              {sort_options.map((status, index) => {
                  return(<MenuItem value={index} key={index}>{status}</MenuItem>)
              })
              }
          
          </Select>

      </FormControl>

      </Grid>

      </Grid>

      </Grid>

        <Grid 
        item
        xs={12}
        >

      { props.isProductsLoading ? <div style={{textAlign:'center', margin:12}}> <CircularProgress /> </div> : props.collection_products.length === 0 ? <Typography fontSize={12} variant = "h6" color="error" style={{textAlign:'center',margin:12}}>No matching products found for this collection</Typography> : 
      
      


          <Grid
              item
              xs={12}
              >

              <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" dragg>
                      {(provided, snapshot) => (
                        <List
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getoptionListStyle(snapshot.isDraggingOver)}
                        >

                          {props.collection_products.map((product, index) => {

                          var url = "";

                          if(product.media.length > 0 && product.media[0].url){
                            url = product.media[0].url
                          }else{
                            url = "/static/images/brand_identity.png"
                          }

                            
                            return(
                            <Draggable isDragDisabled={props.sort_order !== sort_options.indexOf("Manually")} key={index} draggableId={index.toString()} index={index}>
                              {(provided, snapshot) => (
                                <ListItem
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyleCollectionProducts (
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                  secondaryAction={
                                    <>
                                  <IconButton edge="end" aria-label="comments">
                                      <Chip key={index} sx={{width:56}} size="small" color={product.product_status === "Active" ? "success" : "info"} label={product.product_status} /> 
                                  </IconButton>
                                  {props.collection_type === "manual" ?<IconButton sx={{marginLeft:2}} edge="end" aria-label="comments" onClick={() =>{props.remove_collection_product(index)}}>
                                  <CloseIcon></CloseIcon>
                                  </IconButton> : null}
                                  </>
                                  }
                                >
                                {sort_options[props.sort_order] === "Manually" ? <DragIndicatorIcon style={{ float: "left" , marginTop:'auto', marginBottom:'auto', marginRight: 8}}></DragIndicatorIcon> : null}
                                <Typography fontSize={12} sx={{padding:2}}>{index + 1}.</Typography>
                                <ListItemAvatar>
                                <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                style={{border:'1px solid lightgray', width:50 ,height:50,borderRadius:4}}
                                >
                                  <img style={{width:48,height: 48}} alt="snag" src={url}/>
                                </Box>    
                                </ListItemAvatar>
                                <Link href="#" underline="hover">
                                <Typography sx={{padding:2}} fontSize={12}>{product.title}</Typography>
                                </Link>
                                  </ListItem>
                                  )}
                            </Draggable>

                          )})}

                          {provided.placeholder}

                        </List>
                      )}
                    </Droppable>
                  </DragDropContext>
       
                </Grid>
                

     }

    

        </Grid>

      </Grid>

      </Paper>


    )




}


export default React.memo(CollectionPrducts)



