


import moment from "moment-timezone";


const TimeZones = [];

const temp_zones = moment.tz.names()

for (var i=0; i< temp_zones.length; i++){
    TimeZones.push(moment.tz.zone(temp_zones[i]))
}
  

export default TimeZones;