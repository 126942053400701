import React, {useEffect,useState, useCallback, useRef} from 'react';


import {Card} from '@mui/material';

import ReactFlow, {
    useNodesState, 
    useEdgesState,
    addEdge,
    MiniMap,
    Controls,
    Background,
  } from 'react-flow-renderer';




  const onNodeMouseEnter = (event, node) => console.log('mouse enter:', node);
  const onNodeMouseMove = (event, node) => console.log('mouse move:', node);
  const onNodeMouseLeave = (event, node) => console.log('mouse leave:', node);
  const onNodeContextMenu = (event, node) => {
    event.preventDefault();
    console.log('context menu:', node);
  };


  const onLoad = (_reactFlowInstance) => {
    //setReactFlowInstance(_reactFlowInstance)
  };




export default function FlowTasks(props) {


  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const onConnect = useCallback((params) => setEdges((els) => addEdge(params, els)), []);
  
  const [elements, setElements] = useState([]);


    useEffect(()=>{

      const temp_tasks = props.all_tasks

      const temp_obj = {}

      for(var task in temp_tasks){
        
        const temp_task = temp_tasks[task]

        if(!(temp_task.source_store_name in temp_obj)){

          temp_obj[temp_task.source_store_name] = []

        }

        temp_obj[temp_task.source_store_name].push(temp_task.target_store_name)

        console.log('task was', task)

      }

      var final_nodes = []

      var final_edges = []


      var total_indexes = 0

      for(var keys in temp_obj){

        const sub_objs = temp_obj[keys]

        const input_obj = {
          id: 'horizontal-'+total_indexes.toString(),
          sourcePosition: 'right',
          type: 'input',
          className: 'dark-node',
          data: { label: keys},
          position: { x: 100, y: 80 + total_indexes * 80},
        }

        const input_id = input_obj.id

        final_nodes.push(input_obj)

        for(var ss_obj in sub_objs){

          total_indexes = total_indexes + 1

          const temp = sub_objs[ss_obj]


          const temp_obj = {
            id: 'horizontal-'+total_indexes.toString(),
            sourcePosition :'right',
            targetPosition:'left',
            data: { label: temp },
            position: { x: 370, y: ((total_indexes ) * 80)},
          }


          const temp_edge = {
            id: 'horizontal-e1-'+(total_indexes + 1).toString(),
            source: input_id,
            target: 'horizontal-'+total_indexes.toString(),
            animated: true,
          }

          final_nodes.push(temp_obj)

          final_edges.push(temp_edge)

        }

        total_indexes = total_indexes + 1

      }

      console.log('elements were', final_nodes)

      setNodes(final_nodes)

      setEdges(final_edges)


    },[props.all_tasks])




  return (
    <Card style={{width:'100%'}}>
    <div style={{width:'100%', height:'400px'}}>
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onLoad={onLoad}
      selectNodesOnDrag={false}
      onNodeMouseEnter={onNodeMouseEnter}
      onNodeMouseMove={onNodeMouseMove}
      onNodeMouseLeave={onNodeMouseLeave}
      onNodeContextMenu={onNodeContextMenu}
    >
       <MiniMap
        nodeStrokeColor={(n) => {
          if (n.type === 'input') return '#0041d0';
          if (n.type === 'selectorNode') return '#0041d0';
          if (n.type === 'output') return '#0041d0';
        }}
        nodeColor={(n) => {
          if (n.type === 'selectorNode') return '#0041d0';
          return '#0041d0';
        }}
      />
      <Controls />
      <Background color="#aaa" gap={16} />
    </ReactFlow>
    </div>
    </Card>

  );




}