import * as React from 'react';
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { OutlinedInput, InputAdornment, ButtonGroup, Button, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

import FolderCopyIcon from '@mui/icons-material/FolderCopy';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'image',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'created',
    numeric: false,
    disablePadding: false,
    label: 'Created',
  }
];

function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (

        <Stack direction="row"  sx={{ flex: '1 1 100%' }} alignItems="center">

        <Typography
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>

         {numSelected < -1 ? 
           <>

          <Button style={{marginLeft:8}}onClick={() => {props.handle_folder_state("deploy")}}>
            View
          </Button> 

          {/*<Button onClick={() => {props.handle_folder_state("deploy")}}>
            Deploy
         </Button> */}
          
          </>
          
          : null}

          </Stack>

      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        </Typography>
      )}

  
      {numSelected === 1 ? (
        <Tooltip title="Delete">
          <IconButton onClick={(event) =>{props.handle_file_update("folders", "Delete")}}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

 function FoldersTable(props) {

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);

  const rows = [];

  const selected = React.useMemo(() => {

    return(props.all_folders.filter((folder) => folder.is_checked).length)

  },[props.all_folders])




  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {

    const is_checked = event.target.checked;

    props.set_all_folders((previous_folders) => {

      var temp_folders = [...previous_folders]

      temp_folders = temp_folders.map((folder) => {
  
        folder.is_checked = is_checked;
  
        return(folder);
  
      })

      console.log('settings folders', temp_folders)
  
      return(props.set_all_folders(temp_folders))

    })

  };



    function handleClick(event, index){


      //console.log('class list was: ',event.target.classList)
  
      if (event.target.classList.contains("css-1m9pwf3")) {
        console.log("checkbox select");
        return
      }
  
      //console.log("index was: ", index)
  
      //handle_row_update(index, "is_checked", true)

      props.set_selected_folder(props.all_folders[index])
  
  
    }

    
  const handle_row_update = (index, is_checked) => {


   return props.set_all_folders((previousfolders) => {

      const temp_options = [...previousfolders];

      temp_options[index].is_checked = is_checked

      return(props.set_all_folders(temp_options))

    })

  }

  const handle_folder_state = (type) => {

    const folder = props.all_folders.filter((folder) => folder.is_checked === true)

    if(folder.length > 0){

      console.log('folder was', folder)

      props.set_component_state({layout_type: type, folder_id : folder[0].id})

    }else{
      console.log("Error getting folder")
    }

  }
  

  return (
    <>
    {props.all_folders.length > 0 ? <Box sx={{ width: '100%'}}>
      <Paper sx={{ width: '100%', mb: 2 }} >
        <EnhancedTableToolbar handle_file_update={props.handle_file_update} numSelected={selected} delete_folders={props.delete_folders} handle_folder_state={handle_folder_state} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.all_folders.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {props.all_folders.map((folder, index) => {


              return(

                <TableRow
                      style={{cursor:'pointer'}}
                      hover
                      onClick={(event) => handleClick(event, index)}
                      role="checkbox"
                      //aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                    // selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={folder.is_checked}
                         onChange={(event) => {handle_row_update(index,event.target.checked)}}
                        />
                      </TableCell>
                      <TableCell align="left" style={{paddingRight:0}}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{ width:50 ,height:50,borderRadius:4}}
                      >
                         <FolderCopyIcon color="primary"></FolderCopyIcon>
                      </Box>    
                      </TableCell>
                      <TableCell align="left">
                       <Typography fontSize={12}><strong>{folder.folder_name}</strong></Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                       <Typography fontSize={13}> <strong>{folder.created_at}</strong></Typography>
                      </TableCell>
                    </TableRow>
                
                  );

                 
              })}
              
            </TableBody>
          </Table>
        </TableContainer>
    
      </Paper>
      
    </Box> : null}
    </>
  );
}


export default React.memo(FoldersTable);