import {  Card, Grid,  TextField, Typography, FormControl, Divider, FormHelperText, FormControlLabel, Checkbox, Select, MenuItem, Button, InputLabel, Stack, Chip } from '@mui/material';


import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';

import React, { useEffect, useMemo } from 'react';

import { ProductStatus } from '../../../Utilities/Metrics';


import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import ErrorIcon from '@mui/icons-material/Error';

import GoogleIcon from '@mui/icons-material/Google';

function CreateProductSideExtras(props){

    // console.log('CreateProductSideExtras rendered')

    // console.log('collection products were: ', props.product_collections)

    const handleDelete = (index) => () => {

      const temp_collections = [...props.product_collections]

      temp_collections.splice(index, 1)

      props.set_product_collections(temp_collections)

    };


    const count = useMemo(() => {
        // console.log('calculating count')
        return props.initial_product_data.publications.filter((publication) => publication.is_checked).length
      },[props.initial_product_data])


      const error_status = props.product.last_request_status && (props.product.last_request_status.toLowerCase().includes("error") || props.product.last_request_status.toLowerCase().includes("failed") )

      // console.log('erorr status was: ', error_status  )
    
    return(

    <div>

        <Card elevation={2} style={{padding:20}}>

        <Grid
        container
        spacing={2}
        alignItems="center"
        >


        <Grid
            item
            xs={12}

        >
            <Typography variant='h6'>Product status</Typography>
          
         {props.product.last_request_status ? <div style={{display:"flex", textAlign:"center"}}>{!error_status ? <CheckCircleOutlineIcon color={error_status ? "error" : "primary"}></CheckCircleOutlineIcon> : <ErrorIcon color={error_status ? "error" : "primary"}></ErrorIcon>}<Typography fontSize={12} variant="h6" style={{marginTop:4, marginLeft:4, overflow:'hidden'}} color={error_status ? "red" : "blue"}>{props.product.last_request_status}</Typography> </div> : null}

        </Grid>


        <Grid
            item
            xs={12}
        >

        <FormControl fullWidth size="small">

        <Select
            id="product-status-select"
            fullWidth
            value={props.product_status}
            onChange={(event) =>{props.set_product_status(event.target.value)}}
            >

                {ProductStatus.map((status, index) => {
                    return(<MenuItem value={status} key={index}>{status}</MenuItem>)
                })
                }
            
            </Select>

            <FormHelperText style={{margin:0, marginTop:2}}>{props.product_status === 0 ? 'This product will be available to ' + count + ' sales channels' : 'This product will be hidden from all sales channels.' }</FormHelperText>

        </FormControl>

        </Grid>


        <Grid
        item
        xs={12}

        >

        <Divider></Divider>

        </Grid>


        <Grid
        item
        xs={12}

        >

        <Typography variant='subtitle1'>Sales Channels and Apps</Typography>

        <Button style={{padding:0, marginTop:16}}  onClick = {() => {var temp_props = {...props.initial_product_data}; if(count === props.initial_product_data.publications.length){temp_props.publications = temp_props.publications.map((prop) => {return(Object.assign(prop,{'is_checked':false}))})}else{temp_props.publications = temp_props.publications.map((prop) => {return(Object.assign(prop,{'is_checked':true}))})};props.set_initial_product_data(temp_props)}}>{ count === props.initial_product_data.publications.length ? "Deselect all" : "Select all" }</Button>

        </Grid>

        {props.initial_product_data.publications.length > 0 ? props.initial_product_data.publications.map((publication, index) => {

          return(


        <Grid
        item
        xs={12}
        key={index}
        >

        <div><Divider></Divider></div>

        <div style={{marginTop:12}}>

        <FormControlLabel control={<Checkbox style={{padding:0, marginRight:8}} onChange={(event) => {const temp_props = {...props.initial_product_data}; temp_props.publications[index].is_checked = event.target.checked;props.set_initial_product_data(temp_props);}} checked={publication.is_checked} />} style={{marginLeft:0}} label={props.initial_product_data.publications[index].node.name}/>

        </div>

        {/* publication.node.name === "Online Store" &&  publication.is_checked ? 
        
         <>

       {props.schedule_date.should_schedule ?

        <div style={{marginTop:16}}>

        <LocalizationProvider dateAdapter={AdapterDateFns}>

        <DateTimePicker
          renderInput={(params) => <TextField {...params}  />}
          value={props.schedule_date.date}
          onChange={(newValue) => {
            const temp_val = {...props.schedule_date};
            temp_val.date = newValue;
            // console.log('date was', typeof(temp_val.date))
            props.set_schedule_date(temp_val);
          }}
          label="Schedule availability"
        />
         </LocalizationProvider>

        </div>

        : null}

        <Button style={{padding:0, marginLeft:4}} color={props.schedule_date.should_schedule ? "success" : "primary"} onClick={() => {const temp_val = {...props.schedule_date}; temp_val.should_schedule = !temp_val.should_schedule; props.set_schedule_date(temp_val)}}>{props.schedule_date.should_schedule ? 'Cancel schedule' : 'Schedule availibility' }</Button>

        </>
        : null */ }

        </Grid>

          
  

          );

        }) : null}

        {props.google_merchant_available ? <Grid
        item
        xs={12}
        >

        <div><Divider></Divider></div>

        <div style={{marginTop:12}}>

        <FormControlLabel control={<Checkbox style={{padding:0, marginRight:8}} onChange={(event) => {props.set_google_merchant_checked(event.target.checked)}} checked={props.google_merchant_checked} />} style={{marginLeft:0}} label="Google Merchant"/>

        </div>

        </Grid> : null}

        <Grid item xs={12}>

          <Divider></Divider>
          
        </Grid>
        
        </Grid>

        </Card>

        <Card elevation={2} style={{marginTop:20,padding:20}}>

        <Grid
        container
        spacing={2}
        alignItems="center"
        >

        <Grid
        item
        xs={12}>

        <Typography variant="h6">Product organization</Typography>

        </Grid>


        <Grid
        item
        xs={12}>

        <Typography variant="subtitle2">Category</Typography>

        <FormControl label="Swimwear" fullWidth style={{marginTop:8}}>

        <TextField placeholder='Swimwear' size="small" style={{marginTop:8}} fullWidth value={props.productCategory} onChange={(event) => {props.setProductCategory(event.target.value)}}></TextField>

        </FormControl>

        </Grid>

        <Grid
        item
        xs={12}>

        <Typography variant="subtitle2">Type</Typography>

        <FormControl label="Clothing" fullWidth style={{marginTop:8}}>

        <TextField placeholder='Clothing' size="small" style={{marginTop:8}} fullWidth value={props.standardizedProductType} onChange={(event) => {props.setStandardizedProductType(event.target.value)}}></TextField>

        </FormControl>

        </Grid>
        

        <Grid
        item
        xs={12}>

        <Typography variant="subtitle2">Custom Type</Typography>

        <FormControl label="e.g., T-Shirt" fullWidth style={{marginTop:8}}>

        <TextField placeholder='e.g., T-Shirt' size="small" style={{marginTop:8}} fullWidth value={props.customProductType} onChange={(event) => {props.setCustomProductType(event.target.value)}}></TextField>

        </FormControl>

        </Grid>
        

        <Grid
        item
        xs={12}>

        <Divider></Divider>

        </Grid>


        <Grid
        item
        xs={12}>

        <Typography variant="subtitle2">Vendor</Typography>

        <FormControl label="e.g Snag" fullWidth style={{marginTop:8}}>

        <TextField placeholder='e.g Snag' size="small" style={{marginTop:8}} fullWidth value={props.vendor} onChange={(event) => {props.set_vendor(event.target.value)}}></TextField>

        </FormControl>

        </Grid>


        <Grid
        item
        xs={12}>

        <Divider></Divider>

        </Grid>

        {props.product_collections.length > 0 ? <Grid
        item
        xs={12}>

        <Typography variant="subtitle2">Collections</Typography>

        <FormControl fullWidth style={{marginTop:8}}>


        <div style={{marginTop:4}}>

        {props.product_collections.map((chip, index) => {

          return(<Chip key={index} style={{margin:4}} label={chip.title} /> )

        })}

        </div>


        {/* 
        
        onDelete={chip.collection_type !== 'manual' ? undefined : handleDelete(index)}
        <Select
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        fullWidth
        multiple
        onChange={(handleSelectedCollectionsChange)}
        size="small"
        value={props.selected_collection}
        onChange={(event => {props.set_selected_collection(event.target.value)})}
        >

        { props.initial_product_data.collections.map((collection, index) => (

        <MenuItem key={index} value={index}>{collection.node.title}</MenuItem>

        )) }
        
        </Select> 
        
          <FormHelperText style={{margin:0, marginTop:2}}>Add this product to a collection so it’s easy to find in your store.</FormHelperText>
        
        */}

      

        </FormControl>

        </Grid> : null}


        <Grid
        item
        xs={12}>

        <Typography variant="subtitle2">Tags</Typography>


        <TextField  size="small" style={{marginTop:8}} fullWidth value={props.tags} onChange={(event) => {props.set_tags(event.target.value)}}></TextField>

        <div style={{marginTop:4}}>

        {props.tags.trim().length > 0 ? props.tags.split(',').map((chip, index) => {

          return(<Chip key={index} style={{margin:4}} label={chip} /> )

        }) : null}

      </div>

        </Grid>

        </Grid>

        </Card>


        <Card elevation={2} style={{marginTop:20,padding:20}}>

        <Grid
        container
        spacing={2}
        alignItems="center"
        >

        <Grid
        item
        xs={12}>

        <Typography variant="h6">Online Store</Typography>

        </Grid>

        <Grid
        item
        xs={12}>

        <Typography variant="subtitle2">Theme template</Typography>

        <FormControl fullWidth style={{marginTop:8}}>

        <Select
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        fullWidth
        size="small"
        value={props.selected_product_template}
        onChange={(event => {props.set_selected_product_template(event.target.value)})}
        >

           { props.initial_product_data.templates.map((template, index) => (

                <MenuItem key={index} value={template}>{template}</MenuItem>

            )) }

        </Select>

        <FormHelperText style={{margin:0, marginTop:2}}>Assign a template from your current theme to define how the product is displayed.</FormHelperText>

        </FormControl>

        </Grid>

        </Grid>

        </Card>


    </div>

    )

}


export default React.memo(CreateProductSideExtras)