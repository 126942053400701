import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';

export default function CreateFolderDialog(props) {


  return (
    <div>

      <Dialog fullWidth open={props.create_folder_dialog_state.is_dialog_open} onClose={props.hide_create_folder_dialog}>
        <DialogTitle>Folder</DialogTitle>
        <DialogContent dividers>
          <TextField
            value={props.create_folder_dialog_state.folder_name}
            onChange={(event)=>{const temp_folder_dialog = {...props.create_folder_dialog_state};temp_folder_dialog.folder_name = event.target.value; props.set_create_folder_dialog_state(temp_folder_dialog);}}
            autoFocus
            label="Folder name"
            fullWidth
            size='small'
            InputLabelProps={{shrink:true}}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button size="small" variant='outlined' onClick={props.hide_create_folder_dialog}>Cancel</Button>
          <LoadingButton size="small" variant='outlined' loading={props.create_folder_dialog_state.isLoading} onClick={() => {props.handle_file_update("folders", "Create")}}>Create</LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
