import React, { useCallback, useEffect } from 'react';

import { useReactFlow } from 'reactflow';



export default function ContextMenu({
  id,
  top,
  left,
  right,
  bottom,
  ...props
}) {

    const { getNode, setNodes, addNodes, setEdges } = useReactFlow();


    console.log("data was: ", props.data)

    const deleteNode = useCallback(() => {

      setNodes((nodes) => nodes.filter((node) => node.id !== id));
      setEdges((edges) => edges.filter((edge) => edge.source !== id));
  
      console.log("delete called");
      
    }, [id, setNodes, setEdges]);



  return (
    <div
      style={{ top, left, right, bottom, minWidth:'20%' }}
      className="context-menu"
    >
      <p style={{ margin: '0.5em' }}>
        <small>{props.data.label}</small>
      </p>

      <button style={{fontSize:'0.875rem'}} onClick={() => {props.delete_node(id)}}>Delete</button>
    </div>
  );

}