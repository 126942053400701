import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Chip, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, makeStyles, FormControl, InputLabel, Select, MenuItem, Autocomplete} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';

import { UserCircle as UserCircleIcon } from '../../icons/user-circle';


import CmsContext from '../../store/cms-context';
import { LoadingButton } from '@mui/lab';
import FacebookAdGroupDialogAdsListTable from './FacebookAdGroupDialogAdsListTable';





function FacebookAdGroupDialog(props) {

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);



  function handle_close() {

    const temp_state = {...props.facebook_ad_group_dialog_state}

    temp_state.is_dialog_open = false

    props.set_facebook_ad_group_dialog_state(temp_state)

  }

  console.log('ad group dialog state was: ', props.facebook_ad_group_dialog_state)

  var is_dialog_open = false;

  if(props.facebook_ad_group_dialog_state.is_dialog_open){

    is_dialog_open = true

  }

  if(props.campaign_dialog_state && props.campaign_dialog_state.is_dialog_open){

    is_dialog_open = false

  }

  if(props.adset_dialog_state && props.adset_dialog_state.is_dialog_open){

    is_dialog_open = false

  }

  return (

      <Dialog
        open={is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'md'}
      >

        <DialogTitle id="alert-dialog-title">
         Ad Group
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

              <Grid
              item
              xs={12}
             
              >

                <div style={{display:'flex'}}>

                <Avatar
                    sx={{
                      height: 84,
                      width: 84
                    }}
                    src="/static/images/advertisement.png"
                  >
                    
                    <UserCircleIcon fontSize="small" />

                </Avatar>

              </div>

              </Grid> 

              {!props.facebook_ad_group_dialog_state.is_dialog_loading ? 
              
              <>
              
              <Grid
              item
              xs={12}
              sx={{marginTop:1}}
              >
                
                <TextField fullWidth label="Name" disabled={props.facebook_ad_group_dialog_state.type === "Edit"} value={props.facebook_ad_group_dialog_state.adgroup.name} onChange={(event) => {const temp_state = {...props.facebook_ad_group_dialog_state}; temp_state.adgroup.name = event.target.value; props.set_facebook_ad_group_dialog_state(temp_state);}} variant="outlined" />
                  
              </Grid>
              
              {props.facebook_ad_group_dialog_state.type === "Deploy" ?
                
                <>
                <Grid item xs={12} sx={{marginTop:1}}>
                                
                <FormControl fullWidth >
                <InputLabel id="demo-select-small-label">Account</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  fullWidth
                  value={props.facebook_ad_group_dialog_state.selected_account}
                  label="Account"
                  onChange={(event) => {props.get_facebook_campaigns(event.target.value);}}
                >
                  {props.facebook_ad_group_dialog_state.accounts.map((item, index)=> (
                  <MenuItem
                  key={index} value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
                </FormControl>
                </Grid>

                {props.facebook_ad_group_dialog_state.campaigns.length > 0 ? <Grid item xs={12} sx={{marginTop:1}}>

                <Autocomplete
                    id="selected-campaigns"
                    options={props.facebook_ad_group_dialog_state.campaigns}
                    getOptionLabel={(option) => option.name ? option.name : ""}
                    value={props.facebook_ad_group_dialog_state.selected_campaign}
                    isOptionEqualToValue={(option, value) => {
                      return value.id ? value.id === option.id : false
                    }} 
                    onChange={(event, newValue) => {

                      if(newValue){

                      const temp_state = {...props.facebook_ad_group_dialog_state};
                      temp_state.selected_campaign = newValue;
                      props.set_facebook_ad_group_dialog_state(temp_state)
                      props.get_adsets(newValue.id)

                      }

                    }}
                    renderOption={(props, option, state) => {
                      //console.log('index was: ', props, " : ", option, " : ", state)
                      const key = `listItem-${option.id}`;
                      return (
                        <li {...props} key={key}>
                          {option.name}
                        </li>
                      );
                      
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Campaigns" placeholder="Campaigns" />
                    )}
                />

                </Grid> : null}

                {props.facebook_ad_group_dialog_state.adsets.length > 0 ? <Grid item xs={12} sx={{marginTop:1}}>

                <Autocomplete
                    id="selected-adsets"
                    options={props.facebook_ad_group_dialog_state.adsets}
                    getOptionLabel={(option) => option.name ? option.name : ""}
                    value={props.facebook_ad_group_dialog_state.selected_adset}
                    isOptionEqualToValue={(option, value) => {
                      return value.id ? value.id === option.id : false
                    }} 
                    onChange={(event, newValue) => {

                      if(newValue){

                      props.set_facebook_ad_group_dialog_state((previous_state) => {



                        const temp_state = {...previous_state};
                        temp_state.selected_adset = newValue;
                        return props.set_facebook_ad_group_dialog_state(temp_state);

                      })

                    }
                      
                    }}
                    renderOption={(props, option, state) => {
                      //console.log('index was: ', props, " : ", option, " : ", state)
                      const key = `listItem-${option.id}`;
                      return (
                        <li {...props} key={key}>
                          {option.name}
                        </li>
                      );
                      
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Adsets" placeholder="Adsets" />
                    )}
                />

                </Grid> : null}


                </> : null}

                

              <Grid item xs={12}>

              <FacebookAdGroupDialogAdsListTable facebook_ad_group_dialog_state={props.facebook_ad_group_dialog_state} set_facebook_ad_group_dialog_state={props.set_facebook_ad_group_dialog_state}></FacebookAdGroupDialogAdsListTable>
        
              </Grid>

              </> : <Grid item xs={12}><div style={{textAlign:'center', margin:60}}><CircularProgress /></div></Grid>} 

            
            </Grid>

        </DialogContent>
        <DialogActions>
        <Button disabled={props.facebook_ad_group_dialog_state.is_dialog_loading} onClick={() => {props.post_ad_group(props.facebook_ad_group_dialog_state, props.all_facebook_breakdowns, props.facebook_ad_group_dialog_state)}} variant='outlined'>{props.facebook_ad_group_dialog_state.type}</Button>
        {props.facebook_ad_group_dialog_state.selected_account.length > 0 ? <Button disabled={props.facebook_ad_group_dialog_state.is_dialog_loading} onClick={() => {props.show_campaign_dialog("Create")}} variant='outlined'>Create Campaign</Button> : null}
        {Object.keys(props.facebook_ad_group_dialog_state.selected_campaign).length > 0 ? <Button disabled={props.facebook_ad_group_dialog_state.is_dialog_loading} onClick={() => {props.show_adset_dialog("Create")}} variant='outlined'>Create AdSet</Button> : null}
        <Button onClick={handle_close} variant='outlined'>{"Close"}</Button>
        </DialogActions>
      </Dialog>

  );

}

export default React.memo(FacebookAdGroupDialog);