import * as React from 'react';
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Button, Stack, Avatar, Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { key_ui_map_ql } from '../../../../Utilities/Metrics';

import TrackChangesIcon from '@mui/icons-material/TrackChanges';



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'image',
    numeric: false,
    disablePadding: true,
    label: ''
  },
  {
    id: 'cliebt',
    numeric: false,
    disablePadding: false,
    label: 'Client'
  },
  {
    id: 'currency',
    numeric: false,
    disablePadding: false,
    label: 'Currency'
  },
  { 
    id: 'threshold',
    numeric: false,
    disablePadding: false,
    label: 'Threshold'
  },
  { 
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: 'Created At'
  },
  { 
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'Updated At'
  },
  { 
    id: 'omitted_tags',
    numeric: false,
    disablePadding: false,
    label: 'Omitted Tags'
  }
];

function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (

        <Stack direction="row"  sx={{ flex: '1 1 100%' }} alignItems="center">

        <Typography
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>

         {numSelected === 1 ? 
          
           <>
          
          <Button style={{marginLeft:8}} onClick={() => {props.handle_mintsoft_order_split_target_state("edit")}}>
            Edit
          </Button> 
          
          </>
          
          : null}

          </Stack>

      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        </Typography>
      )}

  
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={(event) =>{props.delete_mintsoft_order_split_targets()}}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

 function MintsoftOrderSplitsTable(props) {

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  console.log("mintsoft split targets table rendered")

  const rows = [];

  const selected = React.useMemo(() => {

    return(props.all_mintsoft_order_split_targets.filter((mintsoft_order_split_target) => mintsoft_order_split_target.is_checked).length)

  },[props.all_mintsoft_order_split_targets])



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {

    const is_checked = event.target.checked;

    props.set_all_mintsoft_order_split_targets((previous_mintsoft_order_split_targets) => {

      var temp_mintsoft_order_split_targets = [...previous_mintsoft_order_split_targets]

      temp_mintsoft_order_split_targets = temp_mintsoft_order_split_targets.map((mintsoft_order_split_target) => {
  
        mintsoft_order_split_target.is_checked = is_checked;
  
        return(mintsoft_order_split_target);
  
      })

  
      return(props.set_all_mintsoft_order_split_targets(temp_mintsoft_order_split_targets))

    })

  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  // Avoid a layout jump when reaching the last page with empty rows.

        
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    
  const handle_row_update = (index, type, value, optionNum) => {

    if(type === 'price' && value < 0){
      return;
    }

   return props.set_all_mintsoft_order_split_targets((previousmintsoft_order_split_targets) => {

      const temp_options = [...previousmintsoft_order_split_targets];

      temp_options[index][type] = value;

      return(props.set_all_mintsoft_order_split_targets(temp_options))

    })

  }

  const handle_mintsoft_order_split_target_state = (type) => {

    if(type === "edit"){

    const mintsoft_order_split_target = props.all_mintsoft_order_split_targets.filter((mintsoft_order_split_target) => mintsoft_order_split_target.is_checked === true)

    if(mintsoft_order_split_target.length > 0){

      console.log('mintsoft target was: ', mintsoft_order_split_target)

      props.set_dialog_mintsoft_order_split_target({...mintsoft_order_split_target[0]}, true)

    }else{

      console.log("invalid mintsoft target")

    }

  }

  }


  

  return (
    <>
    {props.all_mintsoft_order_split_targets.length > 0 ? <Box sx={{ width: '100%'}} style={{marginTop:20}}>
      <Paper sx={{ width: '100%', mb: 2 }} >
        <EnhancedTableToolbar numSelected={selected} delete_mintsoft_order_split_targets={props.delete_mintsoft_order_split_targets} handle_mintsoft_order_split_target_state={handle_mintsoft_order_split_target_state} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.all_mintsoft_order_split_targets.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {props.all_mintsoft_order_split_targets.map((mintsoft_split_target, index) => {


              return(

                <TableRow
                      hover
                    // onClick={(event) => handleClick(event, option_value.value)}
                      role="checkbox"
                      //aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                    // selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={mintsoft_split_target.is_checked}
                          onChange={(event) => {handle_row_update(index, "is_checked", event.target.checked, index)}}
                        />
                      </TableCell>
                      <TableCell align="left" style={{paddingRight:0}}>
                      <TrackChangesIcon color='primary' fontSize="small" />
                      </TableCell>
                      <TableCell align="left">
                      <strong>{mintsoft_split_target.client}</strong>
                      </TableCell>
                      <TableCell align="left">
                      <strong>{mintsoft_split_target.currency}</strong>
                      </TableCell>
                      <TableCell align="left">
                      <strong>{mintsoft_split_target.threshold}</strong>
                      </TableCell>
                      <TableCell align="left">
                      <strong>{mintsoft_split_target.created_at}</strong>
                      </TableCell>
                      <TableCell align="left">
                      <strong>{mintsoft_split_target.updated_at}</strong>
                      </TableCell>
                      <TableCell align="left">
                      <div style={{display:'flex',width:200,overflowX:'scroll'}}>
                      {mintsoft_split_target.omitted_tags.map((hash_tag, index) =>{
                         return(<Chip key={index} color="primary" label={hash_tag} style={{marginLeft:4, marginTop:4, marginBottom:4}}></Chip>)
                       })}
                       </div>
                      </TableCell>
                    </TableRow>
                
                  );

              })}
              
            </TableBody>
          </Table>
        </TableContainer>
    
      </Paper>
      
    </Box> : null}

    </>
  );
}


export default React.memo(MintsoftOrderSplitsTable);