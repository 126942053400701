import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Chip, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, makeStyles} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';

import { UserCircle as UserCircleIcon } from '../../icons/user-circle';



import CmsContext from '../../store/cms-context';




function FacebookDialog(props) {

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);


  function handle_view(){

    console.log(props.facebook_dialog_state.facebook)

    window.open(props.facebook_dialog_state.facebook.permalink_url, "_blank")

  }


  function findHashtags(searchText) {
      var regexp = /\B\#\w\w+\b/g
      var result = searchText.match(regexp);
      if (result) {
          return(result)
      } else {
          return([])
      }
  }

  var hash_tags = [];

  if(props.facebook_dialog_state.facebook.message_tags){

    if(props.facebook_dialog_state.facebook.message_tags.length > 0){
      hash_tags = props.facebook_dialog_state.facebook.message_tags.split(',');
    }else{
      hash_tags = props.facebook_dialog_state.facebook.message_tags
    }

  }


  function handle_close(){

    const temp_state = {...props.facebook_dialog_state}

    temp_state.is_dialog_open = false

    props.set_facebook_dialog_state(temp_state)

  }


  var url = "";

  if(props.facebook_dialog_state.facebook.url && props.facebook_dialog_state.facebook.url.length > 0){

    url = props.facebook_dialog_state.facebook.url

  }else if(props.facebook_dialog_state.facebook.full_picture){

    url = props.facebook_dialog_state.facebook.full_picture

  }else{

    url = "/static/images/404-error.png"

  }

  React.useEffect(() =>{

    console.log("breakdown was: ", props.facebook_dialog_state.breakdown)

  },[props.facebook_dialog_state.breakdown])



  return (
    <div>
      <Dialog
        open={props.facebook_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         Image Stats
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

            <Grid
              item
              xs={12}
             
              >

                <div style={{display:'flex'}}>

                <Avatar
                    sx={{
                      height: 84,
                      width: 84
                    }}
                    src="/static/images/stats.png"
                  >
                    
                    <UserCircleIcon fontSize="small" />

                </Avatar>

                <Typography style={{marginTop:'auto', marginBottom:4, marginLeft:8}} fontSize={12}><strong>{props.facebook_dialog_state.facebook.username}</strong></Typography>

                
                </div>

              </Grid> 


              {props.facebook_dialog_state.breakdown ? <Grid
              item
              xs={12}>

                <Grid
                container
                spacing={2}
                >

                  <Grid
                  item
                  xs={12}
                  >

                  <Stack
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={1}
                  >

                    <Typography variant='h6' fontSize={20} style={{color:'#4e40e3'}}>Statistics</Typography>

                  </Stack>

                  </Grid>

                  <Grid
                  item
                  xs={12}
                  >

                    <Stack
                      direction="row"
                      justifyContent="space-evenly"
                      alignItems="center"
                      spacing={1}
                    >

                      {props.facebook_dialog_state.breakdown.spend ? <Typography variant='subtitle2' fontSize={14}>

                        Spend: {props.facebook_dialog_state.breakdown.spend.toFixed(2)}

                      </Typography> : null}

                      {props.facebook_dialog_state.breakdown.conversions ? <Typography variant='subtitle2' fontSize={14}>

                        Conversions: {props.facebook_dialog_state.breakdown.conversions.toFixed(2)}

                      </Typography> : null}

                      {props.facebook_dialog_state.breakdown.cpa ? <Typography variant='subtitle2' fontSize={14}>

                        Cpa: {props.facebook_dialog_state.breakdown.cpa.toFixed(2)}

                      </Typography> : null}

                    </Stack>
                  
                </Grid>


                </Grid>


              </Grid> : null}



             <Grid
              item
              xs={12}
              >
               {!props.facebook_dialog_state.is_dialog_loading ?  <div style={{width:'100%',textAlign:'center'}}>

                <img alt="fb_img" style={{width: '100%'}}
                src={url}
                onError={({currentTarget}) => {currentTarget.onerror = null; console.log('image error received'); currentTarget.src = '/static/images/404-error.png';}}
                >
                </img>
                </div> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
                  <CircularProgress  />
                </div>}
              </Grid> 

              {props.facebook_dialog_state.facebook.title && props.facebook_dialog_state.facebook.title.length > 0 ?  <Grid item xs={12}>

              <Typography fontSize={14}><strong>Title</strong></Typography>
              <Typography style={{marginTop:8}} fontSize={13}>{props.facebook_dialog_state.facebook.title}</Typography>

            </Grid> : null}

            {props.facebook_dialog_state.facebook.headline && props.facebook_dialog_state.facebook.headline.length > 0 ?  <Grid item xs={12}>

              <Typography fontSize={14}><strong>Headline</strong></Typography>
              <Typography style={{marginTop:8}} fontSize={13}>{props.facebook_dialog_state.facebook.headline}</Typography>

            </Grid> : null}

            {hash_tags.length > 0 ? <Grid item xs={12}>

            <Typography fontSize={14} style={{marginBottom:8}}><strong>Tags</strong></Typography>
              
              {hash_tags.map((hash_tag, index) =>{
                         return(<Chip key={index} size="small" color="primary" label={hash_tag} style={{marginLeft:4, marginTop:4, marginBottom:4}}></Chip>)
                       })}

            </Grid> : null}


            
            </Grid>


        </DialogContent>
        <DialogActions>
       {/* <Button onClick={handle_view} startIcon={<FacebookIcon />} variant='outlined'>{"View"}</Button> : null */}
        <Button onClick={handle_close} variant='outlined'>{"Close"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default FacebookDialog;