import React from 'react';


import { Grid, Paper, Typography, FormControlLabel, Radio, FormControl, RadioGroup, Divider, FormHelperText, Link, Stack, Select, MenuItem, Box, TextField, OutlinedInput, InputAdornment, InputLabel, IconButton, Button} from '@mui/material';
import { CollectionConditionKeys, CollectionTypes } from '../../Utilities/Metrics';
import {DeleteOutline } from '@mui/icons-material';



function CollectionTypeAndConditions(props) {

  console.log("disabled was: ", props.type_disabled)

  function handleChange(index,type, value) {

    console.log(type, value)

    if(value == null){
      return
    }

    props.set_collection_conditions((previous_conditions) =>{

      const temp_conditions = [...previous_conditions]

      if(type === "condition_key"){
        temp_conditions[index].condition_type = '';
        temp_conditions[index].condition_value = '';
      }

      temp_conditions[index][type] = value
      
      return(props.set_collection_conditions(temp_conditions))



    })

  }


  function add_conditions_row() {

    props.set_collection_conditions((previous_conditions) =>{

      const temp_conditions = [...props.collection_conditions];

      temp_conditions.push({'condition_key':'', 'condition_type':'','condition_value':''});

      return props.set_collection_conditions(temp_conditions);

    })

  }

  function handle_delete(index) {

    props.set_collection_conditions((previous_conditions) =>{

      const temp_conditions = [...props.collection_conditions];

      temp_conditions.splice(index, 1);

      return props.set_collection_conditions(temp_conditions);

    })

  }


    return(

    <Paper elevation = {2} style={{marginTop:20, padding:20}}>
        
    <Grid
      container
      spacing={3}
      alignItems="center"
    >

      <Grid
        item
        xs={12}
      >

        <Typography variant='h6'>Collection type</Typography>

        <FormControl style={{marginTop:12}}>

        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={props.collection_type}
          onChange={(event) => {props.set_collection_type(event.target.value)}}
        >

          <FormControlLabel value="manual"  disabled={props.type_disabled} control={<Radio size="small" />} label={<Typography fontSize={14}>Manual</Typography>} />

          <FormHelperText>Add products to this collection one by one. Learn more about <Link target="_blank" href="https://help.shopify.com/en/manual/products/collections/manual-shopify-collection" rel="noreferrer">manual collections</Link>.</FormHelperText>

          <FormControlLabel value="automated" disabled={props.type_disabled} style={{marginTop:4}} control={<Radio size="small" />} label={<Typography fontSize={14}>Automated</Typography>} />

          <FormHelperText>Existing and future products that match the conditions you set will automatically be added to this collection. Learn more about <Link target="_blank" href="https://help.shopify.com/en/manual/products/collections/automated-collections" rel="noreferrer">automated collections</Link>.</FormHelperText>

        </RadioGroup>

        </FormControl>
    
      </Grid>


    {props.collection_type === "automated" ? <Grid
        item
        xs={12}
    >

    <Grid
      container
      spacing={3}
      alignItems="center"
    >

    <Grid
        item
        xs={12}
    >

      <Divider></Divider>

      </Grid>

      <Grid
        item
        xs={12}
    >

      <Typography variant="h6" fontSize={14}>CONDITIONS</Typography>


      </Grid>

      <Grid
        item
        xs={12}
        style={{marginTop:16, paddingTop:0}}
    >

      <Typography fontSize={14}>Products must match:</Typography>

      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="automated"
        name="radio-buttons-group"
        style={{marginTop:2}}
        row
        value={props.should_match}
        onChange={(event) => {props.set_should_match(event.target.value)}}
        >

        <FormControlLabel value="all" control={<Radio size="small"/>} label={<Typography fontSize={14}>all conditions</Typography>} />

        <FormControlLabel value="any" control={<Radio size="small"/>} label={<Typography fontSize={14}>any condition</Typography>} />

        </RadioGroup>


      </Grid>

      <Grid item xs={12} style={{marginTop:0, paddingTop:0}}>

      {
        props.collection_conditions.map((condition, index) => {

          return(

            <Grid
            container
            spacing={1}
            style={{marginTop:2}}
            key={index}
            >
            
            <Grid item xs={4}>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size='small'
                onChange={(event) => {handleChange(index, 'condition_key', event.target.value)}}
                style={{width:'100%'}}
                value={props.collection_conditions[index].condition_key}
              >
              {
                CollectionConditionKeys.map((coll_key, key_index) => {

                  return(
                    <MenuItem key={key_index} value={coll_key.shopify_key}>{coll_key.local_value}</MenuItem>
                  );

                })
              }
              </Select>

              </Grid>

              <Grid item xs={4}>

              <Select
                labelId="demo-simple-select-label"
                size='small'
                id="demo-simple-select"
                onChange={(event) => {handleChange(index, 'condition_type', event.target.value)}}
                style={{width:'100%'}}
                value={props.collection_conditions[index].condition_type}
              >
              {
                CollectionTypes.map((coll_type, type_index) => {

                  return(
                    <MenuItem disabled = {((props.collection_conditions[index].condition_key === 'TITLE' || props.collection_conditions[index].condition_key === 'TYPE' || props.collection_conditions[index].condition_key === 'VENDOR') && (coll_type.shopify_key === 'GREATER_THAN' || coll_type.shopify_key === 'LESS_THAN' || coll_type.shopify_key === 'IS_SET' || coll_type.shopify_key === 'IS_NOT_SET')) || ((props.collection_conditions[index].condition_key === 'VARIANT_PRICE' || props.collection_conditions[index].condition_key === 'VARIANT_WEIGHT') && (coll_type.shopify_key === 'STARTS_WITH' || coll_type.shopify_key === 'ENDS_WITH' || coll_type.shopify_key === 'CONTAINS' || coll_type.shopify_key === 'NOT_CONTAINS' || coll_type.shopify_key === 'IS_EMPTY' || coll_type.shopify_key === 'IS_NOT_EMPTY')) || (props.collection_conditions[index].condition_key === 'TAG' && coll_type.shopify_key !== 'EQUALS') || (props.collection_conditions[index].condition_key === 'VARIANT_COMPARE_AT_PRICE' && (coll_type.shopify_key === 'STARTS_WITH' || coll_type.shopify_key === 'ENDS_WITH' || coll_type.shopify_key === 'CONTAINS' || coll_type.shopify_key === 'NOT_CONTAINS')) || (props.collection_conditions[index].condition_key === 'VARIANT_INVENTORY' && (coll_type.shopify_key !== 'EQUALS' && coll_type.shopify_key !== 'GREATER_THAN' && coll_type.shopify_key !== 'LESS_THAN')) || (props.collection_conditions[index].condition_key === "VARIANT_TITLE" && (coll_type.shopify_key === 'GREATER_THAN' || coll_type.shopify_key === 'LESS_THAN' || coll_type.shopify_key === 'IS_EMPTY' || coll_type.shopify_key === 'IS_NOT_EMPTY'))} key={type_index} value={coll_type.shopify_key}>{coll_type.local_value}</MenuItem>
                  );

                })
              }
              </Select>

            </Grid>

            <Grid item xs={3}>

            <FormControl fullWidth>
              <OutlinedInput
                size='small'
                id="outlined-adornment-amount"
                onChange={(event) => {handleChange(index,'condition_value', event.target.value)}}
                value={props.collection_conditions[index].condition_value}
                style={props.collection_conditions[index].condition_type === "IS_SET" || props.collection_conditions[index].condition_type === "IS_NOT_SET" ? {visibility:"hidden"}:{}}
                type={(props.collection_conditions[index].condition_key === 'VARIANT_PRICE' || props.collection_conditions[index].condition_key === 'VARIANT_COMPARE_AT_PRICE' || props.collection_conditions[index].condition_key === 'VARIANT_WEIGHT') ? 'number' : "text" }
                startAdornment={(props.collection_conditions[index].condition_key === "VARIANT_COMPARE_AT_PRICE" || props.collection_conditions[index].condition_key === "VARIANT_PRICE") ? <InputAdornment position="start">£</InputAdornment> : null}
                endAdornment={props.collection_conditions[index].condition_key === "VARIANT_WEIGHT" ? <InputAdornment position="end">kg</InputAdornment> : null}
              />
            </FormControl>

            </Grid>

            <Grid item xs={1}>

              <IconButton aria-label="delete" size="large"  style={{padding:6}} onClick={() => {handle_delete(index)}}>
                <DeleteOutline fontSize="inherit" />
              </IconButton>

            </Grid>

            </Grid>

          );

        })


      }

      <Button variant="outlined" style={{marginTop:8}} onClick={() => {add_conditions_row()}}>Add another condition</Button>

    </Grid>
      
    </Grid> 

    </Grid>

    : null }


    </Grid>

    </Paper>
    );



}



export default React.memo(CollectionTypeAndConditions);