export const Reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  
  const grid = 6;
  export const getItemStyle = (isDragging, draggableStyle) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      padding: grid * 2,
      margin: `0 0 ${grid}px 0`,
      textAlign: "left",
      width:'100%',
      height:'auto',
      marginTop:0,
      // change background colour if dragging
      background: isDragging ? "white" : "white",
      border: isDragging ? '2px solid deepskyblue' : 0 ,
      borderRadius: isDragging ? 8 : 0,
      // styles we need to apply on draggables
      ...draggableStyle
    };
  };


  export const getItemStyleCollectionProducts = (isDragging, draggableStyle) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      padding: grid * 2,
      margin: `0 0 ${grid}px 0`,
      textAlign: "left",
      paddingLeft:0,
      width:'100%',
      height:'auto',
      marginTop:0,
      // change background colour if dragging
      background: isDragging ? "white" : "white",
      border: isDragging ? '2px solid deepskyblue' : 0 ,
      borderRadius: isDragging ? 8 : 0,
      // styles we need to apply on draggables
      ...draggableStyle
    };
  };

  export const getSubItemItemStyle = (isDragging, draggableStyle) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      padding: grid * 2,
      margin: `0 0 ${grid}px 0`,
      textAlign: "left",
      width:'100%',
      marginTop:0,
      marginLeft:8,
      // change background colour if dragging
      background: isDragging ? "white" : "white",
      border: isDragging ? '2px solid deepskyblue' : 0,
      borderRadius: 8,
      // styles we need to apply on draggables
      ...draggableStyle
    };
  };

  
  
  export const getoptionListStyle = isDraggingOver => ({
    background: isDraggingOver ? "white" : "white",
    padding: 8,
    width: "100%"
  });
  
  export const  getoption_valueListStyle = isDraggingOver => ({
    background: isDraggingOver ? "white" : "white",
    padding: 4,
    width: "100%"
  });
  