import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link } from '@mui/material';
import Dropzone from 'react-dropzone'

import styles from './SelectImageDialog.module.css'

function SelectImageDialog(props) {

  const dropzoneRef = React.useRef(null);


  const onSelect = (index, checked) => {

    const temp_variant_image = {...props.selected_variant_image}

    if(checked){
      temp_variant_image.image_index = index
    }else{
      temp_variant_image.image_index = -1
    }

    props.set_selected_variant_image(temp_variant_image)
    
  }


  const imageFiles = [...props.imageFiles]
  


  return (
    <div>
      <Dialog
        open={props.selected_variant_image.is_dialog_open}
        onClose={() => {props.set_selected_variant_image({'variant_index':-1, 'image_index':-1,'is_dialog_open':false})}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Update variant image
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
              You can only choose images as variant media 
          </DialogContentText>
          <Grid
            container
            spacing={3}
            alignItems="center"
            style={{marginTop:0}}
            >

              {!(props.selected_variant_image.merchant_index != null) ? <Grid
              key={0}
              item
              xs={6}
              md={3}
              >

               <Box sx={{ background: '#eee' ,cursor: 'pointer',borderRadius:0.5, width:'100%', paddingTop: '100%', position:'relative',border: '2px dashed cyan'}}>

               <div style={{position: 'absolute',top:0, left:0, bottom:0, right: 0, marginTop:'auto', marginBottom:'auto',textAlign:'center',height:66}}>

                <Dropzone ref={dropzoneRef}  maxFiles={1} accept={'image/jpeg, image/png'} onDrop={acceptedFiles => {props.handle_accepted_files(acceptedFiles)}}>

                {({getRootProps, getInputProps}) => (

                  <section>
                    <div {...getRootProps()} >
                      <input {...getInputProps()} />
                      <img src='/static/images/add_file.png' alt='add_file' style={{'width':'25%', 'height':'25%'}}>
                      </img>
                      <Typography fontSize={10}><Link to="/" onClick={(e)=>{e.preventDefault()}}>Add file</Link> or Drag and Drop</Typography>
                      </div>
                  </section>

                )}
                
                </Dropzone>

                </div>

                </Box>

              </Grid> : null}

              {imageFiles.map((image, index) => ( 

              <Grid
              key={index+1}
              item
              xs={6}
              md={3}
              >

              <Box sx={{ bgcolor: 'background.paper', border:'1px solid lightgray',borderRadius:0.5, width:'100%', paddingTop: '100%', position:'relative',overflow:'hidden'}}>
              {image.originalSource && image.originalSource.length > 0 ? <img alt="123" src={image.originalSource} style={{position: 'absolute',top:0, left:0, bottom:0, right: 0, marginTop:'auto', marginBottom:'auto', width:'100%'}}></img> : <CircularProgress style={{position: 'absolute',top:12, left:0, bottom:0, right: 0, marginTop:'auto', marginBottom:'auto', marginLeft:'auto', marginRight:'auto'}} /> }
              <Checkbox size="small" style={{ top:0, left:0,position: 'absolute'}} checked={index === props.selected_variant_image.image_index} onClick={(event) => {onSelect(index, event.target.checked)}}></Checkbox>
              </Box>
              
              </Grid>

              ))}

            </Grid>
        </DialogContent>
        <DialogActions>
        <Button onClick={() => {props.set_selected_variant_image({'variant_index':-1, 'image_index':-1,'is_dialog_open':false})}} variant='outlined'>Cancel</Button>
        {!(props.selected_variant_image.merchant_index != null) ? <Button variant='outlined' onClick={() => {dropzoneRef.current.open()}}>Add image</Button> : null}
          <Button onClick={() => {props.handle_selected_variant_image()}} autoFocus variant='contained'>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default SelectImageDialog;