import * as React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function ToggleButtons(props) {



  const handleChange = (event, newOrder) => {
    console.log('toggle timestamp')
    props.handle_timestamp_order_change(newOrder);
  };

  const children = [
    <ToggleButton value="descending" key="descending">
      <ArrowDownwardIcon />
    </ToggleButton>,
    <ToggleButton value="ascending" key="ascending">
      <ArrowUpwardIcon />
    </ToggleButton>
  ];

  const control = {
    value: props.timestampOrder,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > :not(style) + :not(style)': { mt: 2 },
      }}
    >
      <ToggleButtonGroup size="small" {...control}>
        {children}
      </ToggleButtonGroup>

    </Box>
  );
}
