import * as React from 'react';
import { useEffect } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, TextField, InputAdornment, ListItem, IconButton, Chip, ListItemAvatar, List } from '@mui/material';
import Dropzone from 'react-dropzone'

import SearchIcon from '@mui/icons-material/Search';


function SelectProductsDialog(props) {


  const [rows, setRows] = React.useState([]);


  const onSelect = (index, checked) => {

    const temp_variant_image = {...props.selected_variant_image}

    if(checked){
      temp_variant_image.image_index = index
    }else{
      temp_variant_image.image_index = -1
    }

    props.set_selected_variant_image(temp_variant_image)
    
  }

  useEffect(() =>{

    const filteredRows = props.all_products.filter((row) => {
      return row.title.toLowerCase().includes(props.search_value.toLowerCase());
    });

    setRows(filteredRows);

  }, [props.all_products])




  useEffect(() => {

    if(props.all_products && props.all_products.length > 0){

      const filteredRows = props.all_products.filter((row) => {
        return row.title.toLowerCase().includes(props.search_value.toLowerCase());
      });

      setRows(filteredRows);

    }

  }, [props.search_value])



  function handle_product_select(is_checked, index) {

    const temp_products = [...props.all_products]

    temp_products[index].is_checked = is_checked

    props.set_all_products(temp_products)

  }


  return (
    <div>
      <Dialog
        open={props.product_dialog_open}
        onClose={() => {props.set_product_dialog_open(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
          <DialogTitle id="alert-dialog-title">
            Add products
          </DialogTitle>

          <DialogContent dividers>

          <TextField
              id="outlined_search"
              size='small'
              style={{width:'100%'}}
              value={props.search_value}
              onChange={(event) => {props.set_search_value(event.target.value)}}
              InputProps={{endAdornment : (
                <InputAdornment position="end">
                  <SearchIcon></SearchIcon>
                </InputAdornment>
              ),
              }}
              label="Search products"
            />

            {props.isAllProductsLoading ? <div style={{textAlign:'center', margin:48}}> <CircularProgress /> </div> : null }

            <List>

                {rows.map((product, index) => {

                var url = "";

                if(product.media.length > 0 && product.media[0].url){
                  url = product.media[0].url
                }else{
                  url = "/static/images/brand_identity.png"
                }

                    
                return(

                      <ListItem
                      style={{paddingLeft:0}}
                        key={index}
                        secondaryAction={
                      <>
                        <IconButton edge="end" aria-label="comments">
                            <Chip key={index} sx={{width:56}} size="small" color={product.status === "Active" ? "success" : "info"} label={product.status} /> 
                        </IconButton>
                        {/*<IconButton sx={{marginLeft:2}} edge="end" aria-label="comments" onClick={() =>{console.log("remove product clicked")}}>
                        <CloseIcon></CloseIcon>
                        </IconButton>*/}
                        </>
                        }
                      >
                      <Checkbox size="small" checked={product.is_checked} onClick={(event) => {handle_product_select(event.target.checked, index)}}></Checkbox>
                      <ListItemAvatar>
                      <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{border:'1px solid lightgray', width:50 ,height:50,borderRadius:4}}
                      >
                          <img style={{width:48,height: 48}} alt="snag" src={url}/>
                      </Box>    
                      </ListItemAvatar>
                      <Link href="#" underline="hover">
                      <Typography sx={{padding:2}} fontSize={12}>{product.title}</Typography>
                      </Link>
                      </ListItem>

                )})}


              </List>
        </DialogContent>
        <DialogActions>
        <Button onClick={() => {props.set_product_dialog_open(false);props.reset_all_products()}} variant='outlined'>Cancel</Button>
          <Button onClick={() => {props.handle_selected_products()}} autoFocus variant='contained'>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default SelectProductsDialog;