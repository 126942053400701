import { Grid, Paper, TextField, FormControlLabel, Checkbox, MenuItem, FormControl, Typography, Select, InputLabel, InputAdornment, Divider} from '@mui/material';


import React, { useEffect } from 'react';

import CKEditor from 'ckeditor4-react';

CKEditor.editorUrl = '/ckeditor-4/ckeditor.js'
/*

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



ClassicEditor.defaultConfig = {
  toolbar: {
  items: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'outdent',
      'indent',
      '|',
      'uploadImage',
      'blockQuote',
      'insertTable',
      'mediaEmbed',
      'undo',
      'redo'
  ]
},
mediaEmbed: {
  previewsInData: true
},
image: {
  toolbar: [
      'imageStyle:inline',
      'imageStyle:block',
      'imageStyle:side',
      '|',
      'toggleImageCaption',
      'imageTextAlternative',
  ]
},
table: {
  contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
},
language: 'eng'
}

<CKEditor
                    editor={ ClassicEditor }
                    onReady={ editor => { 
                        // You can store the "editor" and use when it is needed.
                        props.editor_ready();
                    } }
                    onChange={ ( event, editor ) => {
                      //// console.log('all items were, ', Array.from( editor.ui.componentFactory.names() ) )
                        //// console.log( { event, editor, data } );
                       // // console.log('editor data changed, ', editor.getData())
                        props.set_ck_state(editor.getData())
                        //const val = titleRef.current.value;
                      //  // console.log('title',val);
                    } }
                    onBlur={ ( event, editor ) => {
                        // console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        // console.log( 'Focus.', editor );
                      //  const val = titleRef.current.value;
                      //  // console.log('title',val);
                    } }
                />

*/

function Editor(props){

     //console.log('Editor render', props.ck_state)

    return(

      <div style={{overflow:'visible'}} >
        
           <CKEditor 
          onChange={evt => {
            props.set_ck_state(evt.editor.getData().toString())
          }}
          data= {props.ck_state}
          onInstanceReady={ (evt) => {
            props.editor_ready();
            evt.editor.on("beforeCommandExec", function(event) {
              // Show the paste dialog for the paste buttons and right-click paste
              if (event.data.name == "paste") {
                  event.editor._.forcePasteDialog = true;
              }
              // Don't show the paste dialog for Ctrl+Shift+V
              if (event.data.name == "pastetext" && event.data.commandData.from == "keystrokeHandler") {
                  event.cancel();
              }
          })
          }}
          config = {{
          extraPlugins:['stylesheetparser'],
          //contentsCss:props.style_sheet,
          allowedContent:true,
          toolbar:[
            { name: 'document', items: [ 'Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates' ] },
            { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
            { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
            '/',
            { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
            { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
            { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
            { name: 'insert', items: [ 'Image', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
            '/',
            { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
            { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
            { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
            
          ],
          fontSize: {
            options: [
                // Numerical values.
            ],
            supportAllValues: true
          }
          }
          }
          />   
                
      </div>


    )




}


export default Editor;