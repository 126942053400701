import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, FormControl, InputLabel, Select, MenuItem, List, Container, ListItem, IconButton} from '@mui/material';

import Dropzone from 'react-dropzone'

import CardTravelIcon from '@mui/icons-material/CardTravel';

import { UserCircle as UserCircleIcon } from '../../../../icons/user-circle';

import DeleteIcon from '@mui/icons-material/Delete';

import FolderIcon from '@mui/icons-material/Folder';

import ListItemAvatar from '@mui/material/ListItemAvatar';

import ListItemText from '@mui/material/ListItemText';

import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../../../store/cms-context';

import Inventory2Icon from '@mui/icons-material/Inventory2';

import CurrenciesInfo from '../../../../Utilities/CurrenciesInfo';




const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));


function CarrierMappingDialog(props) {

  console.log("carrier dialog rendered: ", props.carrier_mapping_dialog_state)

  const cmsContext = React.useContext(CmsContext);


  function set_carrier_mapping(key, value) {
    
    const temp_state = {...props.carrier_mapping_dialog_state}

    temp_state.carrier_mapping[key] = value

    props.set_carrier_mapping_dialog_state(temp_state)
    
  }



  function handle_carrier_mapping() {
    
    const temp_dialog_state = {...props.carrier_mapping_dialog_state}

    const carrier_mapping = {...temp_dialog_state.carrier_mapping}

    console.log("dialog state was: ", temp_dialog_state)

    /*
    
    if(temp_dialog_state.carrier_mapping.source.toString().trim().length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid source!")
      return
    }

    */

    if(!temp_dialog_state.carrier_mapping.source_carrier || temp_dialog_state.carrier_mapping.source_carrier.toString().trim().length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid source carrier!")
      return
    }


    if(temp_dialog_state.carrier_mapping.target.toString().trim().length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid target!")
      return
    }

    if(!temp_dialog_state.carrier_mapping.source_carrier || temp_dialog_state.carrier_mapping.source_carrier.toString().trim().length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid target carrier!")
      return
    }

    console.log("carrier mapping was: ", temp_dialog_state.carrier_mapping)

    const payload = {}

    payload.source_carrier_name = carrier_mapping.source_carrier

    payload.target_carrier_name = carrier_mapping.target_carrier

    temp_dialog_state.carriers_data.mintsoft_clients.forEach((mintsoft_client) => {

      if(mintsoft_client.name === carrier_mapping.target){

        payload.target = mintsoft_client.name

      }

    })

    /*

    payload.source_carrier = carrier_mapping.source_carrier

    payload.target_carrier = carrier_mapping.target_carrier

    temp_dialog_state.carriers_data.shopify_stores.forEach((store) => {

      if(store.store === carrier_mapping.source){

        payload.source = store.store

      }

    })

    temp_dialog_state.source_shipping_carriers.forEach((carrier) => {

      if(carrier.id === payload.source_carrier){
        payload.source_carrier_name = carrier.name
      }

    })

    temp_dialog_state.target_shipping_carriers.forEach((carrier) => {

      if(carrier.ID === payload.target_carrier){
        payload.target_carrier_name = carrier.Name
      }

    })

    temp_dialog_state.carriers_data.mintsoft_clients.forEach((mintsoft_client) => {

      if(mintsoft_client.name === carrier_mapping.target){

        payload.target = mintsoft_client.name

      }

    })

    */
    
    payload.target_type = 'mintsoft'

    if(carrier_mapping.id) {

      payload.id = carrier_mapping.id

    }

    console.log("payload was: ", payload)


    props.post_carrier_mapping(payload, props.carrier_mapping_dialog_state.type)


  }


  function handle_close(){

    const temp_state = {...props.carrier_mapping_dialog_state}

    temp_state.is_dialog_open = false

    props.set_carrier_mapping_dialog_state(temp_state)

  }

  
  function handle_source_change(event){

    var shopify_store = event.target.value

    console.log("shopify store was: ", shopify_store)

    const temp_dialog_state = {...props.carrier_mapping_dialog_state};

    temp_dialog_state.carrier_mapping.source = shopify_store;

    temp_dialog_state.carrier_mapping.source_carrier = ''

    props.set_carrier_mapping_dialog_state(temp_dialog_state);

    props.get_shopify_carriers(shopify_store)

  }

  function handle_target_change(event){

    const mintsoft_client = event.target.value

    console.log("mintsoft client was: ", mintsoft_client)

    const temp_dialog_state = {...props.carrier_mapping_dialog_state};

    temp_dialog_state.carrier_mapping.target = mintsoft_client

    temp_dialog_state.carrier_mapping.target_carrier = ''

    props.set_carrier_mapping_dialog_state(temp_dialog_state);

   // props.get_mintsoft_couriers(mintsoft_client)

  }

  console.log("dialog state was: ", props.carrier_mapping_dialog_state)

  return (

      <Dialog
        open={props.carrier_mapping_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.carrier_mapping_dialog_state.type} Target
        </DialogTitle>

        <DialogContent dividers>

        {props.carrier_mapping_dialog_state && props.carrier_mapping_dialog_state.carriers_data && Object.keys(props.carrier_mapping_dialog_state.carriers_data).length > 0 ? <Grid
            container
            spacing={3}
            alignItems="center"
            >

              <Grid item xs={12}>

                <div style={{textAlign:'center', marginTop:16}}>

                  <img src="/static/images/shopify.png" alt='g_ads' style={{width:80, height:80}}></img>
                  <img src="/static/images/sync_blue.png" alt='sync_blue' style={{width:20, height:20, marginLeft:20, marginRight:20, marginBottom:24}}></img>
                  <img src="/static/images/mintsoft.png" alt='g_search' style={{width:80, height:80}}></img>

                </div>

              </Grid>

              {/* <Grid item xs={12}>

              <FormControl sx={{width: '100%'}}>

              <InputLabel id="demo-simple-select-autowidth-label">Source</InputLabel>

              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                fullWidth
                value={props.carrier_mapping_dialog_state.carrier_mapping.source}
                onChange={(event) => {handle_source_change(event)}}
                label="Source"
              >

                {props.carrier_mapping_dialog_state.carriers_data.shopify_stores.map((shopify_store, index) => (
                  <MenuItem key={index} value={shopify_store.store}>{shopify_store.name}</MenuItem>
                ))}
                
              </Select>

              </FormControl>

              </Grid> */} 


              <Grid item xs={12}>

              <TextField fullWidth id="outlined-basic" label="Source" variant="outlined" value={props.carrier_mapping_dialog_state.carrier_mapping.source_carrier} onChange={(event) => {const temp_dialog_state = {...props.carrier_mapping_dialog_state}; temp_dialog_state.carrier_mapping.source_carrier = event.target.value; props.set_carrier_mapping_dialog_state(temp_dialog_state)}}/>

              </Grid>

              {props.carrier_mapping_dialog_state.source_shipping_carriers.length > 0 ? <Grid item xs={12}>

                <FormControl sx={{width: '100%'}}>

                <InputLabel id="demo-simple-select-autowidth-label">Carrier</InputLabel>

                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  fullWidth
                  value={props.carrier_mapping_dialog_state.carrier_mapping.source_carrier}
                  onChange={(event) => {const temp_dialog_state = {...props.carrier_mapping_dialog_state}; temp_dialog_state.carrier_mapping.source_carrier = event.target.value; props.set_carrier_mapping_dialog_state(temp_dialog_state); }}
                  label="Carrier"
                >

                  {props.carrier_mapping_dialog_state.source_shipping_carriers.map((source_carrier, index) => (
                    <MenuItem key={index} value={source_carrier.id}>{source_carrier.name}</MenuItem>
                  ))}
                  
                </Select>

                </FormControl>

                </Grid> : null }


            
              {Object.keys(props.carrier_mapping_dialog_state.carriers_data).length > 0 ? <Grid item xs={12}>

              <FormControl sx={{width: '100%'}}>

              <InputLabel id="demo-simple-select-autowidth-label">Target</InputLabel>

              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                fullWidth
                value={props.carrier_mapping_dialog_state.carrier_mapping.target}
                onChange={(event) => {handle_target_change(event)}}
                label="Target"
              >

                {props.carrier_mapping_dialog_state.carriers_data.mintsoft_clients.map((mintsoft_client, index) => (
                  <MenuItem key={index} value={mintsoft_client.name}>{mintsoft_client.name}</MenuItem>
                ))}
                
              </Select>

              </FormControl>

              </Grid> : null }

              {/*props.carrier_mapping_dialog_state.target_shipping_carriers.length > 0 ? <Grid item xs={12}>

                <FormControl sx={{width: '100%'}}>

                <InputLabel id="demo-simple-select-autowidth-label">Carrier</InputLabel>

                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  fullWidth
                  value={props.carrier_mapping_dialog_state.carrier_mapping.target_carrier}
                  onChange={(event) => {const temp_dialog_state = {...props.carrier_mapping_dialog_state}; temp_dialog_state.carrier_mapping.target_carrier = event.target.value; props.set_carrier_mapping_dialog_state(temp_dialog_state); }}
                  label="Carrier"
                >

                  {props.carrier_mapping_dialog_state.target_shipping_carriers.map((target_carrier, index) => (
                    <MenuItem key={index} value={target_carrier.ID}>{target_carrier.Name}</MenuItem>
                  ))}
                  
                </Select>

                </FormControl>

                </Grid> : null */}

                <Grid item xs={12}>

                <TextField fullWidth id="outlined-basic" label="Target" variant="outlined" value={props.carrier_mapping_dialog_state.carrier_mapping.target_carrier} onChange={(event) => {const temp_dialog_state = {...props.carrier_mapping_dialog_state}; temp_dialog_state.carrier_mapping.target_carrier = event.target.value; props.set_carrier_mapping_dialog_state(temp_dialog_state)}}/>

                </Grid>
                          
            </Grid> : null}

        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        <LoadingButton variant='outlined' loading={props.carrier_mapping_dialog_state.isLoading} disabled={false} onClick={() => {handle_carrier_mapping()}}>{props.carrier_mapping_dialog_state.type}</LoadingButton>
        </DialogActions>
      </Dialog>

  );
}


export default React.memo(CarrierMappingDialog);