import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../store/cms-context';

import { getAccessJwtToken } from '../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import ContactDialog from "../ContactDialog/ContactDialog";
import ContactsListTable from "../ContactsListTable/ContactsListTable";
import { default_contact } from "../../Utilities/Metrics";

import cloneDeep from 'lodash/cloneDeep';


function AnalyticsCMS(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_contacts, set_all_contacts] = React.useState([]);

  const [active_accounts, set_active_accounts] = React.useState([]);

  const [email_time, set_email_time] = React.useState('');

  const [contact_dialog_state, set_contact_dialog_state] = React.useState(cloneDeep(default_contact));


  useEffect(() => {

    isMountedRef.current = true

    get_all_contacts()

    return () => isMountedRef.current = false;

  }, [])


 


  function handle_active_accounts_change(event, newValue){

    set_active_accounts(newValue)

  }

  function show_create_contact_dialog(){
    
    const temp_dialog_state = cloneDeep(default_contact);

    temp_dialog_state.is_dialog_open = true

    set_contact_dialog_state(temp_dialog_state);

  }



  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Contacts</Typography>

          <div>

          <Button variant="contained" onClick = {() => {show_create_contact_dialog()}}>Create Contact</Button>

          </div>

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >


          <ContactsListTable delete_contacts={delete_contacts} set_dialog_contact={set_dialog_contact} all_contacts={all_contacts} set_all_contacts={set_all_contacts}>

          </ContactsListTable>

    
        </Grid>


      </Grid>

    </Container>      


    </Box>

     }

     <ContactDialog post_contact={post_contact} contact_dialog_state={contact_dialog_state} set_contact_dialog_state={set_contact_dialog_state}></ContactDialog>

    </> 


  );

  function set_dialog_contact(contact, is_open){

    const temp_contact_state = cloneDeep(default_contact)

    temp_contact_state.contact = contact

    temp_contact_state.is_dialog_open = is_open

    temp_contact_state.type = "Update"

    set_contact_dialog_state(temp_contact_state)

  }

  function get_all_contacts() {
    
    console.log('store_access_token')


    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/contacts',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':jwt,
            'email':cmsContext.current_shop.email
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
  
              setIsLoading(false)
  
              console.log('result was',result)

             
              if(result.contacts){

                console.log('setting analuytis')

                if(result.contacts.length === 0){

                  cmsContext.setSnackBarOpen(true, "info", "No contacts yet. Let's create one to get started.")

                  return

                }


                const all_contacts = []

                var temp_contact;

                result.contacts.forEach((contact) =>{
                  temp_contact = cloneDeep(default_contact.contact)
                  for(var key in contact){
                    temp_contact[key] = contact[key]
                  }
                  temp_contact.is_checked = false
                  console.log('temp contact was: ', temp_contact)
                  all_contacts.push(temp_contact)
                })

              
                set_all_contacts(all_contacts)

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }

              
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
           
      )

    })


  }


  function set_dialog_loading(is_loading){

    const temp_contact_state = {...contact_dialog_state}

    temp_contact_state.isLoading = is_loading

    set_contact_dialog_state(temp_contact_state)

  }

  function reset_dialog_state(){

    set_contact_dialog_state(cloneDeep(default_contact))

  }

  function post_contact(contact, request_type){

    set_dialog_loading(true)

    console.log('updating')

    var request_body = {}

    request_body.contact = contact

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/contacts', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)
                reset_dialog_state()

                get_all_contacts()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                set_dialog_loading(false)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
                set_dialog_loading(false)

              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })



  }

  function delete_contacts(){

    console.log('delete contacts')

    var temp_contacts = [...all_contacts].filter((contact) => contact.is_checked);

    console.log('filtered were', temp_contacts)

    if(temp_contacts.length === 0){
      // might never execute (need to select contacts to display delete button)
      cmsContext.setSnackBarOpen(true, "error", "Please select valid contacts!")
      return;
    }else if(temp_contacts.length > 20){
      cmsContext.setSnackBarOpen(true, "error", "Please select a maximum of 20 contacts!")
      return;
    }

    temp_contacts = temp_contacts.map((contact) => {

      return({'id': contact.id})

    })

    console.log('final contacts were', temp_contacts)

    const request_body = {}

    request_body.contacts = temp_contacts

    request_body.request_type = "delete"

    request_body.account_email = cmsContext.current_shop.email
    

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{


      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/contacts',{
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  console.log('result was',result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_all_contacts()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {
                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)
                }
          );
  
      });

  }


};


export default AnalyticsCMS;