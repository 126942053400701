import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, makeStyles, Select, FormControl, InputLabel, MenuItem, OutlinedInput, InputAdornment} from '@mui/material';
import Dropzone from 'react-dropzone'

import { UserCircle as UserCircleIcon } from '../../../icons/user-circle';

import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../../store/cms-context';

import SyncIcon from '@mui/icons-material/Sync';


import cloneDeep from 'lodash/cloneDeep';

const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));

function NewTaskDialog(props) {
  

  console.log('state was: ', props.link_dialog_state)


  function set_link_state(key, value){
    
    const temp_state = {...props.link_dialog_state}

    temp_state[key] = value

    props.set_link_dialog_state(temp_state)
    
  }


  
  

  function handle_close(){

    const temp_state = {...props.link_dialog_state}

    temp_state.is_dialog_open = false

    props.set_link_dialog_state(temp_state)

  }

  return (
    <div>

      <Dialog
        open={props.link_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.link_dialog_state.type} Link Instagram and Shopify Streams
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

            <Grid item xs={12}>

              <div style={{textAlign:'center', marginTop:16}}>

                <img src="/static/images/instagram.png" alt='g_search' style={{width:80, height:80}}></img>
                <img src="/static/images/sync_blue.png" alt='sync_blue' style={{width:20, height:20, marginLeft:20, marginRight:20, marginBottom:24}}></img>
                <img src="/static/images/shopify.png" alt='g_ads' style={{width:80, height:80}}></img>

              </div>

            </Grid>

            {props.link_dialog_state ? <Grid
              item
              xs={12}
            >

            <FormControl fullWidth sx={{marginTop:1}}>
              <InputLabel htmlFor="outlined-adornment-cpa">Stream name</InputLabel>
              <OutlinedInput
                id="outlined-adornment-cpa"
                value={props.link_dialog_state.stream_name}
                onChange={(event) => {const temp_state = {...props.link_dialog_state}; temp_state.stream_name = event.target.value; props.set_link_dialog_state(temp_state)}}
                label="Stream name"
              />
            </FormControl>

            </Grid> : null }

              {props.link_dialog_state ? <Grid item xs={12}>

              <FormControl sx={{ width:'100%', marginTop:0}}>

              <InputLabel id="demo-simple-select-autowidth-label">Stream</InputLabel>

              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                fullWidth
                value={props.link_dialog_state.selected_stream_type}
                onChange={(event) => {const temp_state = {...props.link_dialog_state}; temp_state.selected_stream_type = event.target.value; props.set_link_dialog_state(temp_state);}}
                label="Stream"
              >

                {props.link_dialog_state.stream_types.map((stream, index) => (
                  <MenuItem key={index} value={stream}>{stream}</MenuItem>
                ))}
                
              </Select>

              </FormControl>

              </Grid> : null}

            {props.link_dialog_state && props.link_dialog_state.selected_stream_type === "Hashtag" ? <Grid
              item
              xs={12}
            >

            <FormControl fullWidth >
              <InputLabel htmlFor="outlined-adornment-cpa">Hashtag</InputLabel>
              <OutlinedInput
                id="outlined-adornment-cpa"
                value={props.link_dialog_state.hash_tag_value}
                onChange={(event) => {const temp_state = {...props.link_dialog_state}; temp_state.hash_tag_value = event.target.value; props.set_link_dialog_state(temp_state)}}
                startAdornment={<InputAdornment position="start">#</InputAdornment>}
                label="Hashtag"
              />
            </FormControl>

            </Grid> : null }


            {props.link_dialog_state && props.link_dialog_state.selected_stream_type === "Account" ? <Grid item xs={12}>

            <FormControl sx={{ width:'100%', marginTop:0}}>

            <InputLabel id="demo-simple-select-autowidth-label">Instagram Account</InputLabel>

            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              fullWidth
              value={props.link_dialog_state.selected_instagram_account && Object.keys(props.link_dialog_state.selected_instagram_account).length > 0 ? props.link_dialog_state.selected_instagram_account.id : ''}
              onChange={(event)=>{console.log('event target value: ', event.target.value); const temp_dialog_state = {...props.link_dialog_state} ;const index = temp_dialog_state.instagram_accounts.findIndex(account => account.id === event.target.value); console.log('found_index', index); temp_dialog_state.selected_instagram_account = cloneDeep(temp_dialog_state.instagram_accounts[index]);props.set_link_dialog_state(temp_dialog_state)}}
              label="Instagram Account"
            >

              {props.link_dialog_state.instagram_accounts.map((item, index)=> (
                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
              ))}
              
            </Select>

            </FormControl>


            </Grid> : null}
              
            {props.link_dialog_state && props.link_dialog_state.selected_stream_type.length > 0  ? <Grid item xs={12}>

            <FormControl sx={{ width:'100%', marginTop:0}}>

            <InputLabel id="demo-simple-select-autowidth-label">Shopify Account</InputLabel>

            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              fullWidth
              value={Object.keys(props.link_dialog_state.selected_shopify_account).length > 0 ? props.link_dialog_state.selected_shopify_account.store_domain : ''}
              onChange={(event) => {console.log('value was: ', event.target.value); const temp_state = {...props.link_dialog_state}; const index = temp_state.shopify_accounts.findIndex(store => store.store_domain === event.target.value);  temp_state.selected_shopify_account = temp_state.shopify_accounts[index]; props.set_link_dialog_state(temp_state)}}
              label="Shopify Account"
            >

              {props.link_dialog_state.shopify_accounts.map((account, index) => (
                <MenuItem key={index} value={account.store_domain}>{account.name}</MenuItem>
              ))}
              
            </Select>

            </FormControl>

            </Grid> : null }

            {props.link_dialog_state ? <Grid item xs={12}>

              <FormControl sx={{ width:'100%', marginTop:0}}>

              <InputLabel id="demo-simple-select-autowidth-label">Sort type</InputLabel>

              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                fullWidth
                value={props.link_dialog_state.sort_type}
                onChange={(event) => {const temp_state = {...props.link_dialog_state}; temp_state.sort_type = event.target.value; props.set_link_dialog_state(temp_state);}}
                label="Sort type"
              >

                {props.link_dialog_state.sort_options.map((sort_option, index) => (
                  <MenuItem key={index} value={sort_option}>{sort_option}</MenuItem>
                ))}
                
              </Select>

              </FormControl>

              </Grid> : null}


            {props.link_dialog_state ? <Grid
              item
              xs={12}
            >

            <FormControl fullWidth >
              <InputLabel htmlFor="outlined-adornment-cpa">Number of Posts</InputLabel>
              <OutlinedInput
                id="outlined-adornment-cpa"
                type='number'
                value={props.link_dialog_state.number_of_posts}
                onChange={(event) => {const temp_state = {...props.link_dialog_state}; temp_state.number_of_posts = event.target.value; props.set_link_dialog_state(temp_state)}}
                label="Number of Posts"
              />
            </FormControl>

            </Grid> : null}


            </Grid>


            
        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        <LoadingButton variant='outlined' loading={props.link_dialog_state.isLoading} disabled={false} onClick={() => {props.handle_stream_update()}}>{props.link_dialog_state.action_type}</LoadingButton>
        </DialogActions>
      </Dialog>

    </div>
  );




}




export default NewTaskDialog;