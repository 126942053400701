import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import { cloneDeep } from 'lodash';
import { default_deploy_dialog, hide_dialog } from '../../Utilities/Metrics';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import DateTimePicker from '@mui/lab/DateTimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { Grid, TextField, Select, MenuItem, FormControl, InputLabel, OutlinedInput, FormControlLabel, Checkbox,  FormHelperText, Autocomplete} from '@mui/material';

import { ProductStatus } from '../../Utilities/Metrics';



function DeployDialog(props) {

  const handleClose = () => {

    hide_dialog(props.deploy_dialog_state, props.set_deploy_dialog_state)

  };

  function handle_change(key, value, index) {

    const temp_state = {...props.deploy_dialog_state}

    if(props.type === "bulk" && temp_state[key][index]['key'] === 'status' && props.deploy_dialog_state.product_status.length > 0){
      temp_state[key][index].is_checked = true
    }else{
      temp_state[key][index].is_checked = value
    }


    props.set_deploy_dialog_state(temp_state)
    
  }

  function handle_selected_stores_change(event, newValue){

    const temp_state = {...props.deploy_dialog_state}

    temp_state.selected_stores = newValue

    props.set_deploy_dialog_state(temp_state)

  }

  function handle_all_checked(checked){

    const temp_state = {...props.deploy_dialog_state}

    temp_state.options.forEach((option) =>{
      if(props.type === "bulk" && option['key'] === 'status' && props.deploy_dialog_state.product_status.length > 0){
        option.is_checked = true
      }else{
        option.is_checked = checked
      }
    })

    temp_state.all_checked = checked

    props.set_deploy_dialog_state(temp_state)

  }

  return (
    
      <Dialog
        fullWidth
        open={props.deploy_dialog_state.is_dialog_open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Select components to Deploy
        </DialogTitle>
        <DialogContent dividers>
        <Grid
            container
            spacing={3}
            alignItems="center"
            >

             {props.product_type === "Master" ? <Grid item xs={12}>

              <Autocomplete
                    multiple
                    id="selected-stores"
                    options={props.all_stores}
                    getOptionLabel={(option) => option.name}
                    value={props.deploy_dialog_state.selected_stores}
                    isOptionEqualToValue={(option, value) => option.store_domain === value.store_domain}
                    onChange={(event, newValue) => {handle_selected_stores_change(event, newValue)}}
                    renderOption={(props, option, index) => {

                      const key = `listItem-${option.store_domain}`;
                      return (
                        <li {...props} key={key}>
                          {option.name}
                        </li>
                      );
                      
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Selected Stores" placeholder="Stores" />
                    )}
                />
                        

              </Grid> : null}

            {props.type === "bulk" ? <>
            
            
            <Grid item xs={12}>


            <LocalizationProvider dateAdapter={AdapterDateFns}>

            <DateTimePicker
              renderInput={(params) => <TextField fullWidth {...params}  />}
              value={props.deploy_dialog_state.schedule_date}
              onChange={(newValue) => {
                const temp_val = {...props.deploy_dialog_state};
                temp_val.schedule_date = newValue;
                console.log('date was', typeof(temp_val.date))
                props.set_deploy_dialog_state(temp_val);
              }}
              label="Schedule availability"
            />
            </LocalizationProvider>




            </Grid> 

            

            <Grid
            item
            xs={12}
              >

              <FormControl fullWidth size="small">

                <Select
                  id="product-status-select"
                  fullWidth
                  value={props.deploy_dialog_state.product_status}
                  onChange={(event) =>{
                    const temp_state = {...props.deploy_dialog_state}
                    temp_state.product_status = event.target.value
                    props.set_deploy_dialog_state(temp_state)
                  
                  }}
                  >

                      {ProductStatus.map((status, index) => {
                          return(<MenuItem value={status} key={index}>{status}</MenuItem>)
                      })
                      }
                  
                </Select>

              </FormControl>

              </Grid>

              </>
            
            : null  }

              <Grid item xs={12}>

              <FormControlLabel control={<Checkbox checked={props.deploy_dialog_state.all_checked} onChange={(event) => {handle_all_checked(event.target.checked)}} />} label={props.deploy_dialog_state.all_checked ? "Deselect All" : "Select All"} />

              </Grid>

              {props.deploy_dialog_state.options.map((option,index) => {

                return(

                  <Grid key={index} item xs={12}>

                  <FormControlLabel control={<Checkbox checked={option.is_checked} onChange={(event) => {handle_change("options", event.target.checked, index)}} />} label={option.title} />

                  </Grid>
    
                )

              }) }

            
           
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>Dismiss</Button>
          <LoadingButton variant='outlined' loading={props.deploy_dialog_state.isLoading} onClick={() => {props.sendProductRequest("Deploy")}}>Deploy</LoadingButton>
        </DialogActions>
      </Dialog>

  );
}


export default DeployDialog;