import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Slider,Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../store/cms-context';

import { getAccessJwtToken } from '../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Analytics, Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import BarChart from "./SubComponents/BarChart";
import TimeZones from "../../Utilities/TimeZones";
import TimezoneSelect from "react-timezone-select";
import { clone, cloneDeep, min } from "lodash";
import { ONE_DAY } from "../../Utilities/Metrics";



const marks = [
  {
    value: 0,
    label: '0x',
  },
  {
    value: 4,
    label: '4x',
  },
  {
    value: 5,
    label: '5x',
  },
  {
    value: 20,
    label: '20x',
  },
];


function GoogleAdsManageSearch(props) {


  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_campaigns, set_all_campaigns] = React.useState([]);

  const [all_links, set_all_links] = React.useState([]);

  const [analytics, set_analytics] = React.useState([]);

  const navigate = useNavigate();


  useEffect(() => {

    isMountedRef.current = true

    get_configurations_settings()

    return () => isMountedRef.current = false;

  }, [])

  useEffect(() =>{

    console.log('all campaigns: ', all_campaigns)

  }, [all_campaigns])



  console.log('manage search init')

  function valuetext(value) {
    return `${value}x`;
  }


  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Google Search Ads</Typography> 

          {all_links && all_links.length > 0 ? <Button variant="contained" onClick = {() => {update_configurations()}}>Update Configurations</Button> : null}

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >

      <Paper  elevation = {2} style={{marginTop:16, padding:20}}>
        
        <Grid
          container
          spacing={3}
          alignItems="center"
        >


    

        {all_links.map((account, index) => { 

          return(

            <Grid key={index} item xs={12} >

            <Grid container spacing={2}>

            <Grid
            item 
            xs={12}
            >

              <Typography style={{marginBottom:8}} fontSize={12}><strong>{account.descriptive_name}</strong></Typography>

            </Grid>

            <Grid
              item
              xs={12}
             >

              <Autocomplete
                    multiple
                    id="selected-campaigns"
                    sx={{marginBottom:1}}
                    options={account.campaigns}
                    getOptionLabel={(option) => option.campaign_name}
                    value={account.selectedCampaigns ? account.selectedCampaigns : []}
                    isOptionEqualToValue={(option, value) => option.campaign_id === value.campaign_id}
                    onChange={(event, newValue) => {const temp_links = [...all_links]; all_links[index].selectedCampaigns = newValue; set_all_links(temp_links);}}
                    renderOption={(props, option, index) => {

                      const key = `listItem-${option.campaign_id}`;
                      return (
                        <li {...props} key={key}>
                          {option.campaign_name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Selected Campaigns" placeholder="Selected Campaigns" />
                    )}
              />
                          

            </Grid>

            <Grid
              item
              xs={6}
              md={4}
            >

            <FormControl fullWidth >
              <InputLabel htmlFor="outlined-adornment-cpa">Cpa Threshold</InputLabel>
              <OutlinedInput
                id="outlined-adornment-cpa"
                value={all_links[index].adRules.cpa_threshold ? all_links[index].adRules.cpa_threshold : ''}
                type="number"
                size="small"
                onChange={(event) => {

                  console.log('value was: ', event.target.value)

                  const temp_accounts = [...all_links];

                  if(event.target.value >= 0){
                    temp_accounts[index].adRules.cpa_threshold = event.target.value;
                  }
                  else{
                    temp_accounts[index].adRules.cpa_threshold = 0;
                  }

                  set_all_links(temp_accounts);

                }}
                startAdornment={<InputAdornment position="start">£</InputAdornment>}
                label="Cpa Threshold"
              />
            </FormControl>

            </Grid>

            <Grid
              item
              xs={6}
              md={4}
            >


            <FormControl fullWidth >

              <InputLabel htmlFor="outlined-adornment-spend">Spend Threshold</InputLabel>

              <OutlinedInput
                id="outlined-adornment-spend"
                value={all_links[index].adRules.spend_threshold ? all_links[index].adRules.spend_threshold : ''}
                type="number"
                size="small"
                onChange={(event) => {

                  console.log('value was: ', event.target.value)

                  const temp_accounts = [...all_links];

                  if(event.target.value >= 0){
                    temp_accounts[index].adRules.spend_threshold = event.target.value;
                  }
                  else{
                    temp_accounts[index].adRules.spend_threshold = 0;
                  }

                  set_all_links(temp_accounts);

                }}
                startAdornment={<InputAdornment position="start">£</InputAdornment>}
                label="Spend Threshold"
              />

            </FormControl>

            </Grid>

            <Grid item xs={4}>

            <FormControl fullWidth >

            <InputLabel htmlFor="outlined-adornment-budget-change">Budget Change</InputLabel>

            <OutlinedInput
              id="outlined-adornment-budget-change"
              size="small"
              startAdornment={<InputAdornment position="start">%</InputAdornment>}
              label="Budget change"
              type="number"
              value={all_links[index].adRules.budget_change ? all_links[index].adRules.budget_change : ''}
              onChange={(event) => {const temp_links = [...all_links]; temp_links[index].adRules.budget_change = event.target.value; set_all_links(temp_links)}}
            />

            </FormControl>

            </Grid>

            <Grid item xs={12}>
            
            <div style={{marginLeft:10, marginRight:10}}>

            <Typography fontSize={14}>

             <strong>ROAS Threshold</strong>

            </Typography>

            <Slider
              sx={{marginTop:1}}
              getAriaLabel={() => 'ROAS Threshold'}
              defaultValue={[4, 5]}
              getAriaValueText={valuetext}
              value={account.adRules.roas_threshold ? account.adRules.roas_threshold : [4, 5]}
              onChange={(event) => {const temp_links = [...all_links]; temp_links[index].adRules.roas_threshold = event.target.value; set_all_links(temp_links)}}
              step={1}
              min={0}
              max={20}
              marks={marks}
              valueLabelDisplay="auto"
            />

            </div>

            </Grid>


          </Grid>

          </Grid>

          )
            
          })}

        </Grid>

        </Paper>


    
        </Grid>

        { analytics.length > 0 ? analytics.map((account_level_analytics, index) =>{

          return(
            <Grid key={index} item xs={12}>
              <BarChart analytics={account_level_analytics}></BarChart>
            </Grid>
          )

        }) : null}



      </Grid>

    </Container>      


    </Box>

     }

    </> 


  );

  function get_configurations_settings() {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/gads/search_ads/configurations',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':jwt,
            'email':cmsContext.current_shop.email
          }})
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was', cloneDeep(result))

              if(result.links){

                const temp_links = [...result.links]

                if(temp_links.length === 0){
                  cmsContext.setSnackBarOpen(true, "info", "Connect accounts under Search Console to get started!")
                  return
                }

                temp_links.forEach((temp_link) =>{
                  if(temp_link.adRules == null){
                    temp_link.adRules = {'roas_threshold':[4,5]}
                  }
                })

                set_all_links(temp_links)
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
      )

    })


  }

  function update_configurations(){

    console.log('all links: ', all_links)

    var links = cloneDeep(all_links)

    var found_error = false;

    for (var i=0; i < links.length; i++){


      if(links[i].adRules.cpa_threshold == null || links[i].adRules.cpa_threshold.length === 0){

        found_error = true;
        break

      }

      if(links[i].adRules.spend_threshold == null || links[i].adRules.spend_threshold.length === 0){

        found_error = true;
        break

      }

      if(links[i].adRules.budget_change == null || links[i].adRules.budget_change.length === 0){

        found_error = true;
        break

      }

      if(links[i].adRules.roas_threshold == null || links[i].adRules.roas_threshold.length === 0){

        found_error = true;
        break

      }

      if(links[i].selectedCampaigns == null){
        links[i].selectedCampaigns = []
      }


    }


    if(found_error){
      cmsContext.setSnackBarOpen(true, "error", "Please enter valid values for all fields")
      return
    }


    const request_body = {}

    request_body.links = links;
    
    console.log('request body: ', request_body)

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/gads/search_ads/configurations', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                get_configurations_settings()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })



  }


};


export default GoogleAdsManageSearch;