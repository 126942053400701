import React, { useState, useRef, useEffect, useContext, useCallback } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../../store/cms-context';

import { getAccessJwtToken } from '../../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import PreOrderGoalDialog from "./SubComponents/PreorderGoalDialog";

import PreOrderGoalListTable from "./SubComponents/PreOrderGoalListTable";

import { default_preorder_goal_dialog } from "../../../Utilities/Metrics";


import cloneDeep from 'lodash/cloneDeep';

function PreOrderGoals(props) {

  console.log("PreOrderGoals rendered")

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_preorder_goals, set_all_preorder_goals] = React.useState([]);

  const [active_accounts, set_active_accounts] = React.useState([]);

  const [email_time, set_email_time] = React.useState('');

  const [preorder_goal_dialog_state, set_preorder_goal_dialog_state] = React.useState(cloneDeep(default_preorder_goal_dialog));


  useEffect(() => {

    isMountedRef.current = true

    get_all_preorder_goals()

    return () => isMountedRef.current = false;

  }, [])


 


  function handle_active_accounts_change(event, newValue){

    set_active_accounts(newValue)

  }

  function show_create_preorder_goal_dialog(){
    
    const temp_dialog_state = cloneDeep(default_preorder_goal_dialog);

    temp_dialog_state.is_dialog_open = true

    temp_dialog_state.type = "Create"

    set_preorder_goal_dialog_state(temp_dialog_state);

  }

  const delete_preorder_goals = useCallback(() => {
    
    console.log('delete preorder_goals')

    var temp_preorder_goals = [...all_preorder_goals].filter((preorder_goal) => preorder_goal.is_checked);

    console.log('filtered were', temp_preorder_goals)

    if(temp_preorder_goals.length === 0){
      // might never execute (need to select preorder_goals to display delete button)
      cmsContext.setSnackBarOpen(true, "error", "Please select valid preorder_goals!")
      return;
    }
    /*
    else if(temp_preorder_goals.length > 20){
      cmsContext.setSnackBarOpen(true, "error", "Please select a maximum of 20 preorder_goals!")
      return;
    }
    */

    temp_preorder_goals = temp_preorder_goals.map((preorder_goal) => {

      return(preorder_goal.id)

    })

    console.log('final preorder_goals were', temp_preorder_goals)

    const request_body = {}

    request_body.preorder_goal = temp_preorder_goals

    request_body.request_type = "Delete"

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)
    

    setIsLoading(true)

    getAccessJwtToken().then((jwt) => {


      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/preorder/goals', {
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  console.log('result was: ', result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_all_preorder_goals()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {

                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)

                }
          );
  
      });

  }, [all_preorder_goals]);


  const handle_product_reset = useCallback((index) => {
    
    console.log('reset preorder goal')

    var temp_goal = [...all_preorder_goals][index]
    
    setIsLoading(true)

    getAccessJwtToken().then((jwt) => {


      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/preorder/goals', {
              crossDomain:true,
              method: 'DELETE',
              headers: {
                'content-type':'application/json',
                'Authorization':jwt
              },
              body: JSON.stringify(temp_goal)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  console.log('result was: ', result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_all_preorder_goals()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {

                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)

                }
          );
  
      });

  }, [all_preorder_goals]);


  const set_dialog_preorder_goal = useCallback((preorder_goal, is_open) => {

      const temp_preorder_goal_state = cloneDeep(default_preorder_goal_dialog)

      temp_preorder_goal_state.preorder_goal.kind = preorder_goal.kind

      temp_preorder_goal_state.preorder_goal.warehouse = preorder_goal.warehouse

      temp_preorder_goal_state.preorder_goal.variant_name = ''

      temp_preorder_goal_state.preorder_goal.product = preorder_goal.product

      temp_preorder_goal_state.preorder_goal.variants = preorder_goal.variants

      temp_preorder_goal_state.preorder_goal.id = preorder_goal.id

      temp_preorder_goal_state.is_dialog_open = is_open

      temp_preorder_goal_state.type = "Update"

      console.log("new state was: ", temp_preorder_goal_state)

      set_preorder_goal_dialog_state(temp_preorder_goal_state)

  }, [])

  const set_dialog_loading = useCallback(( is_loading) => {

    const temp_preorder_goal_state = {...preorder_goal_dialog_state}

    temp_preorder_goal_state.isLoading = is_loading

    console.log('dialog state was: ', temp_preorder_goal_state)

    set_preorder_goal_dialog_state(temp_preorder_goal_state)

  },[preorder_goal_dialog_state])


  const reset_dialog_state = useCallback((is_loading) => {

    set_preorder_goal_dialog_state(cloneDeep(default_preorder_goal_dialog))

  },[])





  const post_preorder_goal = useCallback((preorder_goal, request_type) => {

    set_dialog_loading(true)

    console.log('updating')

    var request_body = {}

    request_body.preorder_goal = preorder_goal

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)

    getAccessJwtToken().then((jwt) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/preorder/goals', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                reset_dialog_state()
                
                get_all_preorder_goals()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                set_dialog_loading(false)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
                set_dialog_loading(false)

              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }, [reset_dialog_state, set_dialog_loading, cmsContext]);







  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px", overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Preorder Targets</Typography>

          <div>

          <Button variant="contained" onClick = {() => {show_create_preorder_goal_dialog()}}>Create Target</Button>

          </div>

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >


         {preorder_goal_dialog_state ? <PreOrderGoalListTable handle_product_reset={handle_product_reset} delete_preorder_goals={delete_preorder_goals} set_dialog_preorder_goal={set_dialog_preorder_goal} all_preorder_goals={all_preorder_goals} set_all_preorder_goals={set_all_preorder_goals}>

          </PreOrderGoalListTable>  : null}


        </Grid>


      </Grid>

    </Container>      


    </Box>

     }

     {preorder_goal_dialog_state ? <PreOrderGoalDialog post_preorder_goal={post_preorder_goal} preorder_goal_dialog_state={preorder_goal_dialog_state} set_preorder_goal_dialog_state={set_preorder_goal_dialog_state}></PreOrderGoalDialog> : null}

    </> 


  );


  function get_all_preorder_goals() {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/preorder/goals',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
  
              setIsLoading(false)
  
              console.log('result was',result)

             
              if(result.data){

                if(result.data.length === 0){

                  cmsContext.setSnackBarOpen(true, "info", "No goals yet. Let's create one to get started.")

                  set_all_preorder_goals([])

                  return

                }

                const all_preorder_goals = []

                var temp_preorder_goal;

                result.data.forEach((preorder_goal) =>{

                    temp_preorder_goal = cloneDeep(preorder_goal)
                    temp_preorder_goal.is_checked = false
                    all_preorder_goals.push(temp_preorder_goal)

                })
                

                console.log('all options')

                set_all_preorder_goals(all_preorder_goals)

              }else if(result.errorMessage){
                
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
              
              
            },
        
            (error) => {

              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)

            }
           
      )

    })


  }


  



};

export default PreOrderGoals;