import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl, FormControlLabel, Switch } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../store/cms-context';

import { getAccessJwtToken } from '../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Analytics, Storage } from 'aws-amplify';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import BarChart from "./SubComponents/BarChart";
import TimeZones from "../../Utilities/TimeZones";
import TimezoneSelect from "react-timezone-select";
import { clone, cloneDeep, min } from "lodash";
import { ONE_DAY } from "../../Utilities/Metrics";


const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));

function FacebookAds(props) {


  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_collections, set_all_collections] = React.useState([]);

  const [component_state, set_component_state] = React.useState({layout_type: 'list', collection_id : ''})

  const [all_campaigns, set_all_campaigns] = React.useState([]);

  const [all_accounts, set_all_accounts] = React.useState([]);

  const [active_accounts, set_active_accounts] = React.useState([]);

  const [excluded_campaigns, set_excluded_campaigns] = React.useState([]);

  const [analytics, set_analytics] = React.useState([]);

  const [verbose_logging, set_verbose_logging] = React.useState(false);

  const navigate = useNavigate();


  useEffect(() => {

    isMountedRef.current = true

    get_configurations_settings()

    return () => isMountedRef.current = false;

  }, [])

  useEffect(() =>{

    console.log('all campaigns: ', all_campaigns)

  }, [all_campaigns])


  async function get_picture_async(key, index){

    const picUrl = await Storage.get(key, {
      level: 'public',
      bucket: 'snag-cms175447-staging',
      expires:86400,
      region: 'eu-west-2',
    });

    console.log('url was', picUrl)

    set_all_collections((previous_collections) => {

      const temp_collections = [...previous_collections]

      if(index < temp_collections.length){
        temp_collections[index].image.url = picUrl;
      }

      return(set_all_collections(temp_collections))

    })

  }

  function handle_exc_campaigns_change(event, newValue){

    set_excluded_campaigns(newValue)

  }

  function handle_active_accounts_change(event, newValue){

    set_active_accounts(newValue)

  }


  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

  

        { analytics.length > 0 ? analytics.map((account_level_analytics, index) => {

          return(
            <Grid key={index} item xs={12}>
              <BarChart analytics={account_level_analytics}></BarChart>
            </Grid>
          )

        }) : null }



      </Grid>

    </Container>      


    </Box>

     }

    </> 


  );

  function get_configurations_settings() {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/facebook/configurations',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was',cloneDeep(result))

              if(result.data){

                var campaigns = result.data.campaigns;

                const exc_campaigns = result.data.rules.excluded_campaigns;

                set_all_campaigns(campaigns);

                if(exc_campaigns.length > 0){

                const temp_exc_campaigns = []

                for (var i=0; i< campaigns.length; i++){

                  if(exc_campaigns.includes(campaigns[i].id.toString())){

                    temp_exc_campaigns.push(campaigns[i])

                  }

                }

                console.log('setting excluded campaigns: ', temp_exc_campaigns)

                set_excluded_campaigns(temp_exc_campaigns)

              }

              var accounts = result.data.facebook_accounts

              const act_accounts = result.data.rules.active_accounts;

                set_all_accounts(accounts);

                const temp_act_accounts = []

                if(act_accounts && act_accounts.length > 0){


                for (var z=0; z< accounts.length; z++){

                  for (var z1=0; z1< act_accounts.length; z1++){

                    if(act_accounts[z1].id.toString() === accounts[z].id.toString()){

                      const temp_account = act_accounts[z1]

                      temp_account.name = accounts[z].name

                      if(temp_account.cpa_threshold == null){
                        temp_account.cpa_threshold = ''
                      }
  
                      if(temp_account.spend_threshold == null){
                        temp_account.spend_threshold = ''
                      }
  
                      if(temp_account.night_start != null){
  
                        const start_date = new Date();
  
                        start_date.setHours(parseInt(temp_account.night_start.split(":")[0]))
  
                        start_date.setMinutes(parseInt(temp_account.night_start.split(":")[1]))
  
                        start_date.setSeconds(0)
  
                        temp_account.night_start = start_date
  
                        console.log('nigth start was: ', start_date)
  
                      }
  
                      if(temp_account.night_end != null){
  
                        const end_date = new Date();
  
                        end_date.setHours(parseInt(temp_account.night_end.split(":")[0]))
  
                        end_date.setMinutes(parseInt(temp_account.night_end.split(":")[1]))
  
                        end_date.setSeconds(0)
  
                        temp_account.night_end = end_date
  
                        console.log('nigth end was: ', end_date)
  
                      }
  
                      temp_act_accounts.push(temp_account)

                      break;

                    }

                  }

                }

                console.log('setting excluded campaigns: ', temp_act_accounts)

                set_active_accounts(temp_act_accounts)

              }

              set_verbose_logging(result.data.rules.verbose)

              console.log('setting was 0:')

              const temp_analytics = [...result.data.analytics]

              console.log('setting was:')

            const final_analytics = []


            temp_act_accounts.forEach((account) => {

              const account_index = temp_analytics.findIndex(child => ("act" + "_" + child.account) === account.id)

              if(account_index > -1){

                const child = temp_analytics[account_index]

                child.account_name = account.name

                console.log('first was', account.name)

                child.turned_off = child.turned_off.map((second_child)=>{
                  var myToday = new Date(second_child.date.replace(/-/g, "/"))
                  myToday.setMinutes(0)
                  myToday.setSeconds(0)
                   return({"x": myToday.getTime(), "y":second_child.count})
                 })

                 const min_time = new Date().getTime() - 1.5 * ONE_DAY

                 const final_turned_off = []

                 if(child.turned_off.length > 0){


                    child.turned_off.forEach((temp_child) =>{

                      if(temp_child.x >= min_time){

                        final_turned_off.push(temp_child)
                      }

                    })

                 }

                 child.turned_off = final_turned_off


                 child.turned_on = child.turned_on.map((second_child)=>{
                  var myToday = new Date(second_child.date.replace(/-/g, "/"))
                  myToday.setMinutes(0)
                  myToday.setSeconds(0)
                   return({"x": myToday.getTime(), "y":second_child.count})
                 })

                 const final_turned_on = []

                 if(child.turned_on.length > 0){

                    child.turned_on.forEach((temp_child) =>{

                      if(temp_child.x >= min_time){
  
                        final_turned_on.push(temp_child)
                      }

                    })

                 }

                 child.turned_on = final_turned_on

                 final_analytics.push(child)
                
              }

            })



             console.log('setting analuytis', final_analytics)

             set_analytics(final_analytics)


            

                /*

                var temp_collections = result.collections;

                if(temp_collections.length === 0){

                  cmsContext.setSnackBarOpen(true, "info", "No collections yet. Let's create one to get started.")

                }
                
                temp_collections = temp_collections.map((collection) => {
                  return(Object.assign(collection,{'is_checked':false}))
                });

                set_all_collections(temp_collections)

                for(var collection_index in temp_collections){

                  if(temp_collections[collection_index].image && temp_collections[collection_index].image.aws_key){

                    get_picture_async(temp_collections[collection_index].image.aws_key, collection_index)

                  }

                }
                */
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
      )

    })


  }

  function update_configurations(type, is_verbose){

    console.log('updating')

    var request_body = {}

    if(type === "rules"){

    request_body.rules = {}

    var exc_campaigns = [...excluded_campaigns]

    exc_campaigns = exc_campaigns.map((campaign) => campaign.id.toString())

    var act_accounts = cloneDeep(active_accounts)

    var start_date;

    var end_date;

    var found_error = false;

    for (var i=0; i < act_accounts.length; i++){

      start_date = act_accounts[i].night_start

      console.log('start date was: ', start_date)

      if(act_accounts[i].cpa_threshold == null || act_accounts[i].cpa_threshold.trim().length === 0){

        found_error = true;
        break

      }

      if(act_accounts[i].spend_threshold == null || act_accounts[i].spend_threshold.trim().length === 0){

        found_error = true;
        break

      }

      if(act_accounts[i].pixel_id == null || act_accounts[i].pixel_id.trim().length === 0){

        found_error = true;
        break

      }else{
        act_accounts[i].pixel_id = act_accounts[i].pixel_id.trim()
      }

      if(act_accounts[i].conversion_domain == null || act_accounts[i].conversion_domain.trim().length === 0){

        found_error = true;
        break

      }else{
        act_accounts[i].conversion_domain = act_accounts[i].conversion_domain.trim()
      }

      if(start_date == null){

        found_error = true;
        break;

      }else{

        start_date = start_date.getHours().toString() + ":" + start_date.getMinutes().toString()
        act_accounts[i].night_start = start_date;

      }

      end_date = act_accounts[i].night_end

      if(end_date == null){

        found_error = true;
        break;

      }else{

        end_date = end_date.getHours().toString() + ":" + end_date.getMinutes().toString()
        act_accounts[i].night_end = end_date;

      }

    }


    if(found_error){
      cmsContext.setSnackBarOpen(true, "error", "Please enter valid values for all fields!")
      return
    }

    request_body.rules.verbose = is_verbose

    request_body.rules.active_accounts = act_accounts;

    request_body.rules.excluded_campaigns = exc_campaigns;

    }else{
      request_body.verbose = is_verbose
    }
    
    console.log('request body: ', request_body)

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/facebook/configurations', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was',result)

              if(result.message){

                if(type === "rules"){

                console.log('yes type rules')

                cmsContext.setSnackBarOpen(true, "success", result.message)

                get_configurations_settings()

                }else{

                  console.log('no only verbose')

                  set_verbose_logging(is_verbose)
                }
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }


};


export default FacebookAds;