import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, makeStyles} from '@mui/material';
import Dropzone from 'react-dropzone'

import { UserCircle as UserCircleIcon } from '../../../icons/user-circle';

import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../../store/cms-context';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import DateTimePicker from '@mui/lab/DateTimePicker';

import cloneDeep from 'lodash/cloneDeep';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import VariantsListTable from '../Variants/SubComponents/VariantsListTable';
import VariantsTable from './VariantsTable';
import { default_product } from '../../../Utilities/Metrics';



const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));

function MintsoftProductDialog(props) {

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);




  const onSelect = (index, checked) => {

    const temp_variant_image = {...props.selected_variant_image}

    if(checked){
      temp_variant_image.image_index = index
    }else{
      temp_variant_image.image_index = -1
    }

    props.set_selected_variant_image(temp_variant_image)
    
  }

  function set_style_state(key, value){
    
    const temp_state = {...props.mintsoft_product_dialog_state}

    temp_state.product[key] = value

    props.set_mintsoft_product_dialog_state(temp_state)
    
  }

  function set_product_state(key, value){
    
    const temp_state = {...props.mintsoft_product_dialog_state}

    temp_state.product[key] = value

    props.set_mintsoft_product_dialog_state(temp_state)
    
  }

  function handle_style(request_type){

    var temp_state = cloneDeep(props.mintsoft_product_dialog_state)

    const name = temp_state.product.name.trim()

    const country_of_origin = temp_state.product.country_of_origin.trim()

    const customs_description = temp_state.product.customs_description.trim()

    var hs_code = temp_state.product.hs_code.toString().trim()

    var gbp_cost_price = temp_state.product.gbp_cost_price.toString().trim()

    var eur_cost_price = temp_state.product.eur_cost_price.toString().trim()

    var usd_cost_price = temp_state.product.usd_cost_price.toString().trim()

    var sale_price = temp_state.product.sale_price.toString().trim()

    if(name.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid product name!")
      return
    }

    if(hs_code.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid HS Code!")
      return
    }

    if(gbp_cost_price.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid GBP Cost Price!")
      return
    }

    if(eur_cost_price.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid EUR Cost Price!")
      return
    }

    if(usd_cost_price.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid USD Cost Price!")
      return
    }

    if(sale_price.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Sale Price!")
      return
    }

    if(country_of_origin.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Country of Origin!")
      return
    }

    if(customs_description.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Customs Description!")
      return
    }

    hs_code = parseFloat(hs_code)

    temp_state.product.hs_code = hs_code

    gbp_cost_price = parseFloat(gbp_cost_price)

    temp_state.product.gbp_cost_price = gbp_cost_price

    eur_cost_price = parseFloat(eur_cost_price)

    temp_state.product.eur_cost_price = eur_cost_price

    usd_cost_price = parseFloat(usd_cost_price)

    temp_state.product.usd_cost_price = usd_cost_price

    sale_price = parseFloat(sale_price)

    temp_state.product.sale_price = sale_price

    var dateString = ''

    const style = temp_state.product;

    try{

      if(typeof(style.active_from) === 'string'){

        Date.parse(style.active_from)

      }else{

      if(isNaN(style.active_from.getTime())) {

        throw new Error("Invalid date")

      }

      dateString = `${style.active_from.getFullYear().toString()}-${('0' + (style.active_from.getMonth() + 1)).slice(-2)}-${('0' + style.active_from.getDate()).slice(-2)} ${('0' + style.active_from.getHours()).slice(-2)}:${('0' + style.active_from.getMinutes()).slice(-2)}:${('0' + style.active_from.getSeconds()).slice(-2)}`;

      temp_state.product.active_from = dateString

      }

      // continue here
  
      if(style.active_to){

        if(typeof(style.active_to) === 'string'){

          Date.parse(style.active_to)
  
        }else{
  
        dateString = `${style.active_to.getFullYear().toString()}-${('0' + (style.active_to.getMonth() + 1)).slice(-2)}-${('0' + style.active_to.getDate()).slice(-2)}-${('0' + style.active_to.getHours()).slice(-2)}:${('0' + style.active_to.getMinutes()).slice(-2)}:${('0' + style.active_to.getSeconds()).slice(-2)}`;
  
        temp_state.product.active_to = dateString

        }
  
      }

    }catch(e){
      console.log('exception was: ', e)
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid date!")
      return
    }


    temp_state = {'product':temp_state.product}

    console.log("state was: ", temp_state)

    console.log('action was: ', request_type)

    props.post_product(temp_state, request_type, temp_state.product)

  }

  

  function handle_close(){

    const temp_state = {...props.mintsoft_product_dialog_state}

    temp_state.is_dialog_open = false

    props.set_mintsoft_product_dialog_state(temp_state)

  }



  return (
    <div>
      <Dialog
        open={props.mintsoft_product_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.mintsoft_product_dialog_state.type} Product
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

             <Grid
              item
              xs={12}
             
              >

                <Avatar
                    sx={{
                      height: 160,
                      width: 160,
                      ml: 'auto',
                      mr:'auto'
                    }}
                    src="/static/images/prototype.png"
                  >
                    
                    <UserCircleIcon fontSize="small" />

                </Avatar>
                
              </Grid> 


              <Grid
              item
              xs={12}
              style={{marginTop:16}}
              >

                <TextField id="outlined-basic" disabled={props.mintsoft_product_dialog_state.type === "Update"} label="Name" variant="outlined" value={props.mintsoft_product_dialog_state.product.name} onChange={(event)=>{set_style_state("name", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" size="small" label="Sku" variant="outlined" value={props.mintsoft_product_dialog_state.product.sku} onChange={(event)=>{set_product_state("sku", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" size="small" label="Barcode" variant="outlined" value={props.mintsoft_product_dialog_state.product.barcode ? props.mintsoft_product_dialog_state.product.barcode : ''} type="number" onChange={(event)=>{set_product_state("barcode", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" size="small" label="Weight" variant="outlined" value={props.mintsoft_product_dialog_state.product.weight} type="number" onChange={(event)=>{set_product_state("weight", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>


              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="HS Code" variant="outlined" value={props.mintsoft_product_dialog_state.product.hs_code} type="number" onChange={(event)=>{set_style_state("hs_code", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="Country of Origin" variant="outlined" value={props.mintsoft_product_dialog_state.product.country_of_origin} onChange={(event)=>{set_style_state("country_of_origin", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

              <TextField id="outlined-basic" label="Customs Description" variant="outlined" value={props.mintsoft_product_dialog_state.product.customs_description} onChange={(event)=>{set_style_state("customs_description", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="GBP Cost Price" variant="outlined" value={props.mintsoft_product_dialog_state.product.gbp_cost_price} type="number" onChange={(event)=>{set_style_state("gbp_cost_price", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="EUR Cost Price" variant="outlined" value={props.mintsoft_product_dialog_state.product.eur_cost_price} type="number" onChange={(event)=>{set_style_state("eur_cost_price", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="USD Cost Price" variant="outlined" value={props.mintsoft_product_dialog_state.product.usd_cost_price} type="number" onChange={(event)=>{set_style_state("usd_cost_price", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="Sale Price" variant="outlined" value={props.mintsoft_product_dialog_state.product.sale_price} type="number" onChange={(event)=>{set_style_state("sale_price", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid item xs={12}>

                <LocalizationProvider dateAdapter={AdapterDateFns}>

                <DateTimePicker
                  renderInput={(params) => <TextField fullWidth {...params}  />}
                  value={props.mintsoft_product_dialog_state.product.active_from}
                  onChange={(newValue) => {
                    const temp_val = {...props.mintsoft_product_dialog_state};
                    temp_val.product.active_from = newValue;
                    console.log('date was', typeof(temp_val.date))
                    props.set_mintsoft_product_dialog_state(temp_val);
                  }}
                  label="Active From"
                />
                </LocalizationProvider>


              </Grid> 

              <Grid item xs={12}>

              <LocalizationProvider dateAdapter={AdapterDateFns}>

              <DateTimePicker
                renderInput={(params) => <TextField fullWidth {...params}  />}
                value={props.mintsoft_product_dialog_state.product.active_to}
                onChange={(newValue) => {
                  const temp_val = {...props.mintsoft_product_dialog_state};
                  temp_val.product.active_to = newValue;
                  console.log('date was', typeof(temp_val.date))
                  props.set_mintsoft_product_dialog_state(temp_val);
                }}
                label="Active To"
              />
              </LocalizationProvider>

              </Grid> 

                </Grid>

            
        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        {props.mintsoft_product_dialog_state.type !== "Deploy" ? <LoadingButton variant='outlined' loading={props.mintsoft_product_dialog_state.isLoading} disabled={false} onClick={() => {handle_style("Create")}}>{props.mintsoft_product_dialog_state.type}</LoadingButton> : null}

       {props.mintsoft_product_dialog_state.type === "Deploy"  ? <>

       {props.mintsoft_product_dialog_state.isLoading ? <CircularProgress sx={{marginLeft:2, marginRight:2}} />: <>

       <LoadingButton variant='outlined' color="error" loading={props.mintsoft_product_dialog_state.isLoading} disabled={false} onClick={() => {handle_style("Discontinue")}}>Discontinue</LoadingButton> 

        <LoadingButton variant='outlined' loading={props.mintsoft_product_dialog_state.isLoading} disabled={false} onClick={() =>{handle_style("Save")}}>Update</LoadingButton> 

        <LoadingButton variant='outlined' loading={props.mintsoft_product_dialog_state.isLoading} disabled={false} onClick={() => {handle_style("Deploy")}}>Deploy</LoadingButton> 
        </>}
        </> : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default MintsoftProductDialog;