import * as React from 'react';
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { OutlinedInput, InputAdornment, ButtonGroup, Button, Stack, Tab, TextField, Grid, Select, MenuItem , Chip, Popper} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useTheme } from '@mui/material/styles';
import { key_ui_map } from '../../Utilities/Metrics';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import SearchIcon from '@mui/icons-material/Search';
import ToggleButtons from '../ToggleButtons/ToggleButtons';
import TimeRangeSelector from '../TimeRangeSelector/TimeRangeSelector';
import DatePikcer from '../DatePicker/DatePicker';
import DatePicker from '../DatePicker/DatePicker';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


import cloneDeep from 'lodash/cloneDeep';

import ImageSearchIcon from '@mui/icons-material/ImageSearch';

import FBCTCountries from '../../Utilities/FBCTCountries'

function TablePaginationActions(props) {

  const theme = useTheme();
  
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (

    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );

  }


  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };





function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'image',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'ads',
    numeric: false,
    disablePadding: false,
    label: 'Ads',
  }
];

function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
      <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
      </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  /*
  pl: { sm: 2 },
     pr: { xs: 1, sm: 1 },
  ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
  })*/

  return (
    <>

    <Toolbar
    disableGutters
      sx={{
        pl: { sm: 2.5 },
        pr: { xs: 1, sm: 1 },
        pt:0,
        pb:0
      }}
    >
       {numSelected > 0 ? (

        <Stack direction="row"  sx={{ flex: '1 1 100%' }} alignItems="center">

        <Typography
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>


        {numSelected === 1 ? 
          <>


          <Button sx={{marginLeft:1}} variant='outlined' size="small"  onClick={() => {props.handle_ad_group_action("Deploy")}}>
            Edit / Deploy
          </Button>
          
          </>

          : null}


          </Stack>

        ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        </Typography>
        )}

      {numSelected > 0 ? (

        <>



      <Tooltip title="Delete">
        <IconButton onClick={(event) =>{props.delete_ad_groups()}}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>

      </>
      ): null}
    </Toolbar>
  
    </>
  );
};


EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const temp_styles = theme => ({
    listItemText:{
      fontSize:12
    }
  });


 function FacebookAdGroupsListTable(props) {

  const theme = useTheme();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search_value, set_search_value] = React.useState('');
  const [rows, setRows] = React.useState([]);
  const [all_vendors, set_all_vendors] = React.useState([]);
  const [all_ad_groups, set_all_ad_groups] = React.useState([]);
  const [all_status, set_all_status] = React.useState(['Created', 'Saved', 'Deployed']);
  const [all_sorts, set_all_sorts] = React.useState(['Created', 'Updated', 'Title']);
  const [sku_value, set_sku_value] = React.useState('');
  

  const [selected_filters, set_selected_filters] = React.useState({"selected_vendors":[], "selected_ad_groups":[], "selected_status":[], "selected_sorts":[]});


  console.log("facebook ad groups state was: ", props.all_facebook_ad_groups)


  const selected = React.useMemo(() => {

    return(props.all_facebook_ad_groups.filter((product) => product.is_checked).length)

  }, [props.all_facebook_ad_groups])



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {

    const is_checked = event.target.checked;

    props.set_all_facebook_ad_groups((previous_products) => {

      var temp_products = [...previous_products]

      temp_products = temp_products.map((product) => {
  
        product.is_checked = is_checked;
  
        return(product);
  
      })

      console.log('settings products', temp_products)
  
      return(props.set_all_facebook_ad_groups(temp_products))

    })

  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleAllVendorsChange = ((event) =>{

    const {
      target: { value },
    } = event;


    //set_selected_filters((previous_filters) =>{

      const temp_filters = {...selected_filters}
      temp_filters.selected_vendors = typeof value === 'string' ? value.split(',') : value
      console.log('setting filters: ', temp_filters)
      set_selected_filters(temp_filters)

    //})

  })

  const handleAllad_groupsChange = ((event) =>{

    const {
      target: { value },
    } = event;

    //set_selected_filters((previous_filters) =>{

      const temp_filters = {...selected_filters}
      temp_filters.selected_ad_groups = typeof value === 'string' ? value.split(',') : value
       set_selected_filters(temp_filters)

    //})


  })

  const handleAllStatusChange = ((event) => {

    const {
      target: { value },
    } = event;

    //set_selected_filters((previous_filters) =>{

      const temp_filters = {...selected_filters}
      temp_filters.selected_status = typeof value === 'string' ? value.split(',') : value
      set_selected_filters(temp_filters)

   // })


  })

  const handleAllSortsChange = ((event) =>{

    const {
      target: { value },
    } = event;


    //set_selected_filters((previous_filters) =>{

    const temp_filters = {...selected_filters}
    temp_filters.selected_sorts = typeof value === 'string' ? value.split(',') : value
    set_selected_filters(temp_filters)

    //})


  })


  // Avoid a layout jump when reaching the last page with empty rows.

        
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    
  const handle_row_update = (product_id, type, value, optionNum) => {

    if(type === 'price' && value < 0){
      return;
    }

    //console.log("handle row update: ", product_id, " : ", type, " : ", value, " : ", optionNum)

    const temp_options = cloneDeep(props.all_facebook_ad_groups);

    //console.log('temp options', temp_options,'num: ', optionNum)

    const index = temp_options.findIndex(object => {
      return object.id === product_id;
    });

    //console.log('found index was: ', index)

    temp_options[index][type] = value;

    //console.log("new optios were: ", temp_options )


    props.set_all_facebook_ad_groups((previousproducts) => {

      if(previousproducts){

        const temp_options = [...previousproducts];

       // console.log('temp options', temp_options, 'num: ', optionNum)

        const index = temp_options.findIndex(object => {
          return object.id === product_id;
        });

       // console.log('found index was: ', index)

        temp_options[index][type] = value;

        return(props.set_all_facebook_ad_groups(temp_options))

      }

      })


  }

  const handle_product_state = (type) => {

    const product = props.all_facebook_ad_groups.filter((product) => product.is_checked === true)

    if(product.length > 0){

      console.log('product was: ', product)

      props.set_component_state({layout_type: type, product_id: product[0].id})

    }else{

      console.log("Error getting product")

    }

  }


  useEffect(() => {
    

    if(props.all_facebook_ad_groups && props.all_facebook_ad_groups.length > 0) {

      const filteredRows = [...props.all_facebook_ad_groups]

      /*

      const filteredRows = props.all_facebook_ad_groups.filter((row) => {
        return row.title.toLowerCase().includes(search_value.toLowerCase());
      });

      */

      setRows(filteredRows);

    }

    

  }, [props.all_facebook_ad_groups])


  useEffect(() => {

    /*

    if(props.all_facebook_ad_groups && props.all_facebook_ad_groups.length > 0) {

      const filteredRows = props.all_facebook_ad_groups.filter((row) => {

        var found_sku = false;

        for (var i=0; i < row.variants.length; i++){

          if(row.variants[i].sku.toLowerCase().includes(sku_value.toLocaleLowerCase())){
            found_sku = true;
            break;
          }

        }

        return(found_sku)

      });

      setRows(filteredRows);
      
    }
    */

  }, [sku_value])


  useEffect(() => {

    /*

    if(props.all_facebook_ad_groups && props.all_facebook_ad_groups.length > 0){


      var filteredRows = [...props.all_facebook_ad_groups]

      if(selected_filters.selected_vendors.length > 0){

        filteredRows = filteredRows.filter((row) =>{
          return selected_filters.selected_vendors.indexOf(row.vendor) > -1
        })

      }

      if(selected_filters.selected_ad_groups.length > 0){

        filteredRows = filteredRows.filter((row) =>{
          return row.ad_groups.split(", ").join(',').includes(selected_filters.selected_ad_groups.join(','))
        })

      }

      if(selected_filters.selected_status.length > 0){

        console.log('selected status were: ', selected_filters.selected_status)

        filteredRows = filteredRows.filter((row) =>{
          return selected_filters.selected_status.indexOf(row.last_request_status) > -1
        })

        console.log('status filtered rows were: ', filteredRows)

      }

      filteredRows = filteredRows.filter((row) => {
        return row.title.toLowerCase().includes(search_value.toLowerCase());
      });
      

      setRows(filteredRows);

    }

    */

  }, [selected_filters])
  

  function findHashad_groups(searchText) {
      var regexp = /\B\#\w\w+\b/g
      var result = searchText.match(regexp);
      if (result) {
          return(result)
      } else {
          return([])
      }
  }


  function handleClick(event, index){

    //event.preventDefault()

    console.log('checked: ', event.target.checked)

    handle_row_update(props.all_facebook_ad_groups[index].id, "is_checked", event.target.checked, index)
    
    /*
    if (event.target.classList.contains("css-1m9pwf3")) {
      // console.log("checkbox select");
      handle_row_update(props.all_facebook_ad_groups[index].id, "is_checked", event.target.checked, index)
      return
    }

    //handle_row_update(props.all_ad_groups[index].id, "is_checked", event.target.checked, index)

    props.set_dialog(rows[index], true)

    */

    /*

    // console.log("index was: ", index)

    // handle_row_update(props.all_products[index].id, "is_checked", true, index)

    props.set_component_state({layout_type: "deploy", product_id : rows[index].id})

    handle_product_state("deploy", index)

    console.log("index was: ", rows[index])

    props.set_dialog(rows[index], true)

    */

  }

  

  return (
    <>
     <Box sx={{ width: '100%'}} style={{marginTop:20}}>

      <Paper sx={{ width: '100%', mb: 2 }} >

      {/*<Grid
        container
        spacing={2}
        sx={{overflow:'visible', paddingTop:2, paddingLeft:2, paddingRight:2}}
      >

      <Grid
        item
        xs={3}
      >

        <TextField
          id="outlined_search"
          size='small'
          style={{width:'100%'}}
          value={search_value}
          onChange={(event) => {set_search_value(event.target.value)}}
          InputProps={{endAdornment : (
            <InputAdornment position="end">
              <SearchIcon></SearchIcon>
            </InputAdornment>
          ),
          }}
          label="Search"
        />

      </Grid>


      <Grid
        item
        xs={2.5}
      >

        <TextField
          id="outlined_sku"
          size='small'
          style={{width:'100%'}}
          value={sku_value}
          onChange={(event) => {set_sku_value(event.target.value)}}
          InputProps={{endAdornment : (
            <InputAdornment position="end">
              <SearchIcon></SearchIcon>
            </InputAdornment>
          ),
          }}
          label="Sku"
        />

      </Grid>

    

      <Grid
        item
        xs={2}
      >

      <FormControl fullWidth size='small'>
        <InputLabel id="multiple-vendor-label">Vendor</InputLabel>
        <Select
          labelId="multiple-vendor-label"
          id="multiple-vendor-name"
          multiple
          size='small'
          value={selected_filters.selected_vendors}
          label="Vendor"
          style={{'width':'100%'}}
          onChange={(handleAllVendorsChange)}
          input={<OutlinedInput label="Vendor" />}
        >
          {all_vendors.map((vendor, index) => (
            <MenuItem
              key={index}
              value={vendor}
            >
              {vendor}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>

      <Grid
        item
        xs={1.5}
      >

      <FormControl fullWidth size='small'>
        <InputLabel id="multiple-ad_groups-label">ad_groups</InputLabel>
        <Select
          labelId="multiple-ad_groups-label"
          id="multiple-ad_groups-name"
          multiple
          size='small'
          value={selected_filters.selected_ad_groups}
          style={{'width':'100%'}}
          onChange={(handleAllad_groupsChange)}
          input={<OutlinedInput label="ad_groups" />}
        >
          {all_ad_groups.map((ad_group, index) => (
            <MenuItem
              key={index}
              value={ad_group}
            >
              {ad_group}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>

      <Grid
        item
        xs={1.5}
      >

      <FormControl fullWidth size='small'>
        <InputLabel id="multiple-status-label">Status</InputLabel>
        <Select
          labelId="multiple-status-label"
          id="multiple-status-name"
          multiple
          size='small'
          value={selected_filters.selected_status}
          style={{'width':'100%'}}
          onChange={(handleAllStatusChange)}
          input={<OutlinedInput label="Status" />}
        >
          {all_status.map((status, index) => (
            <MenuItem
              key={index}
              value={status}
            >
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>

      <Grid
        item
        xs={1.5}
      >

  

          <Button style={{width:'100%'}} variant="contained" onClick={(event) => {set_selected_filters({"selected_vendors":[], "selected_ad_groups":[],"selected_status":[],"selected_sorts":[]});set_search_value("")}}>Clear</Button>
          
          </Grid> 

      </Grid> */}
        
        <EnhancedTableToolbar delete_ad_groups={props.delete_ad_groups} handle_ad_group_action={props.handle_ad_group_action} filter_title={props.filter_title} set_filter_title={props.set_filter_title} filter_spend={props.filter_spend} set_filter_spend={props.set_filter_spend} filter_headline={props.filter_headline} set_filter_headline={props.set_filter_headline} filter_description ={props.filter_description} set_filter_description={props.set_filter_description} selected_country={props.selected_country} set_selected_country={props.set_selected_country} handle_timestamp_order_change={props.handle_timestamp_order_change} perform_search={props.perform_search} clearSearch={props.clearSearch} ranges_button_text={props.ranges_button_text} filter_user_name={props.filter_user_name} set_filter_user_name={props.set_filter_user_name} filter_ad_groups={props.filter_ad_groups} set_filter_ad_groups={props.set_filter_ad_groups}  calendar_open={props.calendar_open} set_calendar_open={props.set_calendar_open} onDateChange={props.onDateChange} timestampOrder={props.timestampOrder} numSelected={selected} delete_products={props.delete_products} handle_product_state={handle_product_state} />

        {props.all_facebook_ad_groups.length > 0 ? <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.all_facebook_ad_groups.length}
            />
            <TableBody>
              {(rows
               ).map((ad_group, index) => {

                //console.log('new ad_group was: ', index, " : ", ad_group)


                var hash_ad_groups = [];

                /*

                if(ad_group.caption){
                  hash_ad_groups = findHashad_groups(ad_group.caption)
                }

                */

                if(ad_group.message_ad_groups){

                  if(ad_group.message_ad_groups.length > 0){
                    hash_ad_groups = ad_group.message_ad_groups.split(',');
                  }else{
                    hash_ad_groups = ad_group.message_ad_groups
                  }

                }


                var url = "";


                if(ad_group.url && ad_group.url.length > 0){
                  url = ad_group.url
                }else if(ad_group.full_picture){
                  url = ad_group.full_picture
                }else{
                 url = "/static/images/marketing.png"
                }



              var cpa = "";

              if(ad_group.cpa != null){

                cpa = (ad_group.cpa).toFixed(2).toString()

              }

              var spend = "";

              if(ad_group.spend_company_fx != null){

                spend = (ad_group.spend_company_fx).toFixed(2).toString()

              }



              return(

                <TableRow
                style={{cursor:'pointer'}}
                hover
                //onClick={(event) => handleClick(event, index)}
                role="checkbox"
                tabIndex={-1}
                key={index}
                >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={ad_group.is_checked}
                          onChange={(event) => {handleClick(event, index)}}
                          /*
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          */
                        />
                      </TableCell>
                      <TableCell align="left" sx={{width:100}}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{width:50, height:50}}
                      >
                        <img style={{width: 48, height: 48}} alt="snag" src={url}/>
                      </Box> 
                      </TableCell>

                      <TableCell align="left">
                       <Typography fontSize={12}>{ad_group.name}</Typography>
                      </TableCell>

                      <TableCell align="left">
                       <Typography fontSize={12}>{ad_group.total_number_of_ads}</Typography>
                      </TableCell>

                    </TableRow>
                    
                  );
                
              })}
              
            </TableBody>
            <TableFooter>

        </TableFooter>
          </Table>
        </TableContainer> : null}
    
      </Paper>

      <DatePicker onDateChange={props.onDateChange} calendar_open={props.calendar_open} set_calendar_open={props.set_calendar_open}></DatePicker>
      
    </Box> 
    </>
  );
}


export default React.memo(FacebookAdGroupsListTable);