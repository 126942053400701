import {  Card, Grid,  TextField, Typography, FormControl, Divider, FormHelperText, FormControlLabel, Checkbox, Select, MenuItem, Button, Link, CircularProgress, Stack, Popover, Box, List, ListItemButton, ListItemText } from '@mui/material';


import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Dropzone from 'react-dropzone'

import { getAccessJwtToken } from '../../store/globalUtilities';

import React, { useEffect, useContext, useRef, useMemo } from 'react';


import styles from './CreateCollection.module.css'

import CmsContext from '../../store/cms-context';

import { Storage } from 'aws-amplify';


import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import ErrorIcon from '@mui/icons-material/Error';


function CreateCollectionSideExtras(props){


    const cmsContext = useContext(CmsContext);

    const [uploading, set_uploading] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const dropzoneRef = useRef(null);



    const count = useMemo(() => {
      console.log('calculating count')
      return props.initial_collections_data.publications.filter((publication) => publication.is_checked).length
    },[props.initial_collections_data])

    console.log('count was', count)

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const popover_open = Boolean(anchorEl);

    const id = popover_open ? 'image-popover' : undefined;




    const handleListItemClick = (index) => {


      console.log(index)

      if(index === 0){

        dropzoneRef.current.open()

      }else if(index === 1){



      }else{
        
        set_uploading(true)

        props.delete_image_file(props.imageFile).then((result) =>{
          set_uploading(false)
          
        })

      }

      handleClose()

    }


    const handle_accepted_files = (acceptedFiles) => {

      console.log('accepted files', acceptedFiles.length)

      if(acceptedFiles.length === 0){
        cmsContext.setSnackBarOpen(true, "error", "Please select a valid image file!")
        return
      }

      var temp_file = {...props.imageFile};

      acceptedFiles = acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file), is_uploaded:false, mediaContentType:'',originalSource:''}));

      temp_file = acceptedFiles[0]
  
      console.log('file was',temp_file); 

      upload_file(temp_file);

    }

    async function upload_file(file) {

      set_uploading(true)

      try {

        var key = await Storage.put(cmsContext.current_shop.email + "/images/" + file.name, file, {
         contentType: file.type, // contentType is optional
        });

        console.log('key was', key)

        const url = await Storage.get(key.key, {
          level: 'public',
          bucket: 'snag-cms175447-staging',
          expires:86400,
          region: 'eu-west-2',
        });

        file.mediaContentType = 'IMAGE';

        file.originalSource = url;

        file.aws_key = key.key;

        console.log('file was', file);

        props.setImageFile(file);

      } catch (error) {
        cmsContext.setSnackBarOpen(true, "error", error)
      }

      set_uploading(false)

    }

    const error_status = props.collection.last_request_status && (props.collection.last_request_status.toLowerCase().includes("error") || props.collection.last_request_status.toLowerCase().includes("failed") )

    console.log('erorr status was: ', error_status  )


    return(

    <div>

        {props.type === "Deploy" ? <Card elevation={2} style={{padding:20, marginBottom:20}}>

        <Grid
        container
        spacing={2}
        alignItems="center"
        >

        <Grid
            item
            xs={12}

        >
            <Typography variant='h6'>Collection status</Typography>
          
         {props.collection.last_request_status ? <div style={{display:"flex", textAlign:"center"}}>{!error_status ? <CheckCircleOutlineIcon color={error_status ? "error" : "primary"}></CheckCircleOutlineIcon> : <ErrorIcon color={error_status ? "error" : "primary"}></ErrorIcon>}<Typography fontSize={12} variant="h6" style={{marginTop:4, marginLeft:4}} color={error_status ? "red" : "blue"}>{props.collection.last_request_status}</Typography> </div> : null}

        </Grid>



        <Grid
        item
        xs={12}

        >

        <Typography variant='subtitle1'>Collection availability</Typography>

        <Typography variant="body2">{ count > 0 ? "Will be available to " + count + " sales channels." : "Will be hidden from all sales channels." }</Typography>

        <Button style={{padding:0, marginTop:16}}  onClick = {() => {var temp_props = {...props.initial_collections_data}; if(count === props.initial_collections_data.publications.length){temp_props.publications = temp_props.publications.map((prop) => {return(Object.assign(prop,{'is_checked':false}))})}else{temp_props.publications = temp_props.publications.map((prop) => {return(Object.assign(prop,{'is_checked':true}))})};props.set_initial_collections_data(temp_props)}}>{ count === props.initial_collections_data.publications.length ? "Deselect all" : "Select all" }</Button>

        </Grid>

        {props.initial_collections_data.publications.length > 0 ? props.initial_collections_data.publications.map((publication, index) => {

          return(


        <Grid
        item
        xs={12}
        key={index}
        >

        <div><Divider></Divider></div>

        <div style={{marginTop:12}}>

        <FormControlLabel control={<Checkbox style={{padding:0, marginRight:8}} onChange={(event) => {const temp_props = {...props.initial_collections_data}; temp_props.publications[index].is_checked = event.target.checked;props.set_initial_collections_data(temp_props);console.log('changed')}} checked={publication.is_checked} />} style={{marginLeft:0}} label={props.initial_collections_data.publications[index].node.name}/>

        </div>

        {publication.node.name === "Online Store" &&  publication.is_checked ? 
        
        <>

       {props.schedule_date.should_schedule ?

        <div style={{marginTop:16}}>

        <LocalizationProvider dateAdapter={AdapterDateFns}>

        <DateTimePicker
          renderInput={(params) => <TextField {...params}  />}
          value={props.schedule_date.date}
          onChange={(newValue) => {
            const temp_val = {...props.schedule_date};
            temp_val.date = newValue;
            console.log('date was', typeof(temp_val.date))
            props.set_schedule_date(temp_val);
          }}
          label="Schedule availability"
        />
         </LocalizationProvider>

        </div>

        : null}

        <Button style={{padding:0, marginLeft:4}} color={props.schedule_date.should_schedule ? "success" : "primary"} onClick={() => {const temp_val = {...props.schedule_date}; temp_val.should_schedule = !temp_val.should_schedule; props.set_schedule_date(temp_val)}}>{props.schedule_date.should_schedule ? 'Cancel schedule' : 'Schedule availibility' }</Button>

        </>
        : null  }

        </Grid>

          
  

          );

        }) : null}

      

      
  

        </Grid>

        </Card> : null}

        <Card elevation={2} style={{padding:20}}>

        <Grid
        container
        spacing={2}
        alignItems="center"
        >

        <Grid
        item
        xs={12}>

        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={2}
          >

        <Typography variant="h6">Collection image</Typography>

        {Object.keys(props.imageFile).length > 0 ? <Button style={{padding:0}}  onClick={handleClick}>Edit</Button> : null}

        <Popover
        id={id}
        open={popover_open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >

          <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

            <List component="nav" aria-label="main mailbox folders">

              <ListItemButton
                onClick={(event) => handleListItemClick(0)}
              >
              <ListItemText primaryTypographyProps={{fontSize: 14}}  primary="Change image" />
              </ListItemButton>
              <ListItemButton
                onClick={(event) => handleListItemClick(1)}
              >
                <ListItemText primaryTypographyProps={{fontSize: 14}}  primary="Edit image alt text" />

              </ListItemButton>

              <ListItemButton
                onClick={(event) => handleListItemClick(2)}
              >
                <ListItemText primaryTypographyProps={{fontSize: 14, color:'red'}}  primary="Remove" />

              </ListItemButton>

            </List>
        </Box>

        </Popover>

        </Stack>

        </Grid>

        <Grid
          item
          xs={12}
          >

            <>

            {uploading ? <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: '20vh' }}
              >

              <Grid item xs={12}> <CircularProgress style={{ width:40, height:40}}></CircularProgress></Grid></Grid> :

              <>
                            
              {Object.keys(props.imageFile).length > 0 && props.imageFile.aws_key && props.imageFile.aws_key.length > 0 ? <img src={props.imageFile.originalSource} style={{width:'100%', minHeight:'20vh'}} alt='collection_image'></img> : null}

              <div style={Object.keys(props.imageFile).length > 0 ? {display:'none'} : {}}>

              <Dropzone ref={dropzoneRef}  maxFiles={1} accept={'image/jpeg, image/png'} onDrop={acceptedFiles => {handle_accepted_files(acceptedFiles)}}>

              {({getRootProps, getInputProps}) => (

                <section>
                  <div {...getRootProps()} style={{textAlign:'center'}} className={styles.dropzone} >
                    <input {...getInputProps()} />
                    <div>
                    <img src='/static/images/add_file.png' alt='add_file' style={{'width':'25%', 'height':'25%',marginTop:10}}>
                    </img>
                    </div>
                    <Typography fontSize={12}><Link to="/" onClick={(e)=>{e.preventDefault()}}>Add file</Link> or Drag and Drop</Typography>
                  </div>
                </section>

              )}
              </Dropzone>
              
              </div>
              
              </>
              
            }

            </>


        </Grid>

        </Grid>

        </Card>


        { props.type === "Deploy" ? <Card elevation={2} style={{marginTop:20,padding:20}}>

        <Grid
        container
        spacing={2}
        alignItems="center"
        >

        <Grid
        item
        xs={12}>

        <Typography variant="h6">Online Store</Typography>

        </Grid>

        <Grid
        item
        xs={12}>

        <Typography variant="subtitle2">Theme template</Typography>

        <FormControl fullWidth style={{marginTop:8}}>

        <Select
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        fullWidth
        size="small"
        value={props.selected_collection_template}
        onChange={(event => {props.set_selected_collection_template(event.target.value)})}
        >

           { props.initial_collections_data.templates.map((template, index) => (

                <MenuItem key={index} value={index}>{template}</MenuItem>

            )) }

        </Select>

        <FormHelperText style={{margin:0, marginTop:2}}>Assign a template from your current theme to define how the product is displayed.</FormHelperText>

        </FormControl>

        </Grid>

        </Grid>

        </Card> : null}


    </div>
    )

}


export default React.memo(CreateCollectionSideExtras)