import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import {InputAdornment, FormHelperText} from '@mui/material';
import { cloneDeep } from 'lodash';
import Metrics, { default_bulk_update_dialog } from '../../Utilities/Metrics';



import { Grid, TextField, Select, MenuItem, FormControl, InputLabel, OutlinedInput, Typography, FormGroup, FormControlLabel, Checkbox} from '@mui/material';

import { ProductStatus } from '../../Utilities/Metrics';

function BulkUpdateDialog(props) {



  const handleClose = () => {

    props.set_bulk_update_dialog_state(cloneDeep(default_bulk_update_dialog))

  };

  function handle_change(key, value) {

    const temp_state = {...props.bulk_update_dialog_state}

    temp_state[key] = value

    props.set_bulk_update_dialog_state(temp_state)
    
  }

  return (
    
      <Dialog
        fullWidth
        open={props.bulk_update_dialog_state.is_dialog_open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Update {props.bulk_update_dialog_state.numSelected} Product(s)
        </DialogTitle>
        <DialogContent dividers>
        <Grid
            container
            spacing={3}
            alignItems="center"
            >

            <Grid sx={{marginTop:1}} item xs={12}>

              <Typography><strong>Product</strong></Typography>

            </Grid>

            <Grid item xs={12}>

            <FormControl size="small" fullWidth>
            <InputLabel id="demo-multiple-name-label">Options</InputLabel>
            <Select
              labelId="demo-multiple-options-label"
              id="demo-options-name"
              multiple
              fullWidth
              size='small'
              value={props.bulk_update_dialog_state.selected_options}
              onChange={(event) => {handle_change("selected_options", event.target.value)}}
              input={<OutlinedInput label="Options" />}
            >
              {props.bulk_update_dialog_state.options.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option.key}
                >
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

              
            </Grid>

             {props.bulk_update_dialog_state.selected_options.indexOf("productCategory") > -1 ? <Grid
              item
              xs={12}
              >

              <TextField label="Product Category" size='small' value={props.bulk_update_dialog_state.productCategory} onChange={(event) => {handle_change("productCategory", event.target.value)}} InputLabelProps={{ shrink: true }}
              fullWidth>

              </TextField>

              </Grid> : null}

              {props.bulk_update_dialog_state.selected_options.indexOf("product_type") > -1 ? <Grid
              item
              xs={12}
              >

              <TextField label="Product Type" size='small' value={props.bulk_update_dialog_state.product_type} onChange={(event) => {handle_change("product_type", event.target.value)}} InputLabelProps={{ shrink: true }} fullWidth>
                
              </TextField>

              </Grid> : null }

              {props.bulk_update_dialog_state.selected_options.indexOf("vendor") > -1 ? <Grid
              item
              xs={12}
              >

              <TextField label="Vendor" value={props.bulk_update_dialog_state.vendor} onChange={(event) => {handle_change("vendor", event.target.value)}} size='small' InputLabelProps={{ shrink: true }}
              fullWidth>
                
              </TextField>

              </Grid> : null}


              {props.bulk_update_dialog_state.selected_options.indexOf("status") > -1 ? <Grid
              item
              xs={12}
              >

              <FormControl size='small' fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  label="Status"
                  size='small'
                  id="product-status-select"
                  fullWidth
                  value={props.bulk_update_dialog_state.product_status}
                  onChange={(event) =>{handle_change("product_status", event.target.value)}}
                  >

                  {ProductStatus.map((status, index) => {
                      return(<MenuItem value={status} key={index}>{status}</MenuItem>)
                  })
                  }
            
                </Select>
              </FormControl>

        
              </Grid> : null}

              {props.bulk_update_dialog_state.selected_options.indexOf("template") > -1 ? <Grid
              item
              xs={12}
              >

              <FormControl size='small' fullWidth>
                <InputLabel id="demo-simple-select-label">Template</InputLabel>
                <Select
                  label="Template"
                  size='small'
                  id="product-template-select"
                  fullWidth
                  value={props.bulk_update_dialog_state.template}
                  onChange={(event) =>{handle_change("template", event.target.value)}}
                  >

                  {props.bulk_update_dialog_state.templates.map((template, index) => {
                      return(<MenuItem value={template} key={index}>{template}</MenuItem>)
                  })
                  }
            
                </Select>
              </FormControl>

              </Grid> : null}

              {props.bulk_update_dialog_state.selected_options.indexOf("url_handle") > -1 ? <Grid
              item
              xs={12}
              >

              <TextField label="URL handle" size='small' value={props.bulk_update_dialog_state.url_handle} onChange={(event) => {handle_change("url_handle", event.target.value)}} InputLabelProps={{ shrink: true }} fullWidth>
                
              </TextField>

              </Grid> : null}


            <Grid item xs={12}>

            <Typography><strong>Variant</strong></Typography>

            </Grid>

            <Grid item xs={12}>

            <FormControl size="small" fullWidth>
            <InputLabel id="demo-multiple-name-label">Options</InputLabel>
            <Select
              labelId="demo-multiple-options-label"
              id="demo-options-name"
              multiple
              fullWidth
              size='small'
              value={props.bulk_update_dialog_state.selected_variant_options}
              onChange={(event) => {handle_change("selected_variant_options", event.target.value)}}
              input={<OutlinedInput label="Options" />}
            >
              {props.bulk_update_dialog_state.variant_options.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option.key}
                >
                  {option.title}
                </MenuItem>
              ))}
            </Select>
            </FormControl>

              
            </Grid>

             {props.bulk_update_dialog_state.selected_variant_options.indexOf("price") > -1 ?  <Grid 
                item
                xs={12}

                >

                <FormControl size="small" fullWidth >

                <InputLabel htmlFor="outlined-adornment-item-price">Price</InputLabel>

                <OutlinedInput
                  id="outlined-adornment-compare-item-price"
                  placeholder='0.00'
                  type="number"
                  size="small"
                  startAdornment={<InputAdornment position="start">£</InputAdornment>}
                  label="Price"
                  value={props.bulk_update_dialog_state.price}
                  onChange={(event) => {handle_change("price", event.target.value)}}
                />


                </FormControl>

                </Grid> : null}

              {props.bulk_update_dialog_state.selected_variant_options.indexOf("cost_per_item") > -1 ? 

                <Grid 
                item
                xs={12}
                >

                <FormControl size="small" fullWidth >

                <InputLabel htmlFor="outlined-adornment-cost-per-item">Cost per item</InputLabel>

                <OutlinedInput
                  id="outlined-adornment-compare-cost-per-item"
                  size="small"
                  placeholder='0.00'
                  type="number"
                  startAdornment={<InputAdornment position="start">£</InputAdornment>}
                  label="Cost per item"
                  value={props.bulk_update_dialog_state.cost_per_item}
                  onChange={(event) => {handle_change("cost_per_item", event.target.value)}}
                />

                  <FormHelperText>Customers wont see this</FormHelperText>

                </FormControl>

                </Grid>

              : null}


              {props.bulk_update_dialog_state.selected_variant_options.indexOf("quantity") > -1 ? 

              
                <Grid
                item
                xs={12}
                >

                <Typography variant='subtitle1'>Quantity</Typography>

                <FormGroup sx={{paddingLeft:0.25, marginTop:0.4}}>

                <FormControlLabel control={<Checkbox checked={props.bulk_update_dialog_state.track_quantity} onChange={(event) => {handle_change("track_quantity", event.target.checked)}} />} label={<Typography fontSize={14}>Track Quantity</Typography>} />

                {props.bulk_update_dialog_state.track_quantity ? <FormControlLabel control={<Checkbox checked={props.bulk_update_dialog_state.continue_selling} onChange={(event) => {handle_change('continue_selling', event.target.checked)}} />} label={<Typography fontSize={14}>Continue selling when out of stock</Typography>} /> : null}

                </FormGroup>

                <TextField
                id="stock-quantity"
                sx={{marginTop:2}}
                label="Available Quantity"
                size="small"
                type="number"
                fullWidth
                value={props.bulk_update_dialog_state.quantity}
                onChange={(event)=>{handle_change('quantity', event.target.value)}}
                InputLabelProps={{
                  shrink: true,
                }}

                />


                </Grid>

              : null}

              {props.bulk_update_dialog_state.selected_variant_options.indexOf("shipping") > -1 ? 


                <>

 
              <Grid
              item
              xs={12}
              >

              <Typography  variant = 'subtitle1'>Shipping</Typography>


              <FormControlLabel sx={{paddingLeft:0.25}} control={<Checkbox checked={props.bulk_update_dialog_state.shipping_is_physical} onChange={(event) => {handle_change('shipping_is_physical',event.target.checked)}} />} label={<Typography fontSize={14}>This is a physical product</Typography>} />


              </Grid>

             {props.bulk_update_dialog_state.shipping_is_physical ?

              <>

             <Grid
              item
              xs={12}
              >

              <Typography sx={{marginTop:-1}} variant='subtitle1'>Weight</Typography>

              <Typography variant='body2'>Used to calculate shipping rates at checkout and label prices during fulfillment.</Typography>

        
              </Grid> 
              
              <Grid item 
              xs={12}
              style={{ display: "flex", gap: "0.2rem" }}
              >

              <TextField
              size="small"
              id="weight"
              label="Weight"
              type="number"
              placeholder='0.0'
              inputProps={{step:0.1}} 
              value={props.bulk_update_dialog_state.shipping_weight}
              onChange={(event) => {handle_change('shipping_weight', event.target.value)}}
              InputLabelProps={{
                shrink: true,
              }}

              />

              <FormControl size="small"  sx={{ minWidth: 100 }}>

              <InputLabel id="demo-simple-select-autowidth-label">Metric</InputLabel>

              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                autoWidth
                size="small"
                value={props.bulk_update_dialog_state.weight_metric_value}
                onChange={(event)=>{handle_change('weight_metric_value', event.target.value)}}
                label="Metric"
              >

                {Metrics.map((item, index)=> (
                  <MenuItem key={index} value={item}>{item}</MenuItem>
                ))}

                
              </Select>

            </FormControl>

            </Grid>

            </>
              
              : null }

              

              </>


              : null}

        </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>Dismiss</Button>
          <LoadingButton variant='outlined' loading={props.bulk_update_dialog_state.isLoading} onClick={() => {props.handle_bulk_actions()}}>Update</LoadingButton>
        </DialogActions>
      </Dialog>

  );
}


export default BulkUpdateDialog;