import { Grid, Paper, TextField, FormControlLabel, OutlinedInput, FormGroup, Divider, Checkbox, FormControl, Typography, InputLabel } from '@mui/material';


import React from 'react';


function Inventory(props){

   // console.log('inventory render')


    return(

     
      <Paper  elevation = {2} style={{marginTop:20, padding:20}}>
  
      <Grid
        container
        spacing={3}
        alignItems="center"
      >

        <Grid
          item
          xs={12}
        >
      
         <Typography variant='h6'>Inventory</Typography>

      
        </Grid>

        <Grid 
        item
        xs={12}
        lg={6}
        >

        <FormControl fullWidth >

        <InputLabel htmlFor="outlined-adornment-sku">SKU (Stock Keeping Unit)</InputLabel>

        <OutlinedInput
          id="outlined-adornment-sku"
          label="SKU (Stock Keeping Unit)"
          value={props.inventory_sku}
          onChange={(event) =>{props.set_inventory_sku(event.target.value)}}
        />

      </FormControl>

        </Grid>

        <Grid 
        item
        xs={12}
        lg={6}
        >

        <FormControl fullWidth >

        <InputLabel htmlFor="outlined-adornment-barcode">Barcode (ISBN, UPC, GTIN, etc.)</InputLabel>

        <OutlinedInput
          id="outlined-adornment-compare-barcode"
          label="Barcode (ISBN, UPC, GTIN, etc.)"
          value={props.inventory_barcode }
          onChange={(event) =>{props.set_inventory_barcode(event.target.value)}}
        />

      </FormControl>


        </Grid>

        <Grid
         item
         xs={12}
        >


          <FormGroup>


        <FormControlLabel control={<Checkbox checked={props.track_quantity} onChange={(event) => {props.set_track_quantity(event.target.checked)}} />} label="Track Quantity" />

       {props.track_quantity ? <FormControlLabel control={<Checkbox checked={props.continue_selling} onChange={(event) => {props.set_continue_selling(event.target.checked)}} />} label="Continue selling when out of stock" /> : null}
       </FormGroup>
        </Grid>

        {props.track_quantity ? <>

        <Grid
          item
          xs={12}
        >
      
         <Divider></Divider>

      
        </Grid>



        <Grid
          item
          xs={12}
        >
      
         <Typography variant='subtitle1'>Quantity</Typography>

      
        </Grid>

        <Grid
          item
          xs={12}
        >

        <TextField

        id="stock-quantity"
        label="Available"
        type="number"
        value={props.available_quantity}
        onChange={(event)=>{props.set_available_quantity(event.target.value)}}
        InputLabelProps={{
          shrink: true,
        }}

        />

    

      </Grid>

      </>: null}
      </Grid>

      </Paper>


    )




}


export default React.memo(Inventory)