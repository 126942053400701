import { Grid, Paper, Typography, Link, IconButton, Stack, CircularProgress} from '@mui/material';


import React, { useCallback, useContext, useEffect } from 'react';

import Dropzone from 'react-dropzone'

import {useDropzone} from 'react-dropzone';

import styles from '.././CreateProduct.module.css'

import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { arrayMove } from 'react-sortable-hoc';

import CmsContext from '../../../store/cms-context';

import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";

import { getAccessJwtToken } from '../../../store/globalUtilities';

import { Reorder, getItemStyle, getoptionListStyle } from "./utils";

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box } from "@mui/system";
import { Divider, TextField, Button, Checkbox } from "@mui/material";
import { DeleteOutline, Add, CheckBox, Umbrella } from "@mui/icons-material";

import { Storage } from 'aws-amplify';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


function MediaGrid(props){

    // console.log('Media render', props.imageFiles)

    const cmsContext = useContext(CmsContext);


    const {getRootProps, getInputProps} = useDropzone({
      accept: 'image/*',
      onDrop: acceptedFiles => {
        props.setImageFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
      }
    });


    const handle_delete = useCallback((index)=>{

      const temp_files = [...props.imageFiles];

      temp_files.splice(index, 1);

      props.setImageFiles(temp_files.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));

    },[props]);



    const selected = React.useMemo(() => {

      return(props.imageFiles.filter((image) => image.is_checked).length)
  
    },[props.imageFiles])


    useEffect(() =>{

      // console.log("media files changed: ", props.domain, props.imageFiles)

    },[props.imageFiles])


    const deleteSelectedImages = () => {

      var delete_images = [...props.imageFiles]

      delete_images = delete_images.filter((image) => image.is_checked)

      props.set_all_variants((previous_variants) =>{

        const variants = [...previous_variants]

        delete_images.forEach((image) =>{

          variants.forEach((variant) => {
  
            if(image.originalSource === variant.imageSrc){
              variant.imageSrc = null
            }
  
          })
  
        })

        return(props.set_all_variants(variants))

      });

      var temp_images = [...props.imageFiles]

      temp_images = temp_images.filter((image) => !image.is_checked)

      props.setImageFiles(temp_images)

      /*

      props.setImageFiles((previous_images) => {

        var temp_images = [...previous_images]

        temp_images = temp_images.filter((image) => !image.is_checked)

        return (props.setImageFiles(temp_images))

      })
      */

      
      if(props.type === "Create"){

      delete_images.forEach((image) =>{

        handle_image_delete(image)

      })

    }

    }

    async function handle_image_delete(file){

      // console.log('deleting file',file , file.modified_name)

      try {

        // console.log('remove key', cmsContext.current_shop.email + "/images/" + file.modified_name)
        
        const result = await Storage.remove(cmsContext.current_shop.email + "/images/" + file.modified_name);

        // console.log('delete result was: ' , result)
  
      } catch (error) {
        //cmsContext.setSnackBarOpen(true, "error", error)
        // console.log('error deleting some fils')
      }

    }


    const onSelectAllClick = (checked) => {

      // console.log('checked was', checked)

      const temp_images = [...props.imageFiles]

      temp_images.forEach((image) => {
        image.is_checked = checked
      })
      
      // console.log('images were', temp_images)

      props.setImageFiles(temp_images)

      /*

      props.setImageFiles((previous_images) => {

        const temp_images = [...previous_images]

        temp_images.forEach((image) => {
          image.is_checked = checked
        })
        
        // console.log('images were', temp_images)

        return props.setImageFiles(temp_images)

      })
      */
    }

    const onSelect = (index, checked) => {

      // console.log('index was: ', index)

      const temp_images = [...props.imageFiles]

        // console.log('previous images were', temp_images)

        temp_images[index].is_checked = checked

        props.setImageFiles(temp_images)

        /*


      props.setImageFiles((previous_images) => {

        const temp_images = [...previous_images]

        // console.log('previous images were', temp_images)

        temp_images[index].is_checked = checked

        return props.setImageFiles(temp_images)

      })

    }
    */

  }

    const onDragEnd = useCallback((result) => {



      if (!result.destination) {
        //// console.log("no-change");
        return;
      }

      const temp_images = [...props.imageFiles]
  
        if (result.type === "OPTIONS") {
          // console.log(result);
          const images = Reorder(
            temp_images,
            result.source.index,
            result.destination.index
          );
  
          // console.log('images were', images)
    
          props.setImageFiles(images)
          
        }
  
        /*
  
      props.setImageFiles((previous_images) =>{
  
        const temp_images = [...previous_images]
  
        if (result.type === "OPTIONS") {
          // console.log(result);
          const images = Reorder(
            temp_images,
            result.source.index,
            result.destination.index
          );
  
          // console.log('images were', images)
    
          return props.setImageFiles(images)
          
        }
  
  
      })

      */
  
    
      
    },[props]);
  
    const onDragUpdate = useCallback(() => {
  
  
  
    },[]);

    const SortableItem = SortableElement((props) => (

      <div className={styles.item}>

          <Box sx={{ bgcolor: 'background.paper', border:'1px solid lightgray', borderRadius:0.5, width:'100%', paddingTop: '100%', position:'relative', overflow:'hidden'}} >
          {props.image.originalSource && props.image.originalSource.length > 0 ? <img alt="123" src={props.image.originalSource} style={{position: 'absolute',top:0, left:0, bottom:0, right: 0, marginTop:'auto', marginBottom:'auto', width:'100%',cursor:'pointer'}}></img> : <CircularProgress style={{position: 'absolute',top:12, left:0, bottom:0, right: 0, marginTop:'auto', marginBottom:'auto', marginLeft:'auto', marginRight:'auto'}} /> }
          <Checkbox size="small" style={{ top:0, left:0,position: 'absolute'}} checked={props.image.is_checked} onClick={(event) => {onSelect(props.position, event.target.checked)}}></Checkbox>
          <DragIndicatorIcon style={{ top:2, right:1,position: 'absolute'}}></DragIndicatorIcon>
          </Box>
  
      </div>
        
    ));


   
    const SortableList = SortableContainer(({ items }) => (
      <div className={styles.container}>
        {items.map((image, index) => (
          <SortableItem
            key={`${index}`}
            index={index}
            position={index}
            image={image}
          />
        ))}
        </div>
    ));
  
  


    React.useEffect(() => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      props.imageFiles.forEach(file => URL.revokeObjectURL(file.preview));
    }, [props.imageFiles]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
      // console.log('on sort end')
      props.setImageFiles(arrayMove(props.imageFiles, oldIndex, newIndex));
    };


    return(

        <Paper elevation = {2} style={{marginTop:20, padding:20}}>
        
        <Grid
          container
          spacing={3}
          alignItems="center"
        >

          <Grid
            item
            xs={12}
          >

            <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={2}
          >

          {selected > 0 ?

            <Checkbox
            color="primary"
            indeterminate={selected > 0 && selected < props.imageFiles.length}
            checked={props.imageFiles.length > 0 && selected === props.imageFiles.length}
            onChange={ (event) => {onSelectAllClick(event.target.checked)}}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
            />

          : <Typography variant='h6'>Media</Typography>}

            {selected > 0 ?<Button color="error" onClick = {() => {deleteSelectedImages()}}>{selected < 2 ? "Delete file" : "Delete files"}</Button> : null}

          </Stack>
        
          </Grid>

          

              <Grid
              item
              xs={12}
              >

                  <Grid
                  container
                  spacing={2}
                  >

                  <Grid
                      item
                      xs={12}
                      >

                    <SortableList
                      items={props.imageFiles}
                      onSortEnd={onSortEnd}
                      axis="xy"
                      helperClass={styles.SortableHelper}
                    />

                        </Grid>
                              
                  </Grid>

                <Grid
                      item
                      xs={12}
                      >
            
                          
                      <Dropzone accept={'image/jpeg, image/png'} onDrop={acceptedFiles => {props.handle_accepted_files(acceptedFiles)}}>
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div {...getRootProps()}  className={styles.dropzone} >
                          <input {...getInputProps()} />
                          <div style={{textAlign:'center'}}>
                          <img src='/static/images/add_file.png' alt='add_file' style={{'width':'25%', 'height':'25%',marginTop:10}}>
                          </img>
                          <Typography variant='body2'><Link to="/" onClick={(e)=>{e.preventDefault()}}>Add files</Link> or Drag and Drop</Typography>
                          </div>
                          </div>
                      </section>
                    )}
                    </Dropzone>
                      </Grid>

  
       
                </Grid>
        

            </Grid>


   

        </Paper>

    )




}


export default React.memo(MediaGrid);