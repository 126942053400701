import * as React from 'react';
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { OutlinedInput, InputAdornment, ButtonGroup, Button, Stack, Tab, TextField, Grid, Select, MenuItem, Autocomplete, ListSubheader, Tabs} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useTheme } from '@mui/material/styles';
import { key_ui_map, product_table_sort_options } from '../../Utilities/Metrics';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import SearchIcon from '@mui/icons-material/Search';
import styles from './ListProducts.module.css';

import { tabsClasses } from '@mui/material/Tabs';

import { styled } from '@mui/material/styles';



const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    
  }),
);




function TablePaginationActions(props) {

  const theme = useTheme();
  
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (

    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );

  }


  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };





function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'image',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'variants',
    numeric: false,
    disablePadding: false,
    label: 'Variants',
  },
  {
    id: 'supplier',
    numeric: false,
    disablePadding: false,
    label: 'Supplier',
  },
  {
    id: 'country_of_origin',
    numeric: false,
    disablePadding: false,
    label: 'Country of Origin',
  }
];

function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  /*
  pl: { sm: 2 },
     pr: { xs: 1, sm: 1 },
  ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
  })*/

  return (
    <>
    {numSelected < 1 ?  <StyledTabs
          value={props.selected_tab}
          onChange={props.handleTabChange}
          aria-label="styled tabs example"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            marginTop:1.2,
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >


          {props.all_tabs.map((store, index) =>{

            return(<StyledTab key={index} label={store.name} />)

          })}

        </StyledTabs>

    :
    <Toolbar
    disableGutters
      sx={{
        pl: { sm: 2.5 },
        pr: { xs: 1, sm: 1 },
        pt:0,
        pb:0
      }}
    >
       {numSelected > 0 ? (

        <Stack direction="row"  sx={{ flex: '1 1 100%' }} alignItems="center">

        <Typography
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>

        
       {/*<>

        <Button variant='outlined' size='small' sx={{marginLeft:2}} onClick={(event) => {props.handle_bulk_actions_change("bulk_update", numSelected)}}>Edit Products</Button>


          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">

          <InputLabel htmlFor="group-actions">Actions</InputLabel>
          
          <Select size='small' defaultValue="" id="group-actions" label="Actions" value="" onChange={(event) => {props.handle_bulk_actions_change(event.target.value, numSelected)}}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <ListSubheader>Status</ListSubheader>
            <MenuItem value="Active">Set as active</MenuItem>
            <MenuItem value="Draft">Set as draft</MenuItem>
            <MenuItem value="Archived">Archive products</MenuItem>
            <ListSubheader>Tags</ListSubheader>
            <MenuItem value="add_tags">Add Tags</MenuItem>
            <MenuItem value="remove_tags">Remove Tags</MenuItem>
          </Select>

          </FormControl>

        <Button variant='outlined' size='small' onClick={(event) => {props.handle_bulk_actions_change("bulk_deploy", numSelected)}}>Bulk Deploy</Button>

        {props.products_type === "Master" ? <Button variant='outlined' size='small' sx={{marginLeft:1}} onClick={(event) => {props.handle_bulk_actions_change("duplicate", numSelected)}}>Duplicate</Button> : null}

       </> */}


        {numSelected === 1 ? 
          <>


          <Button sx={{marginLeft:1}} variant='outlined' size="small"  onClick={() => {props.handle_product_state("deploy")}}>
            Edit / Deploy
          </Button>
          
          </>

          : null}


          </Stack>

        ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        </Typography>
        )}

      {numSelected > 0 ? (

        <>



      <Tooltip title="Delete">
        <IconButton onClick={(event) =>{props.delete_products()}}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>

      </>
      ): null}
    </Toolbar>
    }
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const temp_styles = theme => ({
    listItemText:{
      fontSize:12
    }
  });


 function ProductsListTable(props) {


  const theme = useTheme();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [all_vendors, set_all_vendors] = React.useState([]);
  const [all_skus, set_all_skus] = React.useState([]);
  const [all_status, set_all_status] = React.useState(['Created', 'Saved', 'Deployed'])
  const [all_sorts, set_all_sorts] = React.useState(['Created', 'Updated', 'Title'])


   /*

  useEffect(() =>{

    if(props.all_products) {

    const temp_products = [...props.all_products]

    const found_vendors = []

    const found_tags = []

    const found_skus = []
  

    temp_products.forEach((product) => {

      if(product.vendor.length > 0 && found_vendors.indexOf(product.vendor) === -1) { found_vendors.push(product.vendor) }

        product.tags.split(", ").forEach((tag) => {

          if(tag.trim().length > 0 && found_tags.indexOf(tag) === -1) {
            found_tags.push(tag)
          }

        })

        if(product.variants instanceof Array){

        product.variants.forEach((variant) => {
          if(found_skus.indexOf(variant.sku) === -1){
            found_skus.push(variant.sku)
          }
        })
        
      }

    })

    set_all_vendors(found_vendors)

    set_all_skus(found_skus)

    props.set_all_tags(found_tags)

    const filteredRows = get_products_from_filters(props.all_products);

    setRows(filteredRows)

  }

  }, [props.all_products])

    */

  console.log('products inside were: ', props.all_products)

  const selected = React.useMemo(() => {

    return(props.all_products.filter((product) => product.is_checked).length)

  },[props.all_products])



  // console.log('table options were', props.all_products)


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {

    const is_checked = event.target.checked;

    props.set_all_products((previous_products) => {

      var temp_products = [...previous_products]

      temp_products = temp_products.map((product) => {
  
        product.is_checked = is_checked;
  
        return(product);
  
      })

      // console.log('settings products', temp_products)
  
      return(props.set_all_products(temp_products))

    })

  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleAllVendorsChange = ((event) =>{

    const {
      target: { value },
    } = event;


    //props.set_selected_filters((previous_filters) =>{

      const temp_filters = {...props.selected_filters}
      temp_filters.selected_vendors = typeof value === 'string' ? value.split(',') : value
      // console.log('setting filters: ', temp_filters)
      props.set_selected_filters(temp_filters)

    //})

  })

  const handleAllTagsChange = ((event, newValue) =>{

      const temp_filters = {...props.selected_filters}
      temp_filters.selected_tags = newValue
      props.set_selected_filters(temp_filters)

  })

  const handleAllStatusChange = ((event) => {

    const {
      target: { value },
    } = event;

    //props.set_selected_filters((previous_filters) =>{

      const temp_filters = {...props.selected_filters}
      temp_filters.selected_status = typeof value === 'string' ? value.split(',') : value
      props.set_selected_filters(temp_filters)

   // })

   // console.log('selected status were: ', temp_filters.selected_status)

   props.get_products_list(value, props.all_tabs[props.selected_tab])


  })

  const handleSelectedSkuChange = ((event, newValue) =>{


    //props.set_selected_filters((previous_filters) =>{

      if(newValue == null){
        newValue = ""
      }
      const temp_filters = {...props.selected_filters}
      temp_filters.selected_sku = newValue
      props.set_selected_filters(temp_filters)

   // })


  })

  const handleAllSortsChange = ((event) =>{

    const {
      target: { value },
    } = event;


    //props.set_selected_filters((previous_filters) =>{

    const temp_filters = {...props.selected_filters}
    temp_filters.selected_sorts = typeof value === 'string' ? value.split(',') : value
    props.set_selected_filters(temp_filters)

    //})


  })




  // Avoid a layout jump when reaching the last page with empty rows.

        
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * props.rowsPerPage - rows.length) : 0;


    
  const handle_row_update = (product_id, type, value, optionNum) => {

    if(type === 'price' && value < 0){
      return;
    }

   return props.set_all_products((previousproducts)=>{

      const temp_options = [...previousproducts];

     //console.log('temp options', temp_options,'num: ', optionNum)

      const index = temp_options.findIndex(object => {
        return object.id === product_id;
      });

      temp_options[index][type] = value;

     // console.log(index, " : ", type, " : ", value)

      return(props.set_all_products(temp_options))

    })

  }

  const handle_product_state = (type, index) => {

    console.log('index was: ', index)

    if(index > -1){
      console.log('yes index')
      props.set_component_state({layout_type: type, product_id : props.all_products[index].id})
    }else{

    const product = props.all_products.filter((product) => product.is_checked === true)

    if(product.length > 0){

      // console.log('product was', product)

      props.set_component_state({layout_type: type, product_id : product[0].id})

    }else{
      // console.log("Error getting product")
    }

  }

  }

  useEffect(() => {

    const filteredRows = get_products_from_filters(props.all_products);

    setRows(filteredRows)

  }, [props.selected_filters, props.all_products])


  function get_products_from_filters(all_products){

    if(all_products.length === 0){
      return([])
    }


      var filteredRows = [...props.all_products]

      if(props.selected_filters.search_value.length > 0){

        filteredRows = filteredRows.filter((row) => {
          return row.title.toLowerCase().includes(props.selected_filters.search_value.toLowerCase());
        });

      }

      if(props.selected_filters.selected_vendors.length > 0){

        filteredRows = filteredRows.filter((row) =>{
          return props.selected_filters.selected_vendors.indexOf(row.vendor) > -1
        })

      }

      if(props.selected_filters.selected_tags.length > 0){

        filteredRows = filteredRows.filter((row) =>{
          return row.tags.split(", ").join(',').includes(props.selected_filters.selected_tags.join(','))
        })

      }

      if(props.selected_filters.selected_status.length > 0){

        // console.log('selected status were: ', props.selected_filters.selected_status)

        filteredRows = filteredRows.filter((row) =>{
          return props.selected_filters.selected_status.indexOf(row.last_request_status) > -1
        })

        // console.log('status filtered rows were: ', filteredRows)

      }


      if(props.selected_filters.selected_sku.length > 0){

        filteredRows = filteredRows.filter((row) => {

          var found_sku = false;

          for (var i=0; i < row.variants.length; i++){

            if(row.variants[i].sku.toLowerCase().includes(props.selected_filters.selected_sku.toLocaleLowerCase())){
              found_sku = true;
              break;
            }

          }

          return(found_sku)

        });

      }
      

      return filteredRows;

    
  }
  
  

  function handleClick(event, index){

    // console.log('class list was: ',event.target.classList)

    event.preventDefault()

    /*
    if (event.target.classList.contains("css-1m9pwf3")) {
      // console.log("checkbox select");
      return
    }

    // console.log("index was: ", index)

 
    //handle_row_update(props.all_products[index].id, "is_checked", true, index)


    props.set_component_state({layout_type: "deploy", product_id : rows[index].id})

    */

    handle_product_state("deploy", index)


  }


  return (
    <>
    {props.all_products ? <Box sx={{ width: '100%'}} style={{marginTop:20}}>

      <Paper sx={{ width: '100%', mb: 2 }} >

      {/*
      <>
      <Grid
        container
        spacing={2}
        sx={{overflow:'visible', paddingTop:2, paddingLeft:2, paddingRight:2}}
      >

      <Grid
        item
        xs={3}
      >

        <TextField
          id="outlined_search"
          size='small'
          style={{width:'100%'}}
          value={props.selected_filters.search_value ? props.selected_filters.search_value : ""}
          onChange={(event) => {const temp_filters = {...props.selected_filters}; temp_filters.search_value = event.target.value; props.set_selected_filters(temp_filters);}}
          InputProps={{endAdornment : (
            <InputAdornment position="end">
              <SearchIcon></SearchIcon>
            </InputAdornment>
          ),
          }}
          label="Search"
        />

      </Grid>


      <Grid
        item
        xs={2.5}
      >

        <FormControl fullWidth size='small'>

        <Autocomplete
                  size='small'
                  id="selected-skus"
                  options={all_skus}
                  getOptionLabel={(sku) => sku}
                  value={props.selected_filters.selected_sku != null &&  props.selected_filters.selected_sku.length > 0 ? props.selected_filters.selected_sku : null}
                  isOptionEqualToValue={(option, value) => option === value}
                  onChange={(event, newValue) => {handleSelectedSkuChange(event, newValue)}}
                  renderInput={(params) => (
                    <TextField InputProps={{endAdornment : (
                      <InputAdornment position="end">
                        <SearchIcon></SearchIcon>
                      </InputAdornment>
                    ),
                    }} {...params} label="Sku" placeholder="Sku" />
                  )}
            />

        </FormControl>

      </Grid>

    

      <Grid
        item
        xs={2}
      >

      <FormControl fullWidth size='small'>
        <InputLabel id="multiple-vendor-label">Vendor</InputLabel>
        <Select
          labelId="multiple-vendor-label"
          id="multiple-vendor-name"
          multiple
          size='small'
          value={props.selected_filters.selected_vendors}
          label="Vendor"
          style={{'width':'100%'}}
          onChange={(handleAllVendorsChange)}
          input={<OutlinedInput label="Vendor" />}
        >
          {all_vendors.map((vendor, index) => (
            <MenuItem
              key={index}
              value={vendor}
            >
              {vendor}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>

      <Grid
        item
        xs={3}
      >

      <FormControl fullWidth size='small'>

      <Autocomplete
                multiple
                size='small'
                id="excluded-campaigns"
                options={props.all_tags}
                getOptionLabel={(tag) => tag}
                value={props.selected_filters.selected_tags != null && props.selected_filters.selected_tags.length > 0 ? props.selected_filters.selected_tags : []}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(event, newValue) => {handleAllTagsChange(event, newValue)}}
                renderInput={(params) => (
                  <TextField {...params} label="Tags" placeholder="Tags" />
                )}
          />

      </FormControl>

      </Grid>

      <Grid
        item
        xs={1.5}
      >

      <FormControl fullWidth size='small'>
        <InputLabel id="multiple-status-label">Status</InputLabel>
        <Select
          labelId="multiple-status-label"
          id="multiple-status-name"
          multiple
          size='small'
          value={props.selected_filters.selected_status}
          style={{'width':'100%'}}
          onChange={(handleAllStatusChange)}
          input={<OutlinedInput label="Status" />}
        >
          {all_status.map((status, index) => (
            <MenuItem
              key={index}
              value={status}
            >
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>

      <Grid
          item
          xs={9}
        >

      <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-helper-sort">Sort by</InputLabel>
      <Select
          id="product-status-select"
          labelId="demo-simple-select-helper-sort"
          fullWidth
          label="Sort by"
          value={props.sort_order}
          onChange={(event) =>{props.set_sort_order(event.target.value)}}
          >

              {product_table_sort_options.map((status, index) => {
                  return(<MenuItem value={index} key={index}>{status}</MenuItem>)
              })
              }
          
          </Select>

      </FormControl>

      </Grid>

      <Grid
        item
        xs={3}
      >

          <Button style={{width:'100%'}} variant="contained" onClick={(event) => {props.set_selected_filters({"selected_vendors":[], "selected_tags":[],"selected_status":[],"selected_sorts":[], "selected_sku":"","search_value":""});props.set_sort_order("");}}>Clear</Button>
          
      </Grid>

      </Grid>

      </>*/}
        
        <EnhancedTableToolbar products_type={props.products_type} selected_tab={props.selected_tab} handleTabChange={props.handleTabChange} all_tabs={props.all_tabs} handle_bulk_actions_change={props.handle_bulk_actions_change} numSelected={selected} delete_products={props.delete_products} handle_product_state={handle_product_state} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.all_products.length}
            />
            <TableBody>
              {(props.rowsPerPage > 0
                 ? rows.slice(page * props.rowsPerPage, page * props.rowsPerPage + props.rowsPerPage)
                : rows
               ).map((product, index) => {

                var inventory = 0;

                if(Array.isArray(product.variants)){

                product.variants.forEach((variant) =>{
                  inventory = inventory + (parseInt(variant.quantity) || 0)
                })
              }


                var url = "";

                if(product.imageFiles && product.imageFiles.length > 0 && product.imageFiles[0].url){
                  url = product.imageFiles[0].url
                }else{
                  url = "/static/images/brand_identity.png"
                }

                var variant_text = ""

                if(product.variants){
                  variant_text = inventory.toString() + " in stock for " + product.variants.length.toString() + " variants"
                }else{
                  variant_text = inventory.toString() + " in stock"
                }

              return(

                <TableRow
                      style={{cursor:'pointer'}}
                      hover
                     // onClick={(event) => handleClick(event, index)}
                    // onClick={(event) => handleClick(event, option_value.value)}
                      role="checkbox"
                      //aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                    // selected={isItemSelected}
                    >
                      <TableCell padding="checkbox" className={styles.selectCheckbox}>
                        <Checkbox
                          color="primary"
                          className={styles.selectCheckbox}
                          checked={product.is_checked}
                          onChange={(event) => {handle_row_update(product.id,"is_checked",event.target.checked,index)}}
                          /*
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          */
                        />
                      </TableCell>
                      <TableCell align="left" style={{paddingRight:0}}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{border:'1px solid lightgray', width:50 ,height:50,borderRadius:4}}
                      >
                         <img style={{width:48,height: 48}} alt="snag" src={url}/>
                      </Box>     
                      </TableCell>
                       <TableCell
                       component="th"
                       //id={labelId}
                       scope="row"
                       style={{minWidth:200}}
                     >
                      <a href={"/products/" + props.selected_option.store_domain + "/" + product.id} onClick={(event) => handleClick(event, index)}><strong>{product.name}</strong></a>
                      </TableCell>
                      <TableCell align="left">
                       <Typography color={product.last_request_status && (product.last_request_status.toLowerCase().includes("deleting") || product.last_request_status.toLowerCase().includes("error")) ? "error": null} fontSize={12}>{product.last_request_status ? product.last_request_status : product.status}</Typography>
                      </TableCell>
                      <TableCell align="left">
                       <Typography fontSize={12}>{variant_text}</Typography>
                      </TableCell>
                      <TableCell align="left">
                       <Typography fontSize={12}>{product.supplier}</Typography>
                      </TableCell>
                      <TableCell align="left">
                       <Typography fontSize={12}>{product.country_of_origin}</Typography>
                      </TableCell>
                    </TableRow>
                      
                  );

              })}
              
            </TableBody>
            <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={12}
              count={rows.length}
              rowsPerPage={props.rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
          </Table>
        </TableContainer>
    
      </Paper>
      
    </Box> : null}
    </>
  );
}


export default React.memo(ProductsListTable);