import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';

import { Switch } from '@mui/material';



export const CustomSwitch = styled(Switch)(({ theme }) => ({

    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        '& .MuiSwitch-thumb': {
          color:blue[800]
        }
      },
      '&:hover': {
        backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
      }
    },
    '& .MuiSwitch-thumb': {
      color:grey[200]
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blue[300],
          opacity: 1,
          border: 0,
    },
    
  }));


