import * as React from 'react';
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { OutlinedInput, InputAdornment, ButtonGroup, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Delete, Google } from '@mui/icons-material';

import styles from './VariantsTable.module.css'
import { LoadingButton } from '@mui/lab';

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'variant_picture',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'variant_name',
    numeric: false,
    disablePadding: false,
    label: 'Variant',
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: false,
    label: 'Price',
  },
  {
    id: 'quantity',
    numeric: true,
    disablePadding: false,
    label: 'Quantity',
  },
  {
    id: 'sku',
    numeric: true,
    disablePadding: false,
    label: 'SKU',
  },
  {
    id: 'barcode',
    numeric: true,
    disablePadding: false,
    label: 'Barcode',
  },
  {
    id: 'buttons',
    numeric: false,
    disablePadding: false,
  }
];

function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
    
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Variants
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={props.handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

 function VariantsTable(props) {

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const rows = [];

  var has_non_empty_row = false;

  //const temp_options = [...props.product_options];

  // console.log('table options were', props.all_variants)


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {

    props.set_all_variants((previous_variants) => {

      const temp_variants = [...previous_variants];

      temp_variants.forEach((variant) =>{
        variant.is_checked = event.target.checked
      })

      return props.set_all_variants(temp_variants)

    });

  };

  const handleClick = (event, index) => {


    props.set_all_variants((previous_variants) => {

      const temp_variants = [...previous_variants];

      if(index < temp_variants.length){

      temp_variants[index].is_checked = event.is_checked

      }

      return props.set_all_variants(temp_variants)

    });


  };
  
  const handleDelete = () => {

    props.set_all_variants((previous_variants) => {

      var temp_variants = [...previous_variants];

      temp_variants = temp_variants.filter((variant) => !variant.is_checked)

      return props.set_all_variants(temp_variants)


    })

  }
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.

        
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    
  const handle_row_update = (index, type, value, optionNum) => {

    if(type === 'price' && value < 0){
      return;
    }

   return props.set_all_variants((previousVariants)=>{

      const temp_options = [...previousVariants];

      // console.log('temp options', temp_options,'num: ', optionNum)

      temp_options[index][type] = value;

      return(props.set_all_variants(temp_options))

    })

  }

  const selected = React.useMemo(() => {

    return(props.all_variants.filter((variant) => variant.is_checked).length)

  },[props.all_variants])

  // console.log('table update: ', props.google_merchant_dialog_state)

  return (
    <>
    {props.all_variants.length > 0 ? <Box sx={{ width: '100%'}} style={{marginTop:20}}>
      <Paper sx={{ width: '100%', mb: 2 }} >
        <EnhancedTableToolbar numSelected={selected} handleDelete={handleDelete}/>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.all_variants.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {props.all_variants.map((option, index) => {

              return(

                <TableRow
                      hover
                    // onClick={(event) => handleClick(event, option_value.value)}
                      role="checkbox"
                      //aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                    // selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={option.is_checked}
                          onChange={(event) => {handle_row_update(index,"is_checked",event.target.checked,index)}}
                          /*
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          */
                        />
                      </TableCell>
                      <TableCell
                      >
                        {option.imageSrc ? <div style={{ bgcolor: 'background.paper', border:'1px solid lightgray',borderRadius:4, width:60, height:60,cursor:'pointer',overflow:'hidden'}} onClick={() => {props.select_variant_image(index)}}><img src={option.imageSrc} alt='add_file_variant_image' style={{width:60, height:60}} /></div>  :
                        <div className={styles.dropzone} onClick={() => {props.select_variant_image(index)}}>
                        <img src='/static/images/add_file.png' alt='add_file' style={{'width':32, 'height':32}}>
                        </img>  
                       </div>
                      }
                      
                      </TableCell>
                      <TableCell
                        component="th"
                        //id={labelId}
                        scope="row"
                      >
                         <strong>{option.value}</strong>
                      </TableCell>
                      <TableCell align="right">
                        <OutlinedInput
                        id="outlined-adornment-amount"
                        style={{width:100}}
                        value={option.price ? option.price : ''}
                        onChange={(event) =>{handle_row_update(index,"price",event.target.value, index)}}
                        type="number"
                        size='small'
                        startAdornment={<InputAdornment position="start">{props.store_currency}</InputAdornment>}
                      />   
                      </TableCell>
                      <TableCell align="right"><OutlinedInput
                        id="outlined-adornment-amount"
                        style={{width:80}}
                        value={option.quantity}
                        onChange={(event) =>{handle_row_update(index,"quantity",event.target.value, index)}}
                        type="number"
                        size='small'
                      />   
                      </TableCell>
                      <TableCell align="right"><OutlinedInput
                        id="outlined-adornment-amount"
                        style={{width:80}}
                        onChange={(event) =>{handle_row_update(index,"sku",event.target.value, index)}}
                        value={option.sku}
                        size='small'
                      /> 
                      </TableCell>  
                      <TableCell align="right"><OutlinedInput
                        id="outlined-adornment-amount"
                        style={{width:80}}
                        onChange={(event) =>{handle_row_update(index,"barcode",event.target.value, index)}}
                        value={option.barcode}
                        size='small'
                      /> 
                      </TableCell> 
                      <TableCell align="right">
                      <ButtonGroup disableElevation variant="contained">
                      {props.type === "Deploy" ? <LoadingButton variant='contained' loading={props.google_merchant_dialog_state.variant_index != null && (props.google_merchant_dialog_state.variant_index === index) && props.google_merchant_dialog_state.isLoading} onClick={() => {props.get_merchant_product(index, option, true)}}><Google></Google></LoadingButton> : null}
                      <Button onClick={() => {props.handle_variant_dialog_state(index, option, true)}}>Edit</Button>
                      <Button><Delete></Delete></Button>
                      </ButtonGroup>
                      </TableCell> 
                    </TableRow>
                      
                
                  );


                 
              })}
              
            </TableBody>
          </Table>
        </TableContainer>
    
      </Paper>
      
    </Box> : null}
    </>
  );
}


export default React.memo(VariantsTable);