import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl, FormControlLabel, Switch } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../../../store/cms-context';

import { getAccessJwtToken } from '../../../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Analytics, Storage } from 'aws-amplify';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { clone, cloneDeep, min } from "lodash";
import { ONE_DAY } from "../../../../Utilities/Metrics";

import currencySymbols from "../../../../Utilities/CurrencieSymbols";


const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));


// 50 Denier Tights - Blueberry Muffin
// 30 Denier Sheer Tights - Lemon
// 30 Denier Sheer Tights - Pirouette
// 30 Denier Sheer Tights - Pomegranate
// Your HighNess
// Feline Myself
// Stay Cool Chub Rub Shorts - New Wave
// Stay Cool Chub Rub Shorts - Diamond Age
// Big Softee Towel - Black
// Lunar
// Check why lunar not on Test NL (Sync)

const skus = [
  'SNAG-50-BLUEBERRY',
  'SNAG-30-V3-LEMON',
  'SNAG-30-V3-PIROUETTE',
  'SNAG-30-V3-POMEGRANATE',
  'SNAG-MD-YOURHIGHNESS-BLACK',
  'SNAG-FISHIES-FELINEMYSELF-BLACK',
  'SNAG-CHUBRUBS-NEWWAVE-BLACK',
  'SNAG-CHUBRUBS-DIAMONDAGE-BLACK',
  'SNAG-TOWEL-BIGSOFTEE-BLACK',
  'SNAG-CAPRI-80-BLOK9-BLACK'
]


function PostPurchase(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_stores, set_all_stores] = React.useState([]);

  const [active_stores, set_active_stores] = React.useState([]);

  const navigate = useNavigate();


  useEffect(() => {

    isMountedRef.current = true

    get_configurations_settings()

    return () => isMountedRef.current = false;

  }, [])




  function handle_active_stores_change(event, newValue){

    set_active_stores(newValue)

  }


  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Configurations</Typography> 

          <div>

          <Button variant="contained" onClick = {() => {update_configurations("rules")}}>Update Configurations</Button>

          </div>

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >

      <Paper elevation = {2} style={{marginTop:16, padding:20}}>
        
        <Grid
          container
          spacing={3}
          alignItems="center"
        >

          <Grid
            item
            xs={12}
          >

          <Autocomplete
                multiple
                id="active-stores"
                options={all_stores}
                getOptionLabel={(option) => option.name}
                value={active_stores}
                isOptionEqualToValue={(option, value) => option.store === value.store}
                onChange={(event, newValue) => {handle_active_stores_change(event, newValue)}}
                renderInput={(params) => (
                  <TextField {...params} label="Active stores" placeholder="Active stores" />
                )}
          />     

        </Grid>

    

        {active_stores.map((store, index) => { 

          return(

            <Grid key={index} item xs={12} >

            <Grid container spacing={2}>

            <Grid
            item 
            xs={12}
            >

              <Typography style={{marginBottom:8}} fontSize={12}><strong>{store.name}</strong></Typography>

            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              > 

              <Autocomplete
                    id="product"
                    multiple
                    options={store.all_products}
                    getOptionLabel={(option) => option.title ? option.title : ''}
                    value={store.selected_products ? store.selected_products : []}
                    isOptionEqualToValue={(option, value) => option.id === value.id }
                    onChange={(event, newValue) => {

                      const temp_stores = [...active_stores]

                      temp_stores[index].selected_products = newValue

                      set_active_stores(temp_stores)

                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.title}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} label="Upsell Products" placeholder="Upsell Products" />
                    )}
              />     

            </Grid>


            <Grid
              item
              xs={12}
              md={12}
            >

            <FormControl fullWidth sx={{height:'100%'}}>
              <InputLabel htmlFor="outlined-adornment-discount">Discount</InputLabel>
              <OutlinedInput
                sx={{height:'100%'}}
                id="outlined-adornment-discount"
                value={active_stores[index].discount ? active_stores[index].discount : ''}
                type="number"
                onChange={(event) => {

                  console.log('value was: ', event.target.value)

                  const temp_stores = [...active_stores];

                  if(event.target.value >= 0) {
                    temp_stores[index].discount = event.target.value;
                  }
                  else{
                    temp_stores[index].discount = 0;
                  }

                  set_active_stores(temp_stores);

                }}
                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                label="Discount"
              />
            </FormControl>

            </Grid>


          </Grid>

          </Grid>

          )
            
          })}

        </Grid>

        </Paper>


    
        </Grid>



      </Grid>

    </Container>      


    </Box>

     }

    </> 


  );

  function get_configurations_settings() {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/snag-checkout/post_purchase_extension/configs',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was: ', cloneDeep(result))

              if(result.data){

              var stores = result.data.stores

              set_all_stores(stores)

                const act_stores = result.data.active_stores;

                const temp_act_stores = []

                if(act_stores && act_stores.length > 0){

                for (var z=0; z< stores.length; z++){

                  for (var z1=0; z1 < act_stores.length; z1++){

                    if(act_stores[z1].store === stores[z].store){

                      console.log("Yes matched")

                      const temp_store = act_stores[z1]

                      temp_store.name = stores[z].name

                      temp_store.currency = stores[z].currency

                      temp_store.all_products = stores[z].all_products

                      if(temp_store.discount == null){
                        temp_store.discount = ''
                      }else{
                        temp_store.discount = temp_store.discount.toString()
                      }   
  
                      temp_act_stores.push(temp_store)

                      break;

                    }

                  }

                }

                console.log("setting active stores: ", temp_act_stores)


                set_active_stores(temp_act_stores)

              }


        
            }else if(result.errorMessage){

              cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
              
            } else{
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('Error', result)
            }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
      )

    })


  }

  function update_configurations(){

    console.log('updating')

    var request_body = {}

    request_body.configurations = {}

    var act_stores = cloneDeep(active_stores)

    console.log("active stores were: ", act_stores)

    if(act_stores.length === 0){

      cmsContext.setSnackBarOpen(true, "error", "Please select atleast one store!")
      return

    }

    var found_product_error = false;

    var found_cart_error = false;

    for (var i=0; i < act_stores.length; i++){

      if(act_stores[i].discount && act_stores[i].discount.toString().trim().length > 0 && act_stores[i].discount < 1){

        found_cart_error = true;
        //break

      }else{

        found_cart_error = false;

      }


      if(!(act_stores[i].selected_products && act_stores[i].selected_products.length > 0)){

        found_product_error = true;

      }else{

        found_product_error = false;

      }


      if(found_product_error || found_cart_error){

        break

      }

      act_stores[i] = {store: act_stores[i].store, discount: parseFloat(act_stores[i].discount), selected_products: act_stores[i].selected_products}

    }

    if(found_product_error || found_cart_error){
      cmsContext.setSnackBarOpen(true, "error", "Please enter valid values for all fields!")
      return
    }


    request_body.configurations = act_stores;

    request_body.request_type = "Update";

    console.log('request body: ', request_body)

    setIsLoading(true)

    getAccessJwtToken().then((jwt) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/snag-checkout/post_purchase_extension/configs', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was: ', result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                get_configurations_settings()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }


};


export default PostPurchase;