import React,  { useContext, useState, useEffect, useRef, useMemo } from 'react';


import { Box, InputLabel, FormControl, Select, MenuItem, Container, Grid, Card, Button, CircularProgress, Tabs, Tab} from '@mui/material';

import { tabsClasses } from '@mui/material/Tabs';

import CmsContext from '../../store/cms-context';

import { styled } from '@mui/material/styles';

import {getAccessJwtToken } from '../../store/globalUtilities';

import CreateCollection from '../CreateCollection/CreateCollection';

import cloneDeep from 'lodash/cloneDeep';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    
  }),
);


function DeployCollections(props) {

    const cmsContext = useContext(CmsContext);

    const isMountedRef = useRef(null);

    const [selectedStores, setSelectedStores] = useState([]);

    const [mainLoading, setMainLoading] = useState(false);

    const [selected_tab, set_selected_tab] = React.useState(0);

    const [all_variations, set_all_variations] = React.useState([]);


    useEffect(() => {

      console.log('variations changed', all_variations)

    },[all_variations])


    function handleTabChange(event, newValue) {

      set_selected_tab(newValue)
  
    }

    console.log('deploy rendered')


    const handleChange = (event) => {
      
      const {
        target: { value },
      } = event;
      setSelectedStores(
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    useEffect(()=>{

      isMountedRef.current = true
  
      get_collection_data()
  
      return () => isMountedRef.current = false;
  
    },[])



    //console.log("all variations were: ", all_variations)


  

    function handle_collection_change(index, variation){

      
      set_all_variations((previous_variations) => {

        

        var temp_variations;

        if(previous_variations){

          temp_variations = [...previous_variations]
      
        }else{

         // temp_variations = cloneDeep(all_variations)

         return

        }

        console.log('previous variations were: ' , temp_variations)
    
        temp_variations[index] = variation

        console.log('variation to set was: ', index , " :  ", variation.collection.collection.collection_state)

        console.log('setting variations : ' , temp_variations)
  
        return set_all_variations(temp_variations)

      })
      

      /*

      set_all_variations([...all_variations].map((object, index2) => {

        if(index2 === index) {
          return {...variation}
        }
        else return object;
      }))

      */


    }







    return(

      <>

     {mainLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> : all_variations && all_variations.length > 0  ? 
     
     <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
          
         {/* <Grid
          container
          spacing={3}
          alignItems="center"
          style={{padding:20}}
        >

          <Grid
            item
            xs={12}
            
          >

          <Card elevation={2} style={{padding:20,overflow:'visible'}} >

          <FormControl  sx={{ width: '100%' }}>
            
            <InputLabel id="collection-deploy-select-stores">Stores</InputLabel>
            
            <Select
              labelId="collection-deploy-select-autowidth-label"
              id="collection-deploy-select-autowidth"
              autoWidth
              multiple
              value={selectedStores}
              onChange={handleChange}
              label="Stores"
            >
            
            {
              cmsContext.current_shop.connected_stores.map((store, index) => {
            
                return(<MenuItem value={index} key={index}>{store.name}</MenuItem>)
            
              })
            }
              
            </Select>
      
      </FormControl>


          </Card>

        </Grid>

          </Grid> */}

        <Grid item xs={12}>
          

      <Button onClick={() => {const component_state={...props.component_state}; component_state.layout_type = "list";props.set_component_state(component_state)}}  startIcon={<ArrowBackIosIcon />}>
        Back
      </Button>


        <StyledTabs
          value={selected_tab}
          onChange={handleTabChange}
          aria-label="styled tabs example"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >


          {all_variations.map((variation, index) =>{

            return(<StyledTab key={index} label={variation.store.name} />)

          })}

        </StyledTabs>



        </Grid>



         {/*<Collection type="Deploy" index={selected_tab} variation = {cloneDeep(all_variations[selected_tab])} handle_collection_change={handle_collection_change}></Collection>*/}

        

        {all_variations.map((variation, index) => {

          return(<CreateCollection collection_id={props.collection_id} get_collection_data={get_collection_data} reset_all_with_master={reset_all_with_master} key={index} is_enabled={index === selected_tab} collection_type={index === 0 ? "Master" : "Variation"} type="Deploy" variation = {variation}>
      
          </CreateCollection>)


        })}


       

      
      {/*selectedStores.map((store, index) => {
      
          return(<CreateCollection key={index} type="Deploy" store={cmsContext.current_shop.connected_stores[store]} collection_data={collection_data}>
      
            </CreateCollection>)
        
      
      })*/}

      </Container>

      : null }
      
      </>
      
      )


      function get_collection_data() {


  
        setMainLoading(true)
    
        getAccessJwtToken().then((jwt)=>{
    
          const request_headers = {'store_domain': cmsContext.current_shop.store_domain,
          'Authorization':jwt}
      
          request_headers.collection_id = props.collection_id
    
          fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/create_collection_data',{
              crossDomain:true,
              method: 'GET',
              headers:request_headers})
              .then(res => res.json())
              .then(
                (result) => {
      
                  console.log('result was create was',result)
                  

                  if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)

                  }else{

                  

                  var temp_data = cloneDeep(result.data)

                  const master_collection = temp_data.collection

                  const variations = temp_data.variations

                  delete temp_data.collection

                  delete temp_data.variations

                  console.log('master was: ', master_collection)

                  console.log('variation was: ', variations)

                  //.collection.collection_state.initial_collection_data = new_data;

                  const temp_stores = cmsContext.current_shop.connected_stores;

                  const temp_variations = [];

                  temp_data.collection = master_collection

                  temp_variations.push({"store":{"name":"Master"}, "data": cloneDeep(temp_data)})

                  var temp_data_variation;

                  temp_stores.forEach((store, index) => {

                    if(variations[store.store_domain]){

                      temp_data_variation = cloneDeep(temp_data);

                      temp_data_variation.collection = variations[store.store_domain]

                      temp_variations.push({"store":store, "data": temp_data_variation})

                    }else{

                      temp_variations.push({"store":store, "data": cloneDeep(temp_data)})

                    }

                  })


                  set_all_variations(temp_variations)


                    /*
                  temp_data.publications = temp_data.publications.map((publication) => {
                    return(Object.assign(publication,{'is_checked':false}))
                  }); 
            
                  if(collection.publications && collection.publications.length > 0){
            
            
                    temp_data.publications.forEach((publication) => {
            
                      collection.publications.forEach((published_publication) =>{

                        if(published_publication.id === publication.node.id){
                          publication.is_checked = true
                        }else{
                          console.log('not equals', publication.node.id, published_publication.id)
                        }

                      })
            
                    })
            
                  }






                const new_data = cloneDeep(temp_data)

                delete new_data.collection

                temp_data.collection.collection_state.initial_collection_data = new_data;

                const temp_stores = cmsContext.current_shop.connected_stores;

                const fetched_variations = temp_data.variations;

                const temp_variations = [];

                temp_variations.push({"store":{"name":"Master"}, "data": JSON.parse(JSON.stringify(temp_data))})

                var temp_data_variation;

                temp_stores.forEach((store, index) => {

                  if(fetched_variations[store.store_domain]){

                    temp_data_variation = cloneDeep(temp_data);

                    temp_data_variation.collection = fetched_variations[store.store_domain]

                    temp_variations.push({"store":store, "collection": temp_data_variation})

                  }else{

                    temp_variations.push({"store":store, "collection": JSON.parse(JSON.stringify(temp_data))})

                  }

                })

                console.log('variations generated', temp_variations)

                set_all_variations(temp_variations)
                */
                

                }

                setMainLoading(false)

      
                },
            
                (error) => {
                  setMainLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                  console.log('error was',error)
                }
          )
    
        })
    
    
      }

      function reset_all_with_master(){

        const temp_variations = {...all_variations}

        const new_variations = []

        var master_variation = {}

        var temp_variation = {}

        new_variations.push(cloneDeep(temp_variations[0]))

        for (var i=1; i< temp_variations.length; i++){

          master_variation = cloneDeep(temp_variations[0])

          temp_variation = cloneDeep(temp_variations[i])

          temp_variation.data = master_variation.data

          new_variations.push(temp_variation)

        }

        set_all_variations(new_variations)

      }
    



}


export default DeployCollections;