import * as React from 'react';
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Button, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { visuallyHidden } from '@mui/utils';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'weight',
    numeric: false,
    disablePadding: false,
    label: 'Weight',
  },
  {
    id: 'Active from',
    numeric: false,
    disablePadding: false,
    label: 'Active from',
  },
  {
    id: 'Active to',
    numeric: false,
    disablePadding: false,
    label: 'Active to',
  }
];

function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          { <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> }
          </TableCell> 
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (

        <Stack direction="row"  sx={{ flex: '1 1 100%' }} alignItems="center">

        <Typography
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>

         {numSelected < -1 ? 
           <>

          <Button style={{marginLeft:8}}onClick={() => {props.handle_variant_state("deploy")}}>
            View
          </Button> 

          {/*<Button onClick={() => {props.handle_variant_state("deploy")}}>
            Deploy
         </Button> */}
          
          </>
          
          : null}

          </Stack>

      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        </Typography>
      )}


      {numSelected === 1 ? 

        <Button style={{marginRight:8}}onClick={() => {props.set_editing_variants(true)}}>
        Edit
        </Button> 

      : null}
  
      {numSelected > 0 ? 
       ( <Tooltip title="Delete">
          <IconButton onClick={(event) =>{props.delete_variants()}}>
            <DeleteIcon />
          </IconButton>
        </Tooltip> )
      : null}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

 function VariantsListTable(props) {

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);

  const rows = [];



  console.log('table options were', props.all_variants)


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {

    const is_checked = event.target.checked;

    var temp_variants = [...props.all_variants]

    temp_variants = temp_variants.map((variant) => {
  
      variant.is_checked = is_checked;

      return(variant);

    })

    props.set_all_variants(temp_variants)

  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  // Avoid a layout jump when reaching the last page with empty rows.

        
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * props.rowsPerPage - rows.length) : 0;


    function handleClick(event, index){

        return

      console.log('class list was: ',event.target.classList)
  
      if (event.target.classList.contains("css-1m9pwf3")) {
        console.log("checkbox select");
        return
      }
  
      console.log("index was: ", index)
  
      //handle_row_update(index, "is_checked", true)
  
      props.set_component_state({layout_type: "deploy", variant_id : props.all_variants[index].id})
  
  
    }

    
  const handle_row_update = (index, is_checked) => {


    const temp_variants = [...props.all_variants]

    temp_variants[index].is_checked = is_checked

    props.set_all_variants(temp_variants)


  }

  const handle_variant_state = (type) => {

    const variant = props.all_variants.filter((variant) => variant.is_checked === true)

    if(variant.length > 0){

      console.log('variant was', variant)

      props.set_component_state({layout_type: type, variant_id : variant[0].id})

    }else{
      console.log("Error getting variant")
    }

  }
  

  return (
    <>
    {props.all_variants.length > 0 ? <Box sx={{ width: '100%'}}>
      <Paper sx={{ width: '100%', mb: 2}} elevation={4} >
        <EnhancedTableToolbar set_editing_variants={props.set_editing_variants} type={props.type} numSelected={props.selected} delete_variants={props.delete_variants} handle_variant_state={handle_variant_state} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={props.selected}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.all_variants.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {props.all_variants.map((variant, index) => {

              return(

                <TableRow
                      style={{cursor:'pointer'}}
                      hover
                      onClick={(event) => handleClick(event, index)}
                      role="checkbox"
                      //aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                    // selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={variant.is_checked}
                         onChange={(event) => {handle_row_update(index, event.target.checked)}}
                          /*
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          */
                        />
                      </TableCell>
                      <TableCell align="left" style={{paddingRight:0}}>
                      <Typography fontSize={12}><strong>{variant.variant_name}</strong></Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        //id={labelId}
                        scope="row"
                        style={{minWidth:200}}
                      >
                         <Typography fontSize={12}>{variant.weight}</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{minWidth:200}}
                      >
                        <Typography fontSize={12}>{variant.active_from}</Typography>
                      </TableCell>
                      <TableCell align="left">
                       <Typography fontSize={12}>{variant.active_to}</Typography>
                      </TableCell>
                    </TableRow>
                
                  );


                 
              })}
              
            </TableBody>
          </Table>
        </TableContainer>
    
      </Paper>
      
    </Box> : null}
    </>
  );
}


export default React.memo(VariantsListTable);