
import PropTypes from 'prop-types';
import { Box, Button, ListItem } from '@mui/material';

import {Link, useLocation} from 'react-router-dom'
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useEffect } from 'react';




export const NavItem = (props) => {

  const { href, icon, type, title, is_active, item, item_key, item_title, set_selected_nav_item, ...others } = props;


  return (

    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2,
        marginLeft: props.is_sub_item_sub === "true" ? 1 : 0
      }}
      {...others}
    >

      <div style={type === "item" ? { width: '100%'} : { width: '86%', marginLeft:'auto', marginRight:'auto'}}>
    
     <Link to={props.sub_item && props.sub_item.forward_ref ? item.href + props.sub_item.forward_ref : href} onClick={(e) => {if(type === "item" && item.sub_items.length !== 0){e.preventDefault()}}} style={{ textDecoration: 'none' , width: '100%'}}>
        <Button
          startIcon={icon === null ? null : icon}
          disableRipple
          onClick={() => {props.set_selected_nav_item(item_key)}}
          sx={{
            backgroundColor: type === "sub_item" && is_active && ((props.has_sub_items === "true" && props.is_sub_item_sub === "true") || (props.has_sub_items === "false")) && 'rgba(255,255,255, 0.08)',
            borderRadius: 1,
            color: is_active ? 'secondary.main' : 'neutral.300',
            fontWeight: is_active && 'fontWeightBold',
            justifyContent: 'flex-start',
            px: 3,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            '& .MuiButton-startIcon': {
              color: is_active ? 'secondary.main' : 'neutral.400'
            },
            '&:hover': {
              backgroundColor: 'rgba(255,255,255, 0.08)'
            }
          }}
        >
           
          <Box sx={{ flexGrow: 1 }}>
            {title}
          </Box>
        
        </Button>

        </Link>

        </div>

    </ListItem>
    
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string
};