import React, { useEffect } from 'react';

import PropTypes from 'prop-types';


import { Box, Card, Grid, Typography} from '@mui/material';


import styles from './Instagram.module.css'



function InstagramTag(props) {

    useEffect(() =>{

        console.log('tag rendered')
    },[])


    return(

        <Card elevation={2} style={{overflow:'visible'}}>
        <Grid container
        style={{overflow:'visible'}}>

    

        <Grid item xs={12} md={8}>

        <div className={styles.modelbox}>
        <iframe title='123'  className={styles.model} src={props.instagram_tag.permalink + "embed"} frameBorder="0" scrolling="no"></iframe>
        </div>


        </Grid> 


        </Grid>

        </Card>



    );


}



export default InstagramTag;
