import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Chip, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, makeStyles} from '@mui/material';
import Dropzone from 'react-dropzone'

import { UserCircle as UserCircleIcon } from '../../icons/user-circle';


import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../store/cms-context';

import InstagramIcon from '@mui/icons-material/Instagram';


const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));

function InstagramDialog(props) {

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);


  function handle_view(){

    console.log(props.instagram_dialog_state.instagram)

    window.open(props.instagram_dialog_state.instagram.permalink, "_blank")

  }


  const onSelect = (index, checked) => {

    const temp_variant_image = {...props.selected_variant_image}

    if(checked){
      temp_variant_image.image_index = index
    }else{
      temp_variant_image.image_index = -1
    }

    props.set_selected_variant_image(temp_variant_image)
    
  }

  function set_contact_state(key, value){
    
    const temp_state = {...props.instagram_dialog_state}

    temp_state.contact[key] = value

    props.set_instagram_dialog_state(temp_state)
    
  }

  function handle_contact(){

    const contact = {...props.instagram_dialog_state.contact}


    const email = contact.email.trim()
    
    const phone = contact.phone.trim()

    if(email.length === 0 || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid email!")
      return
    }

    if(phone.length > 0 && !(/^\s*(?:\+?(\d{1,4}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(phone))){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid phone number!")
      return
    }else if(phone.length === 0 && (contact.daily_sales_whatsapp || contact.hourly_sales_whatsapp)){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid phone number, to enable Whatsapp notifications!")
      return
    }

    contact.email = email

    contact.phone = phone

    props.post_contact(contact, props.instagram_dialog_state.type.toLowerCase())

  }

  function findHashtags(searchText) {
      var regexp = /\B\#\w\w+\b/g
      var result = searchText.match(regexp);
      if (result) {
          return(result)
      } else {
          return([])
      }
  }

  var hash_tags = [];

  if(props.instagram_dialog_state.instagram.caption){
    hash_tags = findHashtags(props.instagram_dialog_state.instagram.caption)
  }

  

  function handle_close(){

    const temp_state = {...props.instagram_dialog_state}

    temp_state.is_dialog_open = false

    props.set_instagram_dialog_state(temp_state)

  }

  var url = "";

  if(props.instagram_dialog_state.instagram.url && props.instagram_dialog_state.instagram.url.length > 0){
    url = props.instagram_dialog_state.instagram.url
  }else if(props.instagram_dialog_state.instagram.media_url){
    url = props.instagram_dialog_state.instagram.media_url
  }else{
    url = "/static/images/photo.png"
  }



  return (
    <div>
      <Dialog
        open={props.instagram_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         Instagram Tag
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

            <Grid
              item
              xs={12}
             
              >

                <div style={{display:'flex'}}>

                <Avatar
                    sx={{
                      height: 48,
                      width: 48
                    }}
                    src="/static/images/user.png"
                  >
                    
                    <UserCircleIcon fontSize="small" />

                </Avatar>

                <Typography style={{marginTop:'auto', marginBottom:4, marginLeft:8}} fontSize={12}><strong>{props.instagram_dialog_state.instagram.username}</strong></Typography>
                
                </div>

              </Grid> 



             <Grid
              item
              xs={12}
              >
                <div style={{width:'100%',textAlign:'center'}}>
                <img alt="insta_img" style={{width: '100%'}}
                src={url} onError={({currentTarget}) => {currentTarget.onerror=null;console.log('image error received') ;currentTarget.src='/static/images/photo.png'; }}
                >
                </img>
                </div>
              </Grid> 

           {props.instagram_dialog_state.instagram.caption && props.instagram_dialog_state.instagram.caption.length > 0 ? <Grid item xs={12}>

              <Typography fontSize={14}><strong>Caption</strong></Typography>
              <Typography style={{marginTop:8}} fontSize={13}>{props.instagram_dialog_state.instagram.caption}</Typography>

            </Grid> : null}

            {hash_tags.length > 0 ? <Grid item xs={12}>

            <Typography fontSize={14} style={{marginBottom:8}}><strong>Tags</strong></Typography>
              
              {hash_tags.map((hash_tag, index) =>{
                         return(<Chip key={index} size="small" color="primary" label={hash_tag} style={{marginLeft:4, marginTop:4, marginBottom:4}}></Chip>)
                       })}

            </Grid> : null}


            
            </Grid>


        </DialogContent>
        <DialogActions>
        <Button onClick={handle_view} startIcon={<InstagramIcon />} variant='outlined'>{"View"}</Button>
        <Button onClick={handle_close} variant='outlined'>{"Close"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default InstagramDialog;