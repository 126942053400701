import React, { useContext, useState, useEffect } from 'react';

import { Box, InputLabel, FormControl, Select, MenuItem} from '@mui/material';


import CreateCollection from './CreateCollection';

import DeployCollections from '../DeployCollections/DeployCollections';



function CollectionEncapsulation(props) {

  console.log('collection encapsulation rendered')


      return(
    
    
    
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >

            {props.type === "Create" ? 

            <CreateCollection type="Create" collection_type="Master" is_enabled={true}>

            </CreateCollection>

            :props.type === "Edit" ?
            
            <CreateCollection type="Edit" is_enabled={true} collection_id={props.collection_id} component_state={props.component_state} set_component_state={props.set_component_state}>

            </CreateCollection>

             :

             props.type === "Deploy" ? 

              <DeployCollections type="Deploy" collection_id={props.collection_id} component_state={props.component_state} set_component_state={props.set_component_state}></DeployCollections>

             : null

            }
    
         
        </Box>
    
      );
    




}


export default CollectionEncapsulation;