import * as React from 'react';
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { OutlinedInput, InputAdornment, ButtonGroup, Button, Stack, Chip, Grid, TextField, FormControl, Autocomplete } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import DownloadIcon from '@mui/icons-material/Download';
import { download_image } from '../../../Utilities/Metrics';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'image',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'folder',
    numeric: false,
    disablePadding: false,
    label: 'Folder',
  },
  {
    id: 'created',
    numeric: false,
    disablePadding: false,
    label: 'Created',
  },
  {
    id: 'updated',
    numeric: false,
    disablePadding: false,
    label: 'Updated',
  },
  {
    id: 'tags',
    numeric: false,
    disablePadding: false,
    label: 'Tags',
  }
];

function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
    
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (

        <Stack direction="row"  sx={{ flex: '1 1 100%' }} alignItems="center">

        <Typography
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>

        {numSelected === 1 ?

          <Button sx={{marginLeft:2}} size="small" onClick={() => {props.start_editing()}}>
            Edit
          </Button>

        : null}

         {numSelected > 0 ? 

          <Button sx={{marginLeft:2}} size="small" onClick={() => {props.show_deploy_dialog()}}>
            Deploy
          </Button>

          : null}

          </Stack>

      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        </Typography>
      )}

  
      {numSelected > 0 ? (
        <div style={{display: 'flex'}}>
        <Tooltip title="Delete">
          <IconButton onClick={(event) =>{props.handle_file_update("files", "Delete")}}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip sx={{marginTop:0.3}} title="Download">
        <IconButton onClick={(event) =>{props.handle_download_files()}}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
      </div>
      ) : null}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

 function FilesTable(props) {

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);

  const selected = React.useMemo(() => {

    return(props.all_files.filter((file) => file.is_checked).length)

  },[props.all_files])



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {

    const is_checked = event.target.checked;

    props.set_all_files((previous_files) => {

      var temp_files = [...previous_files]

      temp_files = temp_files.map((file) => {

        file.is_checked = is_checked
  
        return(file);
  
      })

      // console.log('settings files', temp_files)
  
      return(props.set_all_files(temp_files))

    })

    props.reset_editor_state()

  };



    function handleClick(event, index){


     // console.log('class list was: ',event.target.classList)
  
      if (event.target.classList.contains("css-1m9pwf3")) {
        //console.log("checkbox select");
        return
      }
  
      //console.log("index was: ", index)
  
      //handle_row_update(index, "is_checked", true)

      props.set_selected_file(props.all_files[index])  
  
    }

    
  const handle_row_update = (index, is_checked) => {

    props.set_all_files((previous_files) => {

      const temp_files = [...previous_files];

      temp_files[index].is_checked = is_checked

      return(props.set_all_files(temp_files))

    })

    props.reset_editor_state()
    
  }

  const handle_file_state = (type) => {

    const file = props.all_files.filter((file) => file.is_checked === true)

    if(file.length > 0){

      console.log('file was', file)

      props.set_component_state({layout_type: type, file_id : file[0].id})

    }else{
      console.log("Error getting file")
    }

  }

  function handle_download_files(){

    const files = props.all_files.filter((file) => file.is_checked === true)

    files.forEach((file)=> {

      if(file.preview && file.preview.length > 0){

          download_image(file)

      }

    })

  }




  return (

    <>
    {props.all_files.length > 0 ? <Box sx={{ width: '100%'}} >
      <Paper sx={{ width: '100%', mb: 2 }}>

        <EnhancedTableToolbar handle_download_files={handle_download_files} show_deploy_dialog={props.show_deploy_dialog} start_editing={props.start_editing} numSelected={selected} handle_file_update={props.handle_file_update} handle_file_state={handle_file_state} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.all_files.length}
            />
            <TableBody>

              {props.rows.map((file, index) => {

                  var tags = [];

                  if(file.tags){

                    if(file.tags.length > 0){
                      tags = file.tags;
                    }else{
                      tags = file.tags
                    }

                  }

                  var url = "";

                  if(file.preview && file.preview.length > 0){
                    url = file.preview
                  }else{
                    url = "/static/images/photo.png"
                  }



              return(

                <TableRow
                      style={{cursor:'pointer'}}
                      hover
                      onClick={(event) => handleClick(event, index)}
                      role="checkbox"
                      //aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                    // selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={file.is_checked}
                         onChange={(event) => {handle_row_update(index, event.target.checked)}}
                        />
                      </TableCell>
                      <TableCell align="left" >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{border:'1px solid lightgray', width:50 ,height:50,borderRadius:4}}
                      >
                         <img style={{width:48,height: 48}} alt="snag" src={url} onError={({currentTarget}) => {currentTarget.onerror=null;console.log('image rror received') ;currentTarget.src='/static/images/photo.png'; }}/>
                      </Box>     
                      </TableCell> 
                      <TableCell align="left">
                       <Typography fontSize={12}><strong>{file.name}</strong></Typography>
                      </TableCell>
                      <TableCell align="left">
                       <Typography fontSize={12}><strong>{file.parent}</strong></Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                       <Typography fontSize={13}> <strong>{file.created_at_time}</strong></Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                       <Typography fontSize={13}> <strong>{file.updated_at_time}</strong></Typography>
                      </TableCell>
                      <TableCell align="left">
                      <div style={{display:'flex',width:200,overflowX:'scroll'}}>
                      {tags && tags.length > 0 ? tags.map((tag, index) =>{
                         return(<Chip key={index} color="primary" label={tag} style={{marginLeft:4, marginTop:4, marginBottom:4}}></Chip>)
                       }): null}
                       </div>
                      </TableCell>
                    </TableRow>
                
                  );

                 
              })}
              
            </TableBody>
          </Table>
        </TableContainer>
    
      </Paper>
      
    </Box> : null}
    </>
  );
}


export default React.memo(FilesTable);