import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../store/cms-context';

import { getAccessJwtToken } from '../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import GoogleSearchLinkDialog from "../GoogleSearchLinkDialog/GoogleSearchLinkDialog";


import cloneDeep from 'lodash/cloneDeep';
import FlowTasks from "../FlowTasks/FlowTasks";

import { default_gsearch_link_dialog } from "../../Utilities/Metrics"

import { LoadingButton } from "@mui/lab";


function GoogleSearch(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [active_accounts, set_active_accounts] = React.useState([]);

  const [email_time, set_email_time] = React.useState('');

  const [link_dialog_state, set_link_dialog_state] = React.useState(cloneDeep(default_gsearch_link_dialog));

  const [isLinkAccountsLoading, setIsLinkAccountsLoading] = React.useState(false)

  const [all_gads_search_connections, set_all_gads_search_connections] = React.useState([]);


  useEffect(() => {

    isMountedRef.current = true

    get_google_ads_link_data()

    return () => isMountedRef.current = false;

  }, [])


 


  function show_link_accounts_dialog(action_type){

    setIsLinkAccountsLoading(true)

    getAccessJwtToken().then((jwt)=>{

    fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/gads_google_search',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':jwt,
            'email':cmsContext.current_shop.email
          }})
          .then(res => res.json())
          .then(
            (result) => {

              console.log('result was: ', result)

              setIsLinkAccountsLoading(false)

              if(result.search_accounts){

                const temp_dialog_state = cloneDeep(default_gsearch_link_dialog);

                temp_dialog_state.action_type = action_type
  
                temp_dialog_state.is_dialog_open = true

                temp_dialog_state.search_accounts = result.search_accounts

                set_link_dialog_state(temp_dialog_state);
    
                console.log('result was: ', result)

              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please try again")

              }
  
            },
        
            (error) => {
              setIsLinkAccountsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please try again")
              console.log('error was',error)
            }
           
      )

    })
    

  }



  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Google Search</Typography>

          <div>

          <LoadingButton loading={isLinkAccountsLoading} variant="contained" onClick = {() => {show_link_accounts_dialog('Update')}}>Link Accounts</LoadingButton>

          </div>

        </Stack>

      </Grid>


        {Object.keys(all_gads_search_connections).length > 0 ? 
        
        <Grid
          item
          xs={12}
        >

          <FlowTasks all_tasks={all_gads_search_connections}></FlowTasks>

        </Grid> : null}


      </Grid>

    </Container>      


    </Box>

     }

     <GoogleSearchLinkDialog link_dialog_state={link_dialog_state} set_link_dialog_state={set_link_dialog_state} handle_search_gads_update={handle_search_gads_update}></GoogleSearchLinkDialog>


    </> 


  );




  function get_google_ads_link_data() {
    
    console.log('store_access_token')


    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/gads/search_links',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':jwt,
            'email':cmsContext.current_shop.email
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
  
              setIsLoading(false)
  
              console.log('result was',result)

              if(result.links){

                set_all_gads_search_connections(result.links)

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }

              /*

             
              if(result.contacts){

                console.log('setting analytics')

                if(result.contacts.length === 0){

                  cmsContext.setSnackBarOpen(true, "info", "No contacts yet. Let's create one to get started.")

                  return

                }


                const all_contacts = []

                var temp_contact;

                result.contacts.forEach((contact) =>{
                  temp_contact = cloneDeep(default_contact.contact)
                  for(var key in contact){
                    temp_contact[key] = contact[key]
                  }
                  temp_contact.is_checked = false
                  console.log('temp contact was: ', temp_contact)
                  all_contacts.push(temp_contact)
                })

              
                set_all_gads_search_connections(all_contacts)

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
                */
              
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
           
      )

    })


  }


  function set_dialog_loading(is_loading){

    const temp_contact_state = {...link_dialog_state}

    temp_contact_state.isLoading = is_loading

    set_link_dialog_state(temp_contact_state)

  }

  function reset_gads_dialog_state(){

    set_link_dialog_state(cloneDeep(default_gsearch_link_dialog))

  }



  function handle_search_gads_update(selected_gsearch_account){


    if(Object.keys(selected_gsearch_account).length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid searchant account")
      return
    }

    var has_target = false

    selected_gsearch_account.gads_accounts.forEach((account) =>{
      if(account.is_checked){
        has_target = true
      }
    })

    if(!has_target){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid Google Ads Account!")
      return
    }



    set_dialog_loading(true)

    console.log('updating')
    
    var request_body = {}
    
    request_body.gsearch_account = selected_gsearch_account

    console.log('body was: ', request_body)


  
    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/gads/search_links', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              set_dialog_loading(false)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", "Accounts successfully updated!")

                reset_gads_dialog_state()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })

    

  }



};


export default GoogleSearch;