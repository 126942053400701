import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl, Slider, ButtonGroup, Switch } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../../../store/cms-context';

import { getAccessJwtToken } from '../../../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import TaskDialog from "./SubComponents/TaskDialog";

import cloneDeep from 'lodash/cloneDeep';

import FlowTasks from "./SubComponents/FlowTasks";

import { default_order_sync_task_dialog } from "../../../../Utilities/Metrics"

import { LoadingButton } from "@mui/lab";

import BarChart from "./SubComponents/BarChart"

import CurrenciesInfo from '../../../../Utilities/CurrenciesInfo'

import { CustomSwitch } from "../../../../Utilities/UIWidgets";


const marks = [
  {
    value: 0,
    label: '0x',
  },
  {
    value: 4,
    label: '4x',
  },
  {
    value: 5,
    label: '5x',
  },
  {
    value: 20,
    label: '20x',
  },
];


function MintsoftOrdersSync(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [active_accounts, set_active_accounts] = React.useState([]);

  const [email_time, set_email_time] = React.useState('');

  const [analytics, set_analytics] = React.useState([]);

  const [task_dialog_state, set_task_dialog_state] = React.useState(cloneDeep(default_order_sync_task_dialog));

  const [isLinkAccountsLoading, setIsLinkAccountsLoading] = React.useState(false);

  const [configurations, set_configurations] = React.useState({});

  useEffect(() => {

    isMountedRef.current = true

    get_google_ads_link_data()

    return () => isMountedRef.current = false;

  }, [])


  function valuetext(value) {
    return `${value}x`;
  }
 

  function show_link_accounts_dialog(action_type){

    if(Object.keys(configurations).length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Invalid Configurations, please reload page!")
      return
    }

    const temp_state = cloneDeep(default_order_sync_task_dialog)

    temp_state.all_stores = configurations.stores

    temp_state.mintsoft_clients = configurations.mintsoft_clients

    temp_state.is_dialog_open = true

    temp_state.all_stores.forEach((store) => {
      if(store.is_master === true){
        temp_state.source = store
      }
    })

    set_task_dialog_state(temp_state)

  }



  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Connections</Typography>

          <div>

          <LoadingButton loading={isLinkAccountsLoading} variant="contained" onClick = {() => {show_link_accounts_dialog('Update')}}>Link Accounts</LoadingButton>

          </div>

        </Stack>

      </Grid>


        {Object.keys(configurations).length > 0 ? 
        
        <Grid
          item
          xs={12}
        >

          <FlowTasks post_sync_task={post_sync_task} all_tasks={configurations.tasks}></FlowTasks>

        </Grid> : null}


      </Grid>

    </Container>


    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth: "1000px", overflow: "visible"}}>
  
      <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >


        { analytics.length > 0 ? analytics.map((account_level_analytics, index) =>{

          return(
            <Grid key={index} item xs={12}>
              <BarChart analytics={account_level_analytics}></BarChart>
            </Grid>
          )

        }) : null}



         </Grid>

       </Container>

      </Box>

      }

      </>

    </Box>

     }

     {task_dialog_state ? <TaskDialog task_dialog_state={task_dialog_state} set_task_dialog_state={set_task_dialog_state} post_sync_task={post_sync_task}></TaskDialog> : null}


    </> 


  );




  
  function get_google_ads_link_data() {
    
    console.log('store_access_token')


    // user api

    // error handling and state managment required


    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/shopify/orders/sync',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
              setIsLoading(false)
  
              console.log('result was',result)

              if(result.data){

                set_configurations(result.data)

                /*

                const temp_links = [...result.links]

                temp_links.forEach((link) =>{
                  link.isLoading = false
                  link.adRules.isActive = false

                  if(link.adRules.budget){
                    link.adRules.budget = link.adRules.budget.toString()
                  }

                  if(link.adRules.roas){
                    link.adRules.roas = link.adRules.roas.toString()
                  }

                })

                set_all_gads_merch_connections(temp_links)
                
                */

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }

              /*

             
              if(result.contacts){

                console.log('setting analytics')

                if(result.contacts.length === 0){

                  cmsContext.setSnackBarOpen(true, "info", "No contacts yet. Let's create one to get started.")

                  return

                }


                const all_contacts = []

                var temp_contact;

                result.contacts.forEach((contact) =>{
                  temp_contact = cloneDeep(default_contact.contact)
                  for(var key in contact){
                    temp_contact[key] = contact[key]
                  }
                  temp_contact.is_checked = false
                  console.log('temp contact was: ', temp_contact)
                  all_contacts.push(temp_contact)
                })

              
                set_all_gads_merch_connections(all_contacts)

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
                */
              
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
           
      )

    })


  }


  function set_dialog_loading(is_loading){

    const temp_contact_state = {...task_dialog_state}

    temp_contact_state.isLoading = is_loading

    set_task_dialog_state(temp_contact_state)

  }

  function reset_task_dialog_state(){

    set_task_dialog_state(cloneDeep(default_order_sync_task_dialog))

  }


  function post_sync_task(data) {
    
    const temp_tasks = [...configurations.tasks]

    console.log("data was: ", data)

    var request_body = {}

    if(data){

      const delete_ids = []

      const index = parseInt(data.index)

      if(data.type === "source"){

        temp_tasks.forEach((task) => {

         if(task.source.store ===  temp_tasks[index].source.store){
          
          delete_ids.push(task.id)

         }

        })

      }else{

        delete_ids.push(temp_tasks[parseInt(index)].id)

      }

      request_body.delete_ids = delete_ids

      request_body.request_type = "Delete"

      setIsLoading(true)

    }

    else{

    request_body.sync_task = {}

    const temp_state = {...task_dialog_state}

    if(Object.keys(temp_state.source).length === 0){

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid source account!")
      return

    }

    if(temp_state.target_type.length === 0){

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid target type!")
      return

    }

    if(temp_state.order_prefix.trim().length === 0){

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Order Prefix!")
      return

    }

    if(Object.keys(temp_state.target).length === 0){

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid target account!")
      return

    }

    if(temp_state.source.store === temp_state.target.store){

      cmsContext.setSnackBarOpen(true, "error", "Please select a different source and target!")
      return

    }

    console.log('updating')

    request_body.sync_task.source = temp_state.source

    request_body.sync_task.target_type = temp_state.target_type

    request_body.sync_task.target = temp_state.target

    request_body.sync_task.order_prefix = temp_state.order_prefix

    request_body.request_type = temp_state.action_type

    set_dialog_loading(true)

    }

    console.log('body was: ', request_body)

    getAccessJwtToken().then((jwt) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/shopify/orders/sync', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              set_dialog_loading(false)

              setIsLoading(false)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                reset_task_dialog_state()

                get_google_ads_link_data()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })

    
  }


  
};



export default MintsoftOrdersSync;