import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl, FormControlLabel, Switch } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../../../store/cms-context';

import { getAccessJwtToken } from '../../../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Analytics, Storage } from 'aws-amplify';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { clone, cloneDeep, min } from "lodash";
import { ONE_DAY } from "../../../../Utilities/Metrics";

import currencySymbols from "../../../../Utilities/CurrencieSymbols";


const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));


function ProductDiscounts(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_stores, set_all_stores] = React.useState([]);

  const [active_stores, set_active_stores] = React.useState([]);

  const navigate = useNavigate();


  useEffect(() => {

    isMountedRef.current = true

    get_configurations_settings()

    return () => isMountedRef.current = false;

  }, [])




  function handle_active_stores_change(event, newValue){

    set_active_stores(newValue)

  }


  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Configurations</Typography> 

          <div>

          <Button variant="contained" onClick = {() => {update_configurations("rules")}}>Update Configurations</Button>

          </div>

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >

      <Paper elevation = {2} style={{marginTop:16, padding:20}}>
        
        <Grid
          container
          spacing={3}
          alignItems="center"
        >

          <Grid
            item
            xs={12}
          >

          <Autocomplete
                multiple
                id="active-stores"
                options={all_stores}
                getOptionLabel={(option) => option.name}
                value={active_stores}
                disabled
                isOptionEqualToValue={(option, value) => option.store === value.store}
                onChange={(event, newValue) => {handle_active_stores_change(event, newValue)}}
                renderInput={(params) => (
                  <TextField {...params} label="Active stores" placeholder="Active stores" />
                )}
          />     

        </Grid>

    

        {active_stores.map((store, index) => { 

          return(

            <Grid key={index} item xs={12} >

            <Grid container spacing={1}>

            <Grid
            item 
            xs={12}
            >

              <Typography style={{marginBottom:8}} fontSize={12}><strong>{store.name}</strong></Typography>

            </Grid>

            <Grid
              item
              xs={6}
              md={6}
              >

              <Autocomplete
                    id="product"
                    options={store.all_products}
                    getOptionLabel={(option) => option.title ? option.title : ''}
                    value={store.product ? store.product : {}}
                    size="small"
                    isOptionEqualToValue={(option, value) => option.id === value.id }
                    onChange={(event, newValue) => {

                      const temp_stores = [...active_stores]

                      temp_stores[index].product = newValue

                      set_active_stores(temp_stores)

                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.title}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} label="Sticker Product" placeholder="Sticker Product" />
                    )}
              />     

            </Grid>



            <Grid
              item
              xs={6}
              md={6}
            >


            <FormControl fullWidth >
              <InputLabel htmlFor="outlined-adornment-cpa">Cart Threshold</InputLabel>
              <OutlinedInput
                id="outlined-adornment-cpa"
                value={active_stores[index].cart_threshold ? active_stores[index].cart_threshold : ''}
                type="number"
                size="small"
                onChange={(event) => {

                  console.log('value was: ', event.target.value)

                  const temp_stores = [...active_stores];

                  if(event.target.value >= 0){
                    temp_stores[index].cart_threshold = event.target.value;
                  }
                  else{
                    temp_stores[index].cart_threshold = 0;
                  }

                  set_active_stores(temp_stores);

                }}
                startAdornment={<InputAdornment position="start">{currencySymbols.get(active_stores[index].currency)}</InputAdornment>}
                label="Cart Threshold"
              />
            </FormControl>

            </Grid>


          </Grid>

          </Grid>

          )
            
          })}

        </Grid>

        </Paper>


    
        </Grid>



      </Grid>

    </Container>      


    </Box>

     }

    </> 


  );

  function get_configurations_settings() {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/products/discounts',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was: ', cloneDeep(result))

              if(result.data){

              var stores = result.data.stores

              set_all_stores(stores)

                const act_stores = result.data.active_stores;

                const temp_act_stores = []

                if(act_stores && act_stores.length > 0){

                for (var z=0; z< stores.length; z++){

                  for (var z1=0; z1 < act_stores.length; z1++){

                    if(act_stores[z1].store === stores[z].store){

                      const temp_store = act_stores[z1]

                      temp_store.name = stores[z].name

                      temp_store.currency = stores[z].currency

                      temp_store.all_products = stores[z].all_products

                      for(var z2 = 0; z2 < temp_store.all_products.length; z2++){

                        if(temp_store.product_id && temp_store.all_products[z2].id === temp_store.product_id.split("gid://shopify/Product/")[1]){

                          temp_store.product = temp_store.all_products[z2]

                          break

                        }

                      }

                      if(temp_store.cart_threshold == null){
                        temp_store.cart_threshold = ''
                      }else{
                        temp_store.cart_threshold = temp_store.cart_threshold.toString()
                      }   
  
                      temp_act_stores.push(temp_store)

                      break;

                    }

                  }

                }

                console.log("setting active stores: ", temp_act_stores)


                set_active_stores(temp_act_stores)

              }


        
            }else if(result.errorMessage){

              cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
              
            } else{
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('Error', result)
            }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
      )

    })


  }

  function update_configurations(){

    console.log('updating')

    var request_body = {}

    request_body.configurations = {}

    var act_stores = cloneDeep(active_stores)

    console.log("active stores were: ", act_stores)

    if(act_stores.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select atleast one store!")
      return
    }

    var found_product_error = false;

    var found_cart_error = false;

    for (var i=0; i < act_stores.length; i++){

      if(act_stores[i].cart_threshold == null || act_stores[i].cart_threshold.trim().length === 0 || isNaN(parseFloat(act_stores[i].cart_threshold)) || parseFloat(act_stores[i].cart_threshold) < 0){

        found_cart_error = true;
        //break

      }else{

        found_cart_error = false;

      }


      if(!(act_stores[i].product && act_stores[i].product.id)){

        found_product_error = true;
        //break

      }else{

        found_product_error = false;

      }

      console.log("product and cart error: ", found_product_error, " : ", found_cart_error)


      if((found_product_error && !found_cart_error) || (found_cart_error && !found_product_error)){
        break
      }

      if(!(found_product_error && found_cart_error)){

        act_stores[i] = {store: act_stores[i].store, cart_threshold: parseFloat(act_stores[i].cart_threshold), product_id: act_stores[i].product.id, currency: act_stores[i].currency}

      }else{

        act_stores[i] = {store: act_stores[i].store, cart_threshold: null, product_id: null, currency: act_stores[i].currency}

      }

    }

    if((found_product_error && !found_cart_error) || (found_cart_error && !found_product_error)){
      cmsContext.setSnackBarOpen(true, "error", "Please enter valid values for all fields!")
      return
    }


    request_body.configurations = act_stores;

    request_body.request_type = "Update";

    console.log('request body: ', request_body)

    setIsLoading(true)

    getAccessJwtToken().then((jwt) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/products/discounts', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was: ', result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                get_configurations_settings()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }


};


export default ProductDiscounts;