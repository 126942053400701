import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl, Select, MenuItem, Divider} from '@mui/material';

import { LoadingButton } from "@mui/lab";

import CmsContext from '../../store/cms-context';

import { getAccessJwtToken } from '../../store/globalUtilities';
 
import cloneDeep from 'lodash/cloneDeep';


import DeployTasksListTable from "./SubComponents/DeployTasksListTable";




function ScheduledProducts(props) {

  
  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [analytics, set_analytics] = React.useState([]);

  const [all_tasks, set_all_tasks] = React.useState([]);




  useEffect(() => {

    isMountedRef.current = true

    get_all_tasks()

    //get_configurations_settings()

    return () => isMountedRef.current = false;

  }, [])




  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Deployments</Typography> 

        </Stack>

      </Grid>

     {all_tasks != null && all_tasks.length > 0 ?

        <Grid
        item
        xs={12}
        >

          <Grid
          container
          spacing={3}
          alignItems="center"
          >

          <Grid
          item
          xs={12}
          >

            <DeployTasksListTable delete_tasks={delete_tasks} all_tasks={all_tasks} set_all_tasks={set_all_tasks}></DeployTasksListTable>

          </Grid>

        </Grid>

      </Grid>

      : null}



      </Grid>

    </Container>      


    </Box>

     }

    </> 


  );



  function delete_tasks(){

    var selected_tasks = []

    all_tasks.forEach((task) =>{
      if(task.is_checked){
        selected_tasks.push(task.id)
      }
    })

    if(selected_tasks.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select valid task(s)")
      return
    }

    const request_body = {}

    request_body.tasks = selected_tasks

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/bulk_deploy_tasks', {
          crossDomain:true,
          method: 'DELETE',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)
                reset_page(true)
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }
  

  function get_all_tasks() {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/bulk_deploy_tasks', {
          crossDomain:true,
          method: 'GET',
          headers: {
            'email': cmsContext.current_shop.email,
            'Authorization':jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was',cloneDeep(result))



              if(result.all_tasks){

                if(result.all_tasks.length === 0){
                  cmsContext.setSnackBarOpen(true, "info", "No tasks yet. Let's create one to get started.")
                  return
                }

                result.all_tasks.forEach((task) =>{
                  task.is_checked = false
                })

                set_all_tasks(result.all_tasks)

              }else if(result.errorMessage){
                
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
      )

    })


  }




  function reset_page(delayed){

    setTimeout(function() {

      window.location.reload();

  }, delayed?100:1)
}




};


export default ScheduledProducts;