import * as React from 'react';
import addWeeks from 'date-fns/addWeeks';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker } from 'react-date-range';

import { addDays, subDays } from "date-fns";





export default function DatePicker(props) {

  const handleClickOpen = () => {
    props.set_calendar_open(true);
  };

  const handleClose = () => {
    // handle apply
    props.set_calendar_open(false);
  };

  const [state, setState] = React.useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 1),
      key: "selection"
    }
  ]);

    const handleOnChange = ranges => {
      const { selection } = ranges;
      props.onDateChange(selection);
      setState([selection]);
    };

  return (
    <div>
   
      <Dialog
        fullWidth
        maxWidth="md"
        open={props.calendar_open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Filter Tags by date range
        </DialogTitle>
        <DialogContent dividers>
       
        <div style={{width:'100%', marginRight:8, padding:8}}>

          <DateRangePicker
          onChange={handleOnChange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
          />

        </div>

        </DialogContent>
        <DialogActions>
          <Button onClick={() =>{handleClose('dismiss')}} autoFocus>
            Dismiss
          </Button>
          <Button onClick={() => {handleClose('apply')}} autoFocus>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
