import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Chip, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, makeStyles, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Autocomplete} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';

import { UserCircle as UserCircleIcon } from '../../../icons/user-circle';


import CmsContext from '../../../store/cms-context';
import FBCTCountries from '../../../Utilities/FBCTCountries';





function AdsetDialog(props) {

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);


  function handle_close() {

    const temp_state = {...props.adset_dialog_state}

    temp_state.is_dialog_open = false

    props.set_adset_dialog_state(temp_state)

  }

  console.log('adset dialog state was: ', props.adset_dialog_state)

  return (
    <div>
      <Dialog
        open={props.adset_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'md'}
      >

        <DialogTitle id="alert-dialog-title">
         Adset
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

              <Grid
              item
              xs={12}
              >

                <div style={{display:'flex'}}>

                <Avatar
                    sx={{
                      height: 84,
                      width: 84
                    }}
                    src="/static/images/bullhorn.png"
                  >
                    
                    <UserCircleIcon fontSize="small" />

                </Avatar>

              </div>

              </Grid>

              <Grid
              item
              xs={12}
              sx={{marginTop:1}}
              >
                
                <TextField fullWidth label="Name" InputLabelProps={{shrink:true}} value={props.adset_dialog_state.adset.name} onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.name = event.target.value; props.set_adset_dialog_state(temp_state);}} variant="outlined" />
                  
              </Grid>

              

              <Grid item xs={12} sx={{marginTop:1}}>
                                
                <FormControl fullWidth >
                <InputLabel id="demo-select-small-label">Account</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  disabled
                  fullWidth
                  value={props.adset_dialog_state.selected_account}
                  label="Account"
                  onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.selected_account = event.target.value; props.set_adset_dialog_state(temp_state);}}
                >
                  {props.adset_dialog_state.accounts.map((item, index)=> (
                  <MenuItem
                  key={index} value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
                </FormControl>

              </Grid> 


              <Grid item xs={12} sx={{marginTop:1}}>

                <Autocomplete
                    id="selected-campaigns"
                    disabled
                    options={props.adset_dialog_state.campaigns}
                    getOptionLabel={(option) => option.name ? option.name : ""}
                    value={props.adset_dialog_state.selected_campaign}
                    isOptionEqualToValue={(option, value) => {
                      return value.id ? value.id === option.id : false
                    }}
                    onChange={(event, newValue) => {
                      const temp_state = {...props.adset_dialog_state}; temp_state.selected_campaign = newValue; props.set_adset_dialog_state(temp_state);
                    }}
                    renderOption={(props, option, state) => {
                      //console.log('index was: ', props, " : ", option, " : ", state)
                      const key = `listItem-${option.id}`;
                      return (
                        <li {...props} key={key}>
                          {option.name}
                        </li>
                      );
                      
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Campaigns" placeholder="Campaigns" />
                    )}
                />

                </Grid>

              <Grid
              item
              xs={12}
              >

                <Autocomplete
                      multiple
                      id="targeting-countries"
                      options={FBCTCountries}
                      getOptionLabel={(option) => option.country}
                      value={props.adset_dialog_state.adset.targeting_countries}
                      isOptionEqualToValue={(option, value) => option.code === value.code}
                      onChange={(event, newValue) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.targeting_countries = newValue; props.set_adset_dialog_state(temp_state);}}
                      renderOption={(props, option, state) => {
                        //console.log('index was: ', props, " : ", option, " : ", state)
                        const key = `listItem-${option.code}`;
                        return (
                          <li {...props} key={key}>
                            {option.country}
                          </li>
                        );
                        
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="" placeholder="Countries" />
                      )}
                />     

              </Grid>


              <Grid
              item
              xs={12}
              sx={{marginTop:1}}
              >
                
                <TextField fullWidth type='number' label="Daily budget" InputLabelProps={{shrink:true}} value={props.adset_dialog_state.adset.daily_budget} onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.daily_budget = event.target.value; props.set_adset_dialog_state(temp_state);}} variant="outlined" />
                  
              </Grid>

              <Grid item xs={12} sx={{marginTop:1}}>
                                
                <FormControl fullWidth >
                <InputLabel shrink id="demo-select-small-label">Destination</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput notched label="Destination" />}
                  fullWidth
                  value={props.adset_dialog_state.adset.destination_type}
                  onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.destination_type = event.target.value; props.set_adset_dialog_state(temp_state);}}
                >
                  {props.adset_dialog_state.destinations.map((item, index)=> (

                  <MenuItem key={index} value={item}>{item}</MenuItem>

                  ))}
                </Select>
                </FormControl>

              </Grid>

              <Grid item xs={12} sx={{marginTop:1}}>
                                
                <FormControl fullWidth >
                <InputLabel shrink id="demo-select-small-label">Billing Event</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput notched label="Billing Event" />}
                  fullWidth
                  value={props.adset_dialog_state.adset.billing_event}
                  onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.billing_event = event.target.value; props.set_adset_dialog_state(temp_state);}}
                >
                  {props.adset_dialog_state.billing_events.map((item, index)=> (
                  <MenuItem
                  key={index} value={item}>{item}</MenuItem>
                ))}
                </Select>
                </FormControl>

              </Grid>

              <Grid item xs={12} sx={{marginTop:1}}>
                                
                <FormControl fullWidth >
                <InputLabel shrink id="demo-select-small-label">Optimization Goal</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput notched label="Optimization Goal" />}
                  fullWidth
                  value={props.adset_dialog_state.adset.optimization_goal}
                  onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.optimization_goal = event.target.value; props.set_adset_dialog_state(temp_state);}}
                >
                  {props.adset_dialog_state.optimization_goals.map((item, index)=> (
                  <MenuItem
                  key={index} value={item}>{item}
                  </MenuItem>
                ))}
                </Select>
                </FormControl>

              </Grid>

              <Grid
              item
              xs={12}
              sx={{marginTop:1}}
              >
                
                <TextField fullWidth type='number' label="Bid amount" InputLabelProps={{shrink:true}} value={props.adset_dialog_state.adset.bid_amount} onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.bid_amount = event.target.value; props.set_adset_dialog_state(temp_state);}} variant="outlined" />
                  
              </Grid>

              <Grid item xs={12} sx={{marginTop:1}}>
                                
                <FormControl fullWidth >
                <InputLabel shrink id="demo-select-small-label">Status</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput notched label="Status" />}
                  fullWidth
                  value={props.adset_dialog_state.adset.status}
                  onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.status = event.target.value; props.set_adset_dialog_state(temp_state);}}
                >
                  {props.adset_dialog_state.all_statuses.map((item, index)=> (
                  <MenuItem
                  key={index} value={item}>{item}</MenuItem>
                ))}
                </Select>
                </FormControl>

              </Grid>



              { /*

              <Grid item xs={12} sx={{marginTop:1}}>
                                
                <FormControl fullWidth >
                <InputLabel shrink id="demo-select-small-label">Status</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput notched label="Status" />}
                  fullWidth
                  value={props.adset_dialog_state.adset.status}
                  onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.status = event.target.value; props.set_adset_dialog_state(temp_state);}}
                >
                  {props.adset_dialog_state.all_statuses.map((item, index)=> (
                  <MenuItem
                  key={index} value={item}>{item}</MenuItem>
                ))}
                </Select>
                </FormControl>

                  </Grid> */}

              
            </Grid>

        </DialogContent>
        <DialogActions>
        <Button disabled={props.adset_dialog_state.is_dialog_loading} onClick={() => {props.post_adset(props.adset_dialog_state)}} variant='outlined'>{props.adset_dialog_state.type}</Button>
        <Button onClick={handle_close} variant='outlined'>{"Close"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}

export default React.memo(AdsetDialog);