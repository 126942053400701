import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, makeStyles} from '@mui/material';
import Dropzone from 'react-dropzone'

import { UserCircle as UserCircleIcon } from '../../../icons/user-circle';

import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../../store/cms-context';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import DateTimePicker from '@mui/lab/DateTimePicker';

import cloneDeep from 'lodash/cloneDeep';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import VariantsListTable from '../Variants/SubComponents/VariantsListTable';
import VariantsTable from './VariantsTable';
import { default_variant_style } from '../../../Utilities/Metrics';



const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));

function CreateProductDialog(props) {

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);




  const onSelect = (index, checked) => {

    const temp_variant_image = {...props.selected_variant_image}

    if(checked){
      temp_variant_image.image_index = index
    }else{
      temp_variant_image.image_index = -1
    }

    props.set_selected_variant_image(temp_variant_image)
    
  }

  function set_style_state(key, value){
    
    const temp_state = {...props.create_product_dialog_state}

    temp_state.style[key] = value

    props.set_create_product_dialog_state(temp_state)
    
  }

  function set_variant_style_state(key, value){
    
    const temp_state = {...props.create_product_dialog_state}

    temp_state.variant_style[key] = value

    props.set_create_product_dialog_state(temp_state)
    
  }

  function handle_style(request_type){

    var temp_state = cloneDeep(props.create_product_dialog_state)

    const name = temp_state.style.name.trim()

    const tax_code = temp_state.style.tax_code.toString().trim()

    const supplier = temp_state.style.supplier.trim()

    const country_of_origin = temp_state.style.country_of_origin.trim()

    const customs_description = temp_state.style.customs_description.trim()

    var hs_code = temp_state.style.hs_code.toString().trim()

    var gbp_cost_price = temp_state.style.gbp_cost_price.toString().trim()

    var eur_cost_price = temp_state.style.eur_cost_price.toString().trim()

    var usd_cost_price = temp_state.style.usd_cost_price.toString().trim()

    var sale_price = temp_state.style.sale_price.toString().trim()

    if(name.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid product name!")
      return
    }

    if(hs_code.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid HS Code!")
      return
    }

    if(gbp_cost_price.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid GBP Cost Price!")
      return
    }

    if(eur_cost_price.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid EUR Cost Price!")
      return
    }

    if(usd_cost_price.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid USD Cost Price!")
      return
    }

    if(sale_price.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Sale Price!")
      return
    }

    if(tax_code.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid tax code!")
      return
    }

    if(supplier.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Supplier!")
      return
    }

    if(country_of_origin.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Country of Origin!")
      return
    }

    if(customs_description.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Customs Description!")
      return
    }

    hs_code = parseFloat(hs_code)

    temp_state.style.hs_code = hs_code

    gbp_cost_price = parseFloat(gbp_cost_price)

    temp_state.style.gbp_cost_price = gbp_cost_price

    eur_cost_price = parseFloat(eur_cost_price)

    temp_state.style.eur_cost_price = eur_cost_price

    usd_cost_price = parseFloat(usd_cost_price)

    temp_state.style.usd_cost_price = usd_cost_price

    sale_price = parseFloat(sale_price)

    temp_state.style.sale_price = sale_price

    var dateString = ''

    const style = temp_state.style;

    try{

      if(typeof(style.active_from) === 'string'){

        Date.parse(style.active_from)

      }else{

      if(isNaN(style.active_from.getTime())) {

        throw new Error("Invalid date")

      }

      dateString = `${style.active_from.getFullYear().toString()}-${('0' + (style.active_from.getMonth() + 1)).slice(-2)}-${('0' + style.active_from.getDate()).slice(-2)} ${('0' + style.active_from.getHours()).slice(-2)}:${('0' + style.active_from.getMinutes()).slice(-2)}:${('0' + style.active_from.getSeconds()).slice(-2)}`;

      temp_state.style.active_from = dateString

      }

      // continue here
  
      if(style.active_to){

        if(typeof(style.active_to) === 'string'){

          Date.parse(style.active_to)
  
        }else{
  
        dateString = `${style.active_to.getFullYear().toString()}-${('0' + (style.active_to.getMonth() + 1)).slice(-2)}-${('0' + style.active_to.getDate()).slice(-2)}-${('0' + style.active_to.getHours()).slice(-2)}:${('0' + style.active_to.getMinutes()).slice(-2)}:${('0' + style.active_to.getSeconds()).slice(-2)}`;
  
        temp_state.style.active_to = dateString

        }
  
      }

    }catch(e){
      console.log('exception was: ', e)
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid date!")
      return
    }

    var incorrect_variant_found = false

    temp_state.all_variants.forEach((variant) => {

      console.log('going')

      if(!variant.sku || !variant.barcode){
        incorrect_variant_found = true
      }else{
        
      variant.sku = variant.sku.trim()
      
      variant.barcode = variant.barcode.toString().trim()

      if(variant.sku.length === 0 ){  
        incorrect_variant_found = true
      }

      if(isNaN(parseInt(variant.barcode))) {
        incorrect_variant_found = true
      }

      variant.barcode = parseInt(variant.barcode)

    }

    })

    if(incorrect_variant_found){
      cmsContext.setSnackBarOpen(true, "error", "Please enter valid values for all variants!")
      return
    }

    temp_state = {'product':temp_state.style, 'all_variants': temp_state.all_variants}

    console.log("state was: ", temp_state)

    console.log('action was: ', request_type)

    props.post_product(temp_state, request_type)

  }

  

  function handle_close(){

    const temp_state = {...props.create_product_dialog_state}

    temp_state.is_dialog_open = false

    props.set_create_product_dialog_state(temp_state)

  }

  function set_all_variants(all_variants){

    const temp_state = {...props.create_product_dialog_state}

    temp_state.all_variants = all_variants

    props.set_create_product_dialog_state(temp_state)

  }

  function handle_variant(action){

      const temp_state = cloneDeep(props.create_product_dialog_state)

      const style = cloneDeep(temp_state.variant_style)

      const name = style.variant_name.trim()
    
      const weight = style.weight.toString().trim()

      const barcode = style.barcode.toString().trim()

      if(name.length === 0){
        cmsContext.setSnackBarOpen(true, "error", "Please enter a valid name!")
        return
      }

      if(weight.length === 0){
        cmsContext.setSnackBarOpen(true, "error", "Please enter a valid weight!")
        return
      }

      if(barcode.length === 0){
        cmsContext.setSnackBarOpen(true, "error", "Please enter a valid barcode!")
        return
      }

      style.variant_name = name

      style.weight = parseFloat(weight)

      var dateString = ''

      try{

        if(isNaN(style.active_from.getTime())) {

          throw new Error("Invalid date")
  
        }

        dateString = `${style.active_from.getFullYear().toString()}-${('0' + (style.active_from.getMonth() + 1)).slice(-2)}-${('0' + style.active_from.getDate()).slice(-2)} ${('0' + style.active_from.getHours()).slice(-2)}:${('0' + style.active_from.getMinutes()).slice(-2)}:${('0' + style.active_from.getSeconds()).slice(-2)}`;

        style.active_from = dateString

        if(style.active_to){

          dateString = `${style.active_to.getFullYear().toString()}-${('0' + (style.active_to.getMonth() + 1)).slice(-2)}-${('0' + style.active_to.getDate()).slice(-2)}-${('0' + style.active_to.getHours()).slice(-2)}:${('0' + style.active_to.getMinutes()).slice(-2)}:${('0' + style.active_to.getSeconds()).slice(-2)}`;

          style.active_to = dateString

        }

      }catch(e){
        cmsContext.setSnackBarOpen(true, "error", "Please select a valid date!")
        return
      }
      
      
      console.log("style was: ", style)

      if(action === "add"){

        temp_state.all_variants.push(style)

      }else if(action === "update"){

      console.log('new state was: ', temp_state)

      temp_state.all_variants[props.create_product_dialog_state.editing_variant] = style

      }else if(action === "cancel"){

        temp_state.all_variants.forEach((variant) =>{
          variant.is_checked = false
        })

      }else{
        console.log("invalid action")
      }

      temp_state.variant_style = cloneDeep(default_variant_style)

      temp_state.editing_variant = -1

      props.set_create_product_dialog_state(temp_state)

  }

  function delete_variants(){

    const temp_state = {...props.create_product_dialog_state}

    const new_variants = []

    temp_state.all_variants.forEach((variant)=>{

      if(!variant.is_checked){
        new_variants.push(variant)
      }

    })

    temp_state.all_variants = new_variants

    props.set_create_product_dialog_state(temp_state)

  }


  const selected = React.useMemo(() => {

    if(props.create_product_dialog_state.all_variants){

    console.log('all variants were: ', props.create_product_dialog_state.all_variants)

    const temp_selected = props.create_product_dialog_state.all_variants.filter((variant) => variant.is_checked).length

    return(temp_selected)

    }

  },[props.create_product_dialog_state.all_variants])



  function start_editing_variant(index){

    if(index > -1){

    const temp_state = {...props.create_product_dialog_state}

    temp_state.variant_style = cloneDeep(props.create_product_dialog_state.all_variants[index])

    temp_state.variant_style.active_from = new Date(temp_state.variant_style.active_from)

    console.log('new date was: ', temp_state.variant_style.active_from)

    if(temp_state.variant_style.active_to){

      temp_state.variant_style.active_to = new Date(temp_state.variant_style.active_to)

    }

    temp_state.editing_variant = index

    props.set_create_product_dialog_state(temp_state)

  }

  }


  return (
    <div>
      <Dialog
        open={props.create_product_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.create_product_dialog_state.type} Product
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

             <Grid
              item
              xs={12}
             
              >

                <Avatar
                    sx={{
                      height: 160,
                      width: 160,
                      ml: 'auto',
                      mr:'auto'
                    }}
                    src="/static/images/prototype.png"
                  >
                    
                    <UserCircleIcon fontSize="small" />

                </Avatar>
                
              </Grid> 


              <Grid
              item
              xs={12}
              style={{marginTop:16}}
              >

                <TextField id="outlined-basic" disabled={props.create_product_dialog_state.type === "Update"} label="Name" variant="outlined" value={props.create_product_dialog_state.style.name} onChange={(event)=>{set_style_state("name", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="HS Code" variant="outlined" value={props.create_product_dialog_state.style.hs_code} type="number" onChange={(event)=>{set_style_state("hs_code", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="Tax Code" variant="outlined" value={props.create_product_dialog_state.style.tax_code} onChange={(event)=>{set_style_state("tax_code", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="Supplier" variant="outlined" value={props.create_product_dialog_state.style.supplier} onChange={(event)=>{set_style_state("supplier", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="Country of Origin" variant="outlined" value={props.create_product_dialog_state.style.country_of_origin} onChange={(event)=>{set_style_state("country_of_origin", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

              <TextField id="outlined-basic" label="Customs Description" variant="outlined" value={props.create_product_dialog_state.style.customs_description} onChange={(event)=>{set_style_state("customs_description", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="GBP Cost Price" variant="outlined" value={props.create_product_dialog_state.style.gbp_cost_price} type="number" onChange={(event)=>{set_style_state("gbp_cost_price", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="EUR Cost Price" variant="outlined" value={props.create_product_dialog_state.style.eur_cost_price} type="number" onChange={(event)=>{set_style_state("eur_cost_price", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="USD Cost Price" variant="outlined" value={props.create_product_dialog_state.style.usd_cost_price} type="number" onChange={(event)=>{set_style_state("usd_cost_price", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="Sale Price" variant="outlined" value={props.create_product_dialog_state.style.sale_price} type="number" onChange={(event)=>{set_style_state("sale_price", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <FormControlLabel
                      control={<Checkbox checked={props.create_product_dialog_state.style.noos} onChange={(event) => {set_style_state("noos", event.target.checked);}} />}
                      label="Never Out of Stock"
                    />

              </Grid>

              <Grid item xs={12}>

                <LocalizationProvider dateAdapter={AdapterDateFns}>

                <DateTimePicker
                  renderInput={(params) => <TextField fullWidth {...params}  />}
                  value={props.create_product_dialog_state.style.active_from}
                  onChange={(newValue) => {
                    const temp_val = {...props.create_product_dialog_state};
                    temp_val.style.active_from = newValue;
                    console.log('date was', typeof(temp_val.date))
                    props.set_create_product_dialog_state(temp_val);
                  }}
                  label="Active From"
                />
                </LocalizationProvider>


              </Grid> 

              <Grid item xs={12}>

              <LocalizationProvider dateAdapter={AdapterDateFns}>

              <DateTimePicker
                renderInput={(params) => <TextField fullWidth {...params}  />}
                value={props.create_product_dialog_state.style.active_to}
                onChange={(newValue) => {
                  const temp_val = {...props.create_product_dialog_state};
                  temp_val.style.active_to = newValue;
                  console.log('date was', typeof(temp_val.date))
                  props.set_create_product_dialog_state(temp_val);
                }}
                label="Active To"
              />
              </LocalizationProvider>

              </Grid> 

            </Grid>

            <Grid
            item
            xs={12}
            >

              <Grid
              style={{marginTop:2}}
              container
              spacing={3}
              alignItems="center"
              >
                
                <>
                <Grid 
                item
                xs={6}
                >

                <Typography fontSize={14}><strong>Variants</strong></Typography>

                </Grid>

               <Grid item xs={12}>

                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  >

                  <Grid
                    item
                    xs={12}
                  
                    >

                      <Avatar
                          sx={{
                            height: 120,
                            width: 120,
                            ml: 'auto',
                            mr:'auto'
                          }}
                          src="/static/images/product_variant.png"
                        >
                          
                          <UserCircleIcon fontSize="small" />

                      </Avatar>
                      
                    </Grid> 


                    <Grid
                    item
                    xs={12}
                    style={{marginTop:16}}
                    >

                      <TextField id="outlined-basic" size="small" disabled={props.create_product_dialog_state.type === "Update"} label="Name" variant="outlined" value={props.create_product_dialog_state.variant_style.variant_name} onChange={(event)=>{set_variant_style_state("variant_name", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

                    </Grid>

                    <Grid
                    item
                    xs={12}
                    >

                      <TextField id="outlined-basic" size="small" label="Sku" variant="outlined" value={props.create_product_dialog_state.variant_style.sku} onChange={(event)=>{set_variant_style_state("sku", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

                    </Grid>

                    <Grid
                    item
                    xs={12}
                    >

                      <TextField id="outlined-basic" size="small" label="Barcode" variant="outlined" value={props.create_product_dialog_state.variant_style.barcode ? props.create_product_dialog_state.variant_style.barcode : ''} type="number" onChange={(event)=>{set_variant_style_state("barcode", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

                    </Grid>

                    <Grid
                    item
                    xs={12}
                    >

                      <TextField id="outlined-basic" size="small" label="Weight" variant="outlined" value={props.create_product_dialog_state.variant_style.weight} type="number" onChange={(event)=>{set_variant_style_state("weight", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

                    </Grid>


                    <Grid item xs={12}>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>

                      <DateTimePicker
                        size="small"
                        renderInput={(params) => <TextField fullWidth {...params}  />}
                        value={props.create_product_dialog_state.variant_style.active_from}
                        onChange={(newValue) => {
                          const temp_val = {...props.create_product_dialog_state};
                          temp_val.variant_style.active_from = newValue;
                          console.log('date was', typeof(temp_val.date))
                          props.set_create_product_dialog_state(temp_val);
                        }}
                        label="Active From"
                      />
                      </LocalizationProvider>


                    </Grid> 

                    <Grid item xs={12}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                    <DateTimePicker
                      renderInput={(params) => <TextField fullWidth {...params}  />}
                      value={props.create_product_dialog_state.variant_style.active_to}
                      onChange={(newValue) => {
                        const temp_val = {...props.create_product_dialog_state};
                        temp_val.variant_style.active_to = newValue;
                        console.log('date was', typeof(temp_val.date))
                        props.set_create_product_dialog_state(temp_val);
                      }}
                      label="Active To"
                    />
                    </LocalizationProvider>

                    </Grid> 
                    

                    <Grid item xs={12}>

                      <Grid
                      container
                      spacing={1}
                      >

                    {props.create_product_dialog_state.editing_variant > -1 ? <Grid item xs={6}>

                    <Button variant="contained" fullWidth onClick={() => {handle_variant("cancel")}}>Cancel</Button>

                    </Grid> : null}

                    <Grid item xs={props.create_product_dialog_state.editing_variant > -1 ? 6 : 12}>

                      <Button variant="contained" fullWidth onClick={() => {handle_variant(props.create_product_dialog_state.editing_variant > -1 ? "update": "add")}}>{props.create_product_dialog_state.editing_variant > -1 ? "Update" : "Add"}</Button>
                      
                    </Grid>

                      </Grid>

                    </Grid>

                  </Grid>

                </Grid>

                </>

                <Grid
                item
                xs={12}
                >

                  <VariantsTable type="create_product" selected={selected} start_editing_variant={start_editing_variant} editing_variant={props.create_product_dialog_state.editing_variant} delete_variants={delete_variants} all_variants={props.create_product_dialog_state.all_variants} set_all_variants={set_all_variants}></VariantsTable>

                </Grid>



              </Grid>

          

            </Grid>


            
        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        {props.create_product_dialog_state.type !== "Deploy" ? <LoadingButton variant='outlined' loading={props.create_product_dialog_state.isLoading} disabled={false} onClick={() => {handle_style("Create")}}>{props.create_product_dialog_state.type}</LoadingButton> : null}

       {props.create_product_dialog_state.type === "Deploy"  ? <>

       {props.create_product_dialog_state.isLoading ? <CircularProgress sx={{marginLeft:2, marginRight:2}} />: <>

        <LoadingButton variant='outlined' loading={props.create_product_dialog_state.isLoading} disabled={false} onClick={() =>{handle_style("Save")}}>Update</LoadingButton> 

        <LoadingButton variant='outlined' loading={props.create_product_dialog_state.isLoading} disabled={false} onClick={() => {handle_style("Deploy")}}>Deploy</LoadingButton> 
        </>}
        </> : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default CreateProductDialog;