
import * as React from 'react';

import { Box, Container, Grid } from '@mui/material';

import BulkUpdateDialog from '../BulkUpdateDialog/BulkUpdateDialog';

import { cloneDeep } from "lodash";

import { default_bulk_update_dialog } from '../../Utilities/Metrics';





function Dashboard(props) {



  return(


  
  

    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
          alignItems="center"
        >
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            style={{border:'1px solid blue', padding:40}}
          >
        <div>abcd</div>
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            sm={6}
            xs={12}
            style={{border:'1px solid blue'}}
          >
      
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            sm={6}
            xs={12}
            style={{border:'1px solid blue'}}
          >
       
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            sm={6}
            xs={12}
            style={{border:'1px solid blue'}}
          >

          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
       
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
          
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
     
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
     
          </Grid>
        </Grid>
      </Container>


    </Box>
   

  );

}

export default Dashboard;
