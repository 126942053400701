import React, { useState, useRef, useEffect, useContext, useCallback } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper } from '@mui/material';

import PropTypes from 'prop-types';

import styles from './InventoryReports.module.css';

import CmsContext from '../../store/cms-context';

import ProductsListTable from "./SubComponents/ReportsListTable";

import { getAccessJwtToken } from '../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import SelectCsvDialog from "../SelectCsvDialog/SelectCsvDialog";

import { cloneDeep } from "lodash";

import ReportsListTable from "./SubComponents/ReportsListTable";


function InventoryReports(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_reports, set_all_reports] = React.useState([]);

  const [select_csv_dialog_state, set_select_csv_dialog_state] = React.useState({'is_dialog_open':false,'uploading_csv':false,'csv_files':[], 'warehouse':'', 'all_warehouses':['Livingston', 'Venlo']});



  const navigate = useNavigate();



  useEffect(() => {

    isMountedRef.current = true

    get_all_reports()

    return () => isMountedRef.current = false;

  },[])


  


  const handle_accepted_csv_files = useCallback(

    (acceptedFiles) => {

    if(acceptedFiles.length === 1) {

      var temp_accepted_files = [...acceptedFiles]

      temp_accepted_files.forEach((file) =>{

        file.modified_name = Date.now().toString() + "_" + file.name.replaceAll(' ', '_')

      })

      // console.log('ct files',temp_accepted_files)

      const temp_state = {...select_csv_dialog_state}

      temp_state.csv_files = acceptedFiles

      set_select_csv_dialog_state(temp_state)


  }

    },[select_csv_dialog_state]);


    const delete_reports = useCallback(() => {
    
      console.log('delete reports')
  
      var temp_reports = [...all_reports].filter((report) => report.is_checked);
  
      console.log('filtered were', temp_reports)
  
      if(temp_reports.length === 0){
        // might never execute (need to select reports to display delete button)
        cmsContext.setSnackBarOpen(true, "error", "Please select valid reports!")
        return;
      }
      /*
      else if(temp_reports.length > 20){
        cmsContext.setSnackBarOpen(true, "error", "Please select a maximum of 20 reports!")
        return;
      }

      temp_reports = temp_reports.map((report) => {
  
        return(report.id)
  
      })
      */
  
    
  
      console.log('final reports were', temp_reports)
  
      const request_body = {}
  
      request_body.file = temp_reports[0]
  
      request_body.request_type = "Delete"
  
      request_body.account_email = cmsContext.current_shop.email
  
      console.log('body was: ', request_body)
      
  
      setIsLoading(true)
  
      getAccessJwtToken().then((jwt) => {
  
  
        fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/inventory/reports', {
                crossDomain:true,
                method: 'POST',
                headers: {
                  'content-type':'application/json',
                  'Authorization':jwt
                },
                body: JSON.stringify(request_body)
              })
                .then(res => res.json())
                .then(
                  (result) => {
        
                    setIsLoading(false)
    
                    console.log('result was: ', result)
  
                    if(result.message){
  
                      cmsContext.setSnackBarOpen(true, "success", result.message)
  
                      get_all_reports()
                    
                    }else if(result.errorMessage){
  
                      cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                      
                    }else{
  
                      cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                      console.log('Error', result)
  
                    }
                    
                  },
              
                  (error) => {
                    setIsLoading(false)
                    cmsContext.setSnackBarOpen(true, "error", error)
                    console.log('error was',error)
                  }
            );
    
        });
  
    }, [all_reports]);




  return(

    <>

    {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
    

    <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Reports</Typography>

          <div style={{"display":"flex"}}>

          <Button variant="contained" onClick = {() => {show_upload_csv_dialog()}}>Import CSV</Button>

          </div> 

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >

          {all_reports ? <ReportsListTable delete_reports={delete_reports} all_reports={all_reports} set_all_reports={set_all_reports}></ReportsListTable> : null}

        </Grid>

      </Grid>

    </Container>      

    </Box> }
    

    <SelectCsvDialog type={"Reports"} upload_csv_files={upload_csv_files} handle_accepted_csv_files={handle_accepted_csv_files} select_csv_dialog_state={select_csv_dialog_state} set_select_csv_dialog_state={set_select_csv_dialog_state}></SelectCsvDialog>


    </> 

  );



  async function upload_csv_files() {

    if(select_csv_dialog_state.csv_files.length !== 1){

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid file!")

      return

    }

    if(select_csv_dialog_state.warehouse.length === 0){

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid warehouse!")

      return

    }


    const temp_state = {...select_csv_dialog_state}

    const file = select_csv_dialog_state.csv_files[0];

    temp_state.uploading_csv = true

    set_select_csv_dialog_state(temp_state)

    var url;

    var key;

    try {

      key = await Storage.put(cmsContext.current_shop.email + "/inventory_report_csv/"  + file.modified_name, file, {
       contentType: file.type,
      });

  

      url = await Storage.get(key.key, {
        level: 'public',
        bucket: 'snag-cms175447-staging',
        expires:86400,
        region: 'eu-west-2',
      });

      // console.log('file was', file, url);

    } catch (error) {
      cmsContext.setSnackBarOpen(true, "error", error)
    }

    const static_url = url;

    const static_key = key;

    // console.log('static: ', static_url, ' : ', static_key)


    if(static_key && static_key.key){

      const request_body = {}

      request_body.file = {}
  
      request_body.file.file_type = "csv"

      request_body.file.file_name = file.name
  
      request_body.file.aws_key = static_key.key

      request_body.request_type = "Create"

      request_body.file.warehouse = select_csv_dialog_state.warehouse
  

      getAccessJwtToken().then((jwt)=>{

        fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/inventory/reports', {
            crossDomain:true,
            method: 'POST',
            headers: {
              'Authorization':jwt,
              'content-type':'application/json',
            },
            body: JSON.stringify(request_body)
            })
            .then(res => res.json())
            .then(
              (result) => {
    
                // console.log('result was: ', result)
  
                if(result.message){

                  stop_uploading_reports_csv(true)
                  cmsContext.setSnackBarOpen(true, "info", result.message)
                  get_all_reports()

                }else if(result.errorMessage){
  
                  stop_uploading_reports_csv(false)
                  cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                  
                } else{

                  stop_uploading_reports_csv(false)
                  cmsContext.setSnackBarOpen(true, "error", "Error uploading file, please try again")
                  // console.log('Error', result)

                }
    
              },
          
              (error) => {

                stop_uploading_reports_csv(false)
                cmsContext.setSnackBarOpen(true, "error", "Error uploading file, please try again")
                // console.log('error was',error)

              }
        )
  
      })

    }else{
      cmsContext.setSnackBarOpen(true, "error", "Error uploading file please try again!")
    }


  }

  function stop_uploading_reports_csv(was_success){


    if(was_success){

      set_select_csv_dialog_state({'is_dialog_open':false,'uploading_csv':false,'csv_files':[], 'warehouse':'', 'all_warehouses':['Livingston', 'Venlo']})

    }else{

      const temp_state = {...select_csv_dialog_state}

      temp_state.uploading_csv = false

      set_select_csv_dialog_state(temp_state)
      
    }


  }


  function show_upload_csv_dialog(){

    const temp_state = {'is_dialog_open':false,'uploading_csv':false,'csv_files':[], 'warehouse':'' , 'all_warehouses':['Livingston', 'Venlo']}

    temp_state.is_dialog_open = true;

    set_select_csv_dialog_state(temp_state)

  }


  function get_all_reports() {
    
    console.log('store_access_token')


    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/inventory/reports',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
  
              setIsLoading(false)
  
              console.log('result was: ', result)

             
              if(result.data){

                if(result.data.length === 0){

                  cmsContext.setSnackBarOpen(true, "info", "No inventory reports yet. Let's create one to get started.")

                  set_all_reports([])

                  return

                }


                const all_reports = []

                var temp_report;

                result.data.forEach((report) =>{

                    temp_report = cloneDeep(report)
                    temp_report.is_checked = false
                    all_reports.push(temp_report)

                })

                console.log('all options')

              
                set_all_reports(all_reports)

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }

              
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
           
      )

    })

  }


};


export default InventoryReports;