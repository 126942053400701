import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, makeStyles} from '@mui/material';
import Dropzone from 'react-dropzone'

import CardTravelIcon from '@mui/icons-material/CardTravel';


import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../../store/cms-context';



const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));

function ShippingServiceDialog(props) {

  console.log("shipping dialog rendered")

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);


  const onSelect = (index, checked) => {

    const temp_variant_image = {...props.selected_variant_image}

    if(checked){
      temp_variant_image.image_index = index
    }else{
      temp_variant_image.image_index = -1
    }

    props.set_selected_variant_image(temp_variant_image)
    
  }

  function set_shipping_service_state(key, value){
    
    const temp_state = {...props.shipping_service_dialog_state}

    temp_state.shipping_service[key] = value

    props.set_shipping_service_dialog_state(temp_state)
    
  }

  function handle_shipping_service(){

    const shipping_service = {...props.shipping_service_dialog_state.shipping_service}



    if(shipping_service.Courier.trim().length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Courier!")
      return
    }

    if(shipping_service.Warehouse.trim().length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Warehouse!")
      return
    }

    if(shipping_service.Country.trim().length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Country!")
      return
    }

    if(shipping_service.Currency.trim().length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Currency!")
      return
    }


    shipping_service.SnagPriceBase = parseFloat(shipping_service.SnagPriceBase.toString().trim()) || 0

    shipping_service.SnagPriceVAT = parseFloat(shipping_service.SnagPriceVAT.toString().trim()) || 0

    shipping_service.SnagPricePerKG = parseFloat(shipping_service.SnagPricePerKG.toString().trim()) || 0

    shipping_service.CourierBasePrice = parseFloat(shipping_service.CourierBasePrice.toString().trim()) || 0

    shipping_service.CourierPerKG = parseFloat(shipping_service.CourierPerKG.toString().trim()) || 0

    shipping_service.CourierBasePrice6 = parseFloat(shipping_service.CourierBasePrice6.toString().trim()) || 0

    console.log(shipping_service)

    props.post_shipping_service(shipping_service, props.shipping_service_dialog_state.type)

  }

  

  function handle_close(){

    const temp_state = {...props.shipping_service_dialog_state}

    temp_state.is_dialog_open = false

    props.set_shipping_service_dialog_state(temp_state)

  }


  return (
    <div>
      <Dialog
        open={props.shipping_service_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.shipping_service_dialog_state.type} Shipping Service
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

             <Grid
              item
              xs={12}
             
              >

                <Avatar

                    sx={{
                        height: 120,
                        width: 120,
                        ml: 'auto',
                        mr:'auto'
                    }}
                    src="/static/images/food-truck.png"
                    >

                </Avatar>
                
              </Grid> 


              <Grid
              item
              xs={12}
              style={{marginTop:16}}
              >

                <TextField id="outlined-basic" placeholder='e.g. FedEx' label="Courier" variant="outlined" value={props.shipping_service_dialog_state.shipping_service.Courier} onChange={(event)=>{set_shipping_service_state("Courier", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" placeholder='e.g. Livingston' label="Warehouse" variant="outlined" value={props.shipping_service_dialog_state.shipping_service.Warehouse} onChange={(event)=>{set_shipping_service_state("Warehouse", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" placeholder='e.g. U.K.' label="Country" variant="outlined" value={props.shipping_service_dialog_state.shipping_service.Country} onChange={(event)=>{set_shipping_service_state("Country", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" placeholder='e.g. Economy' label="Parcelify Name" variant="outlined" value={props.shipping_service_dialog_state.shipping_service.ParcelifyName} onChange={(event)=>{set_shipping_service_state("ParcelifyName", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic"  placeholder='e.g. DHL Ecommerce' label="Service Name" variant="outlined" value={props.shipping_service_dialog_state.shipping_service.ServiceName} onChange={(event)=>{set_shipping_service_state("ServiceName", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" placeholder='e.g. Standard' label="Shopify Reference" variant="outlined" value={props.shipping_service_dialog_state.shipping_service.ShopifyReference} onChange={(event)=>{set_shipping_service_state("ShopifyReference", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic"  placeholder='e.g. Currency' label="Currency" variant="outlined" value={props.shipping_service_dialog_state.shipping_service.Currency} onChange={(event)=>{set_shipping_service_state("Currency", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type="number"  label="Snag Price Base" variant="outlined" value={props.shipping_service_dialog_state.shipping_service.SnagPriceBase} onChange={(event)=>{set_shipping_service_state("SnagPriceBase", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type="number"  label="Snag Price VAT" variant="outlined" value={props.shipping_service_dialog_state.shipping_service.SnagPriceVAT} onChange={(event)=>{set_shipping_service_state("SnagPriceVAT", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type="number"  label="Snag Price Per KG" variant="outlined" value={props.shipping_service_dialog_state.shipping_service.SnagPricePerKG} onChange={(event)=>{set_shipping_service_state("SnagPricePerKG", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type="number"  label="Courier Base Price" variant="outlined" value={props.shipping_service_dialog_state.shipping_service.CourierBasePrice} onChange={(event)=>{set_shipping_service_state("CourierBasePrice", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type="number" label="Courier Per KG" variant="outlined" value={props.shipping_service_dialog_state.shipping_service.CourierPerKG} onChange={(event)=>{set_shipping_service_state("CourierPerKG", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type="number"  label="Courier Base Price + 6%" variant="outlined" value={props.shipping_service_dialog_state.shipping_service.CourierBasePrice6} onChange={(event)=>{set_shipping_service_state("CourierBasePrice6", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

            </Grid>

            
        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        <LoadingButton variant='outlined' loading={props.shipping_service_dialog_state.isLoading} disabled={false} onClick={handle_shipping_service}>{props.shipping_service_dialog_state.type}</LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default React.memo(ShippingServiceDialog);