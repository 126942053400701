import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, FormControl, InputLabel, OutlinedInput, InputAdornment, FormHelperText, FormGroup, FormControlLabel, TextField, Select, MenuItem} from '@mui/material';
import Dropzone from 'react-dropzone'


import LoadingButton from '@mui/lab/LoadingButton';
import Shipping from '../CreateProduct/SubComponents/Shipping';

import SearchIcon from '@mui/icons-material/Search';
import Metrics from '../../Utilities/Metrics';

import Countries from '../../Utilities/Countries'

function EditVariantDialog(props) {

  const dropzoneRef = React.useRef(null);


  const onSelect = (index, checked) => {

    const temp_variant_image = {...props.selected_variant_image}

    if(checked){
      temp_variant_image.image_index = index
    }else{
      temp_variant_image.image_index = -1
    }

    props.set_selected_variant_image(temp_variant_image)
    
  }

  

  function handle_close(){

    const temp_state = {...props.variant_dialog_state}

    temp_state.is_dialog_open = false

    props.set_variant_dialog_state(temp_state)

  }

  function handle_variant_props_change(key, value){

    const temp_state = {...props.variant_dialog_state};
    
    temp_state.variant[key] = value; 
    
    props.set_variant_dialog_state(temp_state)
  
  }

  


  return (
    <div>
      <Dialog
        open={props.variant_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         Edit {props.variant_dialog_state.variant.value}
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            style={{marginTop:0}}
            >

             
              <Grid 
                item
                xs={12}

                >

                <FormControl fullWidth >

                <InputLabel htmlFor="outlined-adornment-item-price">Price</InputLabel>

                <OutlinedInput
                  id="outlined-adornment-compare-item-price"
                  placeholder='0.00'
                  type="number"
                  startAdornment={<InputAdornment position="start">£</InputAdornment>}
                  label="Price"
                  value={props.variant_dialog_state.variant.price}
                  onChange={(event) => {handle_variant_props_change('price', event.target.value)}}
                />


                </FormControl>

                </Grid>

              <Grid 
                item
                xs={12}

                >

                <FormControl fullWidth >

                <InputLabel htmlFor="outlined-adornment-cost-per-item">Cost per item</InputLabel>

                <OutlinedInput
                  id="outlined-adornment-compare-cost-per-item"
                  placeholder='0.00'
                  type="number"
                  startAdornment={<InputAdornment position="start">£</InputAdornment>}
                  label="Cost per item"
                  value={props.variant_dialog_state.variant.cost_per_item}
                  onChange={(event) => {handle_variant_props_change('cost_per_item', event.target.value)}}
                />

                  <FormHelperText>Customers wont see this</FormHelperText>

                </FormControl>

                </Grid>


                <Grid 
                item
                xs={12}
                >

                  <Divider></Divider>

                </Grid>


                <Grid
                item
                xs={12}
                >

          <Grid
                  container
                  spacing={3}
                  alignItems="center"
                >

                  <Grid
                    item
                    xs={12}
                  >
                
                  <Typography variant='h6'>Inventory</Typography>

                
                  </Grid>

            <Grid 
            item
            xs={12}
            lg={6}
            >

                <FormControl fullWidth >

                <InputLabel htmlFor="outlined-adornment-sku">SKU (Stock Keeping Unit)</InputLabel>

                <OutlinedInput
                  id="outlined-adornment-sku"
                  label="SKU (Stock Keeping Unit)"
                  value={props.variant_dialog_state.variant.sku}
                  onChange={(event) =>{handle_variant_props_change('sku',event.target.value)}}
                />

              </FormControl>

                </Grid>

                <Grid 
                item
                xs={12}
                lg={6}
                >

                <FormControl fullWidth >

                <InputLabel htmlFor="outlined-adornment-barcode">Barcode (ISBN, UPC, GTIN, etc.)</InputLabel>

                <OutlinedInput
                  id="outlined-adornment-compare-barcode"
                  label="Barcode (ISBN, UPC, GTIN, etc.)"
                  value={props.variant_dialog_state.variant.barcode }
                  onChange={(event) =>{handle_variant_props_change('barcode', event.target.value)}}
                />

              </FormControl>


                </Grid>

              <Grid
              item
              xs={12}
              >


                <FormGroup>


                <FormControlLabel control={<Checkbox checked={props.variant_dialog_state.variant.track_quantity} onChange={(event) => {handle_variant_props_change('track_quantity', event.target.checked)}} />} label="Track Quantity" />

              {props.variant_dialog_state.variant.track_quantity ? <FormControlLabel control={<Checkbox checked={props.variant_dialog_state.variant.continue_selling} onChange={(event) => {handle_variant_props_change('continue_selling', event.target.checked)}} />} label="Continue selling when out of stock" /> : null}
              </FormGroup>
                </Grid>

                {props.variant_dialog_state.variant.track_quantity ? <>

                <Grid
                  item
                  xs={12}
                >
              
                <Divider></Divider>

              
                </Grid>



                <Grid
                  item
                  xs={12}
                >
              
                <Typography variant='subtitle1'>Quantity</Typography>

              
                </Grid>

                <Grid
                  item
                  xs={12}
                >

                <TextField

                id="stock-quantity"
                label="Available"
                type="number"
                value={props.variant_dialog_state.variant.quantity}
                onChange={(event)=>{handle_variant_props_change('quantity', event.target.value)}}
                InputLabelProps={{
                  shrink: true,
                }}

                />

              </Grid>

              

              </>: null}
              
              <Grid
              item
              xs={12}
            >

              <Grid
            container
            spacing={3}
            alignItems="center"
          >

            <Grid item xs={12}>

            <Divider></Divider>

            </Grid>

            <Grid
              item
              xs={12}
            >

  
            <Typography variant='h6' >Shipping</Typography>

            </Grid>

            <Grid
                item
                xs={12}
              >


            <FormControlLabel control={<Checkbox checked={props.variant_dialog_state.variant.shipping_is_physical} onChange={(event) => {handle_variant_props_change('shipping_is_physical',event.target.checked)}} />} label="This is a physical product" />

            </Grid>
            

            <Grid
                item
                xs={12}
              >

            <Divider></Divider>

            </Grid>

            {props.variant_dialog_state.variant.shipping_is_physical ? <>

            <Grid
                item
                xs={12}
              >

            <Typography variant = 'subtitle1'>Weight</Typography>

            <Typography variant='body2'>Used to calculate shipping rates at checkout and label prices during fulfillment.</Typography>

        

            </Grid>

            <Grid item 
            xs={12}
            style={{ display: "flex", gap: "0.2rem" }}
            >

            <TextField

            id="weight"
            label="Weight"
            type="number"
            placeholder='0.0'
            inputProps={{step:0.1}} 
            value={props.variant_dialog_state.variant.shipping_weight}
            onChange={(event) => {handle_variant_props_change('shipping_weight', event.target.value)}}
            InputLabelProps={{
              shrink: true,
            }}

            />

          <FormControl  sx={{ minWidth: 100 }}>

            <InputLabel id="demo-simple-select-autowidth-label">Metric</InputLabel>

            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              autoWidth
              value={props.variant_dialog_state.variant.weight_metric_value}
              onChange={(event)=>{handle_variant_props_change('weight_metric_value', event.target.value)}}
              label="Metric"
            >

              {Metrics.map((item, index)=> (
                <MenuItem key={index} value={item}>{item}</MenuItem>
              ))}

              
            </Select>

          </FormControl>

            </Grid>

            <Grid
                item
                xs={12}
              >

            <Divider></Divider>

            </Grid>

            <Grid
                item
                xs={12}
              >

              <Typography variant="subtitle1">Customs Information</Typography>

              <Typography variant='body2'>Customs authorities use this information to calculate duties when shipping internationally. Shown on printed customs forms.</Typography>
              
              <Typography variant='body2' style={{marginTop:16}}>Country/Region of origin</Typography>

            <FormControl size="small" sx={{ minWidth: 200 , marginTop:1.8}}>

            <InputLabel id="select-countries-autowidth-label">Countries</InputLabel>

              <Select
                labelId="select-countries-autowidth-label"
                id="select-countries-autowidth"
                autoWidth
                label="Countries"
                value={props.variant_dialog_state.variant.selected_country}
                onChange={(event) => {handle_variant_props_change('selected_country', event.target.value)}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {Countries.map((country, index) =>(

                <MenuItem value={index} key={index}>{country.country}</MenuItem>
                
                ))}

              </Select>

              </FormControl>

              <Typography variant='body2' style={{marginTop:2}}>In most cases, where the product is manufactured.</Typography>

              
              </Grid>

              <Grid
                  item
                  xs={12}
              >

              <Typography variant="subtitle1">HS (Harmonized System) code</Typography>

                <TextField
                id="input-with-icon-textfield"
                label="HS Code"
                fullWidth
                placeholder='Search or enter a HS code'
                value={props.variant_dialog_state.variant.hs_code}
                onChange={(event) => {handle_variant_props_change('hs_code', event.target.value)}}
                style={{marginTop:12}}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
            
              />

              <Typography variant='body2' style={{marginTop:4}}>Manually enter codes that are longer than 6 numbers.</Typography>

              </Grid>

              </> : null}

            
              </Grid>



              </Grid>

              </Grid>


                </Grid>
                

                    
            </Grid>

        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>Cancel</Button>
        <Button variant='outlined' onClick={() => {props.update_variant()}}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default EditVariantDialog;