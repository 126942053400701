import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../store/cms-context';

import { getAccessJwtToken } from '../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import InstagramDialog from "../InstagramDialog/InstagramDialog";

import cloneDeep from 'lodash/cloneDeep';


import InstagramTag from "./InstagramTag";

import InstagramTagsListTable from "../InstagramTagsListTable/InstagramTagsListTable";
import ToggleButtons from "../ToggleButtons/ToggleButtons";



function Instagram(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_instagram_tags, set_all_instagram_tags] = React.useState([]);

  const [instagram_dialog_state, set_instagram_dialog_state] = React.useState({'instagram':{},is_dialog_open:false});

  const [next_token, set_next_token] = React.useState('');

  const [timestampOrder, setTimestampOrder] = React.useState('descending');

  const [hasMore, setHasMore] = React.useState(false);

  const [filter_tags, set_filter_tags] = React.useState('')

  const [filter_user_name, set_filter_user_name] = React.useState('')

  const [calendar_open, set_calendar_open] = React.useState(false);

  const [time_range, set_time_range] = React.useState('');

  const [ranges_button_text, set_ranges_button_text] = React.useState('Time range')

  const [QueryExecutionId, setQueryExecutionId] = React.useState('');


  useEffect(() => {

    isMountedRef.current = true

    get_all_instagram_tags(filter_user_name, filter_tags, time_range, timestampOrder, QueryExecutionId ,next_token)

    return () => isMountedRef.current = false;

  }, [])




  function handle_timestamp_order_change(tOrder){

    setTimestampOrder(tOrder)

    set_all_instagram_tags([])

    setQueryExecutionId('')

    set_next_token('')
    
    get_all_instagram_tags(filter_user_name, filter_tags, time_range, tOrder, '', '')

  }



  function onDateChange(ranges) {
    // ranges ...
   // alert("changed check the console log");

   if(ranges.startDate && ranges.endDate){


    set_ranges_button_text(ranges.startDate.toISOString().split('T')[0] + " - " + ranges.endDate.toISOString().split('T')[0])

    set_time_range(ranges.startDate.toISOString().split('T')[0] + "-to-" + ranges.endDate.toISOString().split('T')[0])

   }

    console.log("ranges: ", ranges);

  };

  function clearSearch(){

    set_all_instagram_tags([])

    set_time_range('')

    set_ranges_button_text('Time range')

    set_filter_tags('')

    set_filter_user_name('')

    setQueryExecutionId('')

    set_next_token('')

    get_all_instagram_tags('', '', '', timestampOrder, '', '')

  }

  function perform_search(){

    set_all_instagram_tags([])

    get_all_instagram_tags(filter_user_name, filter_tags, time_range, timestampOrder, '', '')

  }

  return(

    <>
   

   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
 
  >

    
    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Instagram Tags</Typography>

          <div>

          <ToggleButtons timestampOrder={timestampOrder} handle_timestamp_order_change={handle_timestamp_order_change}></ToggleButtons>


         {/* <Button variant="contained" onClick = {() => {show_create_instagram_dialog()}}>Create Instagram</Button> */}

          </div>

        </Stack>

      </Grid>




         <Grid
            item
            xs={12}> 


            <InstagramTagsListTable
            perform_search={perform_search}
            onDateChange={onDateChange}
            calendar_open={calendar_open}
            set_calendar_open={set_calendar_open}
            filter_user_name={filter_user_name} 
            set_filter_user_name={set_filter_user_name} 
            filter_tags={filter_tags} 
            set_filter_tags={set_filter_tags}
            timestampOrder={timestampOrder} handle_timestamp_order_change={handle_timestamp_order_change}
            set_dialog={set_dialog}
            clearSearch={clearSearch}
            all_instagram_tags={all_instagram_tags}
            ranges_button_text={ranges_button_text}
            set_all_instagram_tags={ () =>{}}
            >


            </InstagramTagsListTable>

            </Grid>
          


      <Grid item xs={12}>


      {isLoading ? <div style={{textAlign:'center', marginTop:40}}> <CircularProgress /> </div> : null}

      </Grid>

     { <Grid item xs={12} >

      <div style={{width:'100%', textAlign:'center'}}>
      {hasMore && !isLoading ? <Button variant="contained" onClick = {() => {get_all_instagram_tags(filter_user_name, filter_tags, time_range, timestampOrder, QueryExecutionId,next_token)}}>Load more</Button> : null}

      </div>
  </Grid> }

    </Grid>
        
    </Container> 

    <InstagramDialog instagram_dialog_state={instagram_dialog_state}   set_instagram_dialog_state={set_instagram_dialog_state}></InstagramDialog>

    </Box>


    </> 


  );


  function refresh(){

    console.log('refresh called')

  }



  function set_dialog(instagram, is_open) {

    const dialog_state = {}

    dialog_state.instagram = cloneDeep(instagram)

    dialog_state.is_dialog_open = is_open

    set_instagram_dialog_state(dialog_state)

  }

  async function get_picture_async(key, index){

    const picUrl = await Storage.get(key, {
      level: 'public',
      bucket: 'snag-cms175447-staging',
      expires:86400,
      region: 'eu-west-2',
    });

    console.log('url was', picUrl)

    set_all_instagram_tags((previous_tags) => {

      const temp_tags = [...previous_tags]

      if(index < temp_tags.length){
        temp_tags[index].url = picUrl;
      }

      return(set_all_instagram_tags(temp_tags))

    })

  }

  function get_all_instagram_tags(filter_user_name, filter_tags, time_range, timestampOrder, QueryExecutionId, next_token) {


    
    console.log('store_access_token')


    // user api

    // error handling and state managment required

    setIsLoading(true)



    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/instagram/tags',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':jwt,
            'NextToken': next_token,
            'QueryExecutionId': QueryExecutionId,
            'is_ascending': timestampOrder === 'ascending' ? "ASC" : "DESC",
            'user_name': filter_user_name,
            'tags': filter_tags.replaceAll("'","cms_apostraphe"),
            'time_range': time_range
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
              setIsLoading(false)
  
              console.log('result was',result)

              if(result.tags){

                console.log('setting analuytis')

                result.tags.forEach((instagram) =>{
                  instagram.is_checked = false
                })

                if(result.tags.length === 0){

                  setHasMore(false)
                  cmsContext.setSnackBarOpen(true, "info", "No tags found. Please select a different search criteria.")

                }

                var temp_tags = [...all_instagram_tags]

                if(QueryExecutionId.length === 0){
                  temp_tags = []
                }

                temp_tags = temp_tags.concat(result.tags)

                console.log('setting all', temp_tags)

                set_all_instagram_tags(temp_tags)

                for(var tag_index in temp_tags){

                  if((!temp_tags[tag_index].url)  && temp_tags[tag_index].s3_key && temp_tags[tag_index].s3_key.length > 0){
         
                    get_picture_async(temp_tags[tag_index].s3_key, tag_index)

                  }

                }

                if(result.NextToken){
                  set_next_token(result.NextToken)
                  if(Object.keys(result.NextToken).length > 0){
                  setHasMore(true)
                  }else{
                    setHasMore(false)
                  }
                  
                }else{
                  setHasMore(false)
                  set_next_token('')
                }

                setQueryExecutionId(result.QueryExecutionId)


              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
           
      )

    })


  }


  function set_dialog_loading(is_loading){

    const temp_instagram_state = {...instagram_dialog_state}

    temp_instagram_state.isLoading = is_loading

    set_instagram_dialog_state(temp_instagram_state)

  }

  function reset_dialog_state(){

    set_instagram_dialog_state(cloneDeep({}))

  }

  function post_instagram(instagram, request_type){

    set_dialog_loading(true)

    console.log('updating')

    var request_body = {}

    request_body.instagram = instagram

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/instagram_tags', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)
                reset_dialog_state()

                get_all_instagram_tags()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                set_dialog_loading(false)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
                set_dialog_loading(false)

              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })



  }

  function delete_instagram_tags(){

    console.log('delete instagram_tags')

    var temp_instagram_tags = [...all_instagram_tags].filter((instagram) => instagram.is_checked);

    console.log('filtered were', temp_instagram_tags)

    if(temp_instagram_tags.length === 0){
      // might never execute (need to select instagram_tags to display delete button)
      cmsContext.setSnackBarOpen(true, "error", "Please select valid instagram_tags!")
      return;
    }else if(temp_instagram_tags.length > 20){
      cmsContext.setSnackBarOpen(true, "error", "Please select a maximum of 20 instagram_tags!")
      return;
    }

    temp_instagram_tags = temp_instagram_tags.map((instagram) => {

      return({'id': instagram.id})

    })

    console.log('final instagram_tags were', temp_instagram_tags)

    const request_body = {}

    request_body.instagram_tags = temp_instagram_tags

    request_body.request_type = "delete"

    request_body.account_email = cmsContext.current_shop.email
    

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{


      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/instagram_tags',{
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  console.log('result was',result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_all_instagram_tags()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {
                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)
                }
          );
  
      });

  }


};


export default Instagram;