const currencySymbols = new Map([
    ['USD', '$'], // United States Dollar
    ['EUR', '€'], // Euro
    ['GBP', '£'], // British Pound Sterling
    ['JPY', '¥'], // Japanese Yen
    ['CNY', '¥'], // Chinese Yuan
    ['INR', '₹'], // Indian Rupee
    ['AUD', 'A$'], // Australian Dollar
    ['CAD', 'C$'], // Canadian Dollar
    ['CHF', 'CHF'], // Swiss Franc
    ['SEK', 'kr'], // Swedish Krona
    ['NZD', 'NZ$'], // New Zealand Dollar
    ['MXN', '$'], // Mexican Peso
    ['SGD', 'S$'], // Singapore Dollar
    ['HKD', 'HK$'], // Hong Kong Dollar
    ['NOK', 'kr'], // Norwegian Krone
    ['KRW', '₩'], // South Korean Won
    ['TRY', '₺'], // Turkish Lira
    ['RUB', '₽'], // Russian Ruble
    ['ZAR', 'R'], // South African Rand
    ['BRL', 'R$'], // Brazilian Real
    ['SAUD', '﷼'], // Saudi Riyal
    ['MYR', 'RM'], // Malaysian Ringgit
    ['THB', '฿'], // Thai Baht
    ['PHP', '₱'], // Philippine Peso
    ['IDR', 'Rp'], // Indonesian Rupiah
    ['AED', 'د.إ'], // United Arab Emirates Dirham
    ['QAR', 'ق.ر'], // Qatari Riyal
    ['KWD', 'د.ك'], // Kuwaiti Dinar
    ['EGP', '£'], // Egyptian Pound
    ['ILS', '₪'], // Israeli New Shekel
    ['DKK', 'kr'], // Danish Krone
    ['PLN', 'zł'], // Polish Zloty
    ['CZK', 'Kč'], // Czech Koruna
    ['HUF', 'Ft'], // Hungarian Forint
    ['RON', 'lei'], // Romanian Leu
    ['CLP', '$'], // Chilean Peso
    ['COP', '$'], // Colombian Peso
    ['PEN', 'S/'], // Peruvian Sol
    ['ARS', '$'], // Argentine Peso
    ['VND', '₫'], // Vietnamese Dong
  ]);

export default currencySymbols;