import * as React from 'react';

import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';

import DialogActions from '@mui/material/DialogActions';

import DialogContent from '@mui/material/DialogContent';

import DialogContentText from '@mui/material/DialogContentText';

import DialogTitle from '@mui/material/DialogTitle';

import LoadingButton from '@mui/lab/LoadingButton';

import {InputAdornment, FormHelperText} from '@mui/material';

import { cloneDeep } from 'lodash';

import Metrics, { default_bulk_update_dialog } from '../../../Utilities/Metrics';

import CmsContext from '../../../store/cms-context';



import { Grid, TextField, Select, MenuItem, FormControl, InputLabel, OutlinedInput, Typography, FormGroup, FormControlLabel, Checkbox} from '@mui/material';

import { ProductStatus } from '../../../Utilities/Metrics';

import { default_deploy_image_dialog } from '../../../Utilities/Metrics';

function DeployImageDialog(props) {

  // continue with state management 


  const handleClose = () => {

    props.set_deploy_image_dialog_state(cloneDeep(default_deploy_image_dialog))

  };

  function handle_change(index, value) {

    const temp_state = {...props.deploy_image_dialog_state}

    temp_state.selected_stores[index].is_checked = value

    props.set_deploy_image_dialog_state(temp_state)
    
  }

  return (
    
      <Dialog
        fullWidth
        open={props.deploy_image_dialog_state.is_dialog_open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Deploy Image
        </DialogTitle>
        <DialogContent dividers>
        <Grid
            container
            spacing={3}
            alignItems="center"
            >
              
               {props.deploy_image_dialog_state.selected_stores.map((selected_store, index) => {

                return(


                <Grid
                key={index}
                item
                xs={12}
                >

                  <FormControlLabel control={<Checkbox checked={selected_store.is_checked} onChange={(event) => {handle_change(index, event.target.checked)}} />} label={<Typography fontSize={14}>{selected_store.store.name}</Typography>} />
             
                </Grid>
  

                )

               })

              }

        </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>Dismiss</Button>
          <LoadingButton variant='outlined' loading={props.deploy_image_dialog_state.isLoading} onClick={() => {props.handle_file_update("files", "Deploy")}}>Deploy</LoadingButton>
        </DialogActions>
      </Dialog>

  );
}


export default DeployImageDialog;