import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, FormControl, InputLabel, Select, MenuItem, List, Container, ListItem, IconButton} from '@mui/material';

import Dropzone from 'react-dropzone'

import CardTravelIcon from '@mui/icons-material/CardTravel';

import { UserCircle as UserCircleIcon } from '../../../../icons/user-circle';

import DeleteIcon from '@mui/icons-material/Delete';

import FolderIcon from '@mui/icons-material/Folder';


import ListItemAvatar from '@mui/material/ListItemAvatar';

import ListItemText from '@mui/material/ListItemText';

import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../../../store/cms-context';

import Inventory2Icon from '@mui/icons-material/Inventory2';




const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));


function BringItBackDialog(props) {

  console.log("bring it back goal dialog rendered")

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);


  const onSelect = (index, checked) => {

    const temp_variant_image = {...props.selected_variant_image}

    if(checked){
      temp_variant_image.image_index = index
    }else{
      temp_variant_image.image_index = -1
    }

    props.set_selected_variant_image(temp_variant_image)
    
  }

  function set_bring_it_back_goal_state(key, value) {
    
    const temp_state = {...props.bring_it_back_goal_dialog_state}

    temp_state.bring_it_back_goal[key] = value

    props.set_bring_it_back_goal_dialog_state(temp_state)
    
  }


  function parseOrKeep(value) {

    if (typeof value === 'string') {
      return parseInt(value);
    }
    return value;

  }


  function handle_bring_it_back_goal() {

    const bring_it_back_goal = {...props.bring_it_back_goal_dialog_state.bring_it_back_goal}

    console.log("bring it back goal was: ", bring_it_back_goal)
    
    if(bring_it_back_goal.product.trim().length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid product!")
      return
    }

    if(bring_it_back_goal.quantity.trim().length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid quantity!")
      return
    }

    var quantity = parseOrKeep(bring_it_back_goal.quantity)

    if(quantity < 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid quantity!")
      return
    }

    const payload = {}

    payload.product = bring_it_back_goal.product

    payload.quantity = bring_it_back_goal.quantity

    if(bring_it_back_goal.id){

      payload.id = bring_it_back_goal.id

    }

    props.post_bring_it_back_goal(payload, props.bring_it_back_goal_dialog_state.type)
    
  }


  function handle_close(){

    const temp_state = {...props.bring_it_back_goal_dialog_state}

    temp_state.is_dialog_open = false

    props.set_bring_it_back_goal_dialog_state(temp_state)

  }

  function handleDelete(){

    

  }


  return (
    <div>
      <Dialog
        open={props.bring_it_back_goal_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.bring_it_back_goal_dialog_state.type} Target
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
            >

             <Grid
              item
              xs={12}
              >

                <div style={{width:100, height:100, marginLeft:'auto', marginRight:'auto'}}>               

                <img alt="restock" style={{
                        marginTop:2,
                        height: 100,
                        width: 100,
                    }}
                src="/static/images/supplier.png"
                >
                </img>

                </div>
                
              </Grid> 


              <Grid
              item
              xs={12}
              style={{marginTop:16}}
              >

                <TextField id="outlined-basic" placeholder='e.g. SNAG-30-LUX-LIQUORICE' label="Product" variant="outlined" value={props.bring_it_back_goal_dialog_state.bring_it_back_goal.product} onChange={(event) => {set_bring_it_back_goal_state("product", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>
                
              </Grid>


              <Grid item xs={12}>

                <TextField
                id="bitb_quantity"
                type="number"
                fullWidth
                value={props.bring_it_back_goal_dialog_state.bring_it_back_goal.quantity}
                onChange={(event) => {set_bring_it_back_goal_state("quantity", event.target.value);}}
                label="Quantity"
                InputLabelProps={{
                  shrink: true,
                }}
                >

                </TextField>

              </Grid>

            </Grid>

        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        <LoadingButton variant='outlined' loading={props.bring_it_back_goal_dialog_state.isLoading} disabled={false} onClick={() => {handle_bring_it_back_goal()}}>{props.bring_it_back_goal_dialog_state.type}</LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default React.memo(BringItBackDialog);