import { Grid, Paper, TextField, Typography, Divider} from '@mui/material';


import React from 'react';



function SearchEngineListingPreview(props){

    // console.log('search engine render')


    return(

    <Paper  elevation = {2} style={{marginTop:20, padding:20}}>
      
        <Grid
          container
          spacing={3}
          alignItems="center"
        >
          
          <Grid
            item
            xs={12}
          >

        <Typography variant='h6'>Search engine listing preview</Typography>

        
        <Typography variant='body2' style={{marginTop:20  }}>Add a title and description to see how this {props.type} might appear in a search engine listing</Typography>


          </Grid>


        <Grid
            item
            xs={12}
          >

        <Divider></Divider>

        </Grid>


        <Grid
            item
            xs={12}
          >


          <TextField id="page-title" label="Page title" value={props.page_title} onChange={(event) => {props.set_page_title(event.target.value)}} InputLabelProps={{ shrink: true }} variant="outlined" helperText="0 of 70 characters used" inputProps={{ maxLength: 70 }}  fullWidth/>

          <TextField id="meta-description" label="Meta description" value={props.meta_description} onChange={(event) => {props.set_meta_description(event.target.value)}} InputLabelProps={{ shrink: true }} multiline rows={4} variant="outlined" helperText="0 of 320 characters used" inputProps={{ maxLength: 320 }} style={{marginTop:20}}  fullWidth/>

          <TextField id="url-handle" label="URL handle" value={props.url_handle_text} onChange={(event) => {props.set_url_handle_text(event.target.value)}} variant="outlined" InputLabelProps={{ shrink: true }} helperText="0 of 320 characters used" style={{marginTop:20}}  fullWidth/>

        </Grid>

        </Grid>
        
        </Paper>

    )




}


export default React.memo(SearchEngineListingPreview)