import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import Dropzone from 'react-dropzone'

import styles from './SelectCsvDialog.module.css'

import LoadingButton from '@mui/lab/LoadingButton';

function SelectCsvDialog(props) {

  const dropzoneRef = React.useRef(null);


  const onSelect = (index, checked) => {

    const temp_variant_image = {...props.selected_variant_image}

    if(checked){
      temp_variant_image.image_index = index
    }else{
      temp_variant_image.image_index = -1
    }

    props.set_selected_variant_image(temp_variant_image)
    
  }

  

  function handle_close(){

    const temp_state = {...props.select_csv_dialog_state}

    temp_state.is_dialog_open = false

    props.set_select_csv_dialog_state(temp_state)

  }

  function handle_warehouse_change(event){

    const temp_state = {...props.select_csv_dialog_state}
    
    temp_state.warehouse = event.target.value

    props.set_select_csv_dialog_state(temp_state)

  }


  return (
    <div>
      <Dialog
        open={props.select_csv_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         Import {props.type} by CSV
        </DialogTitle>

        <DialogContent dividers>

          <DialogContentText id="alert-dialog-description">
              Please select a csv file to {props.type === "Reports" ? "" : "bulk"} upload {props.type}.
          </DialogContentText>

          <Grid
            container
            spacing={3}
            alignItems="center"
            style={{marginTop:0}}
            >

             {props.type === "Reports" ? <Grid
              item
              xs={12}>

                <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Warehouse</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={props.select_csv_dialog_state.warehouse}
                          label="Warehouse"
                          onChange={handle_warehouse_change}
                        >
                        {props.select_csv_dialog_state.all_warehouses.map((warehouse, index) => {
                          return(
                          <MenuItem key={index} value={warehouse}>{warehouse}</MenuItem>
                          )
                        })}
                        </Select>
                </FormControl>

              </Grid> : null}

             <Grid
              item
              xs={12}
              style={props.select_csv_dialog_state.csv_files.length === 0 ? {display:'block'} : {display:'none'}}
              >
              
               <Dropzone ref={dropzoneRef} accept={'text/csv, application/vnd.ms-excel'} maxFiles={1} onDrop={acceptedFiles => {props.handle_accepted_csv_files(acceptedFiles)}}>
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <div {...getRootProps()}  className={styles.dropzone} >
                            <input {...getInputProps()} />
                            <div style={{textAlign:'center', marginTop:20, marginBottom:10}}>
                            <img src='/static/images/add_file.png' alt='add_file' style={{'width':'25%', 'height':'25%'}}>
                            </img>
                            <Typography variant='body2'><Link to="/" onClick={(e)=>{e.preventDefault()}}>Add files</Link> or Drag and Drop</Typography>
                            </div>
                            </div>
                        </section>
                      )}
                </Dropzone>

              </Grid> 



             { props.select_csv_dialog_state.csv_files.length === 1 ? <Grid
                item
                xs={12}
              >

                <Divider></Divider>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  style={{margin:12}}
                  spacing={2}
                >

                  <Typography style={{marginTop:'auto', marginBottom:'auto'}}>{props.select_csv_dialog_state.csv_files[0].name}</Typography>

                  <div style={{"display":"flex"}}>

                  <Button style={{marginLeft:8, borderColor:'DarkGrey',color:'DarkSlateGrey'}} variant="outlined" onClick={() => {dropzoneRef.current.open()}} size="small">Replace file</Button>

                  </div> 

                </Stack>

                <Divider></Divider>

              </Grid> : null}


            </Grid>
        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{props.select_csv_dialog_state.uploading_csv ? "Close" : "Cancel"}</Button>
        <LoadingButton variant='outlined' loading={props.select_csv_dialog_state.uploading_csv} disabled={props.select_csv_dialog_state.csv_files.length === 0} onClick={props.upload_csv_files}>Upload {props.type}</LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default SelectCsvDialog;