import React, { Component, useCallback } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Reorder, getItemStyle, getoptionListStyle } from "./utils";
import NestedSubList from "./NestedSubList";

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box } from "@mui/system";
import { Divider, IconButton, TextField, Typography, Button } from "@mui/material";
import { DeleteOutline, Add } from "@mui/icons-material";
import { default_variant } from "../../Utilities/Metrics";
import { cloneDeep } from "lodash";

// fake data generator

function NestedList(props) {



 function handleSubListDelete(optionNum, index){


  props.set_product_options((previous_options) =>{


    // console.log(optionNum,index, previous_options)

    if(index > 0){

      const options = [...previous_options];

      options[optionNum].option_values.splice(index, 1);

      // console.log('deleted',options)

      return props.set_product_options(options)

    }


  });

  }

  const handle_add_option = (index) => {

    const temp_options = [...props.product_options]; 

    const temp_option = cloneDeep(default_variant);

    temp_option.unique_id = new Date().getTime().toString();
    
    temp_options[index].option_values.push(temp_option);
    
    props.set_product_options([...temp_options]);
    
    // console.log('click',temp_options);


  }


 
  const onDragEnd = useCallback((result) => {


    if (!result.destination) {
      //// console.log("no-change");
      return;
    }

    if (result.type === "OPTIONS") {
      // console.log(result);
      const options = Reorder(
        props.product_options,
        result.source.index,
        result.destination.index
      );

      props.set_product_options(options)
      
    } else {
      const option_values = Reorder(
        props.product_options[parseInt(result.type, 10)].option_values,
        result.source.index,
        result.destination.index
      );

      const options = JSON.parse(JSON.stringify(props.product_options));

      options[result.type].option_values = option_values;

      props.set_product_options(options)

    }
    
  },[props]);

  const onDragUpdate = useCallback(() => {



  },[]);


  const handle_delete_option = (index) => {

    const options = [...props.product_options];

    options.splice(index, 1);

    props.set_product_options(options);

  }

  const handle_main_field_change = (index, text) => {

    props.set_product_options((previous_options) => {

      const temp_options = [...previous_options];

      temp_options[index].option_name = text;

      // console.log(temp_options);

      return(props.set_product_options(temp_options));

    });

  }

    return (
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragUpdate={onDragUpdate}
      >
        <Droppable droppableId="droppable" type="OPTIONS">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getoptionListStyle(snapshot.isDraggingOver)}
            >
              {props.product_options.map((option, index) => (

                <Draggable
                  key={option.id}
                  draggableId={option.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Typography variant="body2">Option name</Typography>
                      <Box sx={{ display: 'flex', p: 1, bgcolor: 'background.paper'}} {...provided.dragHandleProps}>
                      <DragIndicatorIcon style={{ float: "left" , marginTop:'auto', marginBottom:'auto', marginRight: 8}}></DragIndicatorIcon>
                      <TextField size="small" sx={{ flexGrow: 1 }} value={props.product_options[index].option_name} onChange={(event)=> {handle_main_field_change(index, event.target.value)}}></TextField>
                      <IconButton style={{ marginTop:'auto', marginBottom:'auto', marginLeft:8}} onClick={handle_delete_option.bind(this,index)}><DeleteOutline></DeleteOutline></IconButton>
                      </Box>
                      <div style={{marginTop:24}}></div>
                      <Typography variant="body2">Option values</Typography>
                      <NestedSubList optionNum={index} option={option} product_options ={props.product_options} set_product_options = {props.set_product_options} handleSubListDelete = {handleSubListDelete}/>
                      <Button startIcon={<Add />} onClick={handle_add_option.bind(this, index)}>
                        Add another value
                      </Button>
                      <Divider></Divider>
                    </div>
                  )}
                </Draggable>

              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  
}

export default NestedList;