import React, { useState, useRef, useEffect, useContext, useCallback } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper } from '@mui/material';


import CmsContext from '../../store/cms-context';

import CollectionsListTable from "./CollectionsListTable";

import { getAccessJwtToken } from '../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import CollectionEncapsulation from "../CreateCollection/CollectionEncapsulation";

import SelectCsvDialog from "../SelectCsvDialog/SelectCsvDialog";


function ListCollections(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [isLoading, setIsLoading] = useState(false);

  const [all_collections, set_all_collections] = React.useState([]);

  const [component_state, set_component_state] = React.useState({layout_type: 'list', collection_id : ''});

  const [select_csv_dialog_state, set_select_csv_dialog_state] = React.useState({'is_dialog_open':false,'uploading_csv':false,'csv_files':[]})

  const navigate = useNavigate();

  useEffect(()=>{

    isMountedRef.current = true

    get_collections_list()

    return () => isMountedRef.current = false;

  },[])


  const handle_accepted_csv_files = useCallback(

    (acceptedFiles) => {

    if(acceptedFiles.length === 1){

      var temp_accepted_files = [...acceptedFiles]

      temp_accepted_files.forEach((file) =>{

        file.modified_name = Date.now().toString() + "_" + file.name.replaceAll(' ', '_')

      })

      console.log('ct files',temp_accepted_files)

      const temp_state = {...select_csv_dialog_state}

      temp_state.csv_files = acceptedFiles

      set_select_csv_dialog_state(temp_state)


  }

    },[select_csv_dialog_state]);


    


  return(

    <>

    {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> : component_state.layout_type === "list" ? 
    

    <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Collections</Typography>

          <div style={{"display":"flex"}}>

          <Button variant="contained" onClick = {() => {show_upload_csv_dialog()}}>Import CSV</Button>

          <Button style={{marginLeft:8}} variant="contained" onClick = {() => {navigate("/collections/create");}}>Create Collection</Button>

          </div> 

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >

          <CollectionsListTable set_component_state={set_component_state} delete_collections={delete_collections} all_collections={all_collections} set_all_collections={set_all_collections}></CollectionsListTable>

      </Grid>

      </Grid>

    </Container>      

    </Box> 
    
    : component_state.layout_type === "edit" ? <CollectionEncapsulation component_state={component_state} set_component_state={set_component_state} type="Edit" collection_id = {component_state.collection_id}></CollectionEncapsulation> : component_state.layout_type === "deploy" ? <CollectionEncapsulation type="Deploy" component_state={component_state} set_component_state={set_component_state} collection_id = {component_state.collection_id}></CollectionEncapsulation> : null }  


    <SelectCsvDialog type={"Collections"} upload_csv_files={upload_csv_files} handle_accepted_csv_files={handle_accepted_csv_files} select_csv_dialog_state={select_csv_dialog_state} set_select_csv_dialog_state={set_select_csv_dialog_state}></SelectCsvDialog>


    </> 

  );


  async function upload_csv_files() {

    if(select_csv_dialog_state.csv_files.length !== 1){

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid file!")

      return

    }


    const temp_state = {...select_csv_dialog_state}

     const file = select_csv_dialog_state.csv_files[0];

    temp_state.uploading_csv = true

    set_select_csv_dialog_state(temp_state)


      var url;

      var key;

    try {

      key = await Storage.put(cmsContext.current_shop.email + "/collection_csv/" + file.modified_name, file, {
       contentType: file.type,
      });

  

      url = await Storage.get(key.key, {
        level: 'public',
        bucket: 'snag-cms175447-staging',
        expires:86400,
        region: 'eu-west-2',
      });

      console.log('file was', file, url);

    } catch (error) {
      cmsContext.setSnackBarOpen(true, "error", error)
    }

    const static_url = url;

    const static_key = key;

    console.log('static: ', static_url, ' : ', static_key)


    if(static_key && static_key.key){

      const request_body = {}

      request_body.file = {}

      request_body.file.type = "collection"
  
      request_body.file.file_type = "csv"
  
      request_body.file.aws_key = static_key.key

      request_body.file.id = file.modified_name
  

      getAccessJwtToken().then((jwt)=>{

        fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/streams',{
            crossDomain:true,
            method: 'POST',
            headers: {
              'email': cmsContext.current_shop.email,
              'Authorization':jwt,
              'content-type':'application/json',
            },
            body: JSON.stringify(request_body)
            })
            .then(res => res.json())
            .then(
              (result) => {
    
                console.log('result was',result)
  
                if(result.message){

                  stop_uploading_collections_csv(true)
  
                  cmsContext.setSnackBarOpen(true, "info", result.message)

                }else if(result.errorMessage){
  
                  stop_uploading_collections_csv(false)

                  cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                  
                } else{
                  stop_uploading_collections_csv(false)
                  cmsContext.setSnackBarOpen(true, "error", "Error uploading file, please try again")
                  console.log('Error', result)
                }
    
              },
          
              (error) => {
                stop_uploading_collections_csv(false)
                cmsContext.setSnackBarOpen(true, "error", "Error uploading file, please try again")
                console.log('error was',error)
              }
        )
  
      })

    }else{
      cmsContext.setSnackBarOpen(true, "error", "Error uploading file please try again!")
    }


  }

  function stop_uploading_collections_csv(was_success){


    if(was_success){

    set_select_csv_dialog_state({'is_dialog_open':false,'uploading_csv':false,'csv_files':[]})

    }else{

      const temp_state = {...select_csv_dialog_state}

      temp_state.uploading_csv = false

      set_select_csv_dialog_state(temp_state)

    }



  }

  async function get_picture_async(key, index){

    const picUrl = await Storage.get(key, {
      level: 'public',
      bucket: 'snag-cms175447-staging',
      expires:86400,
      region: 'eu-west-2',
    });

    console.log('url was', picUrl)

    set_all_collections((previous_collections) =>{

      const temp_collections = [...previous_collections]

      if(index < temp_collections.length){
        temp_collections[index].image.url = picUrl;
      }

      return(set_all_collections(temp_collections))

    })

  }


  function show_upload_csv_dialog(){

    const temp_state = {'is_dialog_open':false,'uploading_csv':false,'csv_files':[]}

    temp_state.is_dialog_open = true;

    set_select_csv_dialog_state(temp_state)

  }


  function get_collections_list() {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/collections',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'store_domain': cmsContext.current_shop.store_domain,
            'Authorization':jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was',result)

              if(result.collections){

                var temp_collections = result.collections;

                if(temp_collections.length === 0){

                  cmsContext.setSnackBarOpen(true, "info", "No collections yet. Let's create one to get started.")

                }
                
                temp_collections = temp_collections.map((collection) => {
                  return(Object.assign(collection,{'is_checked':false}))
                });

                set_all_collections(temp_collections)

                for(var collection_index in temp_collections){

                  if(temp_collections[collection_index].image && temp_collections[collection_index].image.aws_key){
                    get_picture_async(temp_collections[collection_index].image.aws_key, collection_index)
                  }

                }
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
      )

    })


  }

  function delete_collections(){

    console.log('delete collections')

    var temp_collections = [...all_collections].filter((product) => product.is_checked);

    console.log('filtered were', temp_collections)

    if(temp_collections.length === 0){
      // might never execute (need to select collections to display delete button)
      cmsContext.setSnackBarOpen(true, "error", "Please select valid collections!")
      return;
    }else if(temp_collections.length > 20){
      cmsContext.setSnackBarOpen(true, "error", "Please select a maximum of 20 collections!")
      return;
    }

    temp_collections = temp_collections.map((product) => {

      return({'id': product.id})

    })

    console.log('final collections were', temp_collections)

    const request_body = {}

    request_body.collections = temp_collections

    request_body.request_type = "Delete"

    request_body.collection_type = "Master"
    

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{


      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/collections',{
              crossDomain:true,
              method: 'POST',
              headers: {
                'store_domain': props.variation ? props.variation.store.store_domain : cmsContext.current_shop.store_domain,
                'email': cmsContext.current_shop.email,
                'content-type':'application/json',
                'Authorization':jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  console.log('result was',result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_collections_list()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {
                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)
                }
          );
  
      });

  }


};


export default ListCollections;