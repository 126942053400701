import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, makeStyles, Select, FormControl, InputLabel, MenuItem} from '@mui/material';
import Dropzone from 'react-dropzone'

import { UserCircle as UserCircleIcon } from '../../../../../icons/user-circle';

import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../../../../store/cms-context';

import SyncIcon from '@mui/icons-material/Sync';


import cloneDeep from 'lodash/cloneDeep';

const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));


function TaskDialog(props) {

  console.log("task dialog state was: ", props.task_dialog_state)

  const cmsContext = React.useContext(CmsContext);

  function set_task_state(key, value) {
    
    const temp_state = {...props.task_dialog_state}

    temp_state[key] = value

    props.set_task_dialog_state(temp_state)
    
  }
  
  

  function handle_close(){

    const temp_state = {...props.task_dialog_state}

    temp_state.is_dialog_open = false

    props.set_task_dialog_state(temp_state)

  }

  return (
    <div>
      <Dialog
        open={props.task_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.task_dialog_state.type} Sync Task
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

            <Grid item xs={12}>

              <div style={{textAlign:'center', marginTop:16}}>

                <img src="/static/images/shopify_speed.png" alt='g_search' style={{width:80, height:80}}></img>
                <SyncIcon color="primary" style={{marginLeft:20, marginRight:20, marginBottom:16}}></SyncIcon>
                <img src="/static/images/storage-stacks.png" alt='g_ads' style={{width:80, height:80}}></img>

              </div>

            </Grid>

            {props.task_dialog_state.all_stores != null ? <Grid item xs={12}>

            <FormControl sx={{ width:'100%', marginTop:2}}>

            <InputLabel id="demo-simple-select-autowidth-label">Shopify Account</InputLabel>

            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              fullWidth
              disabled
              value={Object.keys(props.task_dialog_state.source).length > 0 ? props.task_dialog_state.source.store : ''}
              onChange={(event)=>{console.log('event target value: ', event.target.value); const temp_dialog_state = {...props.task_dialog_state} ;const index = temp_dialog_state.all_stores.findIndex(store => store.store === event.target.value); console.log('found_index', index); temp_dialog_state.source = cloneDeep(temp_dialog_state.all_stores[index]);props.set_task_dialog_state(temp_dialog_state)}}
              label="Shopify Account"
            >

              {props.task_dialog_state.all_stores.map((item, index)=> (
                <MenuItem key={index} value={item.store}>{item.name}</MenuItem>
              ))}
              
            </Select>

            </FormControl>

            </Grid> : null}


            { Object.keys(props.task_dialog_state.source).length > 0 ? <Grid item xs={12}>

              <FormControl sx={{ width:'100%', marginTop:2}}>

              <InputLabel id="demo-simple-select-autowidth-label">Target Type</InputLabel>

              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                fullWidth
                value={props.task_dialog_state.target_type}
                onChange={(event) => {console.log('value was: ', event.target.value); const temp_dialog_state = {...props.task_dialog_state}; temp_dialog_state.target_type = event.target.value; temp_dialog_state.target={}; props.set_task_dialog_state(temp_dialog_state);}}
                label="Target Type"
              >

                {props.task_dialog_state.target_options.map((option, index) => (
                  <MenuItem key={index} value={option}>{option}</MenuItem>
                ))}
                
              </Select>

              </FormControl>

                </Grid> : null }

              {props.task_dialog_state.target_type === "Shopify" ? <Grid item xs={12}>

              <FormControl sx={{ width:'100%', marginTop:2}}>

              <InputLabel id="demo-simple-select-autowidth-label">Target</InputLabel>

              <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              fullWidth
              value={Object.keys(props.task_dialog_state.target).length > 0 ? props.task_dialog_state.target.store : ''}
              onChange={(event)=>{console.log('event target value: ', event.target.value); const temp_dialog_state = {...props.task_dialog_state} ;const index = temp_dialog_state.all_stores.findIndex(store => store.store === event.target.value); console.log('found_index', index); temp_dialog_state.target = cloneDeep(temp_dialog_state.all_stores[index]); if(temp_dialog_state.target.store !== temp_dialog_state.source.store){props.set_task_dialog_state(temp_dialog_state)}else{cmsContext.setSnackBarOpen(true, "error", "Please select a different source and target")}}}
              label="Target"
              >

              {props.task_dialog_state.all_stores.map((item, index)=> (
                <MenuItem key={index} value={item.store}>{item.name}</MenuItem>
              ))}
              
              </Select>

              </FormControl>

                </Grid> : null}


                {props.task_dialog_state.target_type === "Mintsoft" ? <Grid item xs={12}>

              <FormControl sx={{ width:'100%', marginTop:2}}>

              <InputLabel id="demo-simple-select-autowidth-label">Target</InputLabel>

              <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              fullWidth
              value={Object.keys(props.task_dialog_state.target).length > 0 ? props.task_dialog_state.target.id : ''}
              onChange={(event)=> {console.log('event target value: ', event.target.value); const temp_dialog_state = {...props.task_dialog_state}; const index = temp_dialog_state.mintsoft_clients.findIndex(client => client.id === event.target.value); console.log('found_index', index); temp_dialog_state.target = cloneDeep(temp_dialog_state.mintsoft_clients[index]); props.set_task_dialog_state(temp_dialog_state)}}
              label="Target"
              >

              {props.task_dialog_state.mintsoft_clients.map((item, index)=> (
                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
              ))}
              
              </Select>

              </FormControl>

                </Grid> : null}

            </Grid>

        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        <LoadingButton variant='outlined' loading={props.task_dialog_state.isLoading} disabled={false} onClick={() => {props.post_sync_task()}}>{props.task_dialog_state.action_type}</LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );




}



export default TaskDialog;