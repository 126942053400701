import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha,styled } from '@mui/system';
import { grey , blue} from '@mui/material/colors';
import { Container, InputLabel, Select,FormControl, Card, Typography, Grid, Box, Chip, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, makeStyles, AppBar, Toolbar, IconButton, List, ListItem, ListItemText, MenuItem} from '@mui/material';
import Dropzone from 'react-dropzone'



import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../store/cms-context';

import GoogleIcon from '@mui/icons-material/Google';

import CloseIcon from '@mui/icons-material/Close';

import Slide from '@mui/material/Slide';
import Countries from '../../Utilities/Countries';
import Languages from '../../Utilities/Languages';
import Currencies from '../../Utilities/Currencies';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import ErrorIcon from '@mui/icons-material/Error';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { theme } from '../../theme/theme';

import useMediaQuery from '@mui/material/useMediaQuery';

import Metrics, { availability_options, conditions, size_systems, energy_efficiencies, length_units, default_installment, default_subscription_cost, default_loyality_points } from '../../Utilities/Metrics';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ChippedTextField from '../ChippedTextField/ChippedTextField';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));



function GoogleMerchantDialog(props) {

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);


  const isLarge = useMediaQuery(theme.breakpoints.up('md'));


  function handleClickOpen(){

    const temp_state = {...props.google_merchant_dialog_state}

    temp_state.is_dialog_open = true

    props.set_google_merchant_dialog_state(temp_state)

  }

  function handleClose() {

    const temp_state = {...props.google_merchant_dialog_state}

    temp_state.is_dialog_open = false

    props.set_google_merchant_dialog_state(temp_state)

  }

  function handleSave() {

    props.send_merchant_request("create")

  }


  function set_merchant_field(key, value){


    const temp_state = {...props.google_merchant_dialog_state}

    if(key !== "shippings"){
      temp_state.google_merchant[key] = value
    }else{
      temp_state.shippings = value
    }

    props.set_google_merchant_dialog_state(temp_state)

  }

  function handleSelecetedTags(items) {
    console.log(items);
  }


  const error_status = (props.google_merchant_dialog_state.google_merchant.last_request_type && props.google_merchant_dialog_state.google_merchant.last_request_type === "error")


  return (
    <div>
      <Dialog
        fullScreen
        open={props.google_merchant_dialog_state.is_dialog_open}
        PaperProps={{
          style: {
            backgroundColor: '#F3F4F6'
          }
        }}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' , backgroundColor: theme.palette.primary }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <div style={{ml: 2, flex: 1}}>
            <Button size="large" sx={{ color:'white' }} component="div" startIcon={<GoogleIcon />}>
              Merchant
            </Button>
            </div>
            {props.google_merchant_dialog_state.isLoading ? <CircularProgress style={{color:'white'}}/> : <Button autoFocus size="large" color="inherit" onClick={handleSave}>
              Save
            </Button>}
          </Toolbar>
        </AppBar>
        <Box
        component="main"
        sx={{
          flexGrow: 1
        }}
      >

        <Container maxWidth={false} sx={{maxWidth:"1000px",overflow:'visible', marginTop:8, marginBottom:8}}>
      
        <Grid
            container
            spacing={2}
            style={{overflow:'visible'}}
          >


          <Grid
            item
            xs={12}
          >

            {props.google_merchant_dialog_state.google_merchant.last_request_status ? <div style={{display:"flex", textAlign:"end"}}>{ !error_status ? <CheckCircleOutlineIcon style={{marginLeft:'auto'}} color={error_status ? "error" : "primary"}></CheckCircleOutlineIcon> : <ErrorIcon style={{marginLeft:'auto'}} color={error_status ? "error" : "primary"}></ErrorIcon>}<Typography fontSize={12} variant="h6" style={{marginTop:4, marginLeft:4}} color={error_status ? "red" : "blue"}>{props.google_merchant_dialog_state.google_merchant.last_request_status}</Typography> </div> : null}


          </Grid>



          <Grid
            item
            xs={12}
            
          >

        <Card elevation={2} style={{padding:20,overflow:'visible', backgroundColor:"white"}} >

          <Grid container 
            spacing={isLarge ? 2 : 1}
            style={{overflow:'visible'}}
            >

              <Grid item xs={12}>

                <Typography fontSize={14}>

                <strong>Country of sale and destinations</strong>

                </Typography>

              </Grid>

              <Grid item xs={12} md={2} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto',marginLeft:{md:'auto'}, marginBottom:'auto'}}>Country of sale<sup>*</sup></Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={4} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth >


              <Select
                id="select-countries-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.google_merchant.targetCountry}
                onChange={(event) => {set_merchant_field('targetCountry', event.target.value)}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {Countries.map((country, index) =>(

                <MenuItem value={country.code} key={index}>{country.country}</MenuItem>
                
                ))}

              </Select>

              </FormControl>


              </Grid>



              <Grid item xs={12} md={2} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginBottom:'auto', marginLeft:{md:'auto'}}}>Language<sup>*</sup></Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={4} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth >


              <Select
                id="select-language-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.google_merchant.contentLanguage}
                onChange={(event) => {set_merchant_field('contentLanguage', event.target.value)}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {Languages.map((language, index) =>(

                <MenuItem value={language.code} key={index}>{language.name}</MenuItem>
                
                ))}

              </Select>

              </FormControl>


              </Grid>

              <Grid item xs={12} md={2} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginBottom:'auto', marginLeft:{md:'auto'}}}>Destinations<sup>*</sup></Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={4} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControlLabel style={{padding:0}} control={<Checkbox size="small" checked={props.google_merchant_dialog_state.google_merchant.distination_shopping_ads} onChange={(event) =>{set_merchant_field("distination_shopping_ads", event.target.checked)}} />} label={<Typography fontSize={12}>Shopping ads</Typography>} />

              </Grid>
    
                  


          </Grid>

        </Card>

        </Grid>

        {/* next */}


        <Grid
            item
            xs={12}
            
          >

        <Card elevation={2} style={{padding:20,overflow:'visible', backgroundColor:"white"}} >

          <Grid container 
            spacing={isLarge ? 2 : 1}
            style={{overflow:'visible'}}
            >

              <Grid item xs={12}>

                <Typography fontSize={14}>

                <strong>Product identifiers</strong>

                </Typography>

              </Grid>

              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginLeft:{md:'auto'}, marginBottom:'auto'}}>Identifier exists</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControlLabel style={{padding:0}} control={<Checkbox size="small" checked={props.google_merchant_dialog_state.google_merchant.identifierExists} onChange={(event) =>{set_merchant_field("identifierExists", event.target.checked)}} />} label={<Typography fontSize={12}>This product has an identifier</Typography>} />

              </Grid>

              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}} >GTIN, UPC, EAN, JAN or ISBN</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}}>

              <TextField
                id="input-mpn-language-autowidth"
                size='small'
                value={props.google_merchant_dialog_state.google_merchant.gtin}
                onChange={(event) => {set_merchant_field('gtin', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>



              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginBottom:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}}}>Manufacturer Part Number (MPN)</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >

      
              <TextField
                id="input-gtin-language-autowidth"
                size='small'
                value={props.google_merchant_dialog_state.google_merchant.mpn}
                onChange={(event) => {set_merchant_field('mpn', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>    
                  
                  
          </Grid>

        </Card>

        </Grid>


        {/* next */}

        <Grid
            item
            xs={12}
            
          >

        <Card elevation={2} style={{padding:20,overflow:'visible', backgroundColor:"white"}} >

          <Grid container 
            spacing={isLarge ? 2 : 1}
            style={{overflow:'visible'}}
            >

              <Grid item xs={12}>

                <Typography fontSize={14}>

                <strong>Product data</strong>

                </Typography>

              </Grid>

             


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto',marginLeft:{md:'auto'}, marginBottom:'auto'}} >ID or SKU</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >

              <TextField
                id="input-mpn-language-autowidth"
                size='small'
                placeholder="Leave blank to auto create id from shopify"
                disabled={(props.google_merchant_dialog_state.google_merchant.merchant_id != null && props.google_merchant_dialog_state.google_merchant.merchant_id.length > 0) || props.product_type === "Master"}
                inputProps={{style: {fontSize: 12}}}
                value={props.google_merchant_dialog_state.google_merchant.merchant_id}
                onChange={(event) => {set_merchant_field('merchant_id', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>



              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginBottom:'auto', marginLeft:{md:'auto'}}}>Title<sup>*</sup></Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >


              <TextField
                id="input-gtin-language-autowidth"
                size='small'
                inputProps={{style: {fontSize: 12}}}
                placeholder="Leave blank to use seo title"
                disabled={props.product_type === "Master"}
                value={props.google_merchant_dialog_state.google_merchant.title}
                onChange={(event) => {set_merchant_field('title', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>   


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginBottom:'auto', marginLeft:{md:'auto'}}}>Brand</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >


              <TextField
                id="input-gtin-language-autowidth"
                size='small'
                inputProps={{style: {fontSize: 12}}}
                value={props.google_merchant_dialog_state.google_merchant.brand}
                onChange={(event) => {set_merchant_field('brand', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>  


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{ marginBottom:'auto', marginLeft:{md:'auto'}}}>Description</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2,width:16, height:16, marginTop:2, marginRight:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16, marginTop:{md:2}}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >


              <TextField
                id="input-gtin-language-autowidth"
                size='small'
                multiline
                rows={6}
                disabled={props.product_type === "Master"}
                placeholder="Leave blank to use seo description"
                inputProps={{style: {fontSize: 12}}}
                value={props.google_merchant_dialog_state.google_merchant.description}
                onChange={(event) => {set_merchant_field('description', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>  


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginBottom:'auto', marginLeft:{md:'auto'}}}>(Landing page) Link<sup>*</sup></Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >


              <TextField
                id="input-gtin-language-autowidth"
                size='small'
                placeholder="Leave blank to use seo landing page link"
                disabled={props.product_type === "Master"}
                inputProps={{style: {fontSize: 12}}}
                value={props.google_merchant_dialog_state.google_merchant.link}
                onChange={(event) => {set_merchant_field('link', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>  



              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginBottom:'auto', marginLeft:{md:'auto'}}}>Mobile Link</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >


              <TextField
                id="input-gtin-language-autowidth"
                size='small'
                inputProps={{style: {fontSize: 12}}}
                value={props.google_merchant_dialog_state.google_merchant.mobileLink}
                onChange={(event) => {set_merchant_field('mobileLink', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>  



              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginBottom:'auto', marginLeft:{md:'auto'}}}>Image link<sup>*</sup></Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >


              <TextField
                id="input-gtin-language-autowidth"
                size='small'
                inputProps={{style: {fontSize: 12}}}
                value={props.google_merchant_dialog_state.google_merchant.imageLink.url}
                onChange={(event) => {const temp_link = {...props.google_merchant_dialog_state.google_merchant.imageLink};temp_link.aws_key = null;temp_link.url=event.target.value;set_merchant_field('imageLink', temp_link)}}
              >

              </TextField>

              <Typography fontSize={12} sx={{margin:1}}>OR</Typography>

              <div style={isLarge ? {width:'40%'} : {width:'60%'}}>


              <Box onClick={() => {props.select_variant_image(props.google_merchant_dialog_state.index, 0)}} sx={{ background: '#eee',border: '2px dashed cyan',borderRadius:0.5, width:'100%', paddingTop: '100%', position:'relative',cursor:'pointer',overflow:'hidden'}} >
                
  

              {/*<Dropzone ref={dropzoneRef}  maxFiles={1} accept={'image/jpeg, image/png'} onDrop={acceptedFiles => {props.handle_accepted_files(acceptedFiles)}}>

              {({getRootProps, getInputProps}) => (

                <section>
                  <div {...getRootProps()} >
                    <input {...getInputProps()} />
                    <img src='/static/images/add_file.png' alt='add_file' style={{'width':'25%', 'height':'25%'}}>
                    </img>
                    </div>
                </section>

              )}
              
              </Dropzone>*/}

              <div style={{width:'100%', height:'100%', textAlign:'center'}}>
              

              <img src={props.google_merchant_dialog_state.google_merchant.imageLink.url.length > 0 ? props.google_merchant_dialog_state.google_merchant.imageLink.url : '/static/images/add_file.png'} onLoad={({currentTarget}) => {if(props.google_merchant_dialog_state.google_merchant.imageLink.url.length > 0){currentTarget.style.width='100%'}else{currentTarget.style.width='40%'}}} alt='add_file' style={{position: 'absolute',top:0, left:0, bottom:0, right: 0, marginTop:'auto', marginBottom:'auto', marginLeft:'auto',marginRight:'auto', width:'40%'}} >
              </img>

              </div>
              </Box>

              </div>

              </FormControl>

              </Grid>  
                  

              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginBottom:'auto', marginLeft:{md:'auto'}}}>Additional image link</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <Grid container>


              {props.google_merchant_dialog_state.google_merchant.additionalImageLinks.map((imageLink, index) => {

                return(

                  <Grid key={index} item xs={12}>

                  <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}, marginTop:2}} >


                  <TextField
                    id="input-gtin-language-autowidth"
                    size='small'
                    inputProps={{style: {fontSize: 12}}}
                    value={imageLink.url}
                    onChange={(event) => {const temp_links = [...props.google_merchant_dialog_state.google_merchant.additionalImageLinks];temp_links[index].aws_key=null;temp_links[index].url = event.target.value; set_merchant_field('additionalImageLinks', temp_links);}}
                  >
    
                  </TextField>
    
                  <Typography fontSize={12} sx={{margin:1}}>OR</Typography>
    
                  <div style={isLarge ? {width:'40%'} : {width:'60%'}}>

                  <Box onClick={() => {props.select_variant_image(props.google_merchant_dialog_state.index, index+1)}} sx={{ background: '#eee',border: '2px dashed cyan',borderRadius:0.5, width:'100%', paddingTop: '100%', position:'relative',cursor:'pointer',overflow:'hidden'}} >
                
  

                  {/*<Dropzone ref={dropzoneRef}  maxFiles={1} accept={'image/jpeg, image/png'} onDrop={acceptedFiles => {props.handle_accepted_files(acceptedFiles)}}>
    
                  {({getRootProps, getInputProps}) => (
    
                    <section>
                      <div {...getRootProps()} >
                        <input {...getInputProps()} />
                        <img src='/static/images/add_file.png' alt='add_file' style={{'width':'25%', 'height':'25%'}}>
                        </img>
                        </div>
                    </section>
    
                  )}
                  
                  </Dropzone>*/}
    
                  <div style={{width:'100%', height:'100%', textAlign:'center'}}>
                  
    
                  <img src={imageLink.url.length > 0 ? imageLink.url : '/static/images/add_file.png'} onLoad={({currentTarget}) => {if(imageLink.url.length > 0){currentTarget.style.width='100%'}else{currentTarget.style.width='40%'}}} alt='add_file' style={{position: 'absolute',top:0, left:0, bottom:0, right: 0, marginTop:'auto', marginBottom:'auto', marginLeft:'auto',marginRight:'auto', width:'40%'}} >
                    
                  </img>
    
                </div>

                </Box>
                  </div>
    
                  </FormControl>

                  <IconButton sx={{marginTop:2}} aria-label="upload picture" component="span" onClick={() => {const temp_links = [...props.google_merchant_dialog_state.google_merchant.additionalImageLinks];temp_links.pop(index);set_merchant_field('additionalImageLinks', temp_links)}}>
                    <HighlightOffIcon />
                  </IconButton>

                  </Grid>

                )

              })}

              </Grid>

              <Button onClick={() => {const temp_images = [...props.google_merchant_dialog_state.google_merchant.additionalImageLinks];temp_images.push({url:''});set_merchant_field("additionalImageLinks", temp_images)}}>Add image</Button>

              </Grid>  
                  
                  
                  
          </Grid>

        </Card>

        </Grid>


        {/* next */}



        <Grid
            item
            xs={12}
            
          >

        <Card elevation={2} style={{padding:20,overflow:'visible', backgroundColor:"white"}} >

          <Grid container 
            spacing={isLarge ? 2 : 1}
            style={{overflow:'visible'}}
            >

              <Grid item xs={12}>

                <Typography fontSize={14}>

                <strong>Price and availability</strong>

                </Typography>

              </Grid>

          

              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}}>Price<sup>*</sup></Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <div style={{display:'flex'}}>

              <FormControl size="small" >

              <TextField
                id="input-mpn-language-autowidth"
                size='small'
                type="number"
              
                sx={{width:100}}
                value={props.google_merchant_dialog_state.google_merchant.price.value}
                onChange={(event) => {set_merchant_field('price', {"value": event.target.value, "currency": props.google_merchant_dialog_state.google_merchant.price.currency})}}
              >

              </TextField>

              </FormControl>

              <FormControl size="small" sx={{minWidth:100, marginLeft:0.25}}>

              <Select
                id="select-countries-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.google_merchant.price.currency}
                onChange={(event) => {set_merchant_field('price', {"value": props.google_merchant_dialog_state.google_merchant.price.value, "currency": event.target.value})}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {Object.keys(Currencies).map((key, index) =>(

                <MenuItem value={key} key={index}>{key}</MenuItem>
                
                ))}

              </Select>

              </FormControl>

              </div>


              </Grid>




              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginBottom:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}}}>availability</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >

           
              <Select
                id="select-availability-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.google_merchant.availability}
                onChange={(event) => {set_merchant_field('availability', event.target.value)}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {availability_options.map((availability, index) =>(

                <MenuItem value={availability} key={index}>{availability}</MenuItem>
                
                ))}

              </Select>

              </FormControl>


              </Grid>   


               <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}} >Availability date</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <div style={{display:'flex'}}>

              <LocalizationProvider dateAdapter={AdapterDateFns}>

                <DateTimePicker
                  renderInput={(params) => <TextField {...params}  />}
                  value={props.google_merchant_dialog_state.google_merchant.availabilityDate}
                  onChange={(newValue) => {
                    set_merchant_field("availabilityDate", newValue)
                  }}
                />
                </LocalizationProvider>

              </div>


              </Grid>



              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}} >Expiration date</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <div style={{display:'flex'}}>

              <LocalizationProvider dateAdapter={AdapterDateFns}>

                <DateTimePicker
                  renderInput={(params) => <TextField {...params}  />}
                  value={props.google_merchant_dialog_state.google_merchant.expirationDate}
                  onChange={(newValue) => {
                    set_merchant_field("expirationDate", newValue)
                  }}
                />

              </LocalizationProvider>

              </div>


              </Grid>


              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}} >Sale price</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <div style={{display:'flex'}}>

              <FormControl size="small" >

              <TextField
                id="input-mpn-language-autowidth"
                size='small'
                type="number"

                sx={{width:100}}
                value={props.google_merchant_dialog_state.google_merchant.salePrice.value}
                onChange={(event) => {set_merchant_field('salePrice', {"value": event.target.value, "currency": props.google_merchant_dialog_state.google_merchant.salePrice.currency})}}
              >

              </TextField>

              </FormControl>

              <FormControl size="small" sx={{minWidth:100, marginLeft:0.25}}>

              <Select
                id="select-countries-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.google_merchant.salePrice.currency}
                onChange={(event) => {set_merchant_field('salePrice', {"value": props.google_merchant_dialog_state.google_merchant.salePrice.value, "currency": event.target.value})}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {Object.keys(Currencies).map((key, index) =>(

                <MenuItem value={key} key={index}>{key}</MenuItem>
                
                ))}

              </Select>

              </FormControl>

              </div>


              </Grid>
                  

              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}} >Cost of goods sold</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <div style={{display:'flex'}}>

              <FormControl size="small" >

              <TextField
                id="input-mpn-language-autowidth"
                size='small'
                type="number"

                sx={{width:100}}
                value={props.google_merchant_dialog_state.google_merchant.costOfGoodsSold.value}
                onChange={(event) => {set_merchant_field('costOfGoodsSold', {"value": event.target.value, "currency": props.google_merchant_dialog_state.google_merchant.costOfGoodsSold.currency})}}
              >

              </TextField>

              </FormControl>

              <FormControl size="small" sx={{minWidth:100, marginLeft:0.25}}>

              <Select
                id="select-countries-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.google_merchant.costOfGoodsSold.currency}
                onChange={(event) => {set_merchant_field('costOfGoodsSold', {"value": props.google_merchant_dialog_state.google_merchant.costOfGoodsSold.value, "currency": event.target.value})}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {Object.keys(Currencies).map((key, index) =>(

                <MenuItem value={key} key={index}>{key}</MenuItem>
                
                ))}

              </Select>

              </FormControl>

              </div>


              </Grid>


              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}} >Unit pricing measure</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <div style={{display:'flex'}}>

              <FormControl size="small" >

              <TextField
                id="input-mpn-language-autowidth"
                size='small'
                type="number"

                sx={{width:100}}
                value={props.google_merchant_dialog_state.google_merchant.unitPricingMeasure.value}
                onChange={(event) => {set_merchant_field('unitPricingMeasure', {"value": event.target.value, "currency": props.google_merchant_dialog_state.google_merchant.unitPricingMeasure.currency})}}
              >

              </TextField>

              </FormControl>

              <FormControl size="small" sx={{minWidth:100, marginLeft:0.25}}>

              <Select
                id="select-countries-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.google_merchant.unitPricingMeasure.currency}
                onChange={(event) => {set_merchant_field('unitPricingMeasure', {"value": props.google_merchant_dialog_state.google_merchant.unitPricingMeasure.value, "currency": event.target.value})}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {Object.keys(Currencies).map((key, index) =>(

                <MenuItem value={key} key={index}>{key}</MenuItem>
                
                ))}

              </Select>

              </FormControl>

              </div>


              </Grid>
                
            
              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}} >Unit pricing base measure</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <div style={{display:'flex'}}>

              <FormControl size="small" >

              <TextField
                id="input-mpn-language-autowidth"
                size='small'
                type="number"

                sx={{width:100}}
                value={props.google_merchant_dialog_state.google_merchant.unitPricingBaseMeasure.value}
                onChange={(event) => {set_merchant_field('unitPricingBaseMeasure', {"value": event.target.value, "currency": props.google_merchant_dialog_state.google_merchant.unitPricingBaseMeasure.currency})}}
              >

              </TextField>

              </FormControl>

              <FormControl size="small" sx={{minWidth:100, marginLeft:0.25}}>

              <Select
                id="select-countries-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.google_merchant.unitPricingBaseMeasure.currency}
                onChange={(event) => {set_merchant_field('unitPricingBaseMeasure', {"value": props.google_merchant_dialog_state.google_merchant.unitPricingBaseMeasure.value, "currency": event.target.value})}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {Object.keys(Currencies).map((key, index) =>(

                <MenuItem value={key} key={index}>{key}</MenuItem>
                
                ))}

              </Select>

              </FormControl>

              </div>


              </Grid>


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginLeft:{md:'auto'}, marginTop:{md:1}}}>Installment</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2,width:16, height:16, marginTop:2, marginRight:2}}
              >

                <HelpOutlineIcon sx={{width:16, height:16, marginTop:{md:2}}} ></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:2} : {}}>

                {props.google_merchant_dialog_state.google_merchant.installment ? <Grid container>

                  <Grid item xs={12}>

                  <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}, marginBottom:2}} >

                  <Typography fontSize={12} sx={{marginTop:1.8, marginLeft:0.5}}>Installment months</Typography>

                  <TextField
                    id="input-ins-mon-language-autowidth"
                    size='small'
                    sx={{marginTop:0.2}}
                    inputProps={{style: {fontSize: 12}}}
                    value={props.google_merchant_dialog_state.google_merchant.installment.months}
                    onChange={(event) => {const temp_details = {...props.google_merchant_dialog_state.google_merchant.installment};temp_details.months = event.target.value; set_merchant_field('installment', temp_details)}}
                  >

                  </TextField>

                  </FormControl>

                  <IconButton sx={{marginTop:2}} component="span" onClick={() => {set_merchant_field('installment', null)}}>
                    <HighlightOffIcon />
                  </IconButton>

                  
                  </Grid>

                  <Grid item xs={12}>

                  <Typography fontSize={12} sx={{ marginLeft:0.5}}>Installment amount</Typography>


                  <div style={{display:'flex', marginTop:2}} >

                  <FormControl size="small" >

                  <TextField
                    id="input-inst-amnt-autowidth"
                    size='small'
                    type="number"
                    sx={{width:100}}
                    value={props.google_merchant_dialog_state.google_merchant.installment.amount.value}
                    onChange={(event) => {set_merchant_field('installment', {months: props.google_merchant_dialog_state.google_merchant.installment.months, amount: {"value": event.target.value, "currency": props.google_merchant_dialog_state.google_merchant.installment.amount.currency}})}}
                  >

                  </TextField>

                  </FormControl>

                  <FormControl size="small" sx={{minWidth:100, marginLeft:0.25}}>

                  <Select
                    id="select-inst-amnt-autowidth"
                    autoWidth
                    value={props.google_merchant_dialog_state.google_merchant.installment.amount.currency}
                    onChange={(event) => {set_merchant_field('installment', {months: props.google_merchant_dialog_state.google_merchant.installment.months, amount: {"value": props.google_merchant_dialog_state.google_merchant.installment.amount.value, "currency": event.target.value}})}}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      }
                    }}
                  >

                    {Object.keys(Currencies).map((currency, index) =>(

                    <MenuItem value={currency} key={index}>{currency}</MenuItem>
                    
                    ))}

                  </Select>

                  </FormControl>

                  </div>
                  
                  </Grid>



                </Grid> : <Button onClick={() => {set_merchant_field('installment', default_installment)}}>Add</Button>}



              </Grid>  
              
              {/* new */}


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginLeft:{md:'auto'}, marginTop:{md:1}}}>Subscription cost</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2,width:16, height:16, marginTop:2, marginRight:2}}
              >

                <HelpOutlineIcon sx={{width:16, height:16, marginTop:{md:2}}} ></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:2} : {}}>

                {props.google_merchant_dialog_state.google_merchant.subscriptionCost ? <Grid container>

                  <Grid item xs={12}>

                  <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >

                  <Typography fontSize={12} sx={{marginTop:1.8, marginLeft:0.5}}>Subscription cost period</Typography>

                  <TextField
                    id="input-ins-mon-language-autowidth"
                    size='small'
                    sx={{marginTop:0.2}}
                    inputProps={{style: {fontSize: 12}}}
                    value={props.google_merchant_dialog_state.google_merchant.subscriptionCost.period}
                    onChange={(event) => {const temp_details = {...props.google_merchant_dialog_state.google_merchant.subscriptionCost};temp_details.period = event.target.value; set_merchant_field('subscriptionCost', temp_details)}}
                  >

                  </TextField>

                  </FormControl>

                  <IconButton sx={{marginTop:2}} component="span" onClick={() => {set_merchant_field('subscriptionCost', null)}}>
                    <HighlightOffIcon />
                  </IconButton>

                  
                  </Grid>

                  <Grid item xs={12}>

                  <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}, marginBottom:2}} >

                  <Typography fontSize={12} sx={{marginTop:1.8, marginLeft:0.5}}>Subscription cost length</Typography>

                  <TextField
                    id="input-ins-mon-language-autowidth"
                    size='small'
                    sx={{marginTop:0.2}}
                    inputProps={{style: {fontSize: 12}}}
                    value={props.google_merchant_dialog_state.google_merchant.subscriptionCost.periodLength}
                    onChange={(event) => {const temp_details = {...props.google_merchant_dialog_state.google_merchant.subscriptionCost};temp_details.periodLength = event.target.value; set_merchant_field('subscriptionCost', temp_details)}}
                  >

                  </TextField>

                  </FormControl>

                  </Grid>

                  <Grid item xs={12}>

                  <Typography fontSize={12} sx={{ marginLeft:0.5}}>Subscription cost amount</Typography>


                  <div style={{display:'flex', marginTop:2}} >

                  <FormControl size="small" >

                  <TextField
                    id="input-inst-amnt-autowidth"
                    size='small'
                    type="number"
                    sx={{width:100}}
                    value={props.google_merchant_dialog_state.google_merchant.subscriptionCost.amount.value}
                    onChange={(event) => {const temp_cost = {...props.google_merchant_dialog_state.google_merchant.subscriptionCost}; temp_cost.amount.value=event.target.value;set_merchant_field('subscriptionCost', temp_cost)}}
                  >

                  </TextField>

                  </FormControl>

                  <FormControl size="small" sx={{minWidth:100, marginLeft:0.25}}>

                  <Select
                    id="select-inst-amnt-autowidth"
                    autoWidth
                    value={props.google_merchant_dialog_state.google_merchant.subscriptionCost.amount.currency}
                    onChange={(event) => {const temp_cost = {...props.google_merchant_dialog_state.google_merchant.subscriptionCost}; temp_cost.amount.currency=event.target.value;set_merchant_field('subscriptionCost', temp_cost)}}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      }
                    }}
                  >

                    {Object.keys(Currencies).map((currency, index) =>(

                    <MenuItem value={currency} key={index}>{currency}</MenuItem>
                    
                    ))}

                  </Select>

                  </FormControl>

                  </div>
                  
                  </Grid>



                </Grid> : <Button onClick={() => {set_merchant_field('subscriptionCost', default_subscription_cost)}}>Add</Button>}



              </Grid>   

              {/* next */}


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginLeft:{md:'auto'}, marginTop:{md:1}}}>Loyalty points</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2,width:16, height:16, marginTop:2, marginRight:2}}
              >

                <HelpOutlineIcon sx={{width:16, height:16, marginTop:{md:2}}} ></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:2} : {}}>

                {props.google_merchant_dialog_state.google_merchant.loyaltyPoints ? <Grid container>

                  <Grid item xs={12}>

                  <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >

                  <Typography fontSize={12} sx={{marginTop:1.8, marginLeft:0.5}}>Loyalty points name</Typography>

                  <TextField
                    id="input-ins-mon-language-autowidth"
                    size='small'
                    sx={{marginTop:0.2}}
                    inputProps={{style: {fontSize: 12}}}
                    value={props.google_merchant_dialog_state.google_merchant.loyaltyPoints.name}
                    onChange={(event) => {const temp_details = {...props.google_merchant_dialog_state.google_merchant.loyaltyPoints};temp_details.name = event.target.value; set_merchant_field('loyaltyPoints', temp_details)}}
                  >

                  </TextField>

                  </FormControl>

                  <IconButton sx={{marginTop:2}} component="span" onClick={() => {set_merchant_field('loyaltyPoints', null)}}>
                    <HighlightOffIcon />
                  </IconButton>

                  
                  </Grid>

                  <Grid item xs={12}>

                  <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}, marginBottom:2}} >

                  <Typography fontSize={12} sx={{marginTop:1.8, marginLeft:0.5}}>Loyalty points value</Typography>

                  <TextField
                    id="input-ins-mon-language-autowidth"
                    size='small'
                    sx={{marginTop:0.2}}
                    inputProps={{style: {fontSize: 12}}}
                    value={props.google_merchant_dialog_state.google_merchant.loyaltyPoints.pointsValue}
                    onChange={(event) => {const temp_details = {...props.google_merchant_dialog_state.google_merchant.loyaltyPoints};temp_details.pointsValue = event.target.value; set_merchant_field('loyaltyPoints', temp_details)}}
                  >

                  </TextField>

                  </FormControl>

                  </Grid>


                  <Grid item xs={12}>

                  <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}, marginBottom:2}} >

                  <Typography fontSize={12} sx={{marginTop:1.8, marginLeft:0.5}}>Loyalty points value</Typography>

                  <TextField
                    id="input-ins-mon-language-autowidth"
                    size='small'
                    sx={{marginTop:0.2}}
                    inputProps={{style: {fontSize: 12}}}
                    value={props.google_merchant_dialog_state.google_merchant.loyaltyPoints.ratio}
                    onChange={(event) => {const temp_details = {...props.google_merchant_dialog_state.google_merchant.loyaltyPoints};temp_details.ratio = event.target.value; set_merchant_field('loyaltyPoints', temp_details)}}
                  >

                  </TextField>

                  </FormControl>

                  </Grid>


                </Grid> : <Button onClick={() => {set_merchant_field('loyaltyPoints', default_loyality_points)}}>Add</Button>}



              </Grid>   


          </Grid>

        </Card>

        </Grid>


        {/* next */}

        <Grid
            item
            xs={12}
            
          >

        <Card elevation={2} style={{padding:20,overflow:'visible', backgroundColor:"white"}} >

          <Grid container 
            spacing={isLarge ? 2 : 1}
            style={{overflow:'visible'}}
            >

              <Grid item xs={12}>

                <Typography fontSize={14}>

                <strong>Detailed product description</strong>

                </Typography>

              </Grid>

             


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto',marginLeft:{md:'auto'}, marginBottom:'auto'}} >Condition</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}}>


              <Select
                id="select-condition-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.google_merchant.condition}
                onChange={(event) => {set_merchant_field('condition', event.target.value)}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {conditions.map((condition, index) =>(

                <MenuItem value={condition} key={index}>{condition}</MenuItem>
                
                ))}

              </Select>

              </FormControl>


              </Grid>

              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}}>I have an apparel or variant products</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControlLabel sx={{marginLeft:0}} control={<CustomSwitch checked={props.google_merchant_dialog_state.google_merchant.has_apparel} onChange={(event) => {set_merchant_field("has_apparel", event.target.checked)}}></CustomSwitch>}  />

              </Grid>  

             {props.google_merchant_dialog_state.google_merchant.has_apparel ? <Grid item xs={12}>
                
              <Grid container 
              spacing={isLarge ? 2 : 1}
              style={{overflow:'visible'}}
              >

              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}}>Colors</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2,width:16, height:16, marginRight:2}}
                >
  
                <HelpOutlineIcon sx={{width:16, height:16, marginTop:{md:0.8}}} ></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>


              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}, marginLeft:{md:0.2}}}>

                <ChippedTextField
                set_selected_item={(items) =>{set_merchant_field('colors',items)}}
                selected_item={props.google_merchant_dialog_state.google_merchant.colors}
                variant="outlined"
                id="tags"
                name="tags"
                helperText='Press enter to separate values'
                placeholder="Example: Gold, Silver"
                >


                </ChippedTextField>

      

              </FormControl>


              </Grid>  

              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}}>Sizes</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2,width:16, height:16, marginRight:2}}
                >

                <HelpOutlineIcon sx={{width:16, height:16, marginTop:{md:0.8}}} ></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>


              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}, marginLeft:{md:0.2}}}>

                <ChippedTextField
                set_selected_item={(items) =>{ set_merchant_field('sizes',items)}}
                selected_item={props.google_merchant_dialog_state.google_merchant.sizes}
                variant="outlined"
                id="tags"
                name="tags"
                helperText='Press Enter to separate values'
                placeholder="Example: XL, L, XS"
                >


                </ChippedTextField>



              </FormControl>


              </Grid>  


              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}}>Genders</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2,width:16, height:16, marginRight:2}}
                >
  
                <HelpOutlineIcon sx={{width:16, height:16, marginTop:{md:0.8}}} ></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>


              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}, marginLeft:{md:0.2}}}>

                <ChippedTextField
                set_selected_item={(items) =>{ set_merchant_field('genders',items)}}
                selected_item={props.google_merchant_dialog_state.google_merchant.genders}
                variant="outlined"
                id="tags"
                name="tags"
                helperText='Press Enter to separate values'
                placeholder="Example: M, F"
                >


                </ChippedTextField>

      

              </FormControl>


              </Grid>  


              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}}>Age groups</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2,width:16, height:16, marginRight:2}}
                >
  
                <HelpOutlineIcon sx={{width:16, height:16, marginTop:{md:0.8}}} ></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>


              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}, marginLeft:{md:0.2}}}>

                <ChippedTextField
                set_selected_item={(items) =>{ set_merchant_field('ageGroups',items)}}
                selected_item={props.google_merchant_dialog_state.google_merchant.ageGroups}
                variant="outlined"
                id="tags"
                name="tags"
                helperText='Press Enter to separate values'
                placeholder="Example: kids"
                >


                </ChippedTextField>

      

              </FormControl>


              </Grid>  


              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}}>Material</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2,width:16, height:16, marginRight:2}}
                >

                <HelpOutlineIcon sx={{width:16, height:16, marginTop:{md:0.8}}} ></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>


              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}, marginLeft:{md:0.2}}}>

                <ChippedTextField
                set_selected_item={(items) =>{ set_merchant_field('material',items)}}
                selected_item={props.google_merchant_dialog_state.google_merchant.material}
                variant="outlined"
                id="tags"
                name="tags"
                helperText='Press Enter to separate values'
                placeholder=""
                >


                </ChippedTextField>



              </FormControl>


              </Grid>  


              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}}>Pattern</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2,width:16, height:16, marginRight:2}}
                >

                <HelpOutlineIcon sx={{width:16, height:16, marginTop:{md:0.8}}} ></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>


              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}, marginLeft:{md:0.2}}}>

                <ChippedTextField
                set_selected_item={(items) =>{ set_merchant_field('pattern',items)}}
                selected_item={props.google_merchant_dialog_state.google_merchant.pattern}
                variant="outlined"
                id="tags"
                name="tags"
                helperText='Press Enter to separate values'
                placeholder=""
                >


                </ChippedTextField>



              </FormControl>


              </Grid>  


             
                

              </Grid>

              </Grid> : null }



              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto',marginLeft:{md:'auto'}, marginBottom:'auto'}} >Adult</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>


              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControlLabel style={{padding:0}} control={<Checkbox size="small" checked={props.google_merchant_dialog_state.google_merchant.adult} onChange={(event) =>{set_merchant_field("adult", event.target.checked)}} />} />

              </Grid>

              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}} >Multipack</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>


              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >


              <TextField
                id="input-gtin-language-autowidth"
                size='small'
                inputProps={{style: {fontSize: 12}}}
                value={props.google_merchant_dialog_state.google_merchant.multipack}
                onChange={(event) => {set_merchant_field('multipack', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>  

              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>

              <Typography fontSize={14} sx={{marginTop:'auto',marginLeft:{md:'auto'}, marginBottom:'auto'}} >Is bundle</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControlLabel style={{padding:0}} control={<Checkbox size="small" checked={props.google_merchant_dialog_state.google_merchant.isBundle} onChange={(event) =>{set_merchant_field("isBundle", event.target.checked)}} />} />

              </Grid>

              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginLeft:{md:'auto'}, marginBottom:'auto'}} >Size type</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2,width:16, height:16, marginRight:4}}
                >
  
                <HelpOutlineIcon sx={{width:16, height:16, marginTop:{md:0.8}}} ></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}}>

                {props.google_merchant_dialog_state.google_merchant.sizeTypes.map((size_type, index) => {

                  return( <FormControlLabel key={index} style={{padding:0}} control={<Checkbox size="small" checked={size_type.is_checked} onChange={(event) =>{const temp_types = [...props.google_merchant_dialog_state.google_merchant.sizeTypes];temp_types[index].is_checked = event.target.checked;set_merchant_field("sizeTypes", temp_types)}} />} label={<Typography fontSize={12}>{size_type.value}</Typography>} />)

                })}

              </FormControl>

              </Grid>

              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto',marginLeft:{md:'auto'}, marginBottom:'auto'}} >Size system</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}}>


              <Select
                id="select-size-system-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.google_merchant.sizeSystem}
                onChange={(event) => {set_merchant_field('sizeSystem', event.target.value)}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {size_systems.map((size_system, index) =>(

                <MenuItem value={size_system} key={index}>{size_system}</MenuItem>
                
                ))}

              </Select>

              </FormControl>

              </Grid>


              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}} >Item group ID</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>


              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >


              <TextField
                id="input-itemGroupId-language-autowidth"
                size='small'
                inputProps={{style: {fontSize: 12}}}
                placeholder="Leave blank to auto create id from shopify"
                value={props.google_merchant_dialog_state.google_merchant.itemGroupId}
                onChange={(event) => {set_merchant_field('itemGroupId', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>  


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginLeft:{md:'auto'}, marginTop:{md:1}}}>Product highlight</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2,width:16, height:16, marginTop:2}}
              >

                <HelpOutlineIcon sx={{width:16, height:16, marginTop:{md:2}}} ></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:2} : {}}>

              <Grid container direction="column"
              justifyContent="center"
              alignItems="stretch">

                {props.google_merchant_dialog_state.google_merchant.productHighlights.map((product_highlight, index) => {

                return( 

                <Grid item key={index} xs={12}>

                <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}, marginBottom:1}} >

                <TextField
                  id="input-productHighlights-language-autowidth"
                  size='small'
                  inputProps={{style: {fontSize: 12}}}
                  value={props.google_merchant_dialog_state.google_merchant.productHighlights[index]}
                  onChange={(event) => {const temp_highlights = [...props.google_merchant_dialog_state.google_merchant.productHighlights];temp_highlights[index] = event.target.value;set_merchant_field('productHighlights', temp_highlights)}}
                >

                </TextField>

                </FormControl>

                {index > 0 ?

                <IconButton  component="span" onClick={() => {const temp_highlights = [...props.google_merchant_dialog_state.google_merchant.productHighlights];temp_highlights.pop(index);set_merchant_field('productHighlights', temp_highlights)}}>
                  <HighlightOffIcon />
                </IconButton>

                : null}
                
                </Grid>

                )

                })}


              </Grid>



              <Button onClick={()=>{const temp_highlights= [...props.google_merchant_dialog_state.google_merchant.productHighlights];temp_highlights.push(''); set_merchant_field('productHighlights', temp_highlights)}}>Add</Button>



              </Grid>   


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto',marginLeft:{md:'auto'}, marginBottom:'auto'}} >Energy efficiency class</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}}>


              <Select
                id="select-energy-efficiency-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.google_merchant.energyEfficiencyClass}
                onChange={(event) => {set_merchant_field('energyEfficiencyClass', event.target.value)}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {energy_efficiencies.map((energy_efficiency, index) =>(

                <MenuItem value={energy_efficiency} key={index}>{energy_efficiency}</MenuItem>
                
                ))}

              </Select>

              </FormControl>

              </Grid>


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto',marginLeft:{md:'auto'}, marginBottom:'auto'}}>Max energy efficiency class</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}}>


              <Select
                id="select-max-energy-efficiency-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.google_merchant.maxEnergyEfficiencyClass}
                onChange={(event) => {set_merchant_field('maxEnergyEfficiencyClass', event.target.value)}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {energy_efficiencies.map((energy_efficiency, index) =>(

                <MenuItem value={energy_efficiency} key={index}>{energy_efficiency}</MenuItem>
                
                ))}

              </Select>

              </FormControl>

              </Grid>


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto',marginLeft:{md:'auto'}, marginBottom:'auto'}}>Min energy efficiency class</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}}>


              <Select
                id="select-max-energy-efficiency-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.google_merchant.minEnergyEfficiencyClass}
                onChange={(event) => {set_merchant_field('minEnergyEfficiencyClass', event.target.value)}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {energy_efficiencies.map((energy_efficiency, index) =>(

                <MenuItem value={energy_efficiency} key={index}>{energy_efficiency}</MenuItem>
                
                ))}

              </Select>

              </FormControl>

              </Grid>


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginLeft:{md:'auto'}, marginTop:{md:1}}}>Product detail</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2,width:16, height:16, marginTop:2}}
              >

                <HelpOutlineIcon sx={{width:16, height:16, marginTop:{md:2}}} ></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:2} : {}}>

                <Grid container>

                  {props.google_merchant_dialog_state.google_merchant.productDetails.map((product_detail, index) => {

                  return( 

                  <Grid item key={index} xs={12}>

                  <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}, marginBottom:2}} >

                  <Typography fontSize={12} sx={{marginTop:1, marginLeft:0.5}}>Product detail section name</Typography>

                  <TextField
                    id="input-pd-sn-language-autowidth"
                    size='small'
                    sx={{marginTop:0.2}}
                    inputProps={{style: {fontSize: 12}}}
                    value={product_detail.sectionName}
                    onChange={(event) => {const temp_details = [...props.google_merchant_dialog_state.google_merchant.productDetails];temp_details[index].sectionName = event.target.value;set_merchant_field('productDetails', temp_details)}}
                  >

                  </TextField>

                  <Typography fontSize={12} sx={{marginTop:1, marginLeft:0.5}}>Product detail attribute name</Typography>
                  <TextField
                    id="input-pd-an-language-autowidth"
                    size='small'
                    sx={{marginTop:0.2}}
                    inputProps={{style: {fontSize: 12}}}
                    value={product_detail.attributeName}
                    onChange={(event) => {const temp_details = [...props.google_merchant_dialog_state.google_merchant.productDetails];temp_details[index].attributeName = event.target.value;set_merchant_field('productDetails', temp_details)}}
                  >

                  </TextField>

                  <Typography fontSize={12} sx={{marginTop:1, marginLeft:0.5}}>Product detail attribute value</Typography>
                  <TextField
                    id="input-pd-av-autowidth"
                    size='small'
                    sx={{marginTop:0.2}}
                    inputProps={{style: {fontSize: 12}}}
                    value={product_detail.attributeValue}
                    onChange={(event) => {const temp_details = [...props.google_merchant_dialog_state.google_merchant.productDetails];temp_details[index].attributeValue = event.target.value;set_merchant_field('productDetails', temp_details)}}
                  >

                  </TextField>


                  </FormControl>

                  {index > 0 ?

                  <IconButton sx={{marginTop:2}} component="span" onClick={() => {const temp_details = [...props.google_merchant_dialog_state.google_merchant.productDetails];temp_details.pop(index);set_merchant_field('productDetails', temp_details)}}>
                    <HighlightOffIcon />
                  </IconButton>

                  : null}
                  
                  </Grid>

                  )

                  })}


                </Grid>



                <Button onClick={() => {const temp_details = [...props.google_merchant_dialog_state.google_merchant.productDetails];temp_details.push({sectionName: '', attributeName: '', attributeValue: ''}); set_merchant_field('productDetails', temp_details)}}>Add</Button>



              </Grid>   



          </Grid>

          </Card>

          </Grid>

        {/* next */}

        <Grid
            item
            xs={12}
            
          >

        <Card elevation={2} style={{padding:20,overflow:'visible', backgroundColor:"white"}} >

          <Grid container 
            spacing={isLarge ? 2 : 1}
            style={{overflow:'visible'}}
            >

              <Grid item xs={12}>

                <Typography fontSize={14}>

                <strong>Shipping, tax, and returns</strong>

                </Typography>

              </Grid>

              {props.google_merchant_dialog_state.shippings.services && props.google_merchant_dialog_state.shippings.services.length > 0 ? <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto',marginLeft:{md:'auto'}, marginBottom:'auto'}}>Shipping class</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

                </Grid> : null}

             {props.google_merchant_dialog_state.shippings.services && props.google_merchant_dialog_state.shippings.services.length > 0  ? <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>


              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}}>

              <Select
                id="select-energy-efficiency-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.shippings.checked_index != null ? props.google_merchant_dialog_state.shippings.checked_index : ""}
                onChange={(event) => {const temp_services = props.google_merchant_dialog_state.shippings; temp_services.checked_index = event.target.value; set_merchant_field('shippings', temp_services)}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {props.google_merchant_dialog_state.shippings.services.map((shipping_service, index) =>(

                <MenuItem value={index} key={index}>{shipping_service.name}</MenuItem>
                
                ))}

              </Select>

              </FormControl>

              {props.google_merchant_dialog_state.shippings.checked_index != null && props.google_merchant_dialog_state.shippings.checked_index > -1 ?

              <IconButton  component="span" onClick={() => {const temp_services = props.google_merchant_dialog_state.shippings; temp_services.checked_index = null; set_merchant_field('shippings', temp_services)}}>
                <HighlightOffIcon />
              </IconButton>

              : null}


              </Grid> : null}

          

              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto',marginLeft:{md:'auto'}, marginBottom:'auto'}}>Shipping label</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >

              <TextField
                id="input-mpn-language-autowidth"
                size='small'
                inputProps={{style: {fontSize: 12}}}
                value={props.google_merchant_dialog_state.google_merchant.shippingLabel}
                onChange={(event) => {set_merchant_field('shippingLabel', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>

 

              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}} >Shipping weight</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <div style={{display:'flex'}}>

              <FormControl size="small" >

              <TextField
                id="input-mpn-language-autowidth"
                size='small'
                type="number"

                sx={{width:100}}
                value={props.google_merchant_dialog_state.google_merchant.shippingWeight.value}
                onChange={(event) => {set_merchant_field('shippingWeight', {"value": event.target.value, "unit": props.google_merchant_dialog_state.google_merchant.shippingWeight.unit})}}
              >

              </TextField>

              </FormControl>

              <FormControl size="small" sx={{minWidth:100, marginLeft:0.25}}>

              <Select
                id="select-countries-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.google_merchant.shippingWeight.unit}
                onChange={(event) => {set_merchant_field('shippingWeight', {"value": props.google_merchant_dialog_state.google_merchant.shippingWeight.value, "unit": event.target.value})}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {Metrics.map((metric, index) =>(

                <MenuItem value={metric} key={index}>{metric}</MenuItem>
                
                ))}

              </Select>

              </FormControl>

              </div>


              </Grid>
                  

              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}} >Shipping length</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <div style={{display:'flex'}}>

              <FormControl size="small" >

              <TextField
                id="input-shipping-length-autowidth"
                size='small'
                type="number"
                sx={{width:100}}
                value={props.google_merchant_dialog_state.google_merchant.shippingLength.value}
                onChange={(event) => {set_merchant_field('shippingLength', {"value": event.target.value, "unit": props.google_merchant_dialog_state.google_merchant.shippingLength.unit})}}
              >

              </TextField>

              </FormControl>

              <FormControl size="small" sx={{minWidth:100, marginLeft:0.25}}>

              <Select
                id="select-shp-ln-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.google_merchant.shippingLength.unit}
                onChange={(event) => {set_merchant_field('shippingLength', {"value": props.google_merchant_dialog_state.google_merchant.shippingLength.value, "unit": event.target.value})}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {length_units.map((unit, index) =>(

                <MenuItem value={unit} key={index}>{unit}</MenuItem>
                
                ))}

              </Select>

              </FormControl>

              </div>


              </Grid>


              <Grid item xs={12} md={3.4} >

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}} >Shipping width</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <div style={{display:'flex'}}>

              <FormControl size="small" >

              <TextField
                id="input-shipping-length-autowidth"
                size='small'
                type="number"
                sx={{width:100}}
                value={props.google_merchant_dialog_state.google_merchant.shippingWidth.value}
                onChange={(event) => {set_merchant_field('shippingWidth', {"value": event.target.value, "unit": props.google_merchant_dialog_state.google_merchant.shippingWidth.unit})}}
              >

              </TextField>

              </FormControl>

              <FormControl size="small" sx={{minWidth:100, marginLeft:0.25}}>

              <Select
                id="select-shp-ln-autowidth"
                autoWidth
                value={props.google_merchant_dialog_state.google_merchant.shippingWidth.unit}
                onChange={(event) => {set_merchant_field('shippingWidth', {"value": props.google_merchant_dialog_state.google_merchant.shippingWidth.value, "unit": event.target.value})}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
              >

                {length_units.map((unit, index) =>(

                <MenuItem value={unit} key={index}>{unit}</MenuItem>
                
                ))}

              </Select>

              </FormControl>

              </div>


            </Grid>


            <Grid item xs={12} md={3.4} >

            <div style={{display:'flex', height:'100%', width:'100%'}}>


            <Typography fontSize={14} sx={{marginTop:'auto', marginLeft:{md:'auto'},textAlign:{md:'end'}, marginBottom:'auto'}} >Shipping height</Typography>


            <IconButton
              edge="start"
              aria-label="close"
              size='small'
              style={{marginLeft:2}}
            >

              <HelpOutlineIcon style={{ width:16, height:16}}></HelpOutlineIcon>

            </IconButton>

            </div>

            </Grid>

            <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

            <div style={{display:'flex'}}>

            <FormControl size="small" >

            <TextField
              id="input-shipping-length-autowidth"
              size='small'
              type="number"
              sx={{width:100}}
              value={props.google_merchant_dialog_state.google_merchant.shippingHeight.value}
              onChange={(event) => {set_merchant_field('shippingHeight', {"value": event.target.value, "unit": props.google_merchant_dialog_state.google_merchant.shippingHeight.unit})}}
            >

            </TextField>

            </FormControl>

            <FormControl size="small" sx={{minWidth:100, marginLeft:0.25}}>

            <Select
              id="select-shp-ln-autowidth"
              autoWidth
              value={props.google_merchant_dialog_state.google_merchant.shippingHeight.unit}
              onChange={(event) => {set_merchant_field('shippingHeight', {"value": props.google_merchant_dialog_state.google_merchant.shippingHeight.value, "unit": event.target.value})}}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                }
              }}
            >

              {length_units.map((unit, index) =>(

              <MenuItem value={unit} key={index}>{unit}</MenuItem>
              
              ))}

            </Select>

            </FormControl>

            </div>


            </Grid>


            <Grid item xs={12} md={3.4}>

            <div style={{display:'flex', height:'100%', width:'100%'}}>


            <Typography fontSize={14} sx={{marginTop:'auto',marginLeft:{md:'auto'}, marginBottom:'auto'}}>Transit time label</Typography>


            <IconButton
              edge="start"
              aria-label="close"
              size='small'
              style={{marginLeft:2}}
            >

              <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

            </IconButton>

            </div>

            </Grid>

            <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

            <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >

            <TextField
              id="input-tt-lbl-autowidth"
              size='small'
              inputProps={{style: {fontSize: 12}}}
              value={props.google_merchant_dialog_state.google_merchant.transitTimeLabel}
              onChange={(event) => {set_merchant_field('transitTimeLabel', event.target.value)}}
            >

            </TextField>

            </FormControl>

            </Grid>

            <Grid item xs={12} md={3.4}>

            <div style={{display:'flex', height:'100%', width:'100%'}}>


            <Typography fontSize={14} sx={{marginTop:'auto',marginLeft:{md:'auto'}, marginBottom:'auto'}}>Min handling time</Typography>


            <IconButton
              edge="start"
              aria-label="close"
              size='small'
              style={{marginLeft:2}}
            >

              <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

            </IconButton>

            </div>

            </Grid>

            <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

            <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >

            <TextField
              id="input-mn-hnd-autowidth"
              size='small'
              inputProps={{style: {fontSize: 12}}}
              value={props.google_merchant_dialog_state.google_merchant.minHandlingTime}
              onChange={(event) => {set_merchant_field('minHandlingTime', event.target.value)}}
            >

            </TextField>

            </FormControl>
            
            </Grid>


            <Grid item xs={12} md={3.4}>

            <div style={{display:'flex', height:'100%', width:'100%'}}>


            <Typography fontSize={14} sx={{marginTop:'auto',marginLeft:{md:'auto'}, marginBottom:'auto'}}>Max handling time</Typography>


            <IconButton
              edge="start"
              aria-label="close"
              size='small'
              style={{marginLeft:2}}
            >

              <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

            </IconButton>

            </div>

            </Grid>

            <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

            <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >

            <TextField
              id="input-mn-hnd-autowidth"
              size='small'
              inputProps={{style: {fontSize: 12}}}
              value={props.google_merchant_dialog_state.google_merchant.maxHandlingTime}
              onChange={(event) => {set_merchant_field('maxHandlingTime', event.target.value)}}
            >

            </TextField>

            </FormControl>

            </Grid>
                        
                          


          </Grid>

        </Card>

        </Grid>



        {/* next */}


        <Grid
            item
            xs={12}
            
          >

        <Card elevation={2} style={{padding:20,overflow:'visible', backgroundColor:"white"}} >

          <Grid container 
            spacing={isLarge ? 2 : 1}
            style={{overflow:'visible'}}
            >

              <Grid item xs={12}>

                <Typography fontSize={14}>

                <strong>Shopping campaigns and other configurations</strong>

                </Typography>

              </Grid>

             


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto',marginLeft:{md:'auto'}, marginBottom:'auto'}} >Ads redirect</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >

              <TextField
                id="input-mpn-language-autowidth"
                size='small'
                value={props.google_merchant_dialog_state.google_merchant.adsRedirect}
                onChange={(event) => {set_merchant_field('adsRedirect', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>



              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginLeft:{md:'auto'}, marginTop:{md:1}}}>Promotion ID</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2,width:16, height:16, marginTop:2}}
              >

                <HelpOutlineIcon sx={{width:16, height:16, marginTop:{md:2}}} ></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:2} : {}}>

                <Grid container direction="column"
                justifyContent="center"
                alignItems="stretch">

                  {props.google_merchant_dialog_state.google_merchant.promotionIds.map((promotion_id, index) => {

                  return( 

                  <Grid item key={index} xs={12}>

                  <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}, marginBottom:1}} >

                  <TextField
                    id="input-gtin-language-autowidth"
                    size='small'
                    value={props.google_merchant_dialog_state.google_merchant.promotionIds[index]}
                    onChange={(event) => {const temp_promotions = [...props.google_merchant_dialog_state.google_merchant.promotionIds];temp_promotions[index] = event.target.value;set_merchant_field('promotionIds', temp_promotions)}}
                  >

                  </TextField>

                  </FormControl>

                  {index > 0 ?

                  <IconButton aria-label="upload picture" component="span" onClick={() => {const temp_promotions = [...props.google_merchant_dialog_state.google_merchant.promotionIds];temp_promotions.pop(index);set_merchant_field('promotionIds', temp_promotions)}}>
                    <HighlightOffIcon />
                  </IconButton>

                  : null}
                  
                  </Grid>

                  )

                  })}


                </Grid>

          

                <Button onClick={()=>{const temp_promotions = [...props.google_merchant_dialog_state.google_merchant.promotionIds];temp_promotions.push(''); set_merchant_field('promotionIds', temp_promotions)}}>Add promotion id</Button>

      

              </Grid>   


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginBottom:'auto', marginLeft:{md:'auto'}}}>Custom label 0</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >


              <TextField
                id="input-gtin-language-autowidth"
                size='small'
                value={props.google_merchant_dialog_state.google_merchant.customLabel0}
                onChange={(event) => {set_merchant_field('customLabel0', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>  


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginBottom:'auto', marginLeft:{md:'auto'}}}>Custom label 1</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >


              <TextField
                id="input-gtin-language-autowidth"
                size='small'
                value={props.google_merchant_dialog_state.google_merchant.customLabel1}
                onChange={(event) => {set_merchant_field('customLabel1', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>  


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginBottom:'auto', marginLeft:{md:'auto'}}}>Custom label 2</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >


              <TextField
                id="input-gtin-language-autowidth"
                size='small'
                value={props.google_merchant_dialog_state.google_merchant.customLabel2}
                onChange={(event) => {set_merchant_field('customLabel2', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>  



              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginBottom:'auto', marginLeft:{md:'auto'}}}>Custom label 3</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>

              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >


              <TextField
                id="input-gtin-language-autowidth"
                size='small'
                value={props.google_merchant_dialog_state.google_merchant.customLabel3}
                onChange={(event) => {set_merchant_field('customLabel3', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>  



              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto', marginBottom:'auto', marginLeft:{md:'auto'}}}>Custom label 4</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >


              <TextField
                id="input-gtin-language-autowidth"
                size='small'
                value={props.google_merchant_dialog_state.google_merchant.customLabel4}
                onChange={(event) => {set_merchant_field('customLabel4', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>  
                  
                  
                  
          </Grid>

        </Card>

        </Grid>

          {/* next */}


          <Grid
            item
            xs={12}
            
          >

        <Card elevation={2} style={{padding:20,overflow:'visible', backgroundColor:"white"}} >

          <Grid container 
            spacing={isLarge ? 2 : 1}
            style={{overflow:'visible'}}
            >

              <Grid item xs={12}>

                <Typography fontSize={14}>

                <strong>Product category</strong>

                </Typography>

              </Grid>

             


              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginTop:'auto',marginLeft:{md:'auto'}, marginBottom:'auto'}}>Google product category</Typography>


              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2}}
              >

                <HelpOutlineIcon  style={{ width:16, height:16}}></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:0} : {}}>

              <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}}} >

              <TextField
                id="input-mpn-language-autowidth"
                size='small'
                value={props.google_merchant_dialog_state.google_merchant.googleProductCategory}
                onChange={(event) => {set_merchant_field('googleProductCategory', event.target.value)}}
              >

              </TextField>

              </FormControl>


              </Grid>



              <Grid item xs={12} md={3.4}>

              <div style={{display:'flex', height:'100%', width:'100%'}}>


              <Typography fontSize={14} sx={{marginLeft:{md:'auto'}, marginTop:{md:1}}}>Product type</Typography>

              <IconButton
                edge="start"
                aria-label="close"
                size='small'
                style={{marginLeft:2,width:16, height:16, marginTop:2}}
              >

                <HelpOutlineIcon sx={{width:16, height:16, marginTop:{md:2}}} ></HelpOutlineIcon>

              </IconButton>
            
              </div>

              </Grid>

              <Grid item xs={12} md={8.6} style={isLarge ? {paddingLeft:2} : {}}>

                <Grid container direction="column"
                justifyContent="center"
                alignItems="stretch">

                  {props.google_merchant_dialog_state.google_merchant.productTypes.map((product_type, index) => {

                  return( 

                  <Grid item key={index} xs={12}>

                  <FormControl size="small" fullWidth={!isLarge} sx={{width:{md:'60%'}, marginBottom:1}} >

                  <TextField
                    id="input-gtin-language-autowidth"
                    size='small'
                    value={props.google_merchant_dialog_state.google_merchant.productTypes[index]}
                    onChange={(event) => {const temp_promotions = [...props.google_merchant_dialog_state.google_merchant.productTypes];temp_promotions[index] = event.target.value;set_merchant_field('productTypes', temp_promotions)}}
                  >

                  </TextField>

                  </FormControl>

                  {index > 0 ?

                  <IconButton component="span" onClick={() => {const temp_promotions = [...props.google_merchant_dialog_state.google_merchant.productTypes];temp_promotions.pop(index);set_merchant_field('productTypes', temp_promotions)}}>
                    <HighlightOffIcon />
                  </IconButton>

                  : null}
                  
                  </Grid>

                  )

                  })}


                </Grid>

          

                <Button onClick={()=>{const temp_types = [...props.google_merchant_dialog_state.google_merchant.productTypes];temp_types.push(''); set_merchant_field('productTypes', temp_types)}}>Add product type</Button>

      

              </Grid>   

                  
                  
          </Grid>

        </Card>

        </Grid>
        
        </Grid>

        </Container>

        </Box>

      </Dialog>
    </div>
   
  );
}


export default GoogleMerchantDialog;

/*
<div>
<Dialog
  open={props.google_merchant_dialog_state.is_dialog_open}
  onClose={handle_close}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  fullScreen
>
  <DialogTitle id="alert-dialog-title">
  <Button size="large" startIcon={<GoogleIcon />}>
    Merchant
  </Button>
  </DialogTitle>

  <DialogContent dividers>


    <Grid
      container
      spacing={3}
      alignItems="center"
      >

     <Grid item xs={12}>


     </Grid>

      
      </Grid>


  </DialogContent>
  <DialogActions>
  <Button size="small" onClick={handle_close} variant='outlined'>{"Close"}</Button>
  <Button size="small" onClick={handle_save} variant='outlined'>{"Save"}</Button>
  </DialogActions>
</Dialog>
</div>}

*/