import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../store/cms-context';

import { getAccessJwtToken } from '../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import StyleDialog from "./SubComponents/StyleDialog";

import StylesListTable from "./SubComponents/StylesListTable";

import { default_create_product, default_product_style } from "../../Utilities/Metrics";

import cloneDeep from 'lodash/cloneDeep';
import CreateProductDialog from "./SubComponents/CreateProductDialog";


function ProductsStyles(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_styles, set_all_styles] = React.useState([]);

  const [style_dialog_state, set_style_dialog_state] = React.useState(cloneDeep(default_product_style));

  const [create_product_dialog_state, set_create_product_dialog_state] = React.useState(cloneDeep(default_create_product));

  useEffect(() => {

    isMountedRef.current = true

    get_all_styles()

    return () => isMountedRef.current = false;

  }, [])


 

  function show_create_style_dialog(){
    
    const temp_dialog_state = cloneDeep(default_product_style);

    temp_dialog_state.is_dialog_open = true

    set_style_dialog_state(temp_dialog_state);

  }

  useEffect(() =>{

    console.log('state was: ', style_dialog_state)

  },[style_dialog_state])



  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Styles</Typography>
          
          <div>

          <Button variant="contained" onClick = {() => {show_create_style_dialog()}}>Create Style</Button>

          </div>

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >

          <StylesListTable delete_styles={delete_styles} set_create_product_dialog={set_create_product_dialog} set_dialog_style={set_dialog_style} all_styles={all_styles} set_all_styles={set_all_styles}>

          </StylesListTable>

      </Grid>


      </Grid>

    </Container>      


    </Box>

     }

     <StyleDialog delete_variants={delete_variants} post_style={post_style} style_dialog_state={style_dialog_state} set_style_dialog_state={set_style_dialog_state}></StyleDialog>

     <CreateProductDialog post_product={post_product} create_product_dialog_state={create_product_dialog_state} set_create_product_dialog_state={set_create_product_dialog_state}></CreateProductDialog>

    </>


  );

  function delete_variants(){

    const temp_state = {...style_dialog_state}

    if(temp_state.type === "Update"){

      post_style(temp_state, "delete_variants")

    }else{

      remove_checked_variants()

    }

  }

  function remove_checked_variants(){

    const new_variants = []

    const temp_state = {...style_dialog_state}

    temp_state.all_variants.forEach((variant) => {

      if(!variant.is_checked){
        new_variants.push(variant)
      }

    })

    temp_state.all_variants = new_variants

    set_style_dialog_state(temp_state)

  }

  function set_dialog_style(style, is_open) {

    console.log('setting style to update: ', style)

    const temp_style_state = cloneDeep(style)

    temp_style_state.style.active_from = new Date(temp_style_state.style.active_from)

    if(temp_style_state.style.active_to){

      temp_style_state.style.active_to = new Date(temp_style_state.style.active_to)

    }

    //temp_style_state.style = style

    temp_style_state.is_dialog_open = is_open

    temp_style_state.type = "Update"

    set_style_dialog_state(temp_style_state)

  }

  function set_create_product_dialog(style, is_open){

    console.log('style was: ', style)

    const temp_style_state = cloneDeep(default_create_product)

    var temp_style = cloneDeep(style)

    for (var key of Object.keys(temp_style)) {

      if(key !== "style"){

        temp_style_state[key] = temp_style[key]

      }else{

        for (var key2 of Object.keys(temp_style.style)) {
          temp_style_state.style[key2] = temp_style.style[key2]
        }
        
      }

    }

    temp_style_state.type = "Create"

    temp_style_state.is_dialog_open = is_open

    set_create_product_dialog_state(temp_style_state)

  }

  function get_all_styles() {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/products/styles',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':jwt,
            'email':cmsContext.current_shop.email
          }})
          .then(res => res.json())
          .then(
            (result) => {
              

              setIsLoading(false)
  
              console.log('result was: ', result)

             
              if(result.styles){

                console.log('setting analuytis')

                if(result.styles.length === 0){

                  cmsContext.setSnackBarOpen(true, "info", "No styles yet. Let's create one to get started.")

                  return

                }


                const all_styles = []

                var temp_style;

                result.styles.forEach((style) =>{

                  temp_style = cloneDeep(default_product_style)

                  for(var key in style){
                    temp_style[key] = style[key]
                  }

                  temp_style.is_checked = false

                  temp_style.all_variants.forEach((variant) =>{
                    variant.is_checked = false
                  })

                  console.log('temp style was: ', temp_style)
                  
                  all_styles.push(temp_style)

                })

                set_all_styles(all_styles)

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }

              
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
           
      )

    })


  }


  function get_create_style_data() {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/products/styles/create',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':jwt,
            'email':cmsContext.current_shop.email
          }})
          .then(res => res.json())
          .then(
            (result) => {
              

              setIsLoading(false)
  
              console.log('result was',result)

             
              if(result.variant_styles){

                console.log('setting analuytis')

                const temp_dialog_state = cloneDeep(default_product_style);

                temp_dialog_state.is_dialog_open = true

                result.variant_styles.forEach((style) =>{
                  style.is_checked = false
                })

                temp_dialog_state.all_variants = result.variant_styles

                set_style_dialog_state(temp_dialog_state);

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }

              
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
           
      )

    })


  }

  function set_dialog_loading(is_loading){

    const temp_style_state = {...style_dialog_state}

    temp_style_state.isLoading = is_loading

    set_style_dialog_state(temp_style_state)

  }

  function set_product_dialog_loading(is_loading){

    const temp_style_state = {...create_product_dialog_state}

    temp_style_state.isLoading = is_loading

    console.log('new state was: ', temp_style_state)

    set_create_product_dialog_state(temp_style_state)

  }

  function reset_dialog_state(){

    set_style_dialog_state(cloneDeep(default_product_style))

  }

  function reset_product_dialog_state(){

    set_create_product_dialog_state(cloneDeep(default_create_product))

  }

  function post_style(style, request_type){

    set_dialog_loading(true)

    console.log('updating', style)

    var request_body = {}

    if(request_type === "delete_variants"){

      const variant_ids = []

      const temp_state = {...style_dialog_state}

      temp_state.all_variants.forEach((variant) => {

        if(variant.is_checked && variant.variant_id > -1){
          variant_ids.push(variant.variant_id)
        }

      })

      if(variant_ids.length > 0){

      request_body.delete_ids = variant_ids

      }else{

        remove_checked_variants()

        return

      }

    }else{

    request_body.style = style.style

    request_body.all_variants = style.all_variants

    }

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)

    getAccessJwtToken().then((jwt) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/products/styles', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                if(request_type === "delete_variants"){

                  set_dialog_loading(false)

                  remove_checked_variants()

                }else{

                  reset_dialog_state()

                  get_all_styles()

                }
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                set_dialog_loading(false)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
                set_dialog_loading(false)

              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })

  }

  function post_product(style, request_type){

    set_product_dialog_loading(true)

    console.log('updating')
    
    var request_body = {}

    request_body.product = style.product

    request_body.all_variants = style.all_variants

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)

    getAccessJwtToken().then((jwt) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/products/create', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt,
            'store_domain':cmsContext.current_shop.store_domain
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                reset_product_dialog_state()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                set_product_dialog_loading(false)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
                set_product_dialog_loading(false)

              }
  
            },
        
            (error) => {
              set_product_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })

  }

  function delete_styles(){

    console.log('delete styles')

    var temp_styles = [...all_styles].filter((style) => style.is_checked);

    console.log('filtered were', temp_styles)

    if(temp_styles.length === 0){
      // might never execute (need to select styles to display delete button)
      cmsContext.setSnackBarOpen(true, "error", "Please select valid styles!")
      return;
    }else if(temp_styles.length > 20){
      cmsContext.setSnackBarOpen(true, "error", "Please select a maximum of 20 styles!")
      return;
    }

    temp_styles = temp_styles.map((style) => {

      return({'id': style.id})

    })

    console.log('final styles were', temp_styles)

    const request_body = {}

    request_body.styles = temp_styles

    request_body.request_type = "delete"

    request_body.account_email = cmsContext.current_shop.email
    

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{


      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/styles',{
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  console.log('result was',result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_all_styles()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {
                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)
                }
          );
  
      });

  }


};


export default ProductsStyles;