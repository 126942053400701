import { Grid, Paper, TextField, FormControlLabel, FormHelperText, OutlinedInput , Checkbox, FormControl, Typography, InputLabel, InputAdornment, Divider} from '@mui/material';


import React from 'react';



function Pricing(props){

    // console.log('Pricing render')


    return(

      <Paper elevation = {2} style={{marginTop:20, padding:20}}>
  
        <Grid
          container
          spacing={3}
          alignItems="center"
        >

          <Grid
            item
            xs={12}
          >
        
           <Typography variant='h6'>Pricing</Typography>
        
          </Grid>

          <Grid 
          item
          xs={12}
          lg={6}
          >

          <FormControl fullWidth >

          <InputLabel htmlFor="outlined-adornment-price">Price</InputLabel>

          <OutlinedInput
            id="outlined-adornment-price"
            placeholder='0.00'
            startAdornment={<InputAdornment position="start">{props.store_currency}</InputAdornment>}
            label="Price"
            type="number"
            value={props.price}
            onChange={(event) => {props.set_price(event.target.value)}}
          />

        </FormControl>

          </Grid>

          <Grid 
          item
          xs={12}
          lg={6}
          >

          <FormControl fullWidth >

          <InputLabel htmlFor="outlined-adornment-compare-at-price">Compare at price</InputLabel>

          <OutlinedInput
            id="outlined-adornment-compare-at-price"
            placeholder='0.00'
            type="number"
            startAdornment={<InputAdornment position="start">{props.store_currency}</InputAdornment>}
            value={props.compare_at_price}
            onChange={(event) => {props.set_compare_at_price(event.target.value)}}
            label="Compare at price"
          />

        </FormControl>


          </Grid>

         

          <Grid 
          item
          xs={12}

          >
        <Divider />

          </Grid>


          <Grid 
          item
          xs={12}

          >


        <FormControl fullWidth >

        <InputLabel htmlFor="outlined-adornment-cost-per-item">Cost per item</InputLabel>

        <OutlinedInput
          id="outlined-adornment-compare-cost-per-item"
          placeholder='0.00'
          type="number"
          startAdornment={<InputAdornment position="start">{props.store_currency}</InputAdornment>}
          label="Cost per item"
          value={props.cost_per_item}
          onChange={(event) => {props.set_cost_per_item(event.target.value)}}
        />

          <FormHelperText>Customers wont see this</FormHelperText>

        </FormControl>

              
            </Grid>

            <Grid 
          item
          xs={12}

          >
        <Divider />

          </Grid>

          

          <Grid 
          item
          xs={12}

          >
         <FormControlLabel control={<Checkbox checked={props.charge_tax} onChange={(event) => {props.set_charge_tax(event.target.checked)}}/>} label="Charge tax on this product" />

        <FormControl fullWidth style={{marginTop:8}} >


        <TextField
          id="outlined-adornment-compare-tax-code"
          InputLabelProps={{ shrink: true }}
          label="Tax code"
          value={props.tax_code}
          onChange={(event) => {props.set_tax_code(event.target.value)}}
        />

        </FormControl>

              
            </Grid>


        </Grid>

        </Paper>


    )




}


export default React.memo(Pricing)