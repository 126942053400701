import React, {useEffect, useState, useRef} from 'react';


import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  LabelSeries,
  LineMarkSeries,
  FlexibleWidthXYPlot,
  DiscreteColorLegend,
  Hint
} from 'react-vis';


import { Card, Typography } from '@mui/material'

import '../../../../../../node_modules/react-vis/dist/style.css';
import { ONE_DAY } from '../../../../../Utilities/Metrics';



const greenData = [{x: 'JAN', y: 10} ,{x: 'FEB', y: 5}, {x: 'MAR', y: 15},{x: 'APR', y: 10},{x: 'MAY', y: 12},{x: 'JUN', y: 6},{x: 'JUL', y: 2},{x: 'AUG', y: 12},{x: 'SEP', y: 6},{x: 'OCT', y: 2}];

const blueData = [{x: 'A', y: 12}, {x: 'B', y: 2}, {x: 'C', y: 11},{x: 'D', y: 5},{x: 'E', y: 6},{x: 'F', y: 12},{x: 'G', y: 4},{x: 'H', y: 6},{x: 'I', y: 12},{x: 'J', y: 4}];

const labelData = greenData.map((d, idx) => ({
  x: d.x,
  y: Math.max(greenData[idx].y, blueData[idx].y)
}));



const ITEMS = [
  'Ads switched on',
  'Ads switched off'
];

var timestamp = new Date()


// console.log('time was: ', timestamp.getTime())

timestamp = timestamp.getTime()

//xDomain={[timestamp - 1.5 * ONE_DAY, timestamp + 0.25 * ONE_DAY]}



function BarChart(props) {

  const { classes } = props;

  const [useCanvas, setUseCanvas] = React.useState(false)

  const content = useCanvas ? 'TOGGLE TO SVG' : 'TOGGLE TO CANVAS';

  const BarSeries = useCanvas ? VerticalBarSeriesCanvas : VerticalBarSeries;

  const [chartData, setChartData] = React.useState({'blueData':[], 'greenData':[]})
  //const [greenData, setgreenData] = React.useState([])
  //const [darkblueData, darkblueData] = React.useState([])
  //var labelData = []

  const [hovered, set_hovered] = React.useState(false);

  
  useEffect(()=>{
    
    const temp_analytics = {...props.analytics}

    var tempblueData  = []

    var tempgreenData = []


    // console.log('temp arrayd was: ', temp_analytics)

    Object.keys(temp_analytics).forEach((x)=>{

      if(x === "turned_on"){
        tempblueData = temp_analytics[x]
      }
      else if(x === "turned_off"){
          tempgreenData = temp_analytics[x]
      }

    });

    // console.log('array was',{'blueData':tempblueData, 'greenData':tempgreenData })

    setChartData({'blueData':tempblueData, 'greenData':tempgreenData})
    
  },[props.analytics])

  return (

    <>

     {chartData.blueData != null && chartData.blueData.length > 1 && chartData.greenData != null && chartData.greenData.length > 1 ? <Card elevation={2} style={{padding:20,overflow:'visible'}}>
      <div style={{position:'relative'}}>

        <Typography fontSize={14}><strong>{props.analytics.account_name}</strong></Typography>

      <div style = {{position: 'absolute','right': '1%','marginTop':8,zIndex: '100'}}>
       <DiscreteColorLegend
          orientation="horizontal"
          items={ITEMS}
        />
        </div>

      <FlexibleWidthXYPlot xType="time" height={360} stackBy="y"
      xDomain={[timestamp - 1.5 * ONE_DAY, timestamp + 0.25 * ONE_DAY]}
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis></XAxis>
        <YAxis />
        <VerticalBarSeries barWidth={0.9} className="vertical-bar-series-example" data={chartData.blueData} onValueMouseOver={d => set_hovered({x: new Date(d.x), y: d.y})} onValueMouseOut={d => set_hovered(false)}/>
        <VerticalBarSeries barWidth={0.9} data={chartData.greenData} onValueMouseOver={d => set_hovered({x: new Date(d.x), y: d.y})} onValueMouseOut={d => set_hovered(false)}/> 
        {/*<LabelSeries data={labelData} getLabel={d => d.x} />*/}

        {hovered && <Hint value={hovered} />}

      </FlexibleWidthXYPlot>
      </div>
      </Card> : null }

      </>

  );

}


export default (BarChart);
