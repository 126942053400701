import React, { Component } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { getItemStyle, getoption_valueListStyle , getSubItemItemStyle } from "./utils";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { IconButton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DeleteOutline } from "@mui/icons-material";

const NestedSubList = props => {
  
  const { option, optionNum } = props;

  // console.log('props were',option, 'num',optionNum )

  const handle_sub_field_change = (optionNum, index, text) => {

    props.set_product_options((previous_options) => {

      const temp_options = [...previous_options];

      temp_options[optionNum].option_values[index].value = text;

      // console.log(temp_options);

      return(props.set_product_options(temp_options));

    });

  }


  return (
    <Droppable droppableId={`droppable${option.id}`} type={`${optionNum}`}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getoption_valueListStyle(snapshot.isDraggingOver)}
        >
          {option.option_values.map((option_value, index) => {
            return (
              <Draggable
                key={`${optionNum}${index}`}
                draggableId={`${optionNum}${index}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getSubItemItemStyle (
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                      <Box sx={{ display: 'flex', p: 1, bgcolor: 'background.paper'}} {...provided.dragHandleProps}>
                      <DragIndicatorIcon style={{ float: "left" , marginTop:'auto', marginBottom:'auto', marginRight: 8}}></DragIndicatorIcon>
                      <TextField size="small" sx={{ flexGrow: 1 }} value={props.product_options[optionNum].option_values[index].value} onChange={(event) => {handle_sub_field_change(optionNum, index, event.target.value)}}></TextField>
                      <IconButton style={{ marginTop:'auto', marginBottom:'auto', marginLeft:8}} onClick={(event) =>{

                      if(index > 0){
                        
                      const options = [...props.product_options];

                      options[optionNum].option_values.splice(index, 1);

                      // console.log('deleted',options)

                      props.set_product_options(options)

                      }

                      }}>
                      <DeleteOutline></DeleteOutline>
                      </IconButton>
                      </Box>
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default NestedSubList;
