import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, makeStyles, Select, FormControl, InputLabel, MenuItem} from '@mui/material';
import Dropzone from 'react-dropzone'

import { UserCircle as UserCircleIcon } from '../../icons/user-circle';

import styles from './GoogleSearchLinkDialog.module.css'

import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../store/cms-context';

import SyncIcon from '@mui/icons-material/Sync';


import cloneDeep from 'lodash/cloneDeep';

const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));

function GoogleSearchLinkDialog(props) {
  




  function set_link_state(key, value){
    
    const temp_state = {...props.link_dialog_state}

    temp_state[key] = value

    props.set_link_dialog_state(temp_state)
    
  }


  
  

  function handle_close(){

    const temp_state = {...props.link_dialog_state}

    temp_state.is_dialog_open = false

    props.set_link_dialog_state(temp_state)

  }

  return (
    <div>
      <Dialog
        open={props.link_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.link_dialog_state.type} Link Google Search and Ads accounts
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

            <Grid item xs={12}>

              <div style={{textAlign:'center', marginTop:16}}>

                <img src="/static/images/GoogleSearchConsole.png" alt='g_search' style={{width:80, height:80}}></img>
                <SyncIcon color="primary" style={{marginLeft:20, marginRight:20, marginBottom:16}}></SyncIcon>
                <img src="/static/images/google_ads.png" alt='g_ads' style={{width:80, height:80}}></img>

              </div>

            </Grid>

            {props.link_dialog_state.search_accounts != null ? <Grid item xs={12}>

            <FormControl sx={{ width:'100%', marginTop:2}}>

            <InputLabel id="demo-simple-select-autowidth-label">Search Account</InputLabel>

            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              fullWidth
              value={Object.keys(props.link_dialog_state.selected_search_account).length > 0 ? props.link_dialog_state.selected_search_account.siteUrl : ''}
              onChange={(event)=>{console.log('event target value: ', event.target.value); const temp_dialog_state = {...props.link_dialog_state} ;const index = temp_dialog_state.search_accounts.findIndex(account => account.siteUrl === event.target.value); console.log('found_index', index); temp_dialog_state.selected_search_account = cloneDeep(temp_dialog_state.search_accounts[index]);props.set_link_dialog_state(temp_dialog_state)}}
              label="Search Account"
            >

              {props.link_dialog_state.search_accounts.map((item, index)=> (
                <MenuItem key={index} value={item.siteUrl}>{item.siteUrl.split('sc-domain:')[1]}</MenuItem>
              ))}
              
            </Select>

            </FormControl>


            </Grid> : null}

              {Object.keys(props.link_dialog_state.selected_search_account).length > 0 ? <Grid item xs={12}>

              <FormControl sx={{ width:'100%', marginTop:2}}>

              <InputLabel id="demo-simple-select-autowidth-label">Ads Account</InputLabel>

              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                fullWidth
                value={props.link_dialog_state.selected_search_account.gads_accounts.findIndex(account => account.is_checked) > - 1 ? props.link_dialog_state.selected_search_account.gads_accounts[props.link_dialog_state.selected_search_account.gads_accounts.findIndex(account => account.is_checked)].account_id : ''}
                onChange={(event) => {console.log('value was: ', event.target.value);const temp_dialog_state = {...props.link_dialog_state};temp_dialog_state.selected_search_account.gads_accounts.forEach((account) => {if(account.account_id === event.target.value){account.is_checked = true}else{account.is_checked = false}}); set_link_state(temp_dialog_state)}}
                label="Ads Account"
              >

                {props.link_dialog_state.selected_search_account.gads_accounts.map((account, index) => (
                  <MenuItem key={index} value={account.account_id}>{account.descriptive_name}</MenuItem>
                ))}
                
              </Select>

              </FormControl>

              </Grid> : null }


            </Grid>


            
        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        <LoadingButton variant='outlined' loading={props.link_dialog_state.isLoading} disabled={false} onClick={() => {props.handle_search_gads_update(props.link_dialog_state.selected_search_account)}}>{props.link_dialog_state.action_type}</LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );




}




export default GoogleSearchLinkDialog;