import React, {useEffect,useState, useCallback, useRef, useContext } from 'react';


import {Card} from '@mui/material';


  import ReactFlow, {
    useNodesState, 
    useEdgesState,
    addEdge,
    MiniMap,
    Controls,
    Background
  } from 'reactflow';

  import 'reactflow/dist/style.css';
  import './style.css';


  import CmsContext from '../../../../../store/cms-context';

  import ContextMenu from './ContextMenu';



export default function FlowTasks(props) {

  const cmsContext = useContext(CmsContext);

  const [menu, setMenu] = useState(null);

  const ref = useRef(null);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);

  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onConnect = useCallback(
    (params) => setEdges((els) => addEdge(params, els)),
    [setEdges],
  );


  const onNodeContextMenu = useCallback(
    (event, node) => {
      // Prevent native context menu from showing
      event.preventDefault();

      // Calculate position of the context menu. We want to make sure it
      // doesn't get positioned off-screen.
      const pane = ref.current.getBoundingClientRect();
      
      setMenu({
        id: node.id,
        data: node.data,
        top: 2,
        right: 2
      });

    },
    [setMenu],
    );



    function delete_node(id){

      const temp_node = nodes.filter((node) => node.id === id)

      if(temp_node.length === 0){
       // This should never happen
       cmsContext.setSnackBarOpen(true, "error", "Invalid node selected!")
       return
      }

      setMenu(null)

      props.post_sync_task(temp_node[0].data)

    }

    const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

    useEffect(() => {

      const temp_tasks = props.all_tasks

      const temp_obj = {}

      for(var task in temp_tasks) {
        
        const temp_task = temp_tasks[task]

        if(!(temp_task.source.name in temp_obj)){

          temp_obj[temp_task.source.name] = []

        }

        temp_obj[temp_task.source.name].push(task)

      }

      var final_nodes = []

      var final_edges = []

      var total_indexes = 0

      for(var key in temp_obj){

        const index = parseInt(temp_obj[key])

        console.log("index was: ", index)

        const input_obj = {
          id: 'horizontal-'+total_indexes.toString(),
          sourcePosition: 'right',
          type: 'input',
          className: 'dark-node',
          data: {  label: temp_tasks[index].source.name, type: "source",  index: index},
          position: { x: 100, y: 80 + total_indexes * 80},
        }

        const input_id = input_obj.id

        final_nodes.push(input_obj)

        for(var task_id in temp_obj[key]){

          total_indexes = total_indexes + 1


          const temp_obj = {
            id: 'horizontal-'+total_indexes.toString(),
            sourcePosition :'right',
            targetPosition:'left',
            data: { label: temp_tasks[task_id].target.name, type: "target",  index: task_id},
            position: { x: 370, y: ((total_indexes ) * 80)},
          }

          const temp_edge = {
            id: 'horizontal-e1-'+(total_indexes + 1).toString(),
            source: input_id,
            target: 'horizontal-'+total_indexes.toString(),
            animated: true,
          }

          final_nodes.push(temp_obj)

          final_edges.push(temp_edge)

        }

        total_indexes = total_indexes + 1

      }


      setNodes(final_nodes)

      setEdges(final_edges)


    },[props.all_tasks])




  return (

    <Card style={{width:'100%'}}>
    <div style={{width:'100%', height:'400px'}}>
    <ReactFlow
      ref={ref}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onPaneClick={onPaneClick}
      selectNodesOnDrag={false}
      onNodeContextMenu={onNodeContextMenu}
    >
       <MiniMap
        nodeStrokeColor={(n) => {
          if (n.type === 'input') return '#0041d0';
          if (n.type === 'selectorNode') return '#0041d0';
          if (n.type === 'output') return '#0041d0';
        }}
        nodeColor={(n) => {
          if (n.type === 'selectorNode') return '#0041d0';
          return '#0041d0';
        }}
      />
      <Controls />
      <Background color="#aaa" gap={16} />
      {menu && <ContextMenu delete_node={delete_node}  onClick={onPaneClick} {...menu} />}
    </ReactFlow>
    </div>
    </Card>
  );

}