import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import { cloneDeep } from 'lodash';
import { default_confirm_dialog } from '../../Utilities/Metrics';

function ConfirmDialog(props) {



  const handleClose = () => {

    props.set_confirm_dialog_state(cloneDeep(default_confirm_dialog))

  };

  return (
    
      <Dialog
        fullWidth
        open={props.confirm_dialog_state.is_dialog_open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.confirm_dialog_state.title}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            {props.confirm_dialog_state.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>Dismiss</Button>
          <LoadingButton variant='outlined' loading={props.confirm_dialog_state.isLoading} onClick={() => {props.handle_confirm_dialog()}}>Confirm</LoadingButton>
        </DialogActions>
      </Dialog>

  );
}


export default ConfirmDialog;